import env from 'config';
import { CookieKey, DragDropEngine } from 'enums/cookies.enums';
import {
  DEFAULT_APP_SETTINGS,
  IAppCookie,
  TableIdentifier,
} from 'interfaces/cookies/i-app.cookie';
import { IMachineCalibrationCookie } from 'interfaces/cookies/i-machine-calibration-cookie';
import {
  DEFAULT_SESSION_UNAUTH,
  ISessionCookie,
} from 'interfaces/cookies/i-session.cookie';
import {
  DEFAULT_SNAPSHOT_COOKIE,
  ISnapshotCookie,
} from 'interfaces/cookies/i-snapshot.cookie';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SessionEventsService } from 'services/session-events.service';

const CONTEXT_NAME = 'CookiesContext';

const LOGIN_SESSION = 'login_session';

type ValidCookie =
  | Partial<IAppCookie>
  | Partial<ISnapshotCookie>
  | Partial<ISessionCookie>
  | Partial<IMachineCalibrationCookie>;

export interface ICookiesContext {
  app: IAppCookie;
  /** observed by authCx to determine when to merge credentials from previous session */
  session: ISessionCookie;

  // ! deprecate after promoting install wizard out of beta
  machineCalibration: IMachineCalibrationCookie;
  snapshot: ISnapshotCookie;

  /** helper for storing page size selections in the cookie */
  readonly setPageSize: (id: TableIdentifier, value: number) => void;
  /** helper for getting page size selections (if found) from the cookie */
  readonly getPageSize: (id: TableIdentifier) => number | undefined;
  /** expires and source are required for session cookie */
  readonly setCookie: (
    key: CookieKey,
    value: ValidCookie,
    options?: {
      expires?: Date;
      loginEvent?: boolean;
    }
  ) => Promise<boolean>;
}

const DEFAULT: ICookiesContext = {
  app: DEFAULT_APP_SETTINGS,
  session: DEFAULT_SESSION_UNAUTH,
  machineCalibration: {
    skippedPitchIDs: [],
  },
  snapshot: DEFAULT_SNAPSHOT_COOKIE,

  setPageSize: () => console.error(`${CONTEXT_NAME}: not init`),
  getPageSize: () => undefined,

  setCookie: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

/** for manipulating anything that needs to load from or save to a cookie */
export const CookiesContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const CookiesProvider: FC<IProps> = (props) => {
  const [appCookie, setAppCookie] = useCookies([CookieKey.app]);
  const [app, setApp] = useState(DEFAULT.app);

  const [sessionCookie, setSessionCookie] = useCookies([CookieKey.session]);
  const [session, setSession] = useState(DEFAULT.session);

  const [snapshotCookie, setSnapshotCookie] = useCookies([CookieKey.snapshot]);
  const [snapshot, setSnapshot] = useState(DEFAULT.snapshot);

  const [calibrationCookie, setCalibrationCookie] = useCookies([
    CookieKey.machineCalibration,
  ]);
  const [calibration, setCalibration] = useState(DEFAULT.machineCalibration);

  const [init, setInit] = useState(false);

  /** to save separate entries in the cookie for each combination of user + table identifier */
  const getSessionTableKey = (id: TableIdentifier) => {
    return `${session.email ?? 'anonymous'}-${id}`;
  };

  const state: ICookiesContext = {
    app: app,
    session: session,
    machineCalibration: calibration,
    snapshot: snapshot,

    setPageSize: (id, value) => {
      app.page_sizes[getSessionTableKey(id)] = value;
      setAppCookie(CookieKey.app, app);
    },

    getPageSize: (id) => {
      return app.page_sizes[getSessionTableKey(id)];
    },

    setCookie: (key, value, options) => {
      return new Promise<boolean>((resolve) => {
        try {
          switch (key) {
            case CookieKey.app: {
              updateApp();
              break;
            }

            case CookieKey.machineCalibration: {
              updateMachineCalibration();
              break;
            }

            case CookieKey.session: {
              updateSession();
              break;
            }

            case CookieKey.snapshot: {
              updateSnapshot();
              break;
            }

            default: {
              break;
            }
          }

          resolve(true);
        } catch (e) {
          console.error(e);
          resolve(false);
        }

        function updateSession() {
          const nextValue: ISessionCookie = {
            ...session,
            ...value,
          };

          nextValue.expires = options?.expires;
          nextValue.auth = !!nextValue.token;

          setSessionCookie(CookieKey.session, nextValue, {
            expires: options?.expires,
          });

          setSession((prev) => ({
            ...prev,
            ...nextValue,
          }));

          if (
            options?.loginEvent &&
            nextValue.session !== localStorage.getItem(LOGIN_SESSION)
          ) {
            // we need to wait for the nextValue to be applied or else the request will fail
            SessionEventsService.postEvent({
              category: 'auth',
              tags: 'login',
            }).then((res) => {
              if (res.success) {
                localStorage.setItem(LOGIN_SESSION, nextValue.session);
              }
            });
          }
        }

        function updateSnapshot() {
          const nextValue: ISnapshotCookie = {
            ...snapshot,
            ...value,
          };

          setSnapshotCookie(CookieKey.snapshot, nextValue, {
            expires: options?.expires,
          });

          setSnapshot((prev) => ({
            ...prev,
            ...nextValue,
          }));
        }

        function updateMachineCalibration() {
          const nextValue: IMachineCalibrationCookie = {
            ...calibration,
            ...value,
          };

          const typedValue = value as Partial<IMachineCalibrationCookie>;

          if (
            typedValue.start_date !== undefined &&
            typedValue.start_date !== calibration.start_date
          ) {
            // reset skipped shots whenever start_date is changing
            nextValue.skippedPitchIDs = [];
          }

          setCalibrationCookie(CookieKey.machineCalibration, nextValue);

          setCalibration((prev) => ({
            ...prev,
            ...nextValue,
          }));
        }

        function updateApp() {
          const nextValue: IAppCookie = {
            ...app,
            ...value,
          };

          setAppCookie(CookieKey.app, nextValue);

          setApp((prev) => ({
            ...prev,
            ...nextValue,
          }));
        }
      });
    },
  };

  useEffect(() => {
    /** ensures the following only runs once, on load, without tripping useEffect warnings */
    if (init) {
      return;
    }

    // console.debug('initializing cookies...');

    setInit(true);

    const defApp: IAppCookie = {
      ...app,
      ...appCookie.app,
    };

    /** fixes for invalid values from past cookies */
    if (!Object.values(DragDropEngine).includes(defApp.drag_drop_engine)) {
      defApp.drag_drop_engine = DEFAULT.app.drag_drop_engine;
    }

    setAppCookie(CookieKey.app, defApp);
    setApp(defApp);

    const defCalibration = {
      ...calibration,
      ...calibrationCookie.machineCalibration,
    };
    setCalibrationCookie(CookieKey.machineCalibration, defCalibration);
    setCalibration(defCalibration);

    const defSession: ISessionCookie = {
      ...session,
      ...sessionCookie.session,
    };

    /** only attempt to continue when token was from the same build version */
    defSession.token =
      defSession.version === env.version ? defSession.token : '';
    /** ensures auth value is set correctly when loading from cookie and not using the setter function */
    defSession.auth = !!defSession.token;

    setSessionCookie(CookieKey.session, defSession);
    setSession(defSession);

    const defSnapshot: ISnapshotCookie = {
      ...snapshot,
      ...snapshotCookie.snapshot,
    };

    setSnapshotCookie(CookieKey.snapshot, defSnapshot);
    setSnapshot(defSnapshot);
  }, []);

  return (
    <CookiesContext.Provider value={state}>
      {props.children}
    </CookiesContext.Provider>
  );
};
