import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@radix-ui/react-icons';
import { Box, Card, Flex, Heading, IconButton } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GlobalContext } from 'contexts/global.context';
import { BASEBALL_PNG_PX } from 'enums/canvas';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { clamp, numberToImperial } from 'lib_ts/classes/math.utilities';
import { MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { Component, Fragment } from 'react';
import Slider from 'react-input-slider';

interface IAxis {
  axis: 'x' | 'y';
  upIcon: JSX.Element;
  downIcon: JSX.Element;
}

const CONTROLS: IAxis[] = [
  {
    axis: 'x',
    upIcon: <ArrowRightIcon />,
    downIcon: <ArrowLeftIcon />,
  },
  {
    axis: 'y',
    upIcon: <ArrowUpIcon />,
    downIcon: <ArrowDownIcon />,
  },
];

const BOUNDARY = {
  /** governs overall boundary for slider */
  SLIDER_X: {
    MIN: -4,
    MAX: 4,
    DEFAULT: 0,
    STEP: 0.1,
  },
  /** governs selectable values within slider */
  VALID_X: MS_LIMITS.POSITION.X,
  /** governs overall boundary for slider */
  SLIDER_Y: {
    MIN: 0,
    MAX: 7.5,
    DEFAULT: 4.2,
    STEP: 0.1,
  },
  /** governs selectable values within slider */
  VALID_Y: MS_LIMITS.POSITION.Z,
};

interface IProps {
  px?: number;
  pz?: number;
  onUpdate: (pos: { px: number; pz: number }) => void;

  showControls?: boolean;
}

interface IState {
  slider_x: number;
  slider_y: number;
}

export class ReleaseView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      slider_x: props.px ?? BOUNDARY.SLIDER_X.DEFAULT,
      slider_y: props.pz ?? BOUNDARY.SLIDER_Y.DEFAULT,
    };

    this.handleChange = this.handleChange.bind(this);
    this.adjustPosition = this.adjustPosition.bind(this);
  }

  /**
   *
   * @param axis 'x' or 'y'
   * @param amount given in feet
   */
  private adjustPosition(axis: string, amount: number) {
    /** component is updating sliders, trigger parent's onUpdate with end results */
    const callback = (x: number, y: number) =>
      this.props.onUpdate({ px: x, pz: y });

    switch (axis) {
      case 'x': {
        this.setState(
          {
            slider_x: clamp(
              numberToImperial(this.state.slider_x + amount).nearestInchFt,
              BOUNDARY.VALID_X.MIN,
              BOUNDARY.VALID_X.MAX
            ),
          },
          () => callback(this.state.slider_x, this.state.slider_y)
        );
        break;
      }

      case 'y': {
        this.setState(
          {
            slider_y: clamp(
              numberToImperial(this.state.slider_y + amount).nearestInchFt,
              BOUNDARY.VALID_Y.MIN,
              BOUNDARY.VALID_Y.MAX
            ),
          },
          () => callback(this.state.slider_x, this.state.slider_y)
        );
        break;
      }

      default: {
        break;
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.px !== prevProps.px || this.props.pz !== prevProps.pz) {
      /** parent is changing the props, set sliders from props */
      this.setState({
        slider_x: this.props.px ?? BOUNDARY.SLIDER_X.DEFAULT,
        slider_y: this.props.pz ?? BOUNDARY.SLIDER_Y.DEFAULT,
      });
    }
  }

  private handleChange(pos: { x: number; y: number }) {
    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  render() {
    const summaries: {
      header: string;
      content: string;
    }[] = [
      {
        header: 'pd.release-side-short',
        content: numberToImperial(this.state.slider_x).display,
      },
      {
        header: 'pd.release-height-short',
        content: numberToImperial(this.state.slider_y).display,
      },
      {
        header: 'common.hand',
        content: t(
          this.state.slider_x > 0
            ? 'constants.pitcher-hand-lhp'
            : 'constants.pitcher-hand-rhp'
        ).toString(),
      },
    ];

    return (
      <ErrorBoundary componentName="ReleaseView">
        <Flex gap={RADIX.FLEX.GAP.SM} direction="column">
          <Box>
            <div className="slider">
              <div
                className="sizer"
                style={{
                  aspectRatio: '0.77',
                  height: '300px',
                }}
              >
                <div className="slider-wrapper">
                  <Slider
                    axis="xy"
                    xstep={BOUNDARY.SLIDER_X.STEP}
                    xmin={BOUNDARY.SLIDER_X.MIN}
                    xmax={BOUNDARY.SLIDER_X.MAX}
                    x={this.state.slider_x}
                    ystep={BOUNDARY.SLIDER_Y.STEP}
                    ymin={BOUNDARY.SLIDER_Y.MIN}
                    ymax={BOUNDARY.SLIDER_Y.MAX}
                    y={this.state.slider_y}
                    onChange={this.handleChange}
                    onDragEnd={() => {
                      /** wait for onChange to finish */
                      setTimeout(() => {
                        this.props.onUpdate({
                          px: clamp(
                            this.state.slider_x,
                            BOUNDARY.VALID_X.MIN,
                            BOUNDARY.VALID_X.MAX
                          ),
                          pz: clamp(
                            this.state.slider_y,
                            BOUNDARY.VALID_Y.MIN,
                            BOUNDARY.VALID_Y.MAX
                          ),
                        });
                      }, 100);
                    }}
                    styles={{
                      track: {
                        backgroundColor: 'rgba(0, 0, 255, 0)',
                        backgroundImage: `url(${appearanceImgPath(
                          `pitcher/${
                            this.state.slider_x < 0 ? 'rhp' : 'lhp'
                          }.png`
                        )})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%',
                      },
                      thumb: {
                        backgroundImage: 'url(/img/baseball.png)',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        boxShadow: 'none',
                        height: BASEBALL_PNG_PX,
                        width: BASEBALL_PNG_PX,
                        margin: -BASEBALL_PNG_PX / 2,
                        opacity: 1,
                      },
                    }}
                    yreverse
                  />
                </div>
              </div>
            </div>
          </Box>

          {this.props.showControls && (
            <Box>
              <Flex gap={RADIX.FLEX.GAP.SM} justify="center" align="center">
                {summaries.map((item, i) => (
                  <Card key={`summary-${i}`} style={{ minWidth: '100px' }}>
                    <Flex direction="column" align="center">
                      <Heading size={RADIX.HEADING.SIZE.SM}>
                        {t(item.header)}
                      </Heading>

                      {item.content}
                    </Flex>
                  </Card>
                ))}
              </Flex>
            </Box>
          )}

          {this.props.showControls && (
            <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
              {CONTROLS.map((item, i) => (
                <Fragment key={`control-${i}`}>
                  <Box>
                    <IconButton
                      color={RADIX.COLOR.NEUTRAL}
                      variant="soft"
                      onClick={() => this.adjustPosition(item.axis, -1 / 12)}
                    >
                      {item.downIcon}
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      color={RADIX.COLOR.NEUTRAL}
                      variant="soft"
                      onClick={() => this.adjustPosition(item.axis, 1 / 12)}
                    >
                      {item.upIcon}
                    </IconButton>
                  </Box>
                </Fragment>
              ))}
            </Flex>
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}

ReleaseView.contextType = GlobalContext;
