import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ServerListener } from 'components/main/listeners/server';
import { VideoEditorDialogHoC } from 'components/sections/video-library/video-editor';
import { AuthContext } from 'contexts/auth.context';
import {
  addMinutes,
  isFuture,
  lightFormat,
  parseISO,
  subHours,
} from 'date-fns';
import { CrudAction } from 'enums/tables';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { ContextName } from 'lib_ts/enums/machine-msg.enum';
import { PitcherHand, PitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IVideo,
  IVideoOption,
  IVideoPlayback,
} from 'lib_ts/interfaces/i-video';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { VideosService } from 'services/videos.service';

const CONTEXT_NAME = 'VideosContext';

const RECENTLY_UPLOADED_THRESHOLD_HOURS = 24;
const RECENTLY_UPLOADED_GROUP_LABEL = t('videos.recently-uploaded');

export const STATIC_PREFIX = 'videos/static/';

export const OPTION_DIVIDER_ID = '----divider----';

interface IOptionsDict {
  PitcherFullName: string[];
  _created: string[];
}

interface IFilter {
  pitcher: string[];
  type: PitchType[];
  delivery: string[];
  dateAdded: string[];
}

interface ICrudConfig {
  action: CrudAction;
  models: IVideo[];
  onClose?: () => void;
}

export interface IVideosContext {
  readonly getVideo: (id: string | undefined) => IVideo | undefined;

  staticVideos: IVideo[];
  /** unique values for each key */
  options: IOptionsDict;

  loading: boolean;

  /** for rendering the video library table */
  filteredVideos: IVideo[];
  filter: IFilter;
  readonly mergeFilter: (value: Partial<IFilter>) => void;

  readonly getCachedPlayback: (
    video_id: string
  ) => Promise<IVideoPlayback | undefined>;

  /** sorts videos by right (non-positive, including 0) or left (strictly positive) release to match sign of input px */
  readonly getVideosByReleaseSide: (
    px: number,
    recentlyUploadedFirst?: boolean
  ) => IVideoOption[];

  readonly updateVideo: (payload: Partial<IVideo>) => Promise<boolean>;

  /** clone the metadata into a new record with a new _id (e.g. for reusing the same video on two machines where timing needs to be different) */
  readonly copyVideos: (ids: string[]) => Promise<boolean>;

  readonly openCrudDialog: (config: ICrudConfig) => void;

  readonly uploadVideos: (
    isStatic: boolean,
    files: File[],
    onProgress?: (ev: ProgressEvent) => void
  ) => Promise<boolean>;

  readonly uploadVideosCSV: (files: File[]) => Promise<boolean>;

  /** uses the given attribute values as keys in a dictionary, value is the _id of the last video with the same key
   * BONUS: also creates one entry per video _id which can be used as a fallback, regardless of attribute used for key
   */
  readonly getVideosDict: (attr: keyof IVideo) => { [key: string]: string };

  // todo: deprecate if unnecessary
  readonly refresh: () => void;
}

interface IPlaybackDictionary {
  [video_id: string]: { expires: Date; playback: IVideoPlayback };
}

const PLAYBACK_DICT: IPlaybackDictionary = {};

const DEFAULT: IVideosContext = {
  getVideo: () => undefined,
  staticVideos: [],
  filteredVideos: [],
  filter: {
    pitcher: [],
    type: [],
    delivery: [],
    dateAdded: [],
  },
  mergeFilter: () => console.error(`${CONTEXT_NAME}: not init`),

  options: {
    PitcherFullName: [],
    _created: [],
  },

  loading: false,

  getCachedPlayback: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  getVideosByReleaseSide: () => [],
  openCrudDialog: () => console.error(`${CONTEXT_NAME}: not init`),
  updateVideo: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  copyVideos: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  uploadVideos: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  uploadVideosCSV: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  getVideosDict: () => ({}),
  refresh: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const VideosContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

const getOptions = (videos: IVideo[]): IOptionsDict => {
  if (videos) {
    return {
      PitcherFullName: ArrayHelper.unique(
        videos.map((m) => m.PitcherFullName as string)
      ),

      _created: ArrayHelper.unique(
        videos.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

const getVideoGroup = (v: IVideo, checkRecentlyUploaded: boolean) => {
  const created = parseISO(v._created);
  const recentlyUploadedThreshold = subHours(
    new Date(),
    RECENTLY_UPLOADED_THRESHOLD_HOURS
  );

  if (checkRecentlyUploaded && created > recentlyUploadedThreshold) {
    // "Recently Uploaded"
    return RECENTLY_UPLOADED_GROUP_LABEL;
  }

  // "RHP: John Doe"
  const side = v.ReleaseSide > 0 ? PitcherHand.LHP : PitcherHand.RHP;
  return `${side}${v.PitcherFullName ? `: ${v.PitcherFullName}` : ''}`;
};

export const VideosProvider: FC<IProps> = (props) => {
  const { current } = useContext(AuthContext);

  const [lastFetched, setLastFetched] = useState<Date>();

  /** reload data to match session access */
  useEffect(() => {
    if (!current.auth || !current.session) {
      return;
    }

    setLastFetched(new Date());
  }, [current.auth, current.session]);

  const [videos, setVideos] = useState<IVideo[]>([]);

  const staticVideos = useMemo(
    () => videos.filter((m) => m.video_path.startsWith(STATIC_PREFIX)),
    [videos]
  );

  const [filter, setFilter] = useState(DEFAULT.filter);

  const filtered = useMemo(() => {
    const output = videos
      .filter((m) => !m.video_path.startsWith(STATIC_PREFIX))
      .filter(
        (m) =>
          filter.pitcher.length === 0 ||
          filter.pitcher.includes(m.PitcherFullName as string)
      )
      .filter(
        (m) => filter.type.length === 0 || filter.type.includes(m.PitchType)
      )
      .filter(
        (m) =>
          filter.delivery.length === 0 ||
          filter.delivery.includes(m.DeliveryType as string)
      )
      .filter(
        (m) =>
          filter.dateAdded.length === 0 ||
          filter.dateAdded.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );

    return output;
  }, [filter, videos]);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const options = useMemo(() => getOptions(videos), [videos]);

  const [playbackDict, setPlaybackDict] = useState(PLAYBACK_DICT);

  const [crud, setCRUD] = useState<ICrudConfig>();
  const [dialogCRUD, setDialogCRUD] = useState<number>();

  useEffect(() => {
    if (!crud) {
      return;
    }

    setDialogCRUD(Date.now());
  }, [crud]);

  const getVideo = useCallback(
    (id: string | undefined) => videos.find((m) => m._id === id),
    [videos]
  );

  const state: IVideosContext = {
    getVideo: getVideo,
    staticVideos: staticVideos,

    options: options,
    loading: loading,

    filteredVideos: filtered,
    filter: filter,
    mergeFilter: (value) => {
      setFilter({ ...filter, ...value });
    },

    refresh: () => setLastFetched(new Date()),

    getVideosDict: (attr) => {
      const result: { [key: string]: string } = {};

      videos.forEach((v) => {
        const attrKey = `${v[attr]}`.trim();

        if (attrKey && !result[attrKey]) {
          /** only insert for first encounter */
          result[attrKey] = v._id;
        }

        result[v._id] = v._id;
      });

      return result;
    },

    getCachedPlayback: async (video_id) => {
      try {
        const existing = playbackDict[video_id];

        if (existing && isFuture(existing.expires)) {
          // just use cached values
          return existing.playback;
        }

        // load value from server and then cache it
        setLoading(true);

        const result =
          await VideosService.getInstance().getVideoPlayback(video_id);

        const nextDict: IPlaybackDictionary = {
          ...playbackDict,
          [video_id]: {
            /** set entry to expire within an hour */
            expires: addMinutes(new Date(), 55),
            playback: result,
          },
        };

        // cache for future
        setPlaybackDict(nextDict);

        return result;
      } catch (e) {
        console.error(e);
        return undefined;
      } finally {
        setLoading(false);
      }
    },

    getVideosByReleaseSide: (px, recentlyUploadedFirst) => {
      const safeVideos = videos
        .filter((v) => !v.video_path.startsWith(STATIC_PREFIX))
        .filter((v) => VideoHelper.getFatalErrors(v).length === 0);

      const result: IVideoOption[] = safeVideos
        .filter((v) => {
          // Only show videos that match the release side
          if (px > 0) {
            return v.ReleaseSide > 0;
          }

          return v.ReleaseSide < 0;
        })
        .sort((a, b) => {
          if (px > 0) {
            // higher px first
            return a.ReleaseSide > b.ReleaseSide ? -1 : 1;
          }

          // lower px first
          return a.ReleaseSide > b.ReleaseSide ? 1 : -1;
        })
        .map((v) => {
          const safeLabel = (() => {
            if (v.VideoTitle && v.VideoTitle.trim().length > 0) {
              return v.VideoTitle;
            }

            return v.VideoFileName ?? 'Untitled';
          })();

          const o: IVideoOption = {
            ...v,
            label: safeLabel,
            value: v._id,
            group: getVideoGroup(v, !!recentlyUploadedFirst),
          };

          return o;
        });

      if (recentlyUploadedFirst) {
        result.sort((a, b) => {
          const aIsRecentlyUploaded = a.group === RECENTLY_UPLOADED_GROUP_LABEL;
          const bIsRecentlyUploaded = b.group === RECENTLY_UPLOADED_GROUP_LABEL;

          // Sort recently uploaded videos by created date
          if (
            aIsRecentlyUploaded &&
            bIsRecentlyUploaded &&
            !!a._created &&
            !!b._created
          ) {
            return a._created < b._created ? 1 : -1;
          }

          if (aIsRecentlyUploaded) {
            return -1;
          }

          return 1;
        });
      }

      return result;
    },

    openCrudDialog: setCRUD,

    updateVideo: async (payload) => {
      try {
        setLoading(true);

        const result = await VideosService.getInstance().putVideo(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const uVideo = result.data as IVideo;
        const uVideos = [...videos.filter((v) => v._id !== uVideo._id), uVideo];

        setVideos(uVideos);

        NotifyHelper.success({
          message_md: t('common.x-updated-successfully', {
            x: t('videos.video'),
          }),
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    copyVideos: async (ids) => {
      try {
        setLoading(true);

        const result = await VideosService.getInstance().copyVideos(ids);

        if (!result.success) {
          throw new Error(result.error);
        }

        /** append to end */
        const newVideos = result.data as IVideo[];
        setVideos((prev) => [...prev, ...newVideos]);

        NotifyHelper.success({
          message_md: t('common.x-copied-successfully', {
            x: t(ids.length === 1 ? 'videos.video' : 'videos.videos'),
          }),
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    uploadVideos: async (isStatic, files, onProgress) => {
      try {
        setLoading(true);

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('videos', f);
        });

        const result = await VideosService.getInstance().postVideos(
          isStatic,
          formData,
          onProgress
        );
        const processed = result.reports.filter((f) => !f.skipped);

        if (processed.length > 0) {
          NotifyHelper.success({
            message_md:
              processed.length > 1
                ? `Successfully uploaded ${processed.length} videos!`
                : 'Successfully uploaded one video!',
          });
        }

        const skipped = result.reports.filter((f) => f.skipped);

        if (skipped.length > 0) {
          console.warn({
            event: `Skipped ${skipped.length} video file(s)`,
            skipped,
          });

          NotifyHelper.warning({
            message_md: `${skipped.length} ${
              skipped.length === 1 ? 'video' : 'videos'
            } could not be processed, see console for details.`,
          });
        }

        /** triggers context to refresh videos list */
        setLastFetched(new Date());

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    uploadVideosCSV: async (files) => {
      try {
        setLoading(true);

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('files', f);
        });

        await VideosService.getInstance().importCSV(formData);
        /** triggers context to refresh videos list */
        setLastFetched(new Date());

        return true;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        setLoading(false);
      }
    },
  };

  /** reload the data whenever _lastFetched changes */
  useEffect(() => {
    if (!lastFetched) {
      return;
    }

    const callback = async (): Promise<void> => {
      try {
        setLoading(true);
        const results = await VideosService.getInstance().getVideos();
        setVideos(results);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    callback();
  }, [lastFetched]);

  return (
    <VideosContext.Provider value={state}>
      {props.children}

      <ServerListener
        listenFor={[ContextName.Videos]}
        callback={() => setLastFetched(new Date())}
      />

      {dialogCRUD &&
        crud &&
        crud.action === CrudAction.Update &&
        crud.models.length > 0 && (
          <VideoEditorDialogHoC
            key={dialogCRUD}
            video_id={crud.models[0]._id}
            onClose={() => {
              setDialogCRUD(undefined);
              crud.onClose?.();
            }}
          />
        )}

      {dialogCRUD &&
        crud &&
        crud.action === CrudAction.Delete &&
        crud.models.length > 0 && (
          <CommonConfirmationDialog
            key={dialogCRUD}
            identifier="VideoLibraryDeleteDialog"
            maxWidth={RADIX.DIALOG.WIDTH.MD}
            title={t('common.delete-x', {
              x: t(crud.models.length === 1 ? 'videos.video' : 'videos.videos'),
            }).toString()}
            content={
              <Box>
                <p>
                  {t('common.confirm-remove-n-x', {
                    n: crud.models.length,
                    x: t(
                      crud.models.length === 1
                        ? 'videos.video'
                        : 'videos.videos'
                    ),
                  })}
                </p>
                <ul>
                  {crud.models.map((v) => (
                    <li key={`del-video-${v._id}`}>
                      {v.VideoTitle || v.VideoFileName}
                    </li>
                  ))}
                </ul>
                <p>{t('videos.orphaned-pitches-warning')}</p>
              </Box>
            }
            action={{
              label: 'common.delete',
              color: RADIX.COLOR.DANGER,
              onClick: async () => {
                try {
                  setLoading(true);

                  const safeIDs = crud.models
                    .filter((v) => !v.video_path.startsWith(STATIC_PREFIX))
                    .map((v) => v._id);

                  const result =
                    await VideosService.getInstance().deleteVideos(safeIDs);

                  if (!result.success) {
                    throw new Error(result.error);
                  }

                  setDialogCRUD(undefined);
                  crud.onClose?.();

                  NotifyHelper.success({
                    message_md: t('common.x-deleted-successfully', {
                      x:
                        safeIDs.length === 1
                          ? t('videos.video')
                          : t('videos.videos'),
                    }),
                  });

                  setTimeout(() => {
                    /** remove video from context */
                    const remainingVideos = videos.filter(
                      (v) => !safeIDs.includes(v._id)
                    );
                    setVideos(remainingVideos);
                  }, 500);

                  return true;
                } catch (e) {
                  console.error(e);

                  NotifyHelper.error({
                    message_md: t('common.request-failed-msg'),
                  });

                  return false;
                } finally {
                  setLoading(false);
                }
              },
            }}
          />
        )}
    </VideosContext.Provider>
  );
};
