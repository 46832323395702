import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Blockquote,
  Code,
  DataList,
  Flex,
  IconButton,
  Skeleton,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { ScrollingToggle } from 'components/common/details/scrolling';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IServerError } from 'lib_ts/interfaces/i-server-error';
import { useEffect, useMemo, useState } from 'react';
import { ServerErrorsService } from 'services/admin/server-errors.service';

interface IProps extends IBaseDialog {
  id: string;
}

export const ServerErrorDetailsDialog = (props: IProps) => {
  const [error, setError] = useState<IServerError>();

  useEffect(() => {
    ServerErrorsService.getInstance()
      .getById(props.id)
      .then((result) => setError(result));
  }, [props.id]);

  const content = useMemo(() => {
    if (!error) {
      return <Skeleton height="200px" />;
    }

    return (
      <DataList.Root orientation="horizontal">
        <DataList.Item>
          <DataList.Label>ID</DataList.Label>
          <DataList.Value>
            <Flex gap="2" align="center" style={{ width: '100%' }}>
              <Code>{error._id}</Code>

              <IconButton
                className="btn-floating"
                color={RADIX.COLOR.NEUTRAL}
                size="1"
                variant="soft"
                onClick={() => {
                  if (!error._id) {
                    return;
                  }

                  navigator.clipboard.writeText(error._id);

                  NotifyHelper.success({
                    message_md: t('common.x-copied-to-clipboard', {
                      x: error._id,
                    }),
                  });
                }}
              >
                <ClipboardCopyIcon />
              </IconButton>
            </Flex>
          </DataList.Value>
        </DataList.Item>
        <DataList.Item>
          <DataList.Label>Created</DataList.Label>
          <DataList.Value>
            {format(parseISO(error._created), LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}
          </DataList.Value>
        </DataList.Item>

        <DataList.Item>
          <DataList.Label>Category</DataList.Label>
          <DataList.Value>
            {StringHelper.capitalize(error.category)}
          </DataList.Value>
        </DataList.Item>

        <DataList.Item>
          <DataList.Label>Level</DataList.Label>
          <DataList.Value>
            <Badge color={NotifyHelper.getColorFromLevel(error.level)}>
              {error.level.toUpperCase()}
            </Badge>
          </DataList.Value>
        </DataList.Item>

        <DataList.Item>
          <DataList.Label>Summary</DataList.Label>
          <DataList.Value>
            <Blockquote>{error.summary}</Blockquote>
          </DataList.Value>
        </DataList.Item>

        {error.url && (
          <DataList.Item>
            <DataList.Label>URL</DataList.Label>
            <DataList.Value>
              <Code>{error.url}</Code>
            </DataList.Value>
          </DataList.Item>
        )}

        {error.traceback && (
          <DataList.Item>
            <DataList.Label>Traceback</DataList.Label>
            <DataList.Value>
              <ScrollingToggle content={error.traceback} defaultOpen />
            </DataList.Value>
          </DataList.Item>
        )}

        {error.data && (
          <DataList.Item>
            <DataList.Label>Data</DataList.Label>
            <DataList.Value>
              <ScrollingToggle content={JSON.stringify(error.data, null, 2)} />
            </DataList.Value>
          </DataList.Item>
        )}
      </DataList.Root>
    );
  }, [error]);

  return (
    <ErrorBoundary componentName="ViewServerErrorDialog">
      <CommonConfirmationDialog
        key={props.id}
        maxWidth={RADIX.DIALOG.WIDTH.LG}
        identifier="ServerErrorDetails"
        title="Details"
        action={{
          invisible: true,
        }}
        cancel={{
          label: 'common.close',
          onClick: props.onClose,
        }}
        content={content}
      />
    </ErrorBoundary>
  );
};
