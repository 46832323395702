import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { Box, Flex, IconButton, Text } from '@radix-ui/themes';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { CustomIcon } from 'components/common/custom-icon';
import { ErrorBoundary } from 'components/common/error-boundary';
import { LottieFolder } from 'components/common/lottie/folder';
import { CommonMenu } from 'components/common/menu';
import { PitchList } from 'components/main/sidebar/pitch-lists/full-menu/pitch-list';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { CustomIconPath } from 'enums/custom.enums';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useState } from 'react';

const USE_LOTTIE = true;

interface IProps {
  folder: ISidebarFolder;
  getFolderActions: (folder: ISidebarFolder) => IMenuAction[];
}

export const Folder = (props: IProps) => {
  const { collapseKey, sidebarFilterKeys } = useContext(PitchListsContext);
  const { machine } = useContext(MachineContext);

  const [openDict, setOpenDict] = useState<{
    [index: string]: boolean | undefined;
  }>({});

  useEffect(() => setOpenDict({}), [collapseKey]);

  const { folders, files } = props.folder;

  // auto-expand this folder if it contains a match
  useEffect(() => {
    if (sidebarFilterKeys.length === 0) {
      // do nothing
      return;
    }

    if (folders.length === 0) {
      // do nothing
      return;
    }

    const nextOpen = { ...openDict };

    folders.forEach((f) => {
      if (nextOpen[f.pathEnd]) {
        // already open, do nothing
        return;
      }

      // open it
      nextOpen[f.pathEnd] = true;
    });

    setOpenDict(nextOpen);
  }, [folders, sidebarFilterKeys]);

  if (folders.length + files.length === 0) {
    return <></>;
  }

  return (
    <ErrorBoundary componentName="PitchListFolder">
      <Flex data-identifier="SidebarFolder" direction="column">
        {files.map((file, iFile) => (
          <PitchList key={iFile} file={file} />
        ))}

        {folders.map((subFolder, iSub) => (
          <Box key={iSub}>
            <details
              className="FolderDetails"
              onClick={(e) => {
                // prevent infinite redraws
                e.preventDefault();
                // prevent triggering parent details
                e.stopPropagation();
                // toggle the flag for this particular index
                const nextValue = !openDict[subFolder.pathEnd];
                setOpenDict({
                  ...openDict,
                  [subFolder.pathEnd]: nextValue,
                });
              }}
              open={!!openDict[subFolder.pathEnd]}
            >
              <summary>
                <Flex className="Entry" gap="2" p="2" pr="0" align="center">
                  <Box className="FolderIcon">
                    {USE_LOTTIE ? (
                      <LottieFolder open={!!openDict[subFolder.pathEnd]} />
                    ) : (
                      <CustomIcon
                        icon={
                          openDict[subFolder.pathEnd]
                            ? CustomIconPath.FolderOpen
                            : CustomIconPath.FolderClosed
                        }
                      />
                    )}
                  </Box>
                  <Box
                    flexGrow="1"
                    className="cursor-pointer"
                    title={subFolder.pathDisplay}
                    minWidth="0"
                  >
                    <Text as="div" truncate>
                      {SidebarHelper.getFolderName(
                        subFolder,
                        machine.machineID
                      )}
                    </Text>
                  </Box>

                  <Box>
                    <CommonMenu
                      title={'Manage Folder'}
                      actions={props.getFolderActions(subFolder)}
                      trigger={
                        <IconButton
                          mr="2"
                          className="btn-floating"
                          variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                          size="1"
                          color={RADIX.COLOR.NEUTRAL}
                        >
                          <DotsVerticalIcon />
                        </IconButton>
                      }
                      skipSort
                    />
                  </Box>
                </Flex>
              </summary>

              {/* indent all sub-folders */}
              <Box pl="2">
                <Folder {...props} folder={subFolder} />
              </Box>
            </details>
          </Box>
        ))}
      </Flex>
    </ErrorBoundary>
  );
};
