import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MachineContext } from 'contexts/machine.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { addMilliseconds } from 'date-fns';
import { ResetPlateMode } from 'enums/machine.enums';
import {
  IAimingBaseResult,
  IAimingRequest,
  IAimingResult,
} from 'interfaces/i-pitch-aiming';
import { ISendConfig } from 'interfaces/i-pitch-list';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { StaticVideoType } from 'lib_ts/enums/machine-msg.enum';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { DEFAULT_PLATE, IPitch, IPlateLoc } from 'lib_ts/interfaces/pitches';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const CONTEXT_NAME = 'AimingContext';
const CHECK_ASSERTS = false;

export interface IAimingContext {
  lastChanged: number;

  pitch: IPitch | undefined;

  // provide sendConfig to use while outside of training (e.g. queue context needs to change pitch and auto-send to machine)
  readonly setPitch: (
    pitch: IPitch | undefined,
    options?: {
      // force the plate to match the pitch's traj plate location
      resetPlate?: ResetPlateMode;
      // provide to auto-send the new pitch (with saved location) to machine
      sendConfig?: ISendConfig;
    }
  ) => Promise<void>;

  // for plate view to reference
  plate: IPlateLoc;

  readonly setPlate: (loc: IPlateLoc) => void;

  // uses the refPitch and refPlate
  readonly getAimed: (config: IAimingRequest) => IAimingResult | undefined;

  requiresSend: boolean;

  // set this to slightly in the future when sending to machine so fire button immediately shows loading until after
  loadingUntil: Date;

  // e.g. auto-fire toggle automatically updates last MS hash, aiming should not require a resend
  readonly setSentHash: (value: string | undefined) => void;
}

const DEFAULT: IAimingContext = {
  lastChanged: Date.now(),

  pitch: undefined,
  setPitch: () => new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  plate: DEFAULT_PLATE,
  setPlate: () => console.error(`${CONTEXT_NAME}: not init`),

  getAimed: () => undefined,

  requiresSend: true,

  loadingUntil: new Date(),

  setSentHash: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const AimingContext = createContext(DEFAULT);

interface IProps {
  // when provided, every shot update query will include the value for newerThan (e.g. when training)
  newerThan?: string;

  children: ReactNode;
}

export const AimingProvider: FC<IProps> = (props) => {
  const {
    lastMSHash,
    machine,
    requireRapsodoConfidence,
    sendPitchPreview,
    sendTarget,
  } = useContext(MachineContext);

  const { getVideo } = useContext(VideosContext);

  const [refPitch, setRefPitch] = useState(DEFAULT.pitch);
  const [targetPlate, setTargetPlate] = useState<IPlateLoc>(DEFAULT.plate);

  const [sentHash, setSentHash] = useState<string | undefined>();

  const lastChangedSentHash = useMemo(() => Date.now(), [sentHash]);

  const [loadingUntil, setLoadingUntil] = useState(DEFAULT.loadingUntil);

  // bare minimum with pitch and ms aimed at target
  const getAimedBase = (
    pitch: IPitch,
    config: IAimingRequest
  ): IAimingBaseResult | undefined => {
    const FN_NAME = `${CONTEXT_NAME} > _getAimedBase`;

    if (!pitch) {
      return;
    }

    const { ms } = getMSFromMSDict(pitch, machine);
    if (!ms) {
      console.warn(`${FN_NAME}: no ms found`);
      return;
    }

    if (!pitch.bs || !ms || !pitch.traj) {
      /** should not trigger, but will be filtered out to be safe */
      console.warn({
        event: `${FN_NAME}: cannot rotate pitch, invalid data detected`,
        pitch: pitch,
        invalid_bs: !pitch.bs,
        invalid_ms: !ms,
        invalid_traj: !pitch.traj,
      });

      NotifyHelper.warning({
        message_md: `Data for pitch "${pitch.name}" is invalid. Please see console for more info.`,
        delay_ms: 0,
      });

      return;
    }

    const chars = AimingHelper.aimWithShots({
      source: FN_NAME,
      chars: {
        bs: pitch.bs,
        ms: ms,
        traj: pitch.traj,

        seams: pitch.seams,
        breaks: pitch.breaks,
        priority: pitch.priority ?? BuildPriority.Spins,
      },
      release: pitch.bs,
      plate_location: targetPlate,
      plate_distance: machine.plate_distance,
      shots: config.usingShots,
      stepSize: config?.stepSize,
    });

    const plate = TrajHelper.getPlateLoc(chars.traj);

    if (CHECK_ASSERTS) {
      AimingHelper.checkAssertions({
        chars: chars,
        plate: plate,
        refPlate: targetPlate,
      });
    }

    const result: IPitch = {
      ...pitch,
      bs: chars.bs,
      traj: chars.traj,
      msDict: getMergedMSDict(machine, [chars.ms], pitch.msDict),
    };

    return {
      pitch: result,
      ms: chars.ms,
      usingShots: config.usingShots,
    };
  };

  // adds video fallback and ball type to create MS msg
  const getAimed = (
    pitch: IPitch,
    config: IAimingRequest
  ): IAimingResult | undefined => {
    if (!pitch) {
      console.debug(`cannot get aimed without a base pitch`);
      return undefined;
    }

    const safeShots = config.usingShots.filter((s) => {
      if (!requireRapsodoConfidence) {
        return true;
      }

      if (!s.confidence) {
        return false;
      }

      const { confidence: c } = s;

      const cFlags = {
        speed: c.PITCH_SpeedConfidence === 1,
        spin: c.PITCH_SpinConfidence === 1,
        strikezone:
          c.PITCH_StrikePositionHeightConfidence === 1 &&
          c.PITCH_StrikePositionSideConfidence === 1,
      };

      switch (pitch.priority) {
        case BuildPriority.Breaks: {
          return cFlags.speed && cFlags.strikezone;
        }

        case BuildPriority.Spins:
        case BuildPriority.Default:
        default: {
          return cFlags.speed && cFlags.spin && cFlags.strikezone;
        }
      }
    });

    const base = getAimedBase(pitch, {
      training: !!config.training,
      stepSize: config.stepSize,
      usingShots: safeShots,
    });

    if (!base) {
      console.debug(`failed to get aimed base`);
      return undefined;
    }

    const safeVideoID = (() => {
      if (config.training) {
        return StaticVideoType.training_short;
      }

      // ensures the referenced video is still visible to this user
      const pitchVideo = getVideo(base.pitch.video_id);

      if (pitchVideo) {
        return pitchVideo._id;
      }

      const isLHP = base.pitch.bs.px > 0;

      // note: default videos may get deleted; getVideo w/ fallback handles if it doesn't exist anymore
      const defaultVideo = getVideo(
        isLHP ? machine.default_video_lhp : machine.default_video_rhp
      );

      if (defaultVideo) {
        return defaultVideo._id;
      }

      // final fallback if nothing was found
      return isLHP ? StaticVideoType.default_LHP : StaticVideoType.default_RHP;
    })();

    const msg: IMachineStateMsg = {
      ...base.ms,
      ball_type: machine.ball_type,
      video_uuid: safeVideoID,
      training: !!config.training,
      pitch_info: {
        target_traj: base.pitch.traj,
      },
    };

    const output: IAimingResult = {
      ...base,
      msg: msg,
      msgHash: MiscHelper.hashify(msg),
    };

    // console.debug('got aiming result', output);

    return output;
  };

  const requiresSend = useMemo(
    () => !sentHash || sentHash !== lastMSHash,
    [sentHash, lastMSHash]
  );

  const state: IAimingContext = {
    lastChanged: lastChangedSentHash,

    pitch: refPitch,
    setPitch: async (pitch, options) => {
      // forces resend to machine
      setSentHash(undefined);
      setRefPitch(pitch);

      // follow-up actions are only relevant for actual pitches
      if (!pitch) {
        return;
      }

      if (options?.resetPlate) {
        const trajPlate = TrajHelper.getPlateLoc(pitch.traj);

        switch (options.resetPlate) {
          case ResetPlateMode.Default: {
            setTargetPlate(DEFAULT_PLATE);
            break;
          }

          case ResetPlateMode.PitchBackup: {
            setTargetPlate(pitch.plate_loc_backup ?? trajPlate);
            break;
          }

          case ResetPlateMode.PitchTraj: {
            setTargetPlate(trajPlate);
            break;
          }

          default: {
            break;
          }
        }
      }

      if (!options) {
        setSentHash(undefined);
        return;
      }

      if (!options.sendConfig) {
        setSentHash(undefined);
        return;
      }

      const {
        aiming,
        collectionID,
        hitter,
        list,
        skipFiringCheck,
        skipPreview,
        training,
        trigger,
        usingShots,
        onSuccess,
      } = options.sendConfig;

      // todo: this should be revisited and simplified if/when we get rid of cont. training
      const isTrained =
        // training dialog > finished training (won't necessarily be present with continuous training enabled)
        usingShots.findIndex((s) => s.training_complete) !== -1 ||
        // enough total shots (during and after training) to satisfy threshold
        usingShots.length >= machine.training_threshold;

      // * FOR SAFETY - never allow sending a non-training pitch to machine unless there is "sufficient" training data
      if (!training && !isTrained) {
        NotifyHelper.warning({
          message_md:
            'Insufficient training data found for sending the pitch, please try again.',
        });
        setSentHash(undefined);
        return;
      }

      // * start of S2M logic

      const aimed = getAimed(pitch, {
        training: training,
        usingShots: usingShots,
      });

      if (!aimed) {
        console.debug('cannot S2M without an aimed pitch');
        return;
      }

      const video = training ? undefined : getVideo(aimed.msg.video_uuid);

      if (!skipPreview) {
        sendPitchPreview({
          trigger: trigger,
          current: aimed.pitch,
        });
      }

      // console.debug(`aiming context asked to S2M`, aimed);

      const result = await sendTarget({
        source: `${CONTEXT_NAME} using ${aimed.usingShots.length} shots`,
        msMsg: aimed.msg,
        pitch: aimed.pitch,
        video: video,
        plate: targetPlate,
        collectionID: collectionID,
        aiming: aiming,
        list: list,
        hitter_id: hitter?._id,
        trigger: trigger,
        skipFiringCheck: skipFiringCheck,
      });

      if (result.success) {
        setLoadingUntil(addMilliseconds(new Date(), 500));
        setSentHash(result.hash);
      }

      onSuccess?.(result.success);
    },

    plate: targetPlate,
    setPlate: (loc) => {
      // forces resend to machine
      setSentHash(undefined);
      setTargetPlate(loc);
    },

    getAimed: (options) => (refPitch ? getAimed(refPitch, options) : undefined),

    requiresSend: requiresSend,

    loadingUntil: loadingUntil,

    setSentHash: setSentHash,
  };

  return (
    <AimingContext.Provider value={state}>
      {props.children}
    </AimingContext.Provider>
  );
};
