import { BreakCanvas } from 'classes/break-canvas';
import { CanvasHelper } from 'classes/helpers/canvas.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DOT_SIZE_MD, DOT_SIZE_SM } from 'enums/canvas';
import { isAppearanceDark } from 'index';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { ITrajektRefBreak } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import Slider from 'react-input-slider';

const COMPONENT_NAME = 'BreakView';

// how long to wait for all canvas changes before updating img src
const REDRAW_DEQUEUE_MS = 500;

interface IProps {
  actual_x_in: number;
  actual_z_in: number;

  target_x_in: number;
  target_z_in: number;

  shots: IMachineShot[];
  newShotID?: string;

  // not used if disabled
  onUpdate?: (result: ITrajektRefBreak) => void;

  sliderStyle?: CSSProperties;
  sizerStyle?: CSSProperties;

  overriding?: boolean;
  disabled?: boolean;

  // e.g. causes breaks to be rendered larger with index labels
  training?: boolean;
}

export const BreakView = (props: IProps) => {
  const [canvasDef] = useState(BreakCanvas.makeSquare(isAppearanceDark()));

  // what is used for assembly of the results
  const mergeCanvas = useRef<HTMLCanvasElement>(null);
  const rulersCanvas = useRef<HTMLCanvasElement>(null);
  const targetCanvas = useRef<HTMLCanvasElement>(null);
  const shotsCanvas = useRef<HTMLCanvasElement>(null);

  // what gets seen by users + LogRocket
  const [imgSrc, setImgSrc] = useState<string>();
  // for dequeuing updates to the imgSrc
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const updateTimeout = useRef<NodeJS.Timeout>();

  // aggregate updates from various canvases before updating imgSrc
  useEffect(() => {
    updateTimeout.current = setTimeout(async () => {
      console.debug(`${COMPONENT_NAME}: update imgSrc`);

      const canvas = mergeCanvas.current;

      if (!canvas) {
        return;
      }

      // all the canvases that need to be combined should go here
      const sources = [
        rulersCanvas.current,
        targetCanvas.current,
        shotsCanvas.current,
      ].filter((c) => c) as HTMLCanvasElement[];

      const src = await CanvasHelper.combine({
        sources: sources,
        target: canvas,
      });

      setImgSrc(src);
    }, REDRAW_DEQUEUE_MS);

    return () => clearTimeout(updateTimeout.current);
  }, [lastUpdate]);

  const [slider, setSlider] = useState<ITrajektRefBreak>({
    xInches: props.target_x_in,
    zInches: props.target_z_in,
  });

  // drawMain
  useEffect(() => {
    try {
      console.debug(`${COMPONENT_NAME}: draw rulers`);

      const canvas = rulersCanvas.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      canvasDef.drawRulers(ctx);
    } catch (e) {
      console.error(e);
    } finally {
      setLastUpdate(Date.now());
    }
  }, []);

  // drawTarget
  useEffect(() => {
    const callback = async () => {
      try {
        console.debug(`${COMPONENT_NAME}: draw target`);

        const canvas = targetCanvas.current;
        if (!canvas) {
          return;
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        await canvasDef.drawTarget(ctx, {
          xInches: slider.xInches,
          zInches: slider.zInches,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLastUpdate(Date.now());
      }
    };

    callback();
  }, [slider]);

  // drawBreaks
  useEffect(() => {
    try {
      console.debug(`${COMPONENT_NAME}: draw break data points`);

      const canvas = shotsCanvas.current;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (props.overriding) {
        // only draw one green dot for the actual override
        canvasDef.drawBreak(
          ctx,
          {
            xInches: props.actual_x_in,
            zInches: props.actual_z_in,
          },
          {
            isNew: false,
            size: DOT_SIZE_SM,
            index: 0,
          }
        );
        return;
      }

      // draw a dot for each shot w/ breaks, newest last
      const sortedShots = props.shots.sort((a, b) =>
        a._created.localeCompare(b._created)
      );

      sortedShots.forEach((s, i) => {
        const isNew = props.newShotID === s._id;

        const breaks = TrajHelper.getBreaksFromShot(s);

        if (props.training) {
          canvasDef.drawTrainingBreak(
            ctx,
            {
              xInches: -1 * breaks.xInches,
              zInches: breaks.zInches,
            },
            {
              isNew: isNew,
              size: DOT_SIZE_MD,
              index: i + 1,
            }
          );
          return;
        }

        canvasDef.drawBreak(
          ctx,
          {
            xInches: -1 * breaks.xInches,
            zInches: breaks.zInches,
          },
          {
            isNew: isNew,
            size: DOT_SIZE_SM,
            index: i + 1,
          }
        );
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLastUpdate(Date.now());
    }
  }, [
    props.shots,
    props.overriding,
    props.actual_x_in,
    props.actual_z_in,
    slider,
  ]);

  // trigger 1x on mount, then only within onDragEnd to avoid spamming
  useEffect(() => {
    console.debug(`${COMPONENT_NAME}: attempting onUpdate on mount`);
    props.onUpdate?.(slider);
  }, []);

  // targetChanged
  useEffect(() => {
    console.debug(`${COMPONENT_NAME}: target changed`);

    setSlider({
      xInches: props.target_x_in,
      zInches: props.target_z_in,
    });
  }, [props.target_x_in, props.target_z_in]);

  return (
    <ErrorBoundary componentName="BreakView">
      <div className="slider" style={props.sliderStyle}>
        <div
          className="sizer"
          style={
            props.sizerStyle ?? {
              aspectRatio: '1.00',
              maxHeight: '400px',
              minHeight: '100px',
            }
          }
        >
          {/* for log rocket recordings to show the results */}
          {imgSrc && <img className="image-wrapper" src={imgSrc} />}
          <canvas
            ref={mergeCanvas}
            width={canvasDef.CONFIG.canvas.width_px}
            height={canvasDef.CONFIG.canvas.height_px}
          />
          <canvas
            ref={rulersCanvas}
            data-identifier="main-canvas"
            width={canvasDef.CONFIG.canvas.width_px}
            height={canvasDef.CONFIG.canvas.height_px}
          />
          <canvas
            ref={targetCanvas}
            data-identifier="target-canvas"
            width={canvasDef.CONFIG.canvas.width_px}
            height={canvasDef.CONFIG.canvas.height_px}
          />
          <canvas
            ref={shotsCanvas}
            data-identifier="shots-canvas"
            width={canvasDef.CONFIG.canvas.width_px}
            height={canvasDef.CONFIG.canvas.height_px}
          />
          <div
            className={StringHelper.classNames([
              'slider-wrapper',
              props.disabled ? undefined : 'animate-fade',
            ])}
          >
            <Slider
              data-testid="BreakViewSlider"
              data-xcord={slider.xInches}
              data-ycord={slider.zInches}
              axis="xy"
              xstep={canvasDef.CONFIG.x.step}
              xmin={canvasDef.CONFIG.x.min_in}
              xmax={canvasDef.CONFIG.x.max_in}
              x={slider.xInches}
              ystep={canvasDef.CONFIG.y.step}
              ymin={canvasDef.CONFIG.y.min_in}
              ymax={canvasDef.CONFIG.y.max_in}
              y={slider.zInches}
              disabled={props.disabled}
              onChange={(values) =>
                setSlider({
                  xInches: values.x,
                  zInches: values.y,
                })
              }
              onDragEnd={() => {
                props.onUpdate?.(slider);
              }}
              styles={{
                track: {
                  display: 'none',
                  backgroundColor: 'rgba(0, 0, 255, 0)',
                  width: '100%',
                  height: '100%',
                },
                thumb: {
                  display: props.disabled ? 'none' : undefined,
                  backgroundColor: 'rgba(0, 255, 0, 0)',
                  backgroundImage: 'url(/img/crosshair.svg)',
                  backgroundSize: 'cover',
                  boxShadow: 'none',
                  height: 32,
                  width: 32,
                  margin: '-16px',
                },
              }}
              yreverse
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
