import { TrashIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { AnalyticsHeader } from 'components/sections/analytics/header';
import { HittingReportsToolbar } from 'components/sections/analytics/hitting-reports/toolbar';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { lightFormat } from 'date-fns';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  ISessionHitDeleteRequest,
  ISessionHitSummary,
} from 'lib_ts/interfaces/i-session-hit';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const IDENTIFIER = TableIdentifier.HittingReports;
const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export const HittingReports = () => {
  const { tryChangeSection } = useContext(SectionsContext);

  const { machine } = useContext(MachineContext);

  // machineSessions fetched by Analytics parent component
  const {
    sessions,
    batterFilter,
    startDateFilter,
    loading,
    fetchSessions,
    deleteSessions,
  } = useAnalyticsStore();

  // reload at mount (e.g. in case something was excluded for a specific session) and whenever switching machines
  useEffect(() => {
    fetchSessions({
      machineID: machine.machineID,
    });
  }, [machine.machineID]);

  const filteredSessions = useMemo(() => {
    return sessions
      .filter(
        (session) =>
          batterFilter.length === 0 || batterFilter.includes(session.hitterID)
      )
      .filter(
        (session) =>
          startDateFilter.length === 0 ||
          startDateFilter.includes(
            lightFormat(parseISO(session.startDate), 'yyyy-MM-dd')
          )
      );
  }, [sessions, batterFilter, startDateFilter]);

  const [dialogDelete, setDialogDelete] = useState<{
    key: number;
    models: ISessionHitSummary[];
  }>();

  const columns = useMemo(() => {
    const output: ITableColumn[] = [
      {
        label: 'common.hitter-name',
        key: 'hitterName',
        formatFn: ({ hitterName }: ISessionHitSummary) => hitterName ?? '-',
      },
      {
        invisible: true,
        label: 'common.level',
        key: 'hitterLevel',
        formatFn: ({ hitterLevel }: ISessionHitSummary) => hitterLevel ?? '-',
      },
      {
        label: 'common.shots',
        key: 'shots',
        align: 'right',
        formatFn: ({ shots }: ISessionHitSummary) => shots.toFixed(0),
      },
      {
        label: 'common.average-ev',
        subLabel: 'mph',
        key: 'avgExitMPH',
        align: 'right',
        formatFn: ({ avgExitMPH }: ISessionHitSummary) => avgExitMPH.toFixed(1),
      },
      {
        label: 'common.average-la',
        subLabel: 'deg',
        key: 'avgVLaunchDeg',
        align: 'right',
        formatFn: ({ avgVLaunchDeg }: ISessionHitSummary) =>
          avgVLaunchDeg.toFixed(1),
      },
      {
        label: 'common.average-dist',
        subLabel: 'ft',
        key: 'avgDistanceFT',
        align: 'right',
        formatFn: ({ avgDistanceFT }: ISessionHitSummary) =>
          avgDistanceFT.toFixed(1),
      },
      {
        label: 'common.created',
        key: 'startDate',
        formatFn: ({ startDate }: ISessionHitSummary) =>
          format(parseISO(startDate), LOCAL_DATETIME_FORMAT, {
            timeZone: LOCAL_TIMEZONE,
          }),
      },
      {
        label: '',
        key: '_delete',
        formatFn: (m: ISessionHitSummary) => (
          <IconButton
            size={RADIX.BUTTON.SIZE.XS}
            variant={RADIX.BUTTON.VARIANT.TABLE}
            color={RADIX.COLOR.DANGER}
            onClick={(e) => {
              // prevent triggering the onClick of the parent row (which opens the report)
              e.stopPropagation();

              // open alert to confirm the deletion
              setDialogDelete({
                key: Date.now(),
                models: [m],
              });
            }}
          >
            <TrashIcon />
          </IconButton>
        ),
      },
    ];
    return output;
  }, []);

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filteredSessions.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const select: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: (m: ISessionHitSummary | undefined) => {
      if (!m) {
        return;
      }

      tryChangeSection({
        trigger: 'Open hitter session from hitting report',
        section: SectionName.Analytics,
        subsection: SubSectionName.HitterSession,
        fragments: [m.session, m.hitterID],
      });
    },
  };

  const checkedActions = useCallback(
    (checked: ISessionHitSummary[]): IMenuAction[] => {
      const actions: IMenuAction[] = [
        {
          label: 'common.delete-selected',
          onClick: () => {
            setDialogDelete({
              key: Date.now(),
              models: checked,
            });
          },
        },
      ];

      return actions;
    },
    []
  );

  const checkable: ITableCheckable = {
    checkboxColumnIndex: 0,
    checkedActions: checkedActions,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: 'startDate',
      dir: 1,
    },
  };

  return (
    <ErrorBoundary componentName="HittingReports">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={<AnalyticsHeader />}
        table={
          <CommonTableHoC
            id={IDENTIFIER}
            displayColumns={columns}
            displayData={filteredSessions}
            loading={loading}
            toolbarContent={<HittingReportsToolbar />}
            {...pagination}
            {...checkable}
            {...select}
            {...sort}
            vFlex
          />
        }
      />

      {dialogDelete && (
        <CommonConfirmationDialog
          key={dialogDelete.key}
          identifier="ConfirmDeleteHitterSession"
          title="Confirm Deletion"
          content={
            <>
              <p>
                Are you sure you want to delete{' '}
                {dialogDelete.models.length > 1
                  ? 'the selected reports'
                  : 'this report'}
                ? This action cannot be undone.
              </p>
              <ul>
                {dialogDelete.models.map((m, i) => (
                  <li key={i}>
                    {format(parseISO(m.startDate), LOCAL_DATETIME_FORMAT, {
                      timeZone: LOCAL_TIMEZONE,
                    })}
                    : {m.hitterName}
                  </li>
                ))}
              </ul>
            </>
          }
          action={{
            label: 'common.delete',
            color: RADIX.COLOR.DANGER,
            onClick: async () => {
              deleteSessions(
                dialogDelete.models.map((m) => {
                  const o: ISessionHitDeleteRequest = {
                    session: m.session,
                    hitterID: m.hitterID,
                  };

                  return o;
                })
              );
            },
          }}
        />
      )}
    </ErrorBoundary>
  );
};
