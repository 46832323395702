import { Box } from '@radix-ui/themes';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { AimingContext } from 'contexts/aiming.context';
import { MachineContext } from 'contexts/machine.context';
import { getSampleSizes, MAX_ADJUSTMENTS } from 'enums/training.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IPresetOption } from 'lib_ts/interfaces/training/i-preset-training-spec';
import { useContext, useState } from 'react';
import { PresetTrainingContext } from '../context';

export const PresetForm = (props: {
  preset: IPresetOption;
  disabled: boolean;
}) => {
  const { disabled } = props;

  const spec = props.preset.spec;

  const { machine } = useContext(MachineContext);
  const { pitch } = useContext(AimingContext);
  const { updateSpec } = useContext(PresetTrainingContext);

  const [_sampleSizeOptions, _setSampleSizeOptions] = useState(
    getSampleSizes(machine.training_threshold)
  );

  const [_adjustmentsOptions] = useState(
    ArrayHelper.getIntegerOptions(0, MAX_ADJUSTMENTS)
  );

  const safePriority = pitch?.priority ?? BuildPriority.Default;

  return (
    <CommonFormGrid columns={2}>
      <CommonSelectInput
        id="precision-training-samples"
        name="sampleSize"
        label="tr.sample-shots"
        options={_sampleSizeOptions}
        value={spec.sampleSize.toString()}
        onNumericChange={(v) => {
          if (v <= 0) {
            return;
          }

          updateSpec({ sampleSize: v });
        }}
        disabled={disabled}
        skipSort
      />

      <CommonSelectInput
        id="precision-training-adjustments"
        name="adjustments"
        label="tr.max-adjustments"
        options={_adjustmentsOptions}
        value={spec.maxAdjustments.toString()}
        onNumericChange={(v) => {
          if (v <= 0) {
            return;
          }

          updateSpec({ maxAdjustments: v });
        }}
        disabled={disabled}
        skipSort
      />

      <Box gridColumn="span 2">
        <CommonTextInput
          id="precision-training-max-speed"
          label="tr.max-speed-delta-mph"
          type="number"
          name="deltaSpeedMPH"
          value={spec.deltaSpeedMPH.toString()}
          title="Provide a positive number"
          onNumericChange={(v) => {
            updateSpec({ deltaSpeedMPH: Math.abs(v) });
          }}
          disabled={disabled}
        />
      </Box>

      {safePriority === BuildPriority.Breaks && (
        <Box gridColumn="span 2">
          <CommonTextInput
            id="precision-training-max-breaks"
            label="tr.max-breaks-delta-inches"
            type="number"
            name="deltaBreaksInches"
            value={spec.deltaBreaksInches.toString()}
            title="Provide a positive number"
            onNumericChange={(v) => {
              updateSpec({ deltaBreaksInches: Math.abs(v) });
            }}
            disabled={disabled}
          />
        </Box>
      )}

      {[BuildPriority.Spins, BuildPriority.Default].includes(safePriority) && (
        <Box gridColumn="span 2">
          <CommonTextInput
            id="precision-training-max-spin"
            label="tr.max-spins-delta-rpm"
            type="number"
            name="deltaSpinsRPM"
            value={spec.deltaSpinsRPM.toString()}
            title="Provide a positive number"
            onNumericChange={(v) => {
              updateSpec({ deltaSpinsRPM: Math.abs(v) });
            }}
            disabled={disabled}
          />
        </Box>
      )}

      {props.preset.showSmartSamplingToggle && (
        <Box gridColumn="span 2">
          <CommonSwitchInput
            id="precision-training-smart-sampling"
            label="tr.smart-sampling"
            name="smart-sampling"
            title="tr.smart-sampling-msg"
            defaultChecked={spec.smartSampling}
            onCheckedChange={(v) => {
              updateSpec({ smartSampling: v });
            }}
            disabled={disabled}
          />
        </Box>
      )}
    </CommonFormGrid>
  );
};
