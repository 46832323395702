import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Badge, Box, Code, Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import {
  AdminIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { AnnouncementDialog } from 'components/common/dialogs/announcement';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTableHoC } from 'components/common/table';
import { CommonTooltip } from 'components/common/tooltip';
import { MachineInspectionDialogHoC } from 'components/machine/dialogs/inspection';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import { RestoreListsDialog } from 'components/sections/admin-portal/dialogs/restore-lists';
import {
  ArchiveShotsDialog,
  ConfirmHWChangedDialog,
  ExportSessionsDialog,
  MachineDetailsDialog,
  MachineLogsDialog,
  MachinePerformanceDialog,
  ResetConnectionsDialog,
  SoftRebootDialog,
  SystemRestartDialog,
} from 'components/sections/admin-portal/machines/dialogs';
import { UpgradeFirmwareDialog } from 'components/sections/admin-portal/machines/dialogs/upgrade-firmware';
import { MachineUser } from 'components/sections/admin-portal/machines/machine-user';
import { MachinesTableToolbar } from 'components/sections/admin-portal/machines/table-toolbar';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { MachinesContext } from 'contexts/admin/machines.context';
import { TeamsContext } from 'contexts/admin/teams.context';
import { UsersContext } from 'contexts/admin/users.context';
import { AuthContext } from 'contexts/auth.context';
import { GlobalContext } from 'contexts/global.context';
import { CheckedProvider } from 'contexts/layout/checked.context';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { BETA_MACHINE_INSTALLER } from 'enums/beta';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMachineActiveModelID, getModelKey } from 'lib_ts/classes/ms.helper';
import { RestartMode } from 'lib_ts/enums/admin.enums';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { QueueState } from 'lib_ts/enums/machine-msg.enum';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IAnnouncement } from 'lib_ts/interfaces/common/i-announcement';
import { IAdminMachine, IMachine } from 'lib_ts/interfaces/i-machine';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { useCallback, useContext, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { AdminMachinesService } from 'services/admin/machines.service';

const IDENTIFIER = TableIdentifier.AdminMachineList;

const MAX_ERRORS_IN_TOOLTIP = 3;

const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

enum DialogMode {
  Announcement,
  ArchiveShots,
  ConfirmInstall,
  Details,
  ExportSessions,
  FWUpgradeDate,
  HWChanged,
  Inspection,
  Logs,
  Performance,
  Reassign,
  RefreshList,
  RestoreList,
  ResetConnections,
  SoftReboot,
  SystemRestart,
}

export const MachinesTable = () => {
  // todo: convert dialogs to fnl so we don't have to do this
  const globalCx = useContext(GlobalContext);
  const authCx = useContext(AuthContext);
  const machinesCx = useContext(MachinesContext);
  const teamsCx = useContext(TeamsContext);
  const usersCx = useContext(UsersContext);

  const {
    machine,
    lastStatus,
    setDialog: setMachineDialog,
    update,
  } = useContext(MachineContext);
  const { models, filtered, loadDetails, openDialogEditor } =
    useContext(MachinesContext);
  const { current } = useContext(AuthContext);

  const [reassignListsPayload, setReassignListsPayload] =
    useState<IPitchListPutManyRequest>({
      filter: {},
      update: {},
    });

  const [menuMachineID, setMenuMachineID] = useState<string>();
  const [manageMachine, setManageMachine] = useState<IAdminMachine>();
  const [manageMachines, setManageMachines] = useState<IAdminMachine[]>();
  const [announcementModel, setAnnouncementModel] =
    useState<Partial<IAnnouncement>>();

  const [dialog, setDialog] = useState<DialogMode>();
  const dialogKey = useMemo(() => Date.now(), [dialog]);

  const columns = useMemo(() => {
    const output: ITableColumn[] = [
      {
        label: 'common.actions',
        key: ACTIONS_KEY,
        actions: [
          {
            label: 'main.inspect-machine',
            color: RADIX.COLOR.SUCCESS,
            invisibleFn: (m: IAdminMachine) => !m.connected,
            onClick: (m: IAdminMachine) => {
              setMenuMachineID(m.machineID);
              setDialog(DialogMode.Inspection);
            },
          },
          {
            label: 'common.view-details',
            onClick: async (m: IAdminMachine) => {
              await loadDetails(m.machineID);
              setDialog(DialogMode.Details);
            },
          },
          {
            label: 'admin.view-performance',
            onClick: (m: IAdminMachine) => {
              setManageMachine(m);
              setDialog(DialogMode.Performance);
            },
          },
          {
            label: 'common.edit',
            onClick: (m: IAdminMachine) => {
              openDialogEditor(m);
            },
          },
          {
            label: 'common.logs',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            onClick: (m: IAdminMachine) => {
              setManageMachine(m);
              setDialog(DialogMode.Logs);
            },
          },
          {
            label: 'Export Sessions',
            onClick: (m: IAdminMachine) => {
              setManageMachines([m]);
              setDialog(DialogMode.ExportSessions);
            },
          },
          {
            label: 'admin.refresh-lists',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            color: RADIX.COLOR.WARNING,
            onClick: (m: IAdminMachine) => {
              setManageMachine(m);
              setDialog(DialogMode.RefreshList);
            },
          },
          {
            label: 'admin.restore-lists',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            color: RADIX.COLOR.WARNING,
            onClick: (m: IAdminMachine) => {
              setManageMachine(m);
              setDialog(DialogMode.RestoreList);
            },
          },
          {
            label: 'common.reassign-lists',
            onClick: (m: IAdminMachine) => {
              const payload: IPitchListPutManyRequest = {
                filter: {
                  _parent_id: m._id,
                  _parent_def: PitchListOwner.Machine,
                },
                update: {
                  processed: new Date(),
                  process_notes: `reassigned from machine ${m.machineID} by admin ${current.email}`,
                  _parent_def: PitchListOwner.Machine,
                },
              };

              setReassignListsPayload(payload);
              setDialog(DialogMode.Reassign);
            },
            color: RADIX.COLOR.WARNING,
          },
          {
            label: 'admin.reset-connections',
            color: RADIX.COLOR.WARNING,
            invisibleFn: (m: IAdminMachine) => !m.connected,
            onClick: (m: IAdminMachine) => {
              setMenuMachineID(m.machineID);
              setDialog(DialogMode.ResetConnections);
            },
          },
          {
            label: 'Check Firmware',
            color: RADIX.COLOR.WARNING,
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: (m: IAdminMachine) =>
              current.role !== UserRole.admin || !m.connected,
            onClick: (m: IAdminMachine) =>
              AdminMachinesService.getInstance().checkFirmware(m.machineID),
          },
          {
            label: 'Upgrade Firmware',
            color: RADIX.COLOR.WARNING,
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: (m: IAdminMachine) =>
              current.role !== UserRole.admin || !m.connected,
            onClick: (m: IAdminMachine) => {
              if (!m.connected) {
                NotifyHelper.warning({
                  message_md: `Please ensure ${m.machineID} is connected and try again.`,
                });
                return;
              }

              setManageMachines([m]);
              setDialog(DialogMode.FWUpgradeDate);
            },
          },
          {
            label: 'Hardware Changed',
            color: RADIX.COLOR.WARNING,
            onClick: (m: IAdminMachine) => {
              setManageMachines([m]);
              setDialog(DialogMode.HWChanged);
            },
          },
          {
            label: 'common.restart',
            color: RADIX.COLOR.WARNING,
            invisibleFn: (m: IAdminMachine) => !m.connected,
            onClick: (m: IAdminMachine) =>
              AdminMachinesService.getInstance().restart(RestartMode.arc, [
                m.machineID,
              ]),
          },
          {
            label: 'New Install',
            color: RADIX.COLOR.DANGER,
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: (m: IAdminMachine) => {
              if (!BETA_MACHINE_INSTALLER) {
                return true;
              }

              if (current.role !== UserRole.admin) {
                // only super admins
                return true;
              }

              if (machine.machineID !== m.machineID) {
                // must be the user's machine
                return true;
              }

              if (lastStatus.queueState !== QueueState.Active) {
                // user must be active on the machine
                return true;
              }

              return false;
            },
            disableFn: () => lastStatus.queueState !== QueueState.Active,
            onClick: () => {
              setDialog(DialogMode.ConfirmInstall);
            },
          },
          {
            label: 'common.restart-os',
            color: RADIX.COLOR.DANGER,
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: (m: IAdminMachine) =>
              current.role !== UserRole.admin || !m.connected,
            onClick: (m: IAdminMachine) =>
              AdminMachinesService.getInstance().restart(RestartMode.os, [
                m.machineID,
              ]),
          },
          {
            label: 'common.reset-training-data',
            color: RADIX.COLOR.DANGER,
            onClick: (m: IAdminMachine) => {
              setManageMachine(m);
              setDialog(DialogMode.ArchiveShots);
            },
          },
        ],
      },
      {
        label: 'common.issues',
        key: '_issues',
        sortRowsFn: (a: IMachine, b: IMachine, dir: number) => {
          const aErr = MachineHelper.getErrors(a);
          const bErr = MachineHelper.getErrors(b);
          return dir * (aErr.length > bErr.length ? -1 : 1);
        },
        formatFn: (m: IMachine) => {
          const errors = MachineHelper.getErrors(m);
          if (errors.length === 0) {
            return '';
          }

          const tooltip = (
            <Text>
              <p>Errors:</p>

              <ol>
                {errors
                  .filter((_, i) => i < MAX_ERRORS_IN_TOOLTIP)
                  .map((errorMsg, i) => (
                    <li key={`machine-${m._id}-error-${i}`}>{errorMsg}</li>
                  ))}
              </ol>

              {errors.length > MAX_ERRORS_IN_TOOLTIP && (
                <p>...and {errors.length - MAX_ERRORS_IN_TOOLTIP} more</p>
              )}
            </Text>
          );

          return (
            <CommonTooltip
              trigger={<ExclamationTriangleIcon />}
              content={tooltip}
            />
          );
        },
      },
      {
        label: 'common.machine',
        key: 'machineID',
        tooltipFn: (m: IMachine) => {
          if (m.super) {
            return 'Super machines can access every video';
          }
        },
        /** machineID (nickname, if applicable), * for super flag */
        formatFn: (m: IMachine) => {
          const color = ((): RadixColor => {
            if (m.machineID === machine.machineID) {
              switch (lastStatus.queueState) {
                case QueueState.Active: {
                  return RADIX.COLOR.SUCCESS;
                }
                case QueueState.Busy: {
                  return RADIX.COLOR.WARNING;
                }

                case QueueState.Disconnected:
                default: {
                  return RADIX.COLOR.DANGER;
                }
              }
            }

            return RADIX.COLOR.ACCENT;
          })();

          return (
            <Flex direction="column" gap={RADIX.FLEX.GAP.XS} pt="1" pb="1">
              <Box>
                <Badge color={color}>
                  {m.machineID} {m.super ? '*' : undefined}
                </Badge>
              </Box>
              {m.nickname && (
                <Box>
                  <Text size={RADIX.TEXT.SIZE.XS} color={RADIX.COLOR.SECONDARY}>
                    {m.nickname}
                  </Text>
                </Box>
              )}
            </Flex>
          );
        },
      },
      {
        label: 'common.sandbox',
        key: 'sandbox',
        sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
          (a.sandbox ? 1 : 0) > (b.sandbox ? 1 : 0) ? -dir : dir,
        formatFn: (m: IAdminMachine) => (m.sandbox ? 'Yes' : 'No'),
      },
      {
        label: 'common.plate',
        subLabel: 'ft',
        key: 'plate_distance',
        formatFn: (m: IMachine) => (m.plate_distance ?? 0).toFixed(1),
      },
      {
        label: 'common.training',
        subLabel: '#',
        key: 'training_threshold',
        align: 'right',
      },
      {
        label: 'common.rapsodo-serial',
        key: 'rapsodo_serial',
        tooltipFn: (m: IMachine) => {
          if (m.sandbox && m.machineID !== m.rapsodo_serial) {
            return 'Sandbox MachineID does not match Rapsodo Serial.';
          }
        },
        formatFn: (m: IMachine) => {
          return (
            <Code
              color={
                m.sandbox && m.machineID !== m.rapsodo_serial
                  ? RADIX.COLOR.DANGER
                  : undefined
              }
            >
              {m.rapsodo_serial}
            </Code>
          );
        },
        sortRowsFn: (a: IMachine, b: IMachine, dir: number) =>
          (a.rapsodo_serial ?? '').localeCompare(b.rapsodo_serial ?? '') * -dir,
      },
      {
        label: 'Firmware',
        key: 'last_firmware',
        formatFn: (a: IMachine) => {
          if (!a.last_firmware) {
            return undefined;
          }

          const isBeta = a.last_firmware.startsWith('beta');

          return (
            <Code
              title={a.last_firmware}
              className="cursor-pointer"
              onClick={() => {
                if (!a.last_firmware) {
                  return;
                }

                navigator.clipboard.writeText(a.last_firmware);

                NotifyHelper.success({
                  message_md: t('common.x-copied-to-clipboard', {
                    x: a.last_firmware,
                  }),
                });
              }}
              color={isBeta ? RADIX.COLOR.BETA : undefined}
            >
              {a.last_firmware}
            </Code>
          );
        },
      },
      {
        label: 'Last Updated',
        key: 'last_firmware_updated',
        dataType: 'datetime',
      },
      {
        label: 'admin.active-model',
        key: '_active_model',
        disableSort: true,
        tooltipFn: (m: IMachine) => {
          const activeKey = getModelKey(m);
          const activeID = getMachineActiveModelID(m);
          const activeModel = models.find((m) => m._id === activeID);

          if (activeModel) {
            return [
              `Name: ${activeModel.name}`,
              `Key: ${activeKey}`,
              `ID: \`${activeID ?? 'none'}\``,
            ].join('\n\n');
          }

          return 'No active model';
        },
        formatFn: (m: IMachine) => {
          const activeID = getMachineActiveModelID(m);
          const activeModel = models.find((m) => m._id === activeID);

          if (activeModel) {
            return activeModel.name;
          }

          return 'None';
        },
      },
      {
        label: 'admin.last-connected',
        labelTooltip: 'The domain that this machine connected to last',
        key: 'last_domain',
        sortRowsFn: (a: IMachine, b: IMachine, dir: number) =>
          (a.last_domain ?? '').localeCompare(b.last_domain ?? '') * dir,
      },
      {
        label: 'admin.status',
        labelTooltip:
          'The current status of this machine according to this environment',
        key: '_connection_status',
        sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) => {
          return (a.connected ? 1 : 0) > (b.connected ? 1 : 0) ? -dir : dir;
        },
        formatFn: (m: IAdminMachine) => {
          return m.connected ? 'Online' : 'Offline';
        },
      },
      {
        label: 'admin.status-date',
        key: '_connection_date',
        labelTooltip: 'The most recent moment when connection status changed',
        sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
          (() => {
            if (a.connected && !b.connected) {
              return -1;
            }

            if (!a.connected && b.connected) {
              return 1;
            }

            return 1;
          })() * dir,
        formatFn: (m: IAdminMachine) => {
          if (m.connected) {
            if (m.connect_date) {
              return `${format(
                parseISO(m.connect_date),
                LOCAL_DATETIME_FORMAT,
                {
                  timeZone: LOCAL_TIMEZONE,
                }
              )}`;
            }

            return '';
          }

          if (m.disconnect_date) {
            return `${format(
              parseISO(m.disconnect_date),
              LOCAL_DATETIME_FORMAT,
              {
                timeZone: LOCAL_TIMEZONE,
              }
            )}`;
          }

          return '';
        },
      },
      {
        label: 'common.active-user',
        key: 'active',
        formatFn: (m: IAdminMachine) => {
          if (!m.active) {
            return 'None';
          }

          return (
            <MachineUser
              user={m.active}
              callback={(u) => {
                setAnnouncementModel({
                  filter: { email: u.email },
                  message: `RE: ${m.machineID}`,
                });
                setDialog(DialogMode.Announcement);
              }}
            />
          );
        },
        sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
          (a.active?.email ?? '').localeCompare(b.active?.email ?? '') * -dir,
      },
      {
        label: 'admin.waiting',
        key: 'waiting',
        formatFn: (m: IAdminMachine) => {
          if (m.waiting.length === 0) {
            return 'None';
          }

          return (
            <ul className="no-style">
              {m.waiting.map((user) => (
                <li key={user.session}>
                  <MachineUser
                    user={user}
                    callback={(u) => {
                      setAnnouncementModel({
                        filter: { email: u.email },
                        message: `RE: ${m.machineID}`,
                      });
                      setDialog(DialogMode.Announcement);
                    }}
                  />
                </li>
              ))}
            </ul>
          );
        },
        sortRowsFn: (a: IAdminMachine, b: IAdminMachine, dir: number) =>
          (a.waiting.length > b.waiting.length ? 1 : -1) * dir,
      },
    ];

    return output;
  }, [current.role, models, machine.machineID, lastStatus.queueState]);

  const checkedActions = useCallback(
    (checked: IAdminMachine[]) => {
      const connected = checked.filter((m) => m.connected);

      const output: IMenuAction[] = [
        {
          label: 'Download Config',
          invisible: connected.length === 0,
          onClick: async () => {
            for (const machineID of connected.map((c) => c.machineID)) {
              const result =
                await AdminMachinesService.getInstance().getMachineConfig(
                  machineID
                );

              if (!result?.machine) {
                NotifyHelper.warning({
                  message_md: `Empty value for ${machineID}, nothing to download.`,
                });
                return;
              }

              MiscHelper.saveAs(
                new Blob([JSON.stringify(result.machine, null, 2)]),
                `${machine.machineID}.json`
              );
            }
          },
        },
        {
          label: 'Export Sessions',
          suffixIcon: <AdminIcon />,
          invisible: ![UserRole.admin, UserRole.team_admin].includes(
            current.role
          ),
          onClick: () => {
            setManageMachines(checked);
            setDialog(DialogMode.ExportSessions);
          },
        },
        {
          label: 'common.restart',
          color: RADIX.COLOR.WARNING,
          invisible: connected.length === 0,
          onClick: () => {
            setManageMachines(connected);
            setDialog(DialogMode.SystemRestart);
          },
        },
        {
          label: 'common.restart-os',
          suffixIcon: <SuperAdminIcon />,
          color: RADIX.COLOR.DANGER,
          invisible: current.role !== UserRole.admin || connected.length === 0,
          onClick: () => {
            setManageMachines(connected);
            setDialog(DialogMode.SoftReboot);
          },
        },
        {
          label: 'Upgrade Firmware',
          color: RADIX.COLOR.WARNING,
          invisible: connected.length === 0,
          onClick: () => {
            setManageMachines(connected);
            setDialog(DialogMode.FWUpgradeDate);
          },
        },
        {
          label: 'Hardware Changed',
          color: RADIX.COLOR.WARNING,
          onClick: () => {
            setManageMachines(checked);
            setDialog(DialogMode.HWChanged);
          },
        },
      ];

      return output;
    },
    [current.role]
  );

  const hasInstall = !!machine.last_installation;
  const hasIncompleteInstall =
    !!machine.last_installation && !machine.last_installation.end_date;

  const handleStartInstall = async () => {
    // close this confirmation dialog
    setDialog(undefined);

    // mark the start_date
    const payload: Partial<IMachine> = {
      _id: machine._id,
      last_hardware_changed: new Date().toISOString(),
      last_installation: {
        started_by: current.email,
        start_date: new Date().toISOString(),
        ball_type: machine.ball_type,
        calibration_id: StringHelper.getCollectionID(),
        repeatability_id: StringHelper.getCollectionID(),
      },
    };

    await update(payload);

    // open the actual dialog
    setMachineDialog(MachineDialogMode.Installation);
  };

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filtered.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: 'machineID',
      dir: -1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
  };

  return (
    <ErrorBoundary componentName="MachinesTable">
      <CheckedProvider data={filtered}>
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={<AdminTabNav active={SubSectionName.Machines} />}
          table={
            <CommonTableHoC
              id="AdminMachines"
              data-testid="AdminMachines"
              toolbarContent={<MachinesTableToolbar />}
              displayColumns={columns}
              displayData={filtered}
              enableListener={globalCx.dialogs.length === 0}
              checkboxColumnIndex={0}
              checkedActions={checkedActions}
              {...pagination}
              {...select}
              {...sort}
              vFlex
            />
          }
        />
      </CheckedProvider>

      {dialog === DialogMode.Reassign && (
        <ReassignListsDialog
          key={dialogKey}
          identifier="MachinesTableReassignListsDialog"
          onClose={() => setDialog(undefined)}
          refPayload={reassignListsPayload}
        />
      )}

      {dialog === DialogMode.Performance && manageMachine && (
        <MachinePerformanceDialog
          key={dialogKey}
          machine={manageMachine}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.ExportSessions && manageMachines && (
        <ExportSessionsDialog
          key={dialogKey}
          machineIDs={manageMachines.map((m) => m.machineID)}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Inspection && (
        <MachineInspectionDialogHoC
          key={dialogKey}
          identifier="AdminInspectDialog"
          machineID={menuMachineID}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Details && (
        <MachineDetailsDialog
          key={dialogKey}
          machinesCx={machinesCx}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Logs && manageMachine && (
        <MachineLogsDialog
          key={dialogKey}
          machine={manageMachine}
          machinesCx={machinesCx}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.SystemRestart && manageMachines && (
        <SystemRestartDialog
          key={dialogKey}
          machineIDs={ArrayHelper.unique(
            manageMachines.map((m) => m.machineID)
          )}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.SoftReboot && manageMachines && (
        <SoftRebootDialog
          key={dialogKey}
          machineIDs={ArrayHelper.unique(
            manageMachines.map((m) => m.machineID)
          )}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.ResetConnections && menuMachineID && (
        <ResetConnectionsDialog
          key={dialogKey}
          machineID={menuMachineID}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.RefreshList && manageMachine && (
        <RefreshListsDialog
          key={dialogKey}
          name={manageMachine.machineID}
          parent_def={PitchListOwner.Machine}
          parent_id={manageMachine._id}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.RestoreList && manageMachine && (
        <RestoreListsDialog
          key={dialogKey}
          name={manageMachine.machineID}
          parent_def={PitchListOwner.Machine}
          parent_id={manageMachine._id}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.FWUpgradeDate && manageMachines && (
        <UpgradeFirmwareDialog
          key={dialogKey}
          machineIDs={ArrayHelper.unique(
            manageMachines.map((m) => m.machineID)
          )}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Announcement && (
        <AnnouncementDialog
          key={dialogKey}
          identifier="MachinesTableAnnouncementDialog"
          authCx={authCx}
          machinesCx={machinesCx}
          teamsCx={teamsCx}
          usersCx={usersCx}
          defaultModel={announcementModel}
          onClose={() => {
            setDialog(undefined);
          }}
        />
      )}

      {dialog === DialogMode.ArchiveShots && manageMachine && (
        <ArchiveShotsDialog
          key={dialogKey}
          machine={manageMachine}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.ConfirmInstall && !hasInstall && (
        // start new install
        <CommonConfirmationDialog
          key={dialogKey}
          identifier="ConfirmNewInstall"
          title="Start New Machine Install"
          content={
            <ReactMarkdown
              children={[
                'Are you sure you want to start a new machine install?',
                'This action will:',
                [
                  '* Archive all existing trained data.',
                  '* Retain your saved pitches and videos.',
                ].join('\n'),
                'Once started, this action cannot be undone.',
              ].join('\n\n')}
            />
          }
          action={{
            label: 'Start New Install',
            color: RADIX.COLOR.DANGER,
            onClick: handleStartInstall,
          }}
        />
      )}

      {dialog === DialogMode.ConfirmInstall && hasInstall && (
        // resume or review an existing install
        <CommonConfirmationDialog
          key={dialogKey}
          identifier="ResumeInstall"
          title={
            hasIncompleteInstall
              ? 'Resume Machine Install'
              : 'Review Machine Install'
          }
          content={
            <ReactMarkdown
              children={
                hasIncompleteInstall
                  ? 'Do you wish to resume the previous machine install?'
                  : 'Do you wish to review the previous machine install?'
              }
            />
          }
          cancel={{
            label: 'common.start-over',
            color: RADIX.COLOR.ACCENT,
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            onClick: handleStartInstall,
          }}
          action={{
            label: hasIncompleteInstall ? 'Resume Install' : 'Review Install',
            color: RADIX.COLOR.ACCENT,
            onClick: async () => {
              // open the actual dialog
              setMachineDialog(MachineDialogMode.Installation);
            },
          }}
        />
      )}

      {dialog === DialogMode.HWChanged && manageMachines && (
        <ConfirmHWChangedDialog key={dialogKey} machines={manageMachines} />
      )}
    </ErrorBoundary>
  );
};
