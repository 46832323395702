import {
  CheckCircledIcon,
  CrossCircledIcon,
  UpdateIcon,
} from '@radix-ui/react-icons';
import { Box, Text } from '@radix-ui/themes';
import { CustomIcon } from 'components/common/custom-icon';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { CustomIconPath } from 'enums/custom.enums';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';

enum Mode {
  Precision,
  Trained,
  Untrained,
}

const Icon = (props: { mode: Mode; className?: string }) => {
  switch (props.mode) {
    case Mode.Precision: {
      return (
        <Text color={RADIX.COLOR.SUCCESS} className={props.className}>
          <CustomIcon icon={CustomIconPath.CheckCircledFilled} />
        </Text>
      );
    }

    case Mode.Trained: {
      return (
        <Text color={RADIX.COLOR.SUCCESS} className={props.className}>
          <CheckCircledIcon />
        </Text>
      );
    }

    case Mode.Untrained:
    default: {
      return (
        <Text color={RADIX.COLOR.NEUTRAL} className={props.className}>
          <CrossCircledIcon />
        </Text>
      );
    }
  }
};

export const TrainingStatusIcon = (props: {
  pitch: IPitch;
  // if false, then an update icon will be shown if the pitch can be refreshed
  autoRefresh: boolean;
}) => {
  const { getAggShotsByPitch, readyToRefresh } =
    useContext(MatchingShotsContext);

  const { machine } = useContext(MachineContext);
  const summary = getAggShotsByPitch(props.pitch);
  const canRefresh = readyToRefresh(props.pitch);
  const ms = getMSFromMSDict(props.pitch, machine).ms;

  const trained = !!summary?.trained;
  const precision = trained && !!ms?.precision_trained;
  const mode = precision
    ? Mode.Precision
    : trained
      ? Mode.Trained
      : Mode.Untrained;

  const showUpdateIcon = !props.autoRefresh && canRefresh;
  return (
    <Box
      pt="1"
      data-testid="ShotSummary"
      data-total={summary?.total ?? 0}
      data-qt={summary?.qt ?? 0}
      data-qt-complete={summary?.qt_complete ?? false}
    >
      <UpdateIcon className={!showUpdateIcon ? 'hidden' : ''} />
      <Icon mode={mode} className={showUpdateIcon ? 'hidden' : ''} />
    </Box>
  );
};
