import { Box, Code, Flex, Strong } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { getVisibilityBlurb } from 'components/common/pitch-lists/manage-list';
import { AuthContext } from 'contexts/auth.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { useContext, useMemo, useState } from 'react';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

const COMPONENT_NAME = 'ReassignListsDialog';

interface IProps extends IBaseDialog {
  refPayload: IPitchListPutManyRequest;
}

export const ReassignListsDialog = (props: IProps) => {
  const { current, reassignOptions } = useContext(AuthContext);
  const { refreshLists, updateLists } = useContext(PitchListsContext);

  const defOptions = useMemo<IOption[]>(
    () => [
      {
        label: 'common.personal',
        invisible: reassignOptions.users.length === 0,
        value: PitchListOwner.User,
      },
      {
        label: 'common.machine',
        invisible:
          !current.machine_lists || reassignOptions.machines.length === 0,
        value: PitchListOwner.Machine,
      },
      {
        label: 'common.team',
        invisible: !current.team_lists || reassignOptions.teams.length === 0,
        value: PitchListOwner.Team,
      },
    ],

    [current, reassignOptions]
  );

  const [payload, setPayload] = useState<IPitchListPutManyRequest>({
    ...props.refPayload,
  });

  const idOptions = useMemo(
    () =>
      PitchListHelper.getReassignToIdOptions({
        options: reassignOptions,
        role: current.role,
        teamID: current.teamID,
        parentDef: payload.update._parent_def ?? '',
        parentId: payload.update._parent_id ?? '',
      }),
    [current, reassignOptions, payload.update]
  );

  const ownerString = useMemo(() => {
    /** the names of the pitch lists will be displayed below */
    if (payload.filterLists) {
      const count = payload.filterLists.length;
      return `the following ${count} pitch ${count === 1 ? 'list' : 'lists'}`;
    }

    /** warn the user what kind of lists will be impacted */
    switch (payload.filter._parent_def) {
      case PitchListOwner.User: {
        if (current.userID === payload.filter._parent_id) {
          return 'your personal lists';
        }

        return "this user's personal lists";
      }

      case PitchListOwner.Machine: {
        if (current.machineID === payload.filter._parent_id) {
          return "your machine's lists";
        }

        return "this machine's lists";
      }

      case PitchListOwner.Team: {
        if (current.teamID === payload.filter._parent_id) {
          return "your team's lists";
        }

        return "this team's lists";
      }

      default: {
        /** shouldn't trigger */
        return 'these lists';
      }
    }
  }, [payload, current]);

  const selectedMachine = useMemo(
    () =>
      reassignOptions.machines.find((m) => m._id === payload.update._parent_id),
    [reassignOptions.machines, payload.update._parent_id]
  );

  const warning = useMemo(
    () =>
      getVisibilityBlurb(
        payload.update._parent_def,
        selectedMachine ? selectedMachine.machineID : undefined
      ),
    [selectedMachine, payload]
  );

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={props.identifier}
        width={RADIX.DIALOG.WIDTH.MD}
        title="Reassign Pitch Lists"
        content={
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Box>
              <p>
                Use the form below to assign a new parent for{' '}
                <Strong>
                  {ownerString}
                  {props.refPayload.filterLists ? ':' : '.'}
                </Strong>
              </p>

              {props.refPayload.filterLists && (
                <ul>
                  {props.refPayload.filterLists
                    .sort((a, b) => {
                      if (a.folder === b.folder) {
                        return (a.name ?? '').localeCompare(b.name ?? '');
                      }

                      return (a.folder ?? '').localeCompare(b.folder ?? '');
                    })
                    .map((list, iList) => (
                      <li key={iList}>
                        <Code>{list.folder}/</Code>
                        {list.name}
                      </li>
                    ))}
                </ul>
              )}
            </Box>

            <CommonFormGrid columns={1}>
              <CommonSelectInput
                id="reassign-parent-type"
                name="_parent_def"
                label="Parent Type"
                options={defOptions}
                value={payload.update._parent_def}
                onChange={(v) => {
                  setPayload((prev) => ({
                    ...prev,
                    update: {
                      _parent_def: v as PitchListOwner,
                      _parent_id: undefined,
                    },
                  }));
                }}
                hint_md={warning}
                optional
              />
              <CommonSearchInput
                id="reassign-parent"
                name="_parent_id"
                label="New Parent"
                options={idOptions}
                values={
                  payload.update._parent_id ? [payload.update._parent_id] : []
                }
                onChange={(v) => {
                  setPayload((prev) => ({
                    ...prev,
                    update: {
                      ...prev.update,
                      _parent_id: v[0],
                    },
                  }));
                }}
                optional
              />
            </CommonFormGrid>
          </Flex>
        }
        buttons={[
          {
            ...DEFAULT_ACCEPT_BTN,
            onClick: () => {
              if (
                !payload.filter._parent_id ||
                !payload.update._parent_def ||
                !payload.update._parent_id
              ) {
                NotifyHelper.error({
                  message_md: t('common.check-inputs-msg'),
                });
                return;
              }

              if (current.role === UserRole.basic) {
                /** not copying -- check permissions to overwrite/modify */
                if (
                  payload.filter._parent_def !== PitchListOwner.User ||
                  payload.filter._parent_id !== current.userID
                ) {
                  NotifyHelper.warning({
                    message_md:
                      'You cannot reassign pitch lists that do not belong to you as a regular user.',
                  });
                  return;
                }

                /** run the update via regular pitch list service -- can only bulk reassign things belonging to self as user */
                updateLists(payload).then((success) => {
                  if (success) {
                    refreshLists(true);
                    props.onClose();
                  }
                });
                return;
              }

              /** run the update via admin service -- can only bulk reassign things belonging to own team as team admin */
              if (
                [UserRole.admin, UserRole.team_admin].includes(current.role)
              ) {
                AdminPitchListsService.getInstance()
                  .putManyLists(payload)
                  .then((success) => {
                    if (success) {
                      refreshLists(true);
                      props.onClose();
                    }
                  });
                return;
              }
            },
          },
        ]}
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
