import { Avatar, Box, Flex, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ISidebarPitchList } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

interface IProps {
  file: ISidebarPitchList;
}

export const PitchList = (props: IProps) => {
  const { active: aList, loading, updateList } = useContext(PitchListsContext);
  const { active: aSection, tryChangeSection } = useContext(SectionsContext);

  const safePath = useMemo(
    () => [props.file.pathEnd, props.file.name].filter((s) => !!s).join('/'),
    [props.file]
  );

  const isActiveList = useMemo(
    () =>
      aSection.section === SectionName.Pitches &&
      aSection.subsection === SubSectionName.List &&
      aSection.fragments?.[0] === props.file.object._id,
    [props.file.object._id, aSection]
  );

  const abbr = useMemo(
    () => props.file.name?.trim().substring(0, 1).toUpperCase() ?? '*',
    [props.file.name]
  );

  const className = useMemo(
    () =>
      StringHelper.classNames([
        'Entry',
        loading ? 'cursor-not-allowed' : 'cursor-pointer',
      ]),
    [loading]
  );

  return (
    <ErrorBoundary componentName="PitchListItem">
      <Flex
        className={className}
        gap="2"
        p="2"
        mr="2"
        align="center"
        onClick={(e) => {
          // prevents click from collapsing parent folder
          e.stopPropagation();

          if (loading) {
            return;
          }

          if (
            !aList ||
            aList._id !== props.file.object._id ||
            aSection.section !== SectionName.Pitches ||
            aSection.subsection !== SubSectionName.List ||
            aSection.fragments?.[0] !== props.file.object._id
          ) {
            /** move user to pitch list (invisible) section */
            tryChangeSection({
              trigger: 'sidebar, open list',
              section: SectionName.Pitches,
              subsection: SubSectionName.List,
              fragments: [props.file.object._id],
            });
          }
        }}
      >
        <Box>
          <Avatar
            size="1"
            fallback={abbr}
            color={isActiveList ? RADIX.COLOR.ACCENT : RADIX.COLOR.NEUTRAL}
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </Box>
        <Box
          // required for nested text truncation to work
          minWidth="0"
          flexGrow="1"
          title={safePath}
          data-testid="SidebarPitchList"
        >
          <Text
            color={isActiveList ? RADIX.COLOR.ACCENT : undefined}
            // as div + overflow hidden allows truncate to take effect
            as="div"
            truncate
          >
            {props.file.name}
          </Text>
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
