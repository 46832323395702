import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTabs } from 'components/common/tabs';
import { ErrorsTab } from 'components/sections/admin-portal/machines/dialogs/details/errors.tab';
import { SummaryTab } from 'components/sections/admin-portal/machines/dialogs/details/summary.tab';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import React from 'react';

const COMPONENT_NAME = 'MachineDetailsDialog';

enum TabKey {
  Summary = 'Summary',
  Errors = 'Errors',
}

interface IProps {
  machinesCx: IMachinesContext;
  onClose: () => void;
}

interface IState {
  machineDetails: IMachineDetails;
  activeTab: TabKey;
}

export class MachineDetailsDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      machineDetails: props.machinesCx.details,
      activeTab: TabKey.Summary,
    };
  }

  render() {
    const { machineDetails } = this.state;

    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title: `${machineDetails ? machineDetails.machineID : 'Machine'} Details`,
      width: RADIX.DIALOG.WIDTH.XL,
      vFlexHeight: RADIX.DIALOG.HEIGHT.MD,
      content: (
        <CommonTabs
          value={this.state.activeTab}
          onValueChange={(value) => {
            this.setState({ activeTab: value as TabKey });
          }}
          tabs={[
            {
              value: TabKey.Summary,
              label: 'common.summary',
              content: <SummaryTab details={machineDetails} />,
            },
            {
              value: TabKey.Errors,
              label: 'common.errors',
              content: <ErrorsTab details={machineDetails} />,
            },
          ]}
        />
      ),

      loading: this.props.machinesCx.loading,
      buttons: [
        {
          label: 'Refresh',
          onClick: () => {
            /** reload details as mstarget may have changed */
            this.props.machinesCx
              .loadDetails(machineDetails.machineID)
              .then(() => {
                this.setState({
                  machineDetails: this.props.machinesCx.details,
                });
              });
          },
        },
      ],
      onClose: this.props.onClose,
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
