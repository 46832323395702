import { RouteHelper } from 'classes/helpers/route.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GameDataGame } from 'components/sections/game-data/games/game';
import { GameDataPlayer } from 'components/sections/game-data/players/player';
import { GameDataTabs } from 'components/sections/game-data/tabs';
import { SectionsContext } from 'contexts/sections.context';
import { useContext, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

export const GameDataRouter = () => {
  const { active } = useContext(SectionsContext);
  const id = useMemo(() => active.fragments?.[1], [active]);

  return (
    <ErrorBoundary componentName="GameDataRouter">
      <Routes>
        <Route path={RouteHelper.getSlug([''])} element={<GameDataTabs />} />

        {/* pitcher */}
        <Route
          path={RouteHelper.getSlug(['pitchers'], [':id'])}
          element={<GameDataPlayer playerPk={id} mode="pitchers" />}
        />

        {/* hitter */}
        <Route
          path={RouteHelper.getSlug(['hitters'], [':id'])}
          element={<GameDataPlayer playerPk={id} mode="hitters" />}
        />

        <Route
          path={RouteHelper.getSlug(['games'], [':id'])}
          element={<GameDataGame gamePk={id} />}
        />
      </Routes>
    </ErrorBoundary>
  );
};
