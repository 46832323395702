import { MachineContextHelper } from 'classes/helpers/machine-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ReactHelper } from 'classes/helpers/react.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { IntercomSnapshotUpdater } from 'components/common/intercom-snapshot-updater';
import { SettingsDialog } from 'components/common/settings-dialog';
import { MachineConnectionDialog } from 'components/machine/dialogs/connection';
import { MachineControlDialog } from 'components/machine/dialogs/control';
import { HealthCheckDialog } from 'components/machine/dialogs/health-check';
import { MachineInspectionDialogHoC } from 'components/machine/dialogs/inspection';
import { InstallationDialogHoC } from 'components/machine/dialogs/installation';
import { R2FStatusDialog } from 'components/machine/dialogs/r2f-status';
import { RealignMachineDialog } from 'components/machine/dialogs/realign-machine';
import { StereoCheckDialogHoC } from 'components/machine/dialogs/stereo-check/stereo-check';
import { IntercomListener } from 'components/main/listeners/intercom';
import { NotificationListener } from 'components/main/listeners/notification';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { HittersContext, getEmptyStats } from 'contexts/hitters.context';
import { InboxContext } from 'contexts/inbox';
import { SessionEventsContext } from 'contexts/session-events.context';
import { addDays, isFuture, lightFormat, parseISO } from 'date-fns';
import { CookieKey } from 'enums/cookies.enums';
import { MachineMode } from 'enums/machine.enums';
import { t } from 'i18next';
import { INotificationButton } from 'interfaces/i-notification';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import { MPH_TO_KPH } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMachineActiveModelID } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ModelStatus } from 'lib_ts/enums/machine-models.enums';
import {
  BallStatusMsgType,
  HealthCheckType,
  QueueState,
  SfxName,
  StaticVideoType,
  WsMsgType,
} from 'lib_ts/enums/machine-msg.enum';
import {
  BallType,
  FireOption,
  FiringMode,
  MS_LIMITS,
  TrainingMode,
} from 'lib_ts/enums/machine.enums';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import { BuildPriority, PitchType } from 'lib_ts/enums/pitches.enums';
import { TrackingDevice } from 'lib_ts/enums/training.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IHitterStats } from 'lib_ts/interfaces/i-hitter';
import {
  DEFAULT_CONTEXT_MACHINE,
  IMachine,
  IMachineModelDictionary,
} from 'lib_ts/interfaces/i-machine';
import {
  CalibrateProc,
  IBallStatusMsg,
  ICalibrateRequestMsg,
  IPingPongMsg,
  IProcessQueryResponseMsg,
  IProcessStatus,
  IProjectorAdjustmentMsg,
  IQueueMsg,
  IReadyMsg,
  IRulerMsg,
  ITwoFactorMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import {
  IFireEvent,
  IMSSnapshotData,
  IMSTargetEventData,
  ISessionEvent,
} from 'lib_ts/interfaces/i-session-event';
import { IVideo } from 'lib_ts/interfaces/i-video';
import { IFireMsg } from 'lib_ts/interfaces/machine-msg/i-fire';
import {
  IHealthCheckRequestMsg,
  IHealthCheckResponseMsg,
} from 'lib_ts/interfaces/machine-msg/i-health-check';
import {
  IAutoFireMsg,
  IMachineStateMsg,
} from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { IMachineStatusMsg } from 'lib_ts/interfaces/machine-msg/i-machine-status';
import { IProjectorSfxMsg } from 'lib_ts/interfaces/machine-msg/i-projector-sfx';
import { IPitchPreviewOverlayMsg } from 'lib_ts/interfaces/machine-msg/i-projector-text-overlay';
import {
  ISpecialMstargetMsg,
  SpecialMsPosition,
} from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import {
  DEFAULT_BALL_STATE,
  DEFAULT_PLATE,
  DEFAULT_TRAJECTORY,
  IBallDetailsError,
  IPitch,
  IPitchList,
  IPlateLoc,
} from 'lib_ts/interfaces/pitches';
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';
import { UserMachineModelsService } from 'services/machine-models.service';
import { MachinesService } from 'services/machines.service';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import { WebSocketService } from 'services/web-socket.service';
import slugify from 'slugify';
import { v4 } from 'uuid';

const CONTEXT_NAME = 'MachineContext';

const SUPPRESS_LOW_LIGHT_WARNING = true;
const SUPPRESS_REDUNDANT_SNAPSHOTS = true;

const MIN_WAIT_BETWEEN_FIRE_MS = 2_000;

const DAYS_TO_WARN_HW_CHANGE = 1;

const MOUND_HEIGHT_FT = 10 / 12;

const PING_INTERVAL_MS = 10_000;
const PING_TIMEOUT_MS = PING_INTERVAL_MS / 2;

export enum MachineDialogMode {
  CalibrateVision,
  Disconnected,
  HealthCheck,
  Inspect,
  Installation,
  R2F,
  Realign,
  RequestControl,
}

/** if the machine is using any ball type from this array, rapid mode will always be engaged */
const RAPID_BALL_TYPES: BallType[] = [BallType.Smash, BallType.StingFreeJugs];

/** if the machine is using any ball type from this array, rapsodo validation will always be skipped */
const NO_RAPSODO_BALL_TYPES: BallType[] = [
  BallType.Smash,
  BallType.StingFreeJugs,
];

/** how long before the recently fired pitch is cleared */
const RECENTLY_FIRED_DURATION = 3_000;

export interface IMachineContext {
  requireRapsodoConfidence: boolean;

  lastPitchID?: string;

  lastMS?: IMachineStateMsg;

  lastMSHash?: string;
  readonly resetMSHash: () => void;
  // does the target need to be sent, or is it already the last thing sent
  readonly requiresSend: (target?: IMachineStateMsg) => boolean;
  // was the last thing sent something that could (eventually) become R2F (e.g. not a screensaver)
  readonly attemptingR2F: () => boolean;

  lastBallCount?: number;
  lastR2F?: IReadyMsg;

  /** overlay ids as of last request */
  lastOverlayIDs?: string[];

  machine: IMachine;
  activeModel?: IMachineModel;
  readonly getModelName: (id: string) => string;

  buildOptions: IOption[];

  /** if active model is defined, will only return a build priority that is actually supported */
  readonly getSupportedPriority: (
    priority: BuildPriority | undefined
  ) => BuildPriority;

  loading: boolean;
  firing: boolean;

  autoFire: boolean;

  // returns the new hash based on last MS + new auto-fire value
  readonly setAutoFire: (value: boolean) => void;

  fireOptions: FireOption[];
  readonly addFireOption: (flag: FireOption) => void;
  readonly removeFireOption: (flag: FireOption) => void;

  /** true when calibration starts, false after completion */
  calibrating: boolean;

  process_statuses: IProcessStatus[];
  readonly setProcessData: (proc: IProcessQueryResponseMsg) => void;

  /** returns true if the current user is connected + machine is not busy, else shows warning and returns false */
  readonly checkActive: (silently?: boolean) => boolean;

  readonly sendPitchPreview: (config: {
    trigger: string;
    prev?: IPitch;
    current: IPitch;
    next?: IPitch;
  }) => void;

  /** result indicates whether a msg was sent or not */
  readonly sendTarget: (config: {
    source: string;
    msMsg: IMachineStateMsg;
    pitch: Partial<IPitch>;
    plate?: IPlateLoc;
    list?: IPitchList;
    hitter_id?: string;
    video?: IVideo;
    // for data collector
    collectionID?: string;
    // for data collector
    aiming?: boolean;
    // skips checks and validations
    force?: boolean;
    trigger?: string;
    // e.g. auto-changing pitches while firing from a queue
    skipFiringCheck?: boolean;
  }) => Promise<{ success: boolean; hash?: string }>;

  /** bypasses most safety validations */
  readonly sendRawTarget: (
    msg: IMachineStateMsg,
    source: string,
    silently: boolean
  ) => Promise<boolean>;

  /** indicate whether a calibration is required based on success/failure of calibration response */
  readonly setCalibrated: (value: boolean) => void;
  readonly setCanCalibrate: (value: boolean) => void;

  readonly setFiring: (value: boolean) => void;

  // based on lastStatus.queueState
  active: boolean;
  lastStatus: IMachineStatusMsg;
  readonly setLastStatus: (msg: IMachineStatusMsg) => void;

  /** respond to waiting user, whether their request for control is accepted/rejected */
  readonly sendControlResponse: (msg: IQueueMsg) => void;

  readonly update: (
    machine: Partial<IMachine>,
    silently?: boolean
  ) => Promise<boolean>;

  readonly dropball: (notify: boolean, source: string) => void;

  // returns flag indicating whether anything was sent
  readonly specialMstarget: (position: SpecialMsPosition) => boolean;

  readonly calibrate: (procs: CalibrateProc[], source: string) => void;

  readonly send2FA: (source: string, msg: ITwoFactorMsg) => void;

  /** ask the machine which overlays are currently active on projector */
  readonly requestOverlayIDs: (source: string) => void;

  /** called by listener, record whatever was caught */
  readonly setOverlayIDs: (values: string[]) => void;

  readonly sendProcessReset: (source: string, proc: string) => void;

  readonly sendProcessQuery: (source: string) => void;

  readonly sendProcessKill: (source: string, proc: string) => void;

  // aka: soft reboot
  readonly restartOS: (source: string) => void;

  // aka: system restart
  readonly restartArc: (source: string) => void;

  readonly fire: (config: {
    pitch: Partial<IPitch>;
    mode: FiringMode;
    trigger: string;
    training: boolean;
    training_mode?: TrainingMode;
    hitter_id?: string;
    tags?: string;
  }) => Promise<boolean>;

  readonly toggleRuler: (source: string, msg: IRulerMsg) => void;

  readonly adjustKeystone: (
    source: string,
    msg: IProjectorAdjustmentMsg
  ) => void;

  /** temporarily stores the pitch that was recently fired, self-clears after a delay
   * use-case: pitch list can highlight the pitch (if its _id matches) until it's cleared
   */
  recentlyFiredPitch?: Partial<IPitch>;

  readonly setDialog: (mode: MachineDialogMode | undefined) => void;

  readonly getSpecialMode: () => MachineMode | undefined;
  readonly setSpecialMode: (mode?: MachineMode) => void;

  readonly activateModel: (config: {
    modelID: string;
    modelKey: string;
    silently?: boolean;
  }) => Promise<boolean>;

  readonly onEndTraining: () => void;

  readonly playSound: (effect: SfxName) => void;

  // ensures that different instances don't accidentally mutate each other
  readonly getDefaultPitch: () => IPitch;

  healthChecking: boolean;
  healthCheckResults: IHealthCheckResponseMsg | undefined;
  readonly startHealthCheck: (type: HealthCheckType) => void;

  readonly reconnect: () => void;
}

const DEFAULT: IMachineContext = {
  requireRapsodoConfidence: false,

  machine: DEFAULT_CONTEXT_MACHINE,
  getModelName: () => '',
  buildOptions: [],
  getSupportedPriority: () => BuildPriority.Breaks,

  loading: false,

  resetMSHash: () => console.error(`${CONTEXT_NAME}: not init`),
  requiresSend: () => false,
  attemptingR2F: () => false,

  fireOptions: [FireOption.SkipRapsodoValidation],
  addFireOption: () => console.error(`${CONTEXT_NAME}: not init`),
  removeFireOption: () => console.error(`${CONTEXT_NAME}: not init`),

  active: false,

  lastStatus: {
    queueState: QueueState.Disconnected,
    userName: '',
    userID: '',
    machineID: '',
    queue: [],
    calibrated: false,
    can_calibrate: false,
    estop_pressed: false,
    force_notification: false,
  },
  setLastStatus: () => console.error(`${CONTEXT_NAME}: not init`),

  calibrating: false,
  firing: false,

  autoFire: false,
  setAutoFire: () => console.error(`${CONTEXT_NAME}: not init`),

  process_statuses: [],
  setProcessData: () => console.error(`${CONTEXT_NAME}: not init`),

  checkActive: () => false,
  setCalibrated: () => console.error(`${CONTEXT_NAME}: not init`),
  setCanCalibrate: () => console.error(`${CONTEXT_NAME}: not init`),
  setFiring: () => console.error(`${CONTEXT_NAME}: not init`),

  sendPitchPreview: () => console.error(`${CONTEXT_NAME}: not init`),
  sendTarget: () => new Promise(() => ({ success: false })),
  sendRawTarget: () => new Promise(() => false),
  update: () => new Promise(() => false),

  dropball: () => console.error(`${CONTEXT_NAME}: not init`),
  specialMstarget: () => false,
  calibrate: () => console.error(`${CONTEXT_NAME}: not init`),
  send2FA: () => console.error(`${CONTEXT_NAME}: not init`),
  requestOverlayIDs: () => console.error(`${CONTEXT_NAME}: not init`),
  setOverlayIDs: () => console.error(`${CONTEXT_NAME}: not init`),
  sendProcessReset: () => console.error(`${CONTEXT_NAME}: not init`),
  sendProcessQuery: () => console.error(`${CONTEXT_NAME}: not init`),
  sendProcessKill: () => console.error(`${CONTEXT_NAME}: not init`),
  restartOS: () => console.error(`${CONTEXT_NAME}: not init`),
  restartArc: () => console.error(`${CONTEXT_NAME}: not init`),
  fire: () => new Promise(() => false),

  sendControlResponse: () => console.error(`${CONTEXT_NAME}: not init`),

  toggleRuler: () => console.error(`${CONTEXT_NAME}: not init`),

  adjustKeystone: () => console.error(`${CONTEXT_NAME}: not init`),

  setDialog: () => console.error(`${CONTEXT_NAME}: not init`),

  getSpecialMode: () => undefined,
  setSpecialMode: () => console.error(`${CONTEXT_NAME}: not init`),

  activateModel: () => new Promise(() => false),

  onEndTraining: () => console.error(`${CONTEXT_NAME}: not init`),
  playSound: () => console.error(`${CONTEXT_NAME}: not init`),

  getDefaultPitch: () => {
    const o: IPitch = {
      _id: '',
      _created: '',
      _changed: '',
      _parent_id: '',
      _parent_def: 'pitch-lists',
      _parent_field: 'pitches',
      bs: {
        ...DEFAULT_BALL_STATE,
      },
      traj: {
        ...DEFAULT_TRAJECTORY,
      },
      priority: BuildPriority.Default,
    };

    return o;
  },

  healthChecking: false,
  healthCheckResults: undefined,
  startHealthCheck: () => console.error(`${CONTEXT_NAME}: not init`),

  reconnect: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const MachineContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const MachineProvider: FC<IProps> = (props) => {
  const { current, gameStatus, logout, reconnectWS } = useContext(AuthContext);

  const { app, snapshot, setCookie } = useContext(CookiesContext);
  const cookiesCx = useContext(CookiesContext);
  const inboxCx = useContext(InboxContext);

  const {
    stats: hitterStats,
    getHitterExt,
    upsertStats: upsertHitterStats,
  } = useContext(HittersContext);

  const { dialogSettings, increaseFired } = useContext(SessionEventsContext);

  const [ballStatusToast, setBallStatusToast] = useState(false);

  const [ballDate, setBallDate] = useState(new Date());
  const [ballCount, setBallCount] = useState(DEFAULT.lastBallCount);

  const [r2f, setR2f] = useState(DEFAULT.lastR2F);

  const [hardwareWarned, setHardwareWarned] = useState(false);
  const [machine, setMachine] = useState(DEFAULT.machine);

  const [activeModel, setActiveModel] = useState(DEFAULT.activeModel);

  const [status, setStatus] = useState(DEFAULT.lastStatus);

  const buildOptions = useMemo(() => {
    if (!activeModel) {
      return [];
    }

    const { supports_breaks, supports_spins } = activeModel;

    return [
      {
        label: 'pd.spins',
        value: BuildPriority.Spins,
        disabled: !supports_spins,
      },
      {
        label: 'pd.breaks',
        value: BuildPriority.Breaks,
        disabled: !supports_breaks,
      },
    ];
  }, [activeModel]);

  const [modelDict, setModelDict] = useState<IMachineModelDictionary>({});
  const [loading, setLoading] = useState(DEFAULT.loading);
  const [fireOptions, setFireOptions] = useState(DEFAULT.fireOptions);

  const [calibrating, setCalibrating] = useState(DEFAULT.calibrating);
  const [firing, setFiring] = useState(DEFAULT.firing);

  const firingTimeout = useRef<NodeJS.Timeout>();

  const pingTimeout = useRef<NodeJS.Timeout>();
  const [lastPing, setLastPing] = useState<number>(Date.now());

  useEffect(() => {
    const callback = (event: CustomEvent) => {
      const data: IPingPongMsg = event.detail;

      // console.debug('received pong msg', data);

      if (data.key < lastPing) {
        // ignore stale result
        return;
      }

      clearTimeout(pingTimeout.current);
    };

    WebSocketHelper.on(WsMsgType.S2U_Pong, callback);

    return () => {
      WebSocketHelper.remove(WsMsgType.S2U_Pong, callback);
    };
  }, [lastPing]);

  // check if d/c every 10s => update state to match if so
  useEffect(() => {
    const interval = setInterval(() => {
      const FN_NAME = 'WSInterval';

      const dcCallback = (reason: string) => {
        // make sure queueState matches
        console.warn(
          `${FN_NAME}: user is not connected to server as of ${Date.now()} (${reason})`
        );

        setStatus((prev) =>
          prev.queueState === QueueState.Disconnected
            ? // shouldn't trigger any redraws
              prev
            : // force queueState to become d/c (e.g. so UI updates accordingly)
              {
                ...prev,
                queueState: QueueState.Disconnected,
              }
        );

        // try to close things properly
        WebSocketService.getInstance()?.close();
      };

      if (!WebSocketService.isConnected()) {
        dcCallback('isConnected');
        return;
      }

      const data: IPingPongMsg = {
        key: Date.now(),
      };

      setLastPing(data.key);

      // console.debug('sent ping msg', data);

      WebSocketService.sendWithoutRetry(WsMsgType.U2S_Ping, data, FN_NAME);

      clearTimeout(pingTimeout.current);

      // if pong doesn't come in time, this will trigger
      pingTimeout.current = setTimeout(() => {
        dcCallback('ping timeout');
      }, PING_TIMEOUT_MS);
    }, PING_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    clearTimeout(firingTimeout.current);

    if (!firing) {
      return;
    }

    firingTimeout.current = setTimeout(() => {
      setFiring(false);
    }, 30 * 1_000);
  }, [firing]);

  const [autoFire, setAutoFire] = useState(DEFAULT.autoFire);
  const [processStatuses, setProcessStatuses] = useState(
    DEFAULT.process_statuses
  );

  /** updated whenever a ms is sent to the machine, used to track what was last sent
   * e.g. to start screensaver without moving anything else */
  const [lastMS, setLastMS] = useState<IMachineStateMsg>({
    ...DEFAULT_MACHINE_STATE,
    video_uuid: StaticVideoType.screensaver,
  });
  const [lastPitchID, setLastPitchID] = useState(DEFAULT.lastPitchID);
  const [lastFired, setLastFired] = useState<number>();

  /** hash of lastMS used for quickly detecting differences */
  const [lastMSHash, setLastMSHash] = useState<string>();
  const [lastOverlayIDs, setLastOverlayIDs] = useState(DEFAULT.lastOverlayIDs);

  const [recentFiredPitch, setRecentFiredPitch] = useState(
    DEFAULT.recentlyFiredPitch
  );

  const [dialogDC, setDialogDC] = useState<number>();
  const [dialogInspect, setDialogInspect] = useState<number>();
  const [dialogInstallation, setDialogInstallation] = useState<number>();
  const [dialogHealthCheck, setDialogHealthCheck] = useState<number>();
  const [dialogStereoCheck, setDialogStereoCheck] = useState<number>();
  const [dialogR2F, setDialogR2F] = useState<number>();
  const [dialogRequestControl, setDialogRequestControl] = useState<number>();
  const [dialogRealign, setDialogRealign] = useState<number>();

  const [specialMode, setSpecialMode] = useState<MachineMode>();

  // takes on the value of _lastMSHash whenever an Intercom URL is about to be generated, to determine if it can skip
  const [intercomHash, setIntercomHash] = useState<string>();
  const [intercomURL, setIntercomURL] = useState<string>();

  const safeSetMachine = useCallback(
    async (config: { machine: IMachine; silently: boolean }) => {
      const { machine, silently } = config;

      if (
        !hardwareWarned &&
        machine.last_hardware_changed &&
        isFuture(
          addDays(
            parseISO(machine.last_hardware_changed),
            DAYS_TO_WARN_HW_CHANGE
          )
        )
      ) {
        // hardware change happened within the last day and warning has not been shown yet
        NotifyHelper.info({
          message_md: `There was a hardware change for \`${
            machine.machineID
          }\` on ${lightFormat(
            parseISO(machine.last_hardware_changed),
            'yyyy-MM-dd'
          )}. Shot data collected before this change will no longer be usable on this machine.`,
        });

        setHardwareWarned(true);
      }

      setMachine(machine);
      const modelID = getMachineActiveModelID(machine);

      if (!modelID) {
        if (!silently) {
          NotifyHelper.error({
            message_md: `${machine.machineID} has no active model. Please contact support.`,
          });
        }
        return;
      }

      const model =
        await UserMachineModelsService.getInstance().getModel(modelID);
      setActiveModel(model);

      if (model) {
        // automatically select default input method if the active model doesn't support the saved method
        switch (app.build_priority) {
          case BuildPriority.Breaks: {
            if (!model.supports_breaks) {
              setCookie(CookieKey.app, {
                build_priority: BuildPriority.Default,
              });
            }
            break;
          }

          case BuildPriority.Spins: {
            if (!model.supports_spins) {
              setCookie(CookieKey.app, {
                build_priority: BuildPriority.Default,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    },
    [app, hardwareWarned]
  );

  // this needs to be this way so that other actions which specifically require update to finish successfully can properly await
  const updateMachine = useCallback(
    async (
      payload: Partial<IMachine>,
      silently?: boolean
    ): Promise<boolean> => {
      try {
        if (
          payload.plate_distance !== undefined &&
          (payload.plate_distance > MS_LIMITS.PLATE_DISTANCE.MAX ||
            payload.plate_distance < MS_LIMITS.PLATE_DISTANCE.MIN)
        ) {
          if (!silently) {
            NotifyHelper.warning({
              message_md: `Plate distance should be between ${MS_LIMITS.PLATE_DISTANCE.MIN}-${MS_LIMITS.PLATE_DISTANCE.MAX} ft.`,
            });
          }
          return false;
        }

        setLoading(true);

        const result = await MachinesService.getInstance()
          .update(payload)
          .finally(() => setLoading(false));

        if (!result) {
          throw new Error(
            `Empty result received from server while updating machine`
          );
        }

        safeSetMachine({
          machine: result,
          silently: !!silently,
        });

        return true;
      } catch (e) {
        console.error(e);

        if (!silently) {
          NotifyHelper.error({
            message_md:
              'There was a server error while updating your machine. See console for details.',
          });
        }

        return false;
      }
    },
    [safeSetMachine, status]
  );

  const getActiveWithToasts = useCallback(() => {
    switch (status.queueState) {
      case QueueState.Active: {
        break;
      }

      case QueueState.Busy: {
        NotifyHelper.warning({
          message_md: `You must be the active user on ${
            machine ? machine.machineID : 'a machine'
          } to perform this action.`,
          buttons: [
            {
              label: 'Request Control',
              onClick: () => setDialogRequestControl(Date.now()),
              dismissAfterClick: true,
            },
          ],
        });
        break;
      }

      case QueueState.Disconnected:
      default: {
        NotifyHelper.warning({
          message_md: `You must be connected to ${
            machine ? machine.machineID : 'a machine'
          } to perform this action.`,
          buttons: [
            {
              label: 'Reconnect',
              onClick: reconnect,
              dismissAfterClick: true,
            },
          ],
        });
        break;
      }
    }

    return status.queueState === QueueState.Active;
  }, [status.queueState]);

  const _checkFinishedFiring = useCallback(
    (silently?: boolean) => {
      if (!firing) {
        return true;
      }

      if (!silently) {
        NotifyHelper.warning({
          message_md: `Please wait for ${machine.machineID} to finish firing before trying again.`,
        });
      }

      return false;
    },
    [firing]
  );

  /** helper that also keeps lastMS in sync */
  const _sendMS = (msg: IMachineStateMsg, source: string) => {
    WebSocketService.send(WsMsgType.U2S_MsTarget, msg, source);

    setLastMS(msg);
    setLastMSHash(MiscHelper.hashify(msg));

    NotifyHelper.debug({
      message_md: `S2M triggered from ${source}!`,
      buttons: [
        {
          label: 'Save MS',
          dismissAfterClick: true,
          onClick: () => {
            const safeSource = slugify(source, {
              strict: true,
              lower: true,
            });

            const filename = [
              machine.machineID,
              safeSource,
              MachineHelper.getMSHash('matching', msg),
            ].join('-');

            MiscHelper.saveAs(
              new Blob([JSON.stringify(msg, null, 2)]),
              `${filename}.json`
            );
          },
        },
      ],
    });
  };

  // updates machine with latest auto-fire and last MS value
  // e.g. toggle by user or automatically disabled on unmount of certain components
  useEffect(() => {
    if (status.queueState !== QueueState.Active) {
      return;
    }

    const msg: IAutoFireMsg = {
      autoFire: autoFire,
    };

    WebSocketService.send(
      WsMsgType.U2M_AutoFire,
      msg,
      `auto-fire toggled ${autoFire ? 'ON' : 'OFF'}`
    );
  }, [autoFire]);

  const _sendSpecialMsTarget = useCallback(
    (position: SpecialMsPosition) => {
      if (status.queueState !== QueueState.Active) {
        return false;
      }

      if (firing) {
        return false;
      }

      if (autoFire) {
        // safety
        setAutoFire(false);
      }

      // timeout should allow the auto-resend of the last mstarget
      // from any changes to _autoFire to go through before the
      // special mstarget is sent
      setTimeout(() => {
        const data: ISpecialMstargetMsg = {
          position: position,
        };

        WebSocketService.send(
          WsMsgType.U2M_SpecialMsTarget,
          data,
          CONTEXT_NAME
        );
        setLastMSHash(undefined);
      }, 100);

      return true;
    },
    [status.queueState, firing, autoFire]
  );

  const [ballStatus, setBallStatus] = useState<IBallStatusMsg>();

  useEffect(() => {
    if (!ballStatus) {
      return;
    }

    setBallCount(ballStatus.ball_count);
    setBallDate(new Date());

    if (specialMode === 'empty-carousel') {
      return;
    }

    if (ballStatus.type !== BallStatusMsgType.AfterDropball) {
      return;
    }

    if (ballStatus.ball_count !== 1) {
      NotifyHelper.warning({
        message_md: `Drop ball failed.`,
        hideHeader: true,
      });
      return;
    }

    if (ballStatus.already_present) {
      NotifyHelper.success({ message_md: 'Ball is already present.' });
      return;
    }

    // fallback
    NotifyHelper.success({ message_md: 'Drop ball was successful!' });
  }, [ballStatus]);

  useEffect(() => {
    const callback = (event: CustomEvent) => {
      ReactHelper.setStateIfChanged(setBallStatus, event.detail);
    };

    WebSocketHelper.on(WsMsgType.M2U_BallStatus, callback);
    return () => WebSocketHelper.remove(WsMsgType.M2U_BallStatus, callback);
  }, []);

  const [healthChecking, setHealthChecking] = useState(false);
  const [healthCheck, setHealthCheck] = useState<IHealthCheckResponseMsg>();

  useEffect(() => {
    if (!healthCheck) {
      return;
    }

    setHealthChecking(false);

    NotifyHelper.info({
      message_md: `Health check results are ready!`,
      delay_ms: 0,
      buttons: [
        {
          // only show button to re-open the dialog if it's not open already
          invisible: dialogHealthCheck !== undefined,
          label: 'common.view-details',
          onClick: () => setDialogHealthCheck(Date.now()),
          dismissAfterClick: true,
        },
        {
          label: 'common.dismiss',
          onClick: () => {
            // do nothing
          },
          dismissAfterClick: true,
        },
      ],
    });
  }, [healthCheck]);

  // reset health check state if machine switched during health check
  useEffect(() => {
    setHealthChecking(false);
    setHealthCheck(undefined);
  }, [machine.machineID]);

  useEffect(() => {
    const callback = (event: CustomEvent) => {
      ReactHelper.setStateIfChanged(setHealthCheck, event.detail);
    };

    WebSocketHelper.on(WsMsgType.M2U_HealthCheck, callback);
    return () => WebSocketHelper.remove(WsMsgType.M2U_HealthCheck, callback);
  }, []);

  useEffect(() => {
    if (!r2f) {
      return;
    }

    // if all ready, then assume there's 1 ball loaded
    if (r2f.status) {
      setBallCount(1);
      setBallDate(new Date());
    }
  }, [r2f]);

  useEffect(() => {
    const callback = (event: CustomEvent) => {
      ReactHelper.setStateIfChanged(setR2f, event.detail);
    };

    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, callback);
    return () => WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, callback);
  }, []);

  const performDropball = useCallback(
    (notify: boolean, source: string) => {
      const isActive = notify
        ? getActiveWithToasts()
        : status.queueState === QueueState.Active;

      if (!isActive) {
        return;
      }

      // for safety
      if (specialMode !== 'empty-carousel') {
        setAutoFire(false);
      }

      MachineContextHelper.sendDropBall({
        machineID: machine.machineID,
        source: source,
      });
    },
    [getActiveWithToasts, status.queueState, specialMode, machine.machineID]
  );

  const isUserActive = useMemo(
    () => status.queueState === QueueState.Active,
    [status.queueState]
  );

  const requireRapsodoConfidence = useMemo(() => {
    if (fireOptions.includes(FireOption.SkipRapsodoValidation)) {
      return false;
    }

    switch (machine.tracking_device) {
      case TrackingDevice.TrackmanB1:
      case TrackingDevice.TrajektVision: {
        return false;
      }

      case TrackingDevice.RapsodoV3PRO:
      default: {
        return true;
      }
    }
  }, [machine.tracking_device, fireOptions]);

  const reconnect = () => {
    if (WebSocketService.isConnected()) {
      // app WS is working, open dialog to troubleshoot machine
      setDialogDC(Date.now());
      return;
    }

    // app WS is not working, attempt reconnection first
    NotifyHelper.warning({
      message_md: t('common.attempting-to-reconnect-message'),
    });

    reconnectWS();
    setDialogDC(Date.now());
  };

  // show toasts when queueState changes
  useEffect(() => {
    switch (status.queueState) {
      case QueueState.Active: {
        // user became active, reset the hash just to be safe
        setLastMSHash(undefined);

        NotifyHelper.success({
          message_md: `You are now active on \`${current.machineID}\`.`,
        });

        if (status.estop_pressed && status.force_notification) {
          NotifyHelper.warning({
            message_md:
              'Please disengage the emergency stop before proceeding.',
            delay_ms: 0,
            buttons: [
              {
                label: 'common.dismiss',
                dismissAfterClick: true,
                onClick: () => {
                  // do nothing
                },
              },
            ],
          });
        }

        if (
          !status.calibrated &&
          status.can_calibrate &&
          status.force_notification
        ) {
          NotifyHelper.warning({
            message_md:
              'Please realign your machine before continuing your session.',
            delay_ms: 0,
            buttons: [
              {
                label: 'Realign Machine',
                dismissAfterClick: true,
                onClick: () => setDialogRealign(Date.now()),
              },
            ],
          });
        }
        break;
      }

      case QueueState.Busy: {
        NotifyHelper.warning({
          message_md: `You are waiting for \`${current.machineID}\`.`,
        });
        break;
      }

      case QueueState.Disconnected: {
        // for safety
        setAutoFire(false);

        if (status.queue[0] === current.session) {
          NotifyHelper.warning({
            message_md: `\`${current.machineID}\` was disconnected from the server.`,
          });
        }

        break;
      }

      default: {
        break;
      }
    }
  }, [status.queueState]);

  const state: IMachineContext = {
    requireRapsodoConfidence: requireRapsodoConfidence,

    machine: machine,

    active: isUserActive,

    lastR2F: r2f,

    lastStatus: status,
    setLastStatus: (data) => {
      ReactHelper.setStateIfChanged(setStatus, data);
    },

    activeModel: activeModel,
    getModelName: (id) => {
      return modelDict[id] ?? 'Unknown';
    },

    buildOptions: buildOptions,

    getSupportedPriority: (priority) => {
      // assumption is that breaks is always supported and is the safer fallback
      const FALLBACK = BuildPriority.Breaks;

      if (!activeModel) {
        return FALLBACK;
      }

      if (priority === FALLBACK) {
        return FALLBACK;
      }

      switch (priority) {
        case BuildPriority.Default:
        case BuildPriority.Spins: {
          return activeModel.supports_spins ? BuildPriority.Spins : FALLBACK;
        }

        default: {
          return FALLBACK;
        }
      }
    },

    lastPitchID: lastPitchID,

    lastMS: lastMS,

    lastMSHash: lastMSHash,
    resetMSHash: () => setLastMSHash(undefined),

    setDialog: (value) => {
      switch (value) {
        case MachineDialogMode.Disconnected: {
          setDialogDC(Date.now());
          break;
        }
        case MachineDialogMode.Inspect: {
          setDialogInspect(Date.now());
          break;
        }
        case MachineDialogMode.Installation: {
          setDialogInstallation(Date.now());
          break;
        }
        case MachineDialogMode.CalibrateVision: {
          setDialogStereoCheck(Date.now());
          break;
        }
        case MachineDialogMode.R2F: {
          setDialogR2F(Date.now());
          break;
        }
        case MachineDialogMode.Realign: {
          setDialogRealign(Date.now());
          break;
        }
        case MachineDialogMode.RequestControl: {
          setDialogRequestControl(Date.now());
          break;
        }
        case MachineDialogMode.HealthCheck: {
          setDialogHealthCheck(Date.now());
          break;
        }
      }
    },

    requiresSend: (target) => {
      if (!lastMSHash) {
        return true;
      }

      if (!target) {
        return true;
      }

      return MiscHelper.hashify(target) !== lastMSHash;
    },
    attemptingR2F: () => {
      // if any wheel speed is non-zero, it is relevant to care about R2F
      // screensaver should cause this to return false
      return ![lastMS.w1, lastMS.w2, lastMS.w3].every((w) => w === 0);
    },

    lastBallCount: ballCount,

    lastOverlayIDs: lastOverlayIDs,

    fireOptions: fireOptions,
    addFireOption: (flag) => {
      if (!fireOptions.includes(flag)) {
        setFireOptions((prev) => [...prev, flag]);
      }
    },

    removeFireOption: (flag) => {
      if (fireOptions.includes(flag)) {
        setFireOptions((prev) => prev.filter((f) => f !== flag));
      }
    },

    calibrating: calibrating,
    firing: firing,

    autoFire: autoFire,
    setAutoFire: setAutoFire,

    loading: loading,

    process_statuses: processStatuses,
    setProcessData: (procQueryResponse: IProcessQueryResponseMsg) => {
      const processStatus = procQueryResponse.status;
      if (processStatus === true) {
        setProcessStatuses(procQueryResponse.processes);
      }
    },

    sendControlResponse: (msg) => {
      if (getActiveWithToasts()) {
        WebSocketService.send(
          WsMsgType.Misc_ControlResponse,
          msg,
          'machine context'
        );
        NotifyHelper.info({
          message_md: `Response sent to \`${msg.waiting_user?.email}\`.`,
        });
      }
    },

    checkActive: (silently) => {
      if (silently) {
        return status.queueState === QueueState.Active;
      }

      return getActiveWithToasts();
    },

    specialMstarget: _sendSpecialMsTarget,

    dropball: performDropball,

    update: updateMachine,

    recentlyFiredPitch: recentFiredPitch,

    fire: async (config) => {
      try {
        if (!getActiveWithToasts()) {
          return false;
        }

        if (
          lastFired !== undefined &&
          performance.now() - lastFired < MIN_WAIT_BETWEEN_FIRE_MS
        ) {
          return false;
        }

        /** union of options based on user settings + required by other factors */
        const getFireOptions = (config: {
          ball_type: BallType;
        }): FireOption[] => {
          const output: Set<FireOption> = new Set(fireOptions);

          /** example of adding training flags */
          if (
            !machine.enable_raspodo_validation ||
            NO_RAPSODO_BALL_TYPES.includes(config.ball_type)
          ) {
            output.add(FireOption.SkipRapsodoValidation);
          }

          if (!machine.enable_continuous_training) {
            output.add(FireOption.DisableTrainFromFire);
          }

          return Array.from(output);
        };

        const payload: Partial<IFireEvent> = {
          category: 'machine',
          tags: 'fire',
          data: {
            mode: config.mode,

            ball_type: machine.ball_type,
            pitch_id: config.pitch._id,
            pitch_name: config.pitch.name,
            pitch_type: config.pitch.type ?? PitchType.None,

            training: config.training,
            training_mode: config.training_mode,

            in_game: gameStatus === GameStatus.InProgress,

            trigger: config.trigger,
            options: getFireOptions({ ball_type: machine.ball_type }),
          },
        };

        if (payload.data) {
          if (config.hitter_id) {
            /** update hitter stats */
            const stats: IHitterStats =
              hitterStats.find((s) => s.hitter_id === config.hitter_id) ??
              getEmptyStats(config.hitter_id);

            await upsertHitterStats(config.hitter_id, {
              pitches: stats.pitches + 1,
            });

            /** assemble and attach extended hitter object to fire event  */
            payload.data.hitterExt = getHitterExt(config.hitter_id);
          }

          if (config.tags) {
            const tags = ArrayHelper.unique(
              config.tags
                .toUpperCase()
                .split(',')
                .map((t) => t.trim())
            );

            if (tags.length > 0) {
              payload.data.tags = tags;
            }
          }
        }

        const response = await SessionEventsService.postEvent(payload);
        if (!response.success) {
          if (response.error === 'disconnected') {
            NotifyHelper.warning({
              message_md:
                'There was a problem processing your request. Your connection has been reset. Please try again or contact support.',
            });
            reconnect();
          }

          return false;
        }

        const fireEvent = response.data as ISessionEvent | undefined;

        if (!fireEvent) {
          NotifyHelper.warning({
            message_md: `There was a problem logging the fire event (${
              response.error ?? 'unknown error'
            }). Please try again or contact support.`,
          });
          return false;
        }

        const data: IFireMsg = {
          fire_id: fireEvent._id,
        };

        await WebSocketService.send(WsMsgType.U2S_Fire, data, config.trigger);

        // avoid getting locked into firing state if there's an error sending the fire msg
        setFiring(true);

        /** track fired shots for session */
        increaseFired();

        /** take note that the pitch was recently fired */
        setRecentFiredPitch(config.pitch);
        setLastFired(performance.now());

        return true;
      } catch (e) {
        console.error(e);

        // if firing failed for whatever reason,
        // auto-reset the firing flag after a "safe enough" delay
        setTimeout(() => {
          setFiring(false);
        }, 10_000);

        return false;
      }
    },

    send2FA: (source, msg) => {
      WebSocketService.send(WsMsgType.S2M_TwoFa, msg, source);
    },

    requestOverlayIDs: (source) => {
      if (status.queueState !== QueueState.Active) {
        return;
      }

      WebSocketService.send(WsMsgType.U2S_Overlays, {}, source);
    },

    setOverlayIDs: (values) => {
      setLastOverlayIDs(values);
    },

    sendProcessReset: (source, proc) => {
      if (getActiveWithToasts()) {
        WebSocketService.send(WsMsgType.Process_Reset, proc, source);
      }
    },

    sendProcessQuery: (source) => {
      if (getActiveWithToasts()) {
        NotifyHelper.success({
          message_md: `Querying \`${machine.machineID}\` for running processes...`,
        });
        WebSocketService.send(WsMsgType.Process_Query, {}, source);
      }
    },

    sendProcessKill: (source, proc) => {
      if (getActiveWithToasts()) {
        WebSocketService.send(WsMsgType.Process_Kill, proc, source);
      }
    },

    restartOS: (source) => {
      if (getActiveWithToasts()) {
        WebSocketService.send(WsMsgType.Process_SoftReboot, {}, source);
      }
    },

    restartArc: (source) => {
      if (getActiveWithToasts()) {
        WebSocketService.send(WsMsgType.Process_SystemRestart, {}, source);
      }
    },

    calibrate: (procs, source) => {
      if (getActiveWithToasts()) {
        // only wait for calibration completion if there are non-projector procs being calibrated
        if (
          procs.filter((p) => {
            return ![
              CalibrateProc.ProjectorOutline,
              CalibrateProc.ProjectorRulers,
            ].includes(p);
          }).length > 0
        ) {
          setCalibrating(true);
        }

        SessionEventsService.postEvent({
          category: 'machine',
          tags: 'calibrate',
        }).then((response) => {
          if (!response.success) {
            setCalibrating(false);
            return;
          }

          const message: ICalibrateRequestMsg = {
            procs: procs,
          };
          WebSocketService.send(WsMsgType.U2S_Calibrate, message, source);
        });

        // NotifyHelper.info({ message_md: `Calibration started, this will take approximately 1 minute. Please wait...` });
      }
    },

    setCalibrated: (value) => {
      setCalibrating(false);
      setStatus((prev) => ({
        ...prev,
        calibrated: value,
      }));
    },

    setCanCalibrate: (value) => {
      setStatus((prev) => ({
        ...prev,
        can_calibrate: value,
      }));
    },

    setFiring: (value) => {
      setFiring(value);
    },

    sendRawTarget: async (msg, source, silently) => {
      try {
        const isActive = silently
          ? status.queueState === QueueState.Active
          : getActiveWithToasts();

        if (!isActive) {
          return false;
        }

        if (!_checkFinishedFiring(silently)) {
          return false;
        }

        if (!silently) {
          NotifyHelper.info({
            message_md: 'Sending raw mstarget to machine...',
          });
        }

        const data: IMSTargetEventData = {
          pitch_id: 'raw',

          list: '',
          pitch_type: PitchType.None,
          pitch_title: '',
          pitcher: '',
          video_title: '',
          build_priority: BuildPriority.Default,

          bs: DEFAULT_BALL_STATE,
          ms: msg,
          traj: DEFAULT_TRAJECTORY,
        };

        const response = await SessionEventsService.postEvent({
          category: 'machine',
          tags: 'mstarget,sent',
          trigger: source,
          data: data,
        });

        if (!response.success) {
          throw new Error('Failed to create mstarget event for raw target.');
        }

        _sendMS(msg, source);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    sendPitchPreview: (config) => {
      const pitchToText = (pitch: IPitch): string => {
        const DISPLAY_DECIMALS = 0;
        const MIN_SPEED_LENGTH = '100'.length;

        const output: string[] = [];

        if (pitch.type && pitch.type !== PitchType.None) {
          output.push(pitch.type);
        }

        const speedMPH = TrajHelper.getSpeedMPH(pitch.traj);

        if (speedMPH !== undefined) {
          const value = machine.isMetric ? speedMPH * MPH_TO_KPH : speedMPH;
          const units = machine.isMetric ? 'KPH' : 'MPH';

          output.push(
            `${value
              .toFixed(DISPLAY_DECIMALS)
              .padStart(MIN_SPEED_LENGTH)} ${units}`
          );
        }

        return output.join(' ');
      };

      const msg: IPitchPreviewOverlayMsg = {
        trigger: config.trigger,
        clear: false,
        lines: [pitchToText(config.current)],
        rich_lines: [],
      };

      if (config.prev) {
        msg.rich_lines.push({
          text: pitchToText(config.prev),
          color: 'grey',
          active: false,
        });
      }

      msg.rich_lines.push({
        text: pitchToText(config.current),
        color: 'white',
        active: true,
      });

      if (config.next) {
        msg.rich_lines.push({
          text: pitchToText(config.next),
          color: 'grey',
          active: false,
        });
      }

      WebSocketService.send(WsMsgType.S2M_PitchPreview, msg, CONTEXT_NAME);
    },

    sendTarget: async (config) => {
      try {
        if (!getActiveWithToasts()) {
          throw new Error('Not active user');
        }

        if (!config.skipFiringCheck && !_checkFinishedFiring()) {
          throw new Error('Not finished firing');
        }

        // if provided, ensure video would not crash the projector
        if (config.video) {
          if (config.video.MoundPixelY <= config.video.ReleasePixelY) {
            NotifyHelper.warning({
              message_md: `Video mound pixel Y (${config.video.MoundPixelY}) must be greater than release pixel Y (${config.video.ReleasePixelY}).`,
            });
            throw new Error('Invalid video mound pixel Y');
          }

          if (config.video.ReleaseHeight <= MOUND_HEIGHT_FT) {
            NotifyHelper.warning({
              message_md: `Video release height (${
                config.video.ReleaseHeight
              } ft) must be greater than mound height (${MOUND_HEIGHT_FT.toFixed(
                1
              )} ft).`,
            });
            throw new Error('Invalid video release height');
          }

          const CHECK_VIDEO_FRAME_AND_TIME = false;
          if (CHECK_VIDEO_FRAME_AND_TIME) {
            if (
              config.video.ReleaseFrame === undefined ||
              config.video.ReleaseFrame < 1 ||
              config.video.ReleaseFrame > config.video.n_frames
            ) {
              NotifyHelper.warning({
                message_md: `Video has an invalid release frame (${config.video.ReleaseFrame}).`,
              });
              throw new Error('Invalid video release frame');
            }

            if (
              config.video.ReleaseTime === undefined ||
              config.video.ReleaseTime < 0 ||
              config.video.ReleaseTime > config.video.ffmpeg.duration.seconds
            ) {
              NotifyHelper.warning({
                message_md: `Video has an invalid release time (${config.video.ReleaseTime}).`,
              });
              throw new Error('Invalid video release time');
            }
          }
        }

        const safeMsg: IMachineStateMsg = {
          ...config.msMsg,

          rapid:
            // e.g. from auto-fire from settings
            config.msMsg.rapid ||
            machine.enable_rapid_mode ||
            RAPID_BALL_TYPES.includes(machine.ball_type),

          force: config.force,
        };

        if (!config.pitch._id) {
          // NotifyHelper.warning({ message_md: 'Sent pitch has no ID.', });
          config.pitch._id = `no-id-${v4()}`;

          console.warn({
            event: `${CONTEXT_NAME}: generated a temporary _id while sending a pitch without _id`,
            pitch: config.pitch,
          });
        }

        if (!config.pitch.bs) {
          NotifyHelper.error({
            message_md: 'Cannot send a pitch without a ball state.',
          });

          throw new Error('Cannot send a pitch without a ball state');
        }

        if (!config.pitch.traj) {
          NotifyHelper.error({
            message_md: 'Cannot send a pitch without a trajectory.',
          });

          throw new Error('Cannot send a pitch without a trajectory');
        }

        if (config.video) {
          safeMsg.video_uuid = config.video._id;

          const fatalVideoErrors = VideoHelper.getFatalErrors(config.video);
          if (fatalVideoErrors.length > 0) {
            NotifyHelper.error({
              message_md: [
                'Cannot send a pitch using a video with invalid metadata:',
                fatalVideoErrors
                  .filter((_, i) => i < 5)
                  .map((e) => ` - ${e.msg}`)
                  .join('\n'),
              ].join('\n\n'),
              delay_ms: 0,
            });

            throw new Error('Cannot send a pitch without valid metadata');
          }
        }

        const sessionData: IMSTargetEventData = {
          hw_config_id: machine.hw_config_id,
          pitch_id: config.pitch._id,

          list: config.list?.name ?? '',
          pitch_type: config.pitch.type ?? PitchType.None,
          pitch_title: config.pitch.name ?? '',
          pitcher: config.video?.PitcherFullName ?? '',
          video_title: config.video?.VideoTitle ?? '',
          build_priority: config.pitch.priority ?? BuildPriority.Spins,

          bs: config.pitch.bs,
          ms: safeMsg,
          traj: config.pitch.traj,

          plate: config.plate,

          breaks: config.pitch.breaks,

          hitterExt: config.hitter_id
            ? getHitterExt(config.hitter_id)
            : undefined,

          collectionID: config.collectionID,
          aiming: config.aiming,
        };

        /** validation stuff here */
        if (!status.calibrated) {
          NotifyHelper.warning({
            message_md:
              'Machine realignment required. Please realign machine first and try again.',
            buttons: [
              {
                label: 'Realign Machine',
                onClick: () => setDialogRealign(Date.now()),
              },
            ],
          });

          throw new Error('Machine is not calibrated');
        }

        if (!config.force) {
          const errors: IBallDetailsError[] = MachineHelper.getSendTargetErrors(
            {
              bs: config.pitch.bs,
              traj: config.pitch.traj,
              ms: config.msMsg,
              plate_distance: machine.plate_distance,
            }
          );

          /** if one or more errors came up, log them all but don't proceed with sending to machine */
          if (errors.length > 0) {
            sessionData.errors = errors;

            SessionEventsService.postEvent({
              category: 'machine',
              tags: 'mstarget,errors',
              data: sessionData,
            });

            const fixableErrors = errors.filter(
              (m) => m.fix?.autoFixMsFn !== undefined
            );

            const nonFixableErrors = errors.filter(
              (m) => m.fix?.autoFixMsFn === undefined
            );

            console.error({
              event: `${CONTEXT_NAME}: mstarget validation results`,
              fixable: fixableErrors.map((m) => m.msg),
              nonFixable: nonFixableErrors.map((m) => m.msg),
            });

            if (nonFixableErrors.length > 0) {
              NotifyHelper.error({
                message_md: `Cannot send pitch: ${nonFixableErrors[0].msg} (${
                  nonFixableErrors.length - 1
                } other errors)`,
              });

              throw new Error('Cannot send pitch with validation errors');
            }

            fixableErrors.forEach((m) => {
              if (m.fix?.autoFixMsFn) {
                config.msMsg = {
                  ...config.msMsg,
                  ...m.fix.autoFixMsFn(config.msMsg),
                };
              }
            });
          }
        }

        /** everything okay, send the ms */
        const response = await SessionEventsService.postEvent({
          category: 'machine',
          tags: 'mstarget,sent',
          trigger: config.trigger,
          data: sessionData,
        });

        if (!response.success) {
          if (response.error === 'disconnected') {
            NotifyHelper.warning({
              message_md: t('refresh-due-to-network'),
            });
          }

          throw new Error('Failed to create mstarget session event');
        }

        setLastPitchID(config.pitch._id);

        _sendMS(safeMsg, config.source);

        if (config.pitch._id) {
          setCookie(CookieKey.snapshot, {
            pitch_id: config.pitch._id,
          });
        }

        return {
          success: true,
          // instead of assuming last MS hash updated in time (race condition)
          hash: MiscHelper.hashify(safeMsg),
        };
      } catch (e) {
        console.error(e);
        return {
          success: false,
        };
      }
    },

    toggleRuler(source, msg) {
      if (getActiveWithToasts()) {
        NotifyHelper.success({
          message_md: `Toggling ruler for \`${machine.machineID}\``,
        });
        WebSocketService.send(WsMsgType.U2S_Ruler, msg, source);
      }
    },

    adjustKeystone(source, msg) {
      if (getActiveWithToasts()) {
        _sendSpecialMsTarget(SpecialMsPosition.home);
        WebSocketService.send(WsMsgType.U2S_Keystone, msg, source);
      }
    },

    getSpecialMode: () => specialMode,
    setSpecialMode: (mode) => setSpecialMode(mode),

    activateModel: async (config) => {
      const machinePayload: Partial<IMachine> = {
        _id: machine._id,
        model_ids: machine.model_ids ?? {},
      };

      if (machinePayload.model_ids) {
        machinePayload.model_ids[config.modelKey] = config.modelID;
      }

      const success = await updateMachine(machinePayload);

      if (success) {
        MainService.getInstance().postSlack({
          type: 'info',
          msg: [
            'a model was activated',
            ` - machine: ${machine.machineID}`,
            ` - model: \`${config.modelID}\``,
            ` - user: ${current.email}`,
            ` - role: ${current.role}`,
            ` - url: ${env.server_url}/main/model/${config.modelID}/html`,
          ].join('\n'),
        });

        const modelPayload: Partial<IMachineModel> = {
          _id: config.modelID,
          status: ModelStatus.Published,
        };

        AdminMachineModelsService.getInstance().updateModel(
          modelPayload,
          config.silently
        );
      }

      return success;
    },

    onEndTraining: () => {
      if (autoFire) {
        // safety
        setAutoFire(false);
      }

      if (status.queueState !== QueueState.Active) {
        console.debug('onEndTraining while inactive, lowering skipped');
        return;
      }

      if (firing) {
        console.debug('onEndTraining while still firing, lowering skipped');
        return;
      }

      if (machine.enable_auto_reset_ms) {
        _sendSpecialMsTarget(SpecialMsPosition.lowered);
        setLastMSHash(undefined);
        return;
      }

      // only change the video + stop the wheels, leave the other aspects of the machine intact

      const msg: IMachineStateMsg = {
        ...lastMS,

        w1: 0,
        w2: 0,
        w3: 0,

        video_uuid: StaticVideoType.screensaver,
      };

      _sendMS(msg, 'training ended');
      setLastMSHash(undefined);

      /** show toast asking user if they want to bring down the machine */
      NotifyHelper.warning({
        message_md: [
          t('tr.complete-msg'),
          t('common.lower-machine-question'),
        ].join('\n\n'),
        delay_ms: 10 * 1000,
        buttons: [
          {
            label: 'common.lower-machine',
            dismissAfterClick: true,
            onClick: () => _sendSpecialMsTarget(SpecialMsPosition.lowered),
          },
        ],
      });
    },

    playSound: (effect) => {
      if (status.queueState !== QueueState.Active) {
        return;
      }

      const data: IProjectorSfxMsg = {
        name: effect,
      };

      WebSocketService.send(
        WsMsgType.Misc_ProjectorSoundFx,
        data,
        CONTEXT_NAME
      );
    },

    getDefaultPitch: () => {
      const o: IPitch = {
        _id: '',
        _created: '',
        _changed: '',
        _parent_id: '',
        _parent_def: 'pitch-lists',
        _parent_field: 'pitches',
        bs: {
          ...DEFAULT_BALL_STATE,
          py: machine.plate_distance,
        },
        traj: {
          ...DEFAULT_TRAJECTORY,
          py: machine.plate_distance,
        },
        plate_loc_backup: DEFAULT_PLATE,
        breaks: {
          zInches: 0,
          xInches: 0,
        },
        seams: {
          latitude_deg: 0,
          longitude_deg: 0,
        },
        priority: app.build_priority,
      };

      return o;
    },

    healthChecking: healthChecking,
    healthCheckResults: healthCheck,
    startHealthCheck: (type) => {
      if (healthChecking) {
        // prevent re-start while a check is running
        NotifyHelper.warning({
          message_md:
            'Please wait for the current health check to complete first.',
        });
        return;
      }

      setHealthCheck(undefined);
      setHealthChecking(true);

      const msg: IHealthCheckRequestMsg = {
        type: type,
      };

      WebSocketService.send(WsMsgType.U2M_HealthCheck, msg, 'admin request');
    },

    reconnect: reconnect,
  };

  const _getIntercomURL = async (pitch_id: string | undefined) => {
    try {
      const data: IMSSnapshotData = {
        pitch_id: pitch_id,
        r2f: r2f,
        last_ms: lastMS,
      };

      const hash = MiscHelper.hashify(data);

      if (SUPPRESS_REDUNDANT_SNAPSHOTS && hash === intercomHash) {
        // avoid recreating snapshots of basically identical payloads (excluding past_snapshot_ids)
        return;
      }

      // keep track of any previous snapshots for the session
      data.past_snapshot_ids = snapshot.past_snapshot_ids;

      const result = await SessionEventsService.postEvent({
        category: 'machine',
        trigger: 'intercom',
        tags: 'snapshot',
        data: data,
      });

      if (!result.success) {
        throw new Error('Failed to create machine snapshot.');
      }

      const se = result.data as ISessionEvent;
      const url = `${env.server_url}/main/machine-snapshot/${se._id}/html?errors=10`;

      setIntercomHash(hash);

      if (current.role === UserRole.admin || current.mode === 'impostor') {
        NotifyHelper.debug({
          message_md: 'Machine snapshot created!',
          buttons: [
            {
              label: 'Open URL',
              dismissAfterClick: true,
              onClick: () => window.open(url),
            },
          ],
        });
      }

      return {
        event: se,
        url: url,
      };
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md: t('common.request-failed-msg'),
      });
    }
  };

  const _updateIntercomURL = async (pitch_id: string | undefined) => {
    try {
      const result = await _getIntercomURL(pitch_id);
      if (!result) {
        return;
      }

      setIntercomURL(result.url);

      // store the new id for use later
      setCookie(CookieKey.app, {
        past_snapshot_ids: [...snapshot.past_snapshot_ids, result.event._id],
      });

      // create component to run the startup fn, don't draw to avoid flickering
      const ghost = <IntercomSnapshotUpdater url={result.url} />;

      NotifyHelper.debug({
        message_md: 'Machine snapshot URL updated',
        buttons: [
          {
            label: 'Open',
            onClick: () => window.open(result.url),
            dismissAfterClick: true,
          },
        ],
      });

      return ghost;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md: t('common.request-failed-msg'),
      });
    }
  };

  /** fetch the data whenever machineID changes */
  useEffect(() => {
    async function asyncCallback() {
      const result = await MachinesService.getInstance().getByMachineID(
        current.machineID
      );

      if (!result) {
        NotifyHelper.warning({
          message_md: 'Session expired, please login and try again.',
        });

        /** suppress the usual logout message since this is automatically triggered */
        logout(true);
        return;
      }

      // reset hw warned flag whenever changing active machine
      if (machine.machineID !== result.machine.machineID) {
        setHardwareWarned(false);
      }

      safeSetMachine({
        machine: result.machine,
        silently: false,
      });

      if (status.calibrated !== result.calibrated) {
        setStatus((prev) => ({
          ...prev,
          calibrated: result.calibrated,
        }));
      }
    }

    if (!current.machineID) {
      setMachine(DEFAULT.machine);
      setStatus(DEFAULT.lastStatus);
      return;
    }

    asyncCallback();
  }, [current.machineID]);

  useEffect(() => {
    if (recentFiredPitch && recentFiredPitch._id) {
      setTimeout(() => {
        /** clear recently fired after a delay */
        setRecentFiredPitch(undefined);
      }, RECENTLY_FIRED_DURATION);
    }
  }, [recentFiredPitch]);

  useEffect(() => {
    if (current.auth) {
      MachinesService.getInstance()
        .getModelsDictionary()
        .then((result) => {
          setModelDict(result);
        });
    } else {
      setModelDict({});
    }
  }, [current.auth]);

  // whenever ball count is updated (even if the number doesn't change)
  // run the logic to show / hide the inspect option and the notification toast
  useEffect(() => {
    if (ballCount === undefined) {
      // we have no idea what's what yet
      return;
    }

    if (ballCount === 1) {
      // nothing is wrong
      return;
    }

    if (ballStatusToast) {
      // toast is already visible
      return;
    }

    switch (specialMode) {
      case 'empty-carousel':
      case 'inspect-machine':
        return;

      default: {
        const buttons: INotificationButton[] = [
          {
            label: t('main.inspect'),
            onClick: () => setDialogInspect(Date.now()),
            dismissAfterClick: true,
          },
          {
            label: t('common.reset-position'),
            onClick: () => _sendSpecialMsTarget(SpecialMsPosition.home),
            dismissAfterClick: true,
          },
        ];

        switch (ballCount) {
          case -1: {
            if (SUPPRESS_LOW_LIGHT_WARNING) {
              return;
            }

            setBallStatusToast(true);
            NotifyHelper.warning({
              delay_ms: 0,
              header: 'common.lighting-error',
              message_md: t('common.lighting-error-msg'),
              buttons: buttons,
              onClose: () => setBallStatusToast(false),
            });
            break;
          }

          case 0: {
            // no balls detected
            buttons.splice(0, 0, {
              label: t('main.drop-ball'),
              onClick: () => performDropball(true, 'ball-status toast'),
              dismissAfterClick: true,
            });

            setBallStatusToast(true);
            NotifyHelper.warning({
              delay_ms: 0,
              header: 'common.no-ball-detected',
              message_md: t('common.no-ball-detected-msg'),
              buttons: buttons,
              onClose: () => setBallStatusToast(false),
            });
            break;
          }

          default: {
            if (ballCount > 1) {
              // multiple balls detected
              setBallStatusToast(true);
              NotifyHelper.warning({
                delay_ms: 0,
                header: 'common.multiple-balls-detected',
                message_md: t('common.multiple-balls-detected-msg'),
                buttons: buttons,
                onClose: () => setBallStatusToast(false),
              });
            }
            break;
          }
        }

        return;
      }
    }
  }, [ballDate]);

  useEffect(() => {
    _getIntercomURL(snapshot.pitch_id).then((result) => {
      if (!result) {
        NotifyHelper.debug({
          message_md: 'Failed to create initial machine snapshot.',
        });
        return;
      }

      setIntercomURL(result.url);
    });
  }, []);

  return (
    <MachineContext.Provider value={state}>
      {dialogSettings && <SettingsDialog {...dialogSettings} />}

      {intercomURL && (
        <IntercomProvider
          appId={env.integrations.intercom_app_id}
          onShow={() => _updateIntercomURL(snapshot.pitch_id)}
          autoBootProps={{
            email: current.email,
            userId: current.userID,
            customAttributes: {
              'Session ID': current.session,
              'Team ID': current.teamID,
              Team: current.team,
              League: current.league,
              Machine: current.machineID,
              'Machine Nickname': current.machineNickname ?? '(none)',
              'Machine Snapshots URL': intercomURL,
            },
          }}
          autoBoot
        >
          <IntercomListener />
          {props.children}

          {dialogHealthCheck && (
            <HealthCheckDialog
              key={dialogHealthCheck}
              onClose={() => setDialogHealthCheck(undefined)}
            />
          )}

          <MachineContext.Consumer>
            {(machineCx) => (
              <>
                {dialogInspect && (
                  <MachineInspectionDialogHoC
                    key={dialogInspect}
                    machineCx={machineCx}
                    identifier="MachineInspectionDialog"
                    onClose={() => setDialogInspect(undefined)}
                  />
                )}

                {dialogStereoCheck && (
                  <StereoCheckDialogHoC
                    key={dialogStereoCheck}
                    machineCx={machineCx}
                    identifier="MachineInspectionDialog"
                    onClose={() => setDialogStereoCheck(undefined)}
                  />
                )}

                <NotificationListener
                  cookiesCx={cookiesCx}
                  inboxCx={inboxCx}
                  machineCx={machineCx}
                />
              </>
            )}
          </MachineContext.Consumer>

          {dialogInstallation && (
            <InstallationDialogHoC
              key={dialogInstallation}
              identifier="MachineInspectionDialog"
              onClose={() => setDialogInstallation(undefined)}
            />
          )}

          {dialogDC && (
            <MachineConnectionDialog
              key={dialogDC}
              identifier="MachineConnectionDialog"
              onClose={() => setDialogDC(undefined)}
            />
          )}

          {dialogRequestControl && (
            <MachineControlDialog
              key={dialogRequestControl}
              identifier="MachineControlDialog"
              onClose={() => setDialogRequestControl(undefined)}
            />
          )}

          {dialogR2F && (
            <R2FStatusDialog
              key={dialogR2F}
              identifier="R2FStatusDialog"
              onClose={() => setDialogR2F(undefined)}
            />
          )}

          {dialogRealign && (
            <RealignMachineDialog
              key={dialogRealign}
              onClose={() => setDialogRealign(undefined)}
            />
          )}
        </IntercomProvider>
      )}
    </MachineContext.Provider>
  );
};
