import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  IconButton,
} from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { IGlobalContext } from 'contexts/global.context';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { numberToImperial } from 'lib_ts/classes/math.utilities';
import { StaticVideoType } from 'lib_ts/enums/machine-msg.enum';
import { HardwareVersion, MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  DEFAULT_MACHINE_STATE,
  IMachineState,
} from 'lib_ts/interfaces/i-machine-state';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { Component } from 'react';
import Slider from 'react-input-slider';
import './index.scss';

interface IPositionDetails {
  header: string;
  decimal: string;
  imperial: string;
  adjust: {
    axis: 'x' | 'y';
    increaseIcon: JSX.Element;
    decreaseIcon: JSX.Element;
  };
}

const THUMB_HT_PX = 320;

const ENABLE_KEYBOARD_AJUSTMENT = false;
const ENABLE_BUTTON_ADJUSTMENT = false;

/** for adjusting machine position via keyboard input */
const DIR_Y_INC = 'ArrowUp';
const DIR_Y_DEC = 'ArrowDown';
const DIR_X_DEC = 'ArrowLeft';
const DIR_X_INC = 'ArrowRight';

const DIR_KEY_CODES = [DIR_Y_DEC, DIR_Y_INC, DIR_X_DEC, DIR_X_INC];

/** given in inches */
const DIR_PRECISION_INCHES = {
  /** i.e. 0.01 feet per press */
  CTRL_SHIFT: 0.12,
  CTRL: 1,
  SHIFT: 3,
};

const BOUNDARY = {
  /** governs overall boundary for slider */
  SLIDER_X: {
    MIN: -6,
    MAX: 6,
    DEFAULT: 0,
    STEP: 0.1,
  },
  /** governs selectable values within slider */
  VALID_X: MS_LIMITS.POSITION.X,
  /** governs overall boundary for slider */
  SLIDER_Y: {
    MIN: 0,
    MAX: 9,
    DEFAULT: 4.2,
    STEP: 0.1,
  },
  /** governs selectable values within slider */
  VALID_Y: MS_LIMITS.POSITION.Z,
};

interface IProps {
  machineCx: IMachineContext;
  onUpdate: (pos: { px: number; pz: number }) => void;
}

interface IState {
  sliderSide: number;
  sliderHeight: number;
}

export class MachinePositionView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sliderSide: props.machineCx.lastMS?.px ?? DEFAULT_MACHINE_STATE.px,
      sliderHeight: props.machineCx.lastMS?.pz ?? DEFAULT_MACHINE_STATE.pz,
    };

    this.adjustPosition = this.adjustPosition.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.resetPosition = this.resetPosition.bind(this);
    this.sendToMachine = this.sendToMachine.bind(this);
  }

  /**
   *
   * @param axis 'x' or 'y'
   * @param amount given in feet
   */
  private adjustPosition(axis: string, amount: number) {
    /** component is updating sliders, trigger parent's onUpdate with end results */
    const callback = (x: number, y: number) =>
      this.props.onUpdate({ px: x, pz: y });

    switch (axis) {
      case 'x': {
        this.setState(
          {
            sliderSide: this.confineToBounds(
              this.state.sliderSide + amount,
              BOUNDARY.VALID_X
            ),
          },
          () => callback(this.state.sliderSide, this.state.sliderHeight)
        );
        break;
      }

      case 'y': {
        this.setState(
          {
            sliderHeight: this.confineToBounds(
              this.state.sliderHeight + amount,
              BOUNDARY.VALID_Y
            ),
          },
          () => callback(this.state.sliderSide, this.state.sliderHeight)
        );
        break;
      }

      default: {
        break;
      }
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    if ((this.context as IGlobalContext).dialogs.length > 0) {
      return;
    }

    if (!event.ctrlKey && !event.shiftKey) {
      return;
    }

    if (!DIR_KEY_CODES.includes(event.code)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.repeat) {
      return;
    }

    /** in feet */
    const deltaFt: number = (() => {
      if (event.ctrlKey && event.shiftKey) {
        return DIR_PRECISION_INCHES.CTRL_SHIFT / 12;
      }

      if (event.ctrlKey) {
        /** precise */
        return DIR_PRECISION_INCHES.CTRL / 12;
      }

      if (event.shiftKey) {
        /** rough */
        return DIR_PRECISION_INCHES.SHIFT / 12;
      }

      return 0;
    })();

    switch (event.code) {
      case DIR_X_INC: {
        this.adjustPosition('x', deltaFt);
        return;
      }

      case DIR_X_DEC: {
        this.adjustPosition('x', -deltaFt);
        return;
      }
      case DIR_Y_INC: {
        this.adjustPosition('y', deltaFt);
        return;
      }

      case DIR_Y_DEC: {
        this.adjustPosition('y', -deltaFt);
        return;
      }

      default: {
        return;
      }
    }
  }

  componentDidMount() {
    if (ENABLE_KEYBOARD_AJUSTMENT) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    if (ENABLE_KEYBOARD_AJUSTMENT) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  private handleChange(pos: { x: number; y: number }) {
    this.setState({
      sliderSide: pos.x,
      sliderHeight: pos.y,
    });
  }

  private confineToBounds(
    value: number,
    bounds: { MIN: number; MAX: number }
  ): number {
    return Math.max(bounds.MIN, Math.min(bounds.MAX, value));
  }

  private sendToMachine() {
    const msTarget: IMachineStateMsg = {
      ...DEFAULT_MACHINE_STATE,
      px: this.state.sliderSide,
      pz: this.state.sliderHeight,
      video_uuid: StaticVideoType.screensaver,
    };

    this.props.machineCx.sendRawTarget(msTarget, 'MachinePositionView', false);
  }

  private resetPosition(ms?: IMachineState) {
    this.setState({
      sliderSide: ms ? ms.px : BOUNDARY.SLIDER_X.DEFAULT,
      sliderHeight: ms ? ms.pz : BOUNDARY.SLIDER_Y.DEFAULT,
    });
  }

  render() {
    const position_x = numberToImperial(this.state.sliderSide);
    const position_y = numberToImperial(this.state.sliderHeight);

    const imageDir = (() => {
      switch (this.props.machineCx.machine.hardware_version) {
        case HardwareVersion.Arc2023:
          return '/img/wireframes/Arc 2023';

        default:
          return '/img/wireframes/Arc 2022';
      }
    })();

    const sliderStyle = {
      track: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        // backgroundImage: `url("${imageDir}/hdpe-sides.png")`,
        // backgroundPosition: 'center',
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        width: 'auto',
        height: '100%',
      },
      thumb: {
        backgroundImage: `url("${imageDir}/hdpe-center-alt.png")`,
        backgroundColor: 'rgba(0, 255, 0, 0)',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: `${THUMB_HT_PX * 0.8}px`,
        height: `${THUMB_HT_PX}px`,
        boxShadow: 'none',
        opacity: 1,
      },
    };

    const positionDetails: IPositionDetails[] = [
      {
        header: 'SIDE',
        imperial: `${position_x.sign === -1 ? '-' : ''} ${position_x.ft}' ${
          position_x.in
        }"`,
        decimal: `${position_x.originalFt.toFixed(2)} ft`,
        adjust: {
          axis: 'x',
          increaseIcon: <ArrowRightIcon />,
          decreaseIcon: <ArrowLeftIcon />,
        },
      },
      {
        header: 'HEIGHT',
        imperial: `${position_y.sign === -1 ? '-' : ''} ${position_y.ft}' ${
          position_y.in
        }"`,
        decimal: `${position_y.originalFt.toFixed(2)} ft`,
        adjust: {
          axis: 'y',
          increaseIcon: <ArrowUpIcon />,
          decreaseIcon: <ArrowDownIcon />,
        },
      },
    ];

    return (
      <ErrorBoundary componentName="MachinePositionView">
        <div className="MachineView">
          <Grid columns="5" gap={RADIX.FLEX.GAP.LG}>
            <Box gridColumn="span 4">
              <div className="sizer">
                <div className="slider-wrapper">
                  <Slider
                    axis="xy"
                    xstep={BOUNDARY.SLIDER_X.STEP}
                    xmin={BOUNDARY.SLIDER_X.MIN}
                    xmax={BOUNDARY.SLIDER_X.MAX}
                    x={this.state.sliderSide}
                    ystep={BOUNDARY.SLIDER_Y.STEP}
                    ymin={BOUNDARY.SLIDER_Y.MIN}
                    ymax={BOUNDARY.SLIDER_Y.MAX}
                    y={this.state.sliderHeight}
                    onChange={this.handleChange}
                    onDragEnd={() => {
                      /** wait for onChange to finish */
                      setTimeout(() => {
                        this.props.onUpdate({
                          px: this.confineToBounds(
                            this.state.sliderSide,
                            BOUNDARY.VALID_X
                          ),
                          pz: this.confineToBounds(
                            this.state.sliderHeight,
                            BOUNDARY.VALID_Y
                          ),
                        });

                        this.setState({
                          sliderSide: this.confineToBounds(
                            this.state.sliderSide,
                            BOUNDARY.VALID_X
                          ),
                          sliderHeight: this.confineToBounds(
                            this.state.sliderHeight,
                            BOUNDARY.VALID_Y
                          ),
                        });
                      }, 100);
                    }}
                    styles={sliderStyle}
                    yreverse
                  />
                </div>
              </div>
            </Box>

            <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
              <Box pl={RADIX.BOX.PAD.MD}>
                <ul className="no-style no-padding">
                  {positionDetails.map((item, index) => (
                    <li key={index}>
                      {item.header}: {item.imperial} ({item.decimal})
                    </li>
                  ))}
                </ul>
              </Box>
              <Heading size={RADIX.HEADING.SIZE.MD}>Controls</Heading>
              <Button
                disabled={this.props.machineCx.loading}
                onClick={() => this.resetPosition(this.props.machineCx.lastMS)}
              >
                Reset (Current)
              </Button>

              <Button
                disabled={this.props.machineCx.loading}
                onClick={() => this.resetPosition()}
              >
                Reset (Default)
              </Button>

              <Button
                color={RADIX.COLOR.SEND_PITCH}
                disabled={this.props.machineCx.loading}
                onClick={() => this.sendToMachine()}
              >
                {t('common.load-pitch')}
              </Button>

              {ENABLE_BUTTON_ADJUSTMENT && (
                <Flex gap={RADIX.FLEX.GAP.SM}>
                  {positionDetails.map((item, index) => (
                    <Flex
                      key={`pos-details-${index}`}
                      direction="column"
                      align="center"
                    >
                      <Heading size={RADIX.HEADING.SIZE.SM}>
                        {item.header}
                      </Heading>

                      <Card>
                        <Grid
                          columns="2"
                          gap={RADIX.FLEX.GAP.SM}
                          align="center"
                        >
                          <Box gridColumn="span 2">
                            <Heading size={RADIX.HEADING.SIZE.XS}>
                              {item.imperial}
                            </Heading>
                          </Box>

                          <Box gridColumn="span 2">
                            <Heading size={RADIX.HEADING.SIZE.XS}>
                              {item.decimal}
                            </Heading>
                          </Box>

                          <Box>
                            <IconButton
                              variant="soft"
                              onClick={() =>
                                this.adjustPosition(item.adjust.axis, -0.01)
                              }
                            >
                              {item.adjust.decreaseIcon}
                            </IconButton>
                          </Box>

                          <Box>
                            <IconButton
                              variant="soft"
                              onClick={() =>
                                this.adjustPosition(item.adjust.axis, 0.01)
                              }
                            >
                              {item.adjust.increaseIcon}
                            </IconButton>
                          </Box>
                        </Grid>
                      </Card>
                    </Flex>
                  ))}
                </Flex>
              )}
            </Flex>
          </Grid>
        </div>
      </ErrorBoundary>
    );
  }
}
