import {
  Cross2Icon,
  MagnifyingGlassIcon,
  ResetIcon,
} from '@radix-ui/react-icons';
import { Box, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTableButton } from 'components/common/table/button';
import { AuthContext } from 'contexts/auth.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { PITCH_TYPE_FILTER_OPTIONS } from 'enums/filters';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import {
  PITCH_LIST_TYPES,
  PitchListExtType,
  PitchType,
  TRAINING_STATUS_OPTIONS,
  TrainingStatus,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';

export const PitchListsToolbar = () => {
  const { current } = useContext(AuthContext);
  const isSuperAdmin = current.role === UserRole.admin;

  const [searchName, setSearchName] = useState('');
  const { toolbarFilters: filters, setToolbarFilters: setFilter } =
    useContext(PitchListsContext);

  // TODO: create a common debounced search input we can use everywhere
  const onSearch = useCallback(
    debounce((value: string) => {
      setFilter({
        name: value,
      });
    }, 300),
    []
  );

  useEffect(() => {
    onSearch(searchName);
  }, [searchName, onSearch]);

  const resetFilters = () => {
    setSearchName('');
    setFilter({
      name: '',
      training_status: [],
      pitch_type: [],
      visibility: undefined,
      type: undefined,
      key: Date.now(),
    });
  };

  const columns = isSuperAdmin ? '6' : '5';

  return (
    <Grid columns={columns} gap={RADIX.FLEX.GAP.SM}>
      <Box>
        <CommonTextInput
          id="pitch-list-search-name"
          name="name"
          placeholder="common.pitch-list-name"
          value={searchName}
          onChange={(v = '') => setSearchName(v)}
          rightSlot={
            searchName ? (
              <Cross2Icon
                className="cursor-pointer"
                onClick={() => setSearchName('')}
              />
            ) : (
              <MagnifyingGlassIcon />
            )
          }
        />
      </Box>
      <Box>
        <CommonSearchInput
          key={`status-${filters.key}`}
          id="pitch-lists-status"
          placeholder="pl.status"
          options={TRAINING_STATUS_OPTIONS}
          values={filters.training_status}
          onChange={(v) => {
            setFilter({
              training_status: v as TrainingStatus[],
            });
          }}
          multiple
        />
      </Box>
      <Box>
        <CommonSearchInput
          key={`pitch-type-${filters.key}`}
          id="pitch-lists-type"
          name="filter-type"
          placeholder="pl.pitch-type"
          options={PITCH_TYPE_FILTER_OPTIONS}
          values={filters.pitch_type}
          onChange={(v) =>
            setFilter({
              pitch_type: v as PitchType[],
            })
          }
          multiple
          skipSort
        />
      </Box>
      <Box>
        <CommonSelectInput
          key={`vis-${filters.key}`}
          id="pitch-lists-visibility"
          name="filter-visibility"
          placeholder="common.visibility"
          options={[
            {
              label: 'common.personal',
              value: PitchListOwner.User,
            },
            {
              label: 'common.machine',
              value: PitchListOwner.Machine,
            },
            {
              label: 'common.team',
              value: PitchListOwner.Team,
            },
          ]}
          value={filters.visibility}
          onChange={(v) =>
            setFilter({
              visibility: v as PitchListOwner,
            })
          }
          optional
        />
      </Box>
      {isSuperAdmin && (
        <Box>
          <CommonSelectInput
            key={`type-${filters.key}`}
            id="pitch-lists-type"
            name="filter-type"
            placeholder="pl.list-type"
            inputColor={RADIX.COLOR.SUPER_ADMIN}
            options={PITCH_LIST_TYPES}
            value={filters.type}
            onChange={(v) =>
              setFilter({
                type: v as PitchListExtType,
              })
            }
            optional
          />
        </Box>
      )}
      <Box>
        <CommonTableButton
          label="common.reset"
          icon={<ResetIcon />}
          className="btn-block"
          variant="soft"
          onClick={resetFilters}
        />
      </Box>
    </Grid>
  );
};
