import { SectionHeader } from 'components/sections/header';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { useContext, useMemo } from 'react';

interface IProps {
  actions: IMenuAction[] | undefined;
  mainAction: IMenuAction | undefined;
}

export const Header = (props: IProps) => {
  const { active } = useContext(PitchListsContext);
  const { tryChangeSection } = useContext(SectionsContext);

  const badge = useMemo(() => {
    if (!active) {
      return;
    }

    if (active.type) {
      switch (active.type) {
        case PitchListExtType.Card: {
          return 'pl.player-card';
        }

        case PitchListExtType.Reference: {
          if (active.live) {
            return 'pl.reference-list-live';
          }

          return 'pl.reference-list';
        }

        case PitchListExtType.Sample: {
          return 'pl.sample-list';
        }

        default: {
          return;
        }
      }
    }

    switch (active._parent_def) {
      case PitchListOwner.Team: {
        return 'pl.team-list';
      }

      case PitchListOwner.User: {
        return 'pl.personal-list';
      }

      case PitchListOwner.Machine: {
        return 'pl.machine-list';
      }

      default: {
        return;
      }
    }
  }, [active]);

  const header = useMemo(() => {
    if (!active) {
      return t('common.loading').toString();
    }

    return active.name;
  }, [active]);

  return (
    <SectionHeader
      header={header}
      badge={badge ? t(badge) : undefined}
      mainAction={props.mainAction}
      actions={props.actions}
      breadcrumbs={[
        {
          label: 'main.pitches',
          onClick: () =>
            tryChangeSection({
              section: SectionName.Pitches,
              subsection: SubSectionName.Library,
              trigger: 'PitchList > breadcrumbs',
            }),
        },
        {
          label: 'main.pitch-lists',
          onClick: () =>
            tryChangeSection({
              section: SectionName.Pitches,
              subsection: SubSectionName.Lists,
              trigger: 'PitchList > breadcrumbs',
            }),
        },
        {
          label: header,
        },
      ]}
    />
  );
};
