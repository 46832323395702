import { PlusIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Grid } from '@radix-ui/themes';
import { CommonTableButton } from 'components/common/table/button';
import { TeamsContext } from 'contexts/admin/teams.context';
import { AuthContext } from 'contexts/auth.context';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { TeamDialog } from 'lib_ts/enums/teams.enums';
import { useContext } from 'react';

export const TeamsTableToolbar = () => {
  const { current } = useContext(AuthContext);

  const { loading, refresh, openDialog } = useContext(TeamsContext);

  return (
    <Grid columns="6" gap={RADIX.FLEX.GAP.SM}>
      <CommonTableButton
        color={RADIX.COLOR.NEUTRAL}
        icon={<UpdateIcon />}
        label="common.refresh"
        disabled={loading}
        onClick={() => refresh()}
      />

      {current.role === UserRole.admin && (
        <CommonTableButton
          color={RADIX.COLOR.SUCCESS}
          icon={<PlusIcon />}
          label="common.create"
          disabled={loading}
          onClick={() =>
            openDialog({
              mode: TeamDialog.Editor,
              team: undefined,
            })
          }
        />
      )}
    </Grid>
  );
};
