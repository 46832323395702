import { StringHelper } from 'classes/helpers/string.helper';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IServerErrorSummary } from 'lib_ts/interfaces/i-server-error';
import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ServerErrorsService } from 'services/admin/server-errors.service';

const CONTEXT_NAME = 'ServerErrorsContext';

interface IFilters {
  categories: string[];
  levels: string[];
  _created: string[];
}

interface IOptionsDict {
  categories: IOption[];
  levels: IOption[];
  _created: IOption[];
}

export interface IServerErrorsContext {
  loading: boolean;
  filtered: IServerErrorSummary[];
  readonly refresh: () => void;

  options: IOptionsDict;
  filters: IFilters;
  readonly mergeFilters: (value: Partial<IFilters>) => void;
}

const DEFAULT: IServerErrorsContext = {
  loading: false,
  filtered: [],
  refresh: () => console.error(`${CONTEXT_NAME}: not init`),

  options: {
    categories: [],
    levels: [],
    _created: [],
  },
  filters: {
    categories: [],
    levels: [],
    _created: [],
  },
  mergeFilters: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const ServerErrorsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const ServerErrorsProvider: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(DEFAULT.loading);
  const [lastFetched, setLastFetched] = useState(new Date());

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    setLoading(true);

    ServerErrorsService.getInstance()
      .getSummaries()
      .then((result) => {
        setSummaries(result);
      })
      .finally(() => setLoading(false));
  }, [lastFetched]);

  const [filters, setFilters] = useState(DEFAULT.filters);

  const [summaries, setSummaries] = useState(DEFAULT.filtered);

  const filtered = useMemo(
    () =>
      summaries
        .filter(
          (m) =>
            filters.categories.length === 0 ||
            filters.categories.includes(m.category)
        )
        .filter(
          (m) => filters.levels.length === 0 || filters.levels.includes(m.level)
        )
        .filter(
          (m) =>
            filters._created.length === 0 ||
            filters._created.includes(
              lightFormat(parseISO(m._created), 'yyyy-MM-dd')
            )
        ),
    [summaries, filters]
  );

  const options = useMemo(() => {
    const output: IOptionsDict = {
      categories: ArrayHelper.unique(summaries.map((m) => m.category)).map(
        (m) => {
          const o: IOption = {
            label: StringHelper.capitalize(m),
            value: m,
          };

          return o;
        }
      ),

      levels: ArrayHelper.unique(summaries.map((m) => m.level)).map((m) => {
        const o: IOption = {
          label: m.toUpperCase(),
          value: m,
        };

        return o;
      }),

      _created: ArrayHelper.unique(
        summaries.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ).map((m) => {
        const o: IOption = {
          label: m,
          value: m,
        };

        return o;
      }),
    };

    return output;
  }, [summaries]);

  const state: IServerErrorsContext = {
    loading: loading,
    filtered: filtered,
    refresh: () => setLastFetched(new Date()),

    options: options,
    filters: filters,
    mergeFilters: (value) => {
      setFilters((prev) => ({
        ...prev,
        ...value,
      }));
    },
  };

  return (
    <ServerErrorsContext.Provider value={state}>
      {props.children}
    </ServerErrorsContext.Provider>
  );
};
