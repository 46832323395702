import {
  CopyIcon,
  DownloadIcon,
  ExclamationTriangleIcon,
  Share1Icon,
  StarFilledIcon,
  TrashIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { Box, Grid, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFileUploader } from 'components/common/file-uploader';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import {
  ITableContext,
  TableContext,
  TableProvider,
} from 'components/common/table/context';
import { CommonTooltip } from 'components/common/tooltip';
import { SectionHeader } from 'components/sections/header';
import env from 'config';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  DirtyForm,
  ISectionsContext,
  SectionsContext,
} from 'contexts/sections.context';
import { VideosFilterDateAdded } from 'contexts/videos/components/filter-date-added';
import { VideosFilterDelivery } from 'contexts/videos/components/filter-delivery';
import { VideosFilterPitchType } from 'contexts/videos/components/filter-pitch-type';
import { VideosFilterPitcher } from 'contexts/videos/components/filter-pitcher';
import {
  IVideosContext,
  STATIC_PREFIX,
  VideosContext,
} from 'contexts/videos/videos.context';
import { lightFormat } from 'date-fns';
import { ACTIONS_KEY, CrudAction, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IVideo } from 'lib_ts/interfaces/i-video';
import React, { useContext } from 'react';
import { VideosService } from 'services/videos.service';

const COMPONENT_NAME = 'VideoLibrary';

const IDENTIFIER = TableIdentifier.VideoList;

const FLAG_BY_RESOLUTION = env.production;

/** 10 inch mound */
const MOUND_HT_FT = 10 / 12;

const MAX_VIDEO_SIZE_MB = 50;

const getPixelsPerFoot = (v: IVideo) => {
  if (
    isNaN(v.ReleasePixelY) ||
    isNaN(v.MoundPixelY) ||
    isNaN(v.ReleaseHeight)
  ) {
    /** insufficient data, return "bad" result */
    return 0;
  } else {
    const height = Math.abs(v.ReleaseHeight - MOUND_HT_FT);
    return Math.abs(v.ReleasePixelY - v.MoundPixelY) / Math.max(height, 1e-9);
  }
};

interface IBaseProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  sectionsCx: ISectionsContext;
  videosCx: IVideosContext;
}

interface IProps extends IBaseProps {
  tableCx: ITableContext;
}

interface IState {
  selectedVideos: IVideo[];

  editorModel?: IVideo;

  videoProgress: number;
  videoProgressLabel: string;

  disableNext: boolean;
  disablePrev: boolean;
}

const DEFAULT_STATE: IState = {
  selectedVideos: [],

  videoProgress: 0,
  videoProgressLabel: '',
  disableNext: false,
  disablePrev: false,
};

const FILE_TYPES = [
  'text/csv',
  'video/mp4', //mp4 and m4v
  // 'video/quicktime',//mov
  // 'video/x-msvideo',//avi
];

const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

export const VideoLibraryHoC = () => {
  const props: IBaseProps = {
    globalCx: useContext(GlobalContext),
    cookiesCx: useContext(CookiesContext),
    authCx: useContext(AuthContext),
    machineCx: useContext(MachineContext),
    sectionsCx: useContext(SectionsContext),
    videosCx: useContext(VideosContext),
  };

  return (
    <TableProvider>
      <TableContext.Consumer>
        {(tableCx) => <VideoLibrary {...props} tableCx={tableCx} />}
      </TableContext.Consumer>
    </TableProvider>
  );
};

class VideoLibrary extends React.Component<IProps, IState> {
  private fileInput?: CommonFileUploader;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getCheckedMenuActions = this.getCheckedMenuActions.bind(this);
    this.handleVideoProgress = this.handleVideoProgress.bind(this);

    this.renderTable = this.renderTable.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    const { selectedData } = this.props.tableCx;

    if (
      selectedData !== undefined &&
      prevProps.tableCx.selectedData !== selectedData
    ) {
      // keep synced with next/prev buttons in dialog
      this.setState({
        editorModel: selectedData as IVideo,
      });
    }
  }

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          invisibleFn: () => true,
          onClick: (video: IVideo) => {
            this.props.videosCx.openCrudDialog({
              action: CrudAction.Update,
              models: [video],
              // to force default star to move
              onClose: () => this.props.tableCx.updateTableKey(),
            });
          },
        },
        {
          label: 'common.set-as-default',
          color: RADIX.COLOR.WARNING,
          // hide option when already the default
          invisibleFn: (video: IVideo) => {
            const { machine } = this.props.machineCx;
            const isLHP = video.ReleaseSide > 0;

            if (isLHP) {
              return video._id === machine.default_video_lhp;
            }

            return video._id === machine.default_video_rhp;
          },
          onClick: async (video: IVideo) => {
            const { machine, update } = this.props.machineCx;

            const errors = VideoHelper.getErrors(video);
            if (errors.length > 0) {
              NotifyHelper.warning({
                message_md: t('videos.default-video-issues-msg'),
              });
              return;
            }

            const payload: Partial<IMachine> = {
              _id: machine._id,
            };

            const isLHP = video.ReleaseSide > 0;
            if (isLHP) {
              payload.default_video_lhp = video._id;
            } else {
              payload.default_video_rhp = video._id;
            }

            const success = await update(payload);
            if (!success) {
              return;
            }

            NotifyHelper.info({
              message_md: t('videos.updated-default-video-for-x', {
                x: machine.machineID,
              }),
            });

            // to force default star to move
            this.props.tableCx.updateTableKey();
          },
        },
        {
          label: 'common.duplicate',
          onClick: (video: IVideo) =>
            this.props.videosCx.copyVideos([video._id]),
        },
        {
          label: 'common.download',
          suffixIcon: <SuperAdminIcon />,
          onClick: (video: IVideo) => {
            NotifyHelper.info({
              message_md: 'Preparing your download, please wait...',
            });
            this.props.videosCx
              .getCachedPlayback(video._id)
              .then((playback) => {
                if (playback?.video.url) {
                  window.open(playback.video.url);
                }
              });
          },
          disableFn: (video: IVideo) => !video.video_path,
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
        },
        {
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          disableFn: (video: IVideo) =>
            video.video_path.startsWith(STATIC_PREFIX),
          onClick: (video: IVideo) => {
            this.props.videosCx.openCrudDialog({
              action: CrudAction.Delete,
              models: [video],
            });
          },
        },
      ],
    },
    {
      label: 'common.status',
      key: '_issues',
      align: 'center',
      thClassNameFn: () => 'width-80px',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) => {
        const aDefault =
          this.props.machineCx.machine.default_video_lhp === a._id ||
          this.props.machineCx.machine.default_video_rhp === a._id;
        const bDefault =
          this.props.machineCx.machine.default_video_lhp === b._id ||
          this.props.machineCx.machine.default_video_rhp === b._id;

        if (aDefault !== bDefault) {
          return dir * (aDefault ? -1 : 1);
        }

        const aErr = VideoHelper.getErrors(a);
        const bErr = VideoHelper.getErrors(b);
        return dir * (aErr.length > bErr.length ? -1 : 1);
      },
      formatFn: (v: IVideo) => {
        const errors = VideoHelper.getErrors(v);
        if (errors.length > 0) {
          const { msg } = errors[0];

          return (
            <CommonTooltip
              text={msg}
              trigger={
                <ExclamationTriangleIcon
                  style={{ marginTop: RADIX.ICON.TABLE_MT }}
                />
              }
            />
          );
        }

        if (v._id === this.props.machineCx.machine.default_video_lhp) {
          return (
            <CommonTooltip
              text={`Default ${PitcherHand.LHP} Video`}
              trigger={
                <Text color={RADIX.COLOR.WARNING}>
                  <StarFilledIcon
                    style={{ marginTop: RADIX.ICON.TABLE_MT }}
                    className="cursor-clickable"
                    onClick={async (e) => {
                      e.stopPropagation();

                      const { machine, update } = this.props.machineCx;

                      await update({
                        _id: machine._id,
                        default_video_lhp: '',
                      });

                      NotifyHelper.info({
                        message_md: t('videos.updated-default-video-for-x', {
                          x: machine.machineID,
                        }),
                      });

                      this.props.tableCx.updateTableKey();
                    }}
                  />
                </Text>
              }
            />
          );
        }

        if (v._id === this.props.machineCx.machine.default_video_rhp) {
          return (
            <CommonTooltip
              text={`Default ${PitcherHand.RHP} Video`}
              trigger={
                <Text color={RADIX.COLOR.WARNING}>
                  <StarFilledIcon
                    style={{ marginTop: RADIX.ICON.TABLE_MT }}
                    className="cursor-clickable"
                    onClick={async (e) => {
                      e.stopPropagation();

                      const { machine, update } = this.props.machineCx;

                      await update({
                        _id: machine._id,
                        default_video_rhp: '',
                      });

                      NotifyHelper.info({
                        message_md: t('videos.updated-default-video-for-x', {
                          x: machine.machineID,
                        }),
                      });

                      this.props.tableCx.updateTableKey();
                    }}
                  />
                </Text>
              }
            />
          );
        }

        return <></>;
      },
    },
    {
      label: 'common.created',
      key: '_created',
      dataType: 'date',
    },
    {
      label: 'common.file-name',
      key: 'VideoFileName',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.VideoFileName ?? '').localeCompare(b.VideoFileName ?? '') * dir,
    },
    {
      label: 'common.title',
      key: 'VideoTitle',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.VideoTitle ?? '').localeCompare(b.VideoTitle ?? '') * dir,
    },
    {
      label: 'common.pitcher',
      key: 'PitcherFullName',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.PitcherFullName ?? '').localeCompare(b.PitcherFullName ?? '') * dir,
    },
    {
      label: 'common.team',
      key: 'TeamName',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.TeamName ?? '').localeCompare(b.TeamName ?? '') * dir,
    },
    {
      label: 'common.type',
      key: 'PitchType',
      align: 'center',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.PitchType ?? '').localeCompare(b.PitchType ?? '') * dir,
    },
    {
      label: 'videos.pitch-delivery-type',
      key: 'DeliveryType',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.DeliveryType ?? '').localeCompare(b.DeliveryType ?? '') * dir,
    },
    {
      label: 'videos.fps',
      key: 'fps',
      align: 'right',
      formatFn: (v: IVideo) => {
        return isNaN(v.fps) ? undefined : v.fps.toFixed(2);
      },
      colorFn: (v: IVideo) => {
        if (v.fps >= 59.94) {
          return RADIX.COLOR.SUCCESS;
        }

        if (v.fps >= 24) {
          return RADIX.COLOR.WARNING;
        }

        return RADIX.COLOR.DANGER;
      },
    },
    {
      label: 'videos.resolution',
      key: 'resolution',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) => {
        if (a.cap_size_1 === b.cap_size_1) {
          return dir * (a.cap_size_0 > b.cap_size_0 ? -1 : 1);
        }

        return dir * (a.cap_size_1 > b.cap_size_1 ? -1 : 1);
      },
      formatFn: (v: IVideo) => `${v.cap_size_1}x${v.cap_size_0}`,
      colorFn: (v: IVideo) => {
        if (FLAG_BY_RESOLUTION) {
          if (v.cap_size_0 >= 1080) {
            return RADIX.COLOR.SUCCESS;
          }

          if (v.cap_size_0 >= 720) {
            return RADIX.COLOR.WARNING;
          }

          return RADIX.COLOR.DANGER;
        }

        const p = getPixelsPerFoot(v);
        if (p >= 120) {
          return RADIX.COLOR.SUCCESS;
        }

        if (p >= 80) {
          return RADIX.COLOR.WARNING;
        }

        return RADIX.COLOR.DANGER;
      },
    },
    {
      label: 'common.updated',
      key: '_changed',
      dataType: 'datetime',
    },
    {
      label: 'common.updated-by',
      key: 'changer',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.changer ?? '').localeCompare(b.changer ?? '') * dir,
    },
    {
      label: 'common.shared',
      key: 'original_id',
      align: 'center',
      formatFn: (v: IVideo) =>
        v.original_id ? (
          <Text title="This video was shared with your team">
            <Share1Icon style={{ marginTop: RADIX.ICON.TABLE_MT }} />
          </Text>
        ) : (
          ''
        ),
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        ((a.original_id ? 1 : 0) > (b.original_id ? 1 : 0) ? -1 : 1) * dir,
    },
  ];

  private async onCSVChange(files: File[]): Promise<void> {
    NotifyHelper.info({
      message_md: 'CSV import started. Please wait as this may take a while...',
      delay_ms: 15_000,
    });

    return this.props.videosCx.uploadVideosCSV(files).then((success) => {
      if (success) {
        NotifyHelper.success({
          message_md: 'CSV import processed successfully!',
        });
      } else {
        NotifyHelper.error({
          message_md: `CSV import was not processed successfully. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });
      }
    });
  }

  private handleVideoProgress(ev: ProgressEvent) {
    if (ev.total === 0) {
      return;
    }

    const percent = (100 * ev.loaded) / ev.total;

    this.setState({
      videoProgress: percent,
      videoProgressLabel:
        ev.loaded < ev.total
          ? `${percent.toFixed(0)}%`
          : 'Upload complete, processing...',
    });
  }

  /** only triggered by uploader if at least 1 selected file is valid (based on format + size) */
  private async onFilesChange(files: File[]): Promise<void> {
    if (files.filter((f) => f.type !== 'text/csv').length === 0) {
      // the upload is an import file for updating existing video metadata
      this.onCSVChange(files);
      return;
    }

    /** prevent/warn users before leaving if uploads are still processing */
    this.props.sectionsCx.markDirtyForm(DirtyForm.VideoLibrary);
    return this.props.videosCx
      .uploadVideos(false, files, this.handleVideoProgress)
      .then(() => {
        /** hide the upload bar after a pause */
        setTimeout(() => {
          this.setState({
            videoProgress: DEFAULT_STATE.videoProgress,
            videoProgressLabel: DEFAULT_STATE.videoProgressLabel,
          });
        }, 2_000);
      })
      .finally(() =>
        this.props.sectionsCx.clearDirtyForm(DirtyForm.VideoLibrary)
      );
  }

  private getCheckedMenuActions(checked: IVideo[]): IMenuAction[] {
    const removable = checked.filter(
      (v) => !v.video_path.startsWith(STATIC_PREFIX)
    );

    const output: IMenuAction[] = [
      {
        prefixIcon: <DownloadIcon />,
        label: 'common.export-selected',
        invisible: checked.length === 0,
        onClick: () => {
          const ids = checked.map((v) => v._id);

          VideosService.getInstance()
            .exportCSV(ids)
            .then((csvString) => {
              const blob = new Blob([csvString], { type: 'text/csv' });
              MiscHelper.saveAs(
                blob,
                `video-library_checked_${lightFormat(
                  new Date(),
                  'yyyy-MM-dd'
                )}.csv`
              );
            });
        },
      },
      {
        prefixIcon: <CopyIcon />,
        label: 'common.duplicate-selected',
        color: RADIX.COLOR.WARNING,
        invisible: checked.length === 0,
        onClick: () =>
          this.props.videosCx.copyVideos(checked.map((v) => v._id)),
      },
      {
        prefixIcon: <TrashIcon />,
        label: 'common.delete-selected',
        color: RADIX.COLOR.DANGER,
        invisible: removable.length === 0,
        onClick: () => {
          this.props.videosCx.openCrudDialog({
            action: CrudAction.Delete,
            models: removable,
          });
        },
      },
    ];

    return output;
  }

  private renderTable() {
    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      total: this.props.videosCx.filteredVideos.length,
      enablePagination: true,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      defaultSort: { key: '_created', dir: 1 },
      enableSort: true,
    };

    const select: ITableSelectable = {
      enableSelect: true,
      afterChangeSelected: (video: IVideo | undefined) => {
        if (!video) {
          return;
        }

        this.props.videosCx.openCrudDialog({
          action: CrudAction.Update,
          models: [video],
          // to force default star to move
          onClose: () => this.props.tableCx.updateTableKey(),
        });
      },
    };

    const checkable: ITableCheckable = {
      checkboxColumnIndex: 0,
      checkedActions: this.getCheckedMenuActions,
    };

    return (
      <CheckedProvider data={this.props.videosCx.filteredVideos}>
        <CheckedContext.Consumer>
          {(checkedCx) => (
            <CommonTable
              id={COMPONENT_NAME}
              data-testid={COMPONENT_NAME}
              rowTestLocatorFn={(v: IVideo) =>
                VideoHelper.getErrors(v).length === 0
                  ? 'no-issues'
                  : 'has-issues'
              }
              displayColumns={this.BASE_COLUMNS}
              displayData={this.props.videosCx.filteredVideos}
              toolbarContent={
                <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
                  <Box>
                    <VideosFilterPitcher onChange={checkedCx.resetChecked} />
                  </Box>
                  <Box>
                    <VideosFilterPitchType onChange={checkedCx.resetChecked} />
                  </Box>
                  <Box>
                    <VideosFilterDelivery onChange={checkedCx.resetChecked} />
                  </Box>
                  <Box>
                    <VideosFilterDateAdded onChange={checkedCx.resetChecked} />
                  </Box>
                </Grid>
              }
              {...pagination}
              {...checkable}
              {...select}
              {...sort}
              vFlex
            />
          )}
        </CheckedContext.Consumer>
      </CheckedProvider>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="VideoLibrary">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <SectionHeader
              header={t('main.videos')}
              actions={[
                {
                  suffixIcon: <UploadIcon />,
                  label: 'common.upload-files',
                  tooltip: this.fileInput?.getTooltip(),
                  onClick: () => this.fileInput?.handleClick(),
                },
                {
                  suffixIcon: <DownloadIcon />,
                  label: 'common.export-all',
                  invisible: this.props.videosCx.filteredVideos.length === 0,
                  onClick: async () => {
                    const ids = this.props.videosCx.filteredVideos.map(
                      (v) => v._id
                    );

                    const csvString =
                      await VideosService.getInstance().exportCSV(ids);

                    if (!csvString) {
                      NotifyHelper.error({
                        message_md: t('common.request-failed-msg'),
                      });
                      return;
                    }

                    const blob = new Blob([csvString], {
                      type: 'text/csv',
                    });

                    MiscHelper.saveAs(
                      blob,
                      `video-library_${lightFormat(
                        new Date(),
                        'yyyy-MM-dd'
                      )}.csv`
                    );
                  },
                },
              ]}
            />
          }
          table={this.renderTable()}
        />

        <CommonFileUploader
          ref={(elem) => (this.fileInput = elem as CommonFileUploader)}
          id="video-uploader"
          maxMB={MAX_VIDEO_SIZE_MB}
          acceptedTypes={FILE_TYPES}
          progress={this.state.videoProgress}
          progressLabel={this.state.videoProgressLabel}
          notifyMode="aggregate"
          onChange={(files) => this.onFilesChange(files)}
          notes={t('videos.upload-videos-tooltip')}
          multiple
          hidden
        />
      </ErrorBoundary>
    );
  }
}
