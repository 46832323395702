import { Box, Card, Checkbox, Flex, Heading, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { getPitchYearOptions } from 'classes/helpers/pitch-list.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import {
  PitchListState,
  SEARCH_ID,
} from 'components/sections/pitch-list/store/pitch-list-store';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { PITCH_TYPE_FILTER_OPTIONS } from 'enums/filters';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { ISessionCookie } from 'interfaces/cookies/i-session.cookie';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import {
  BuildPriority,
  PitchListExtType,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  IBuildPitchChars,
  IPitch,
  IPitchList,
  IPitchMetadata,
} from 'lib_ts/interfaces/pitches';
import { IVelocity } from 'lib_ts/interfaces/pitches/i-base';
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { SessionEventsService } from 'services/session-events.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'CopyPitchesDialog';

const CREATE_NEW_LIST_ID = 'create-new-list';

interface IListsDict {
  [key: string]: IPitchList[];
}

const getListOptions = (
  lists: IPitchList[],
  auth: ISessionCookie
): IOption[] => {
  const parentDict: IListsDict = {};

  const basicLists = lists.filter((l) => !l.type);

  parentDict['constants.pitch-list-personal'] = basicLists.filter(
    (l) => l._parent_def === PitchListOwner.User
  );

  if (auth?.machine_lists) {
    parentDict[
      t('constants.pitch-list-x-lists', {
        x: auth.machineID,
      }).toString()
    ] = basicLists.filter((l) => l._parent_def === PitchListOwner.Machine);
  }

  if (auth?.team_lists) {
    parentDict['constants.pitch-list-team'] = basicLists.filter(
      (l) => l._parent_def === PitchListOwner.Team
    );
  }

  const extendedLists = lists.filter((l) => !!l.type);

  if (env.enable.sample_lists && auth.role === 'admin') {
    parentDict['constants.pitch-list-samples'] = extendedLists.filter(
      (l) => l.type === PitchListExtType.Sample
    );
  }

  if (auth.role === 'admin') {
    parentDict['constants.pitch-list-references'] = extendedLists.filter(
      (l) => l.type === PitchListExtType.Reference
    );
    parentDict['constants.pitch-list-cards'] = extendedLists.filter(
      (l) => l.type === PitchListExtType.Card
    );
  }

  const options: IOption[] = [
    {
      label: t('common.add-a-new-list'),
      value: CREATE_NEW_LIST_ID,
    },
  ];

  Object.keys(parentDict).forEach((parent) => {
    const lists = parentDict[parent];
    if (lists.length === 0) {
      return;
    }

    options.push(
      ...lists.map((l) => {
        const o: IOption = {
          label: l.name,
          value: l._id,
          group: `${t(parent)}${l.folder ? `: ${l.folder}` : ''}`,
        };

        return o;
      })
    );
  });

  return options;
};

interface IProps extends IBaseDialog {
  title: ReactNode;
  description?: string;

  pitches: Partial<IPitch>[];

  /** ids of lists that should not be shown in the dropdown */
  excludedListIDs?: string[];

  duplicating?: boolean;

  // should also close the dialog when ready
  onCreated: () => void;

  reloadPitches?: PitchListState['reloadPitches'];
}

interface IMetadata extends Partial<IPitchMetadata> {
  listID?: string;
}

/** also used for saving new pitches */
export const CopyPitchesDialog = (props: IProps) => {
  const { current } = useContext(AuthContext);
  const { machine } = useContext(MachineContext);
  const { tryChangeSection } = useContext(SectionsContext);
  const {
    lists,
    loading: loadingLists,
    active: activeList,
    addListSummaryDictHashes,
  } = useContext(PitchListsContext);

  const [loading, setLoading] = useState(false);
  const [listKey, setListKey] = useState(Date.now());
  const [dialogCreateList, setDialogCreateList] = useState<number>();

  const yearOptions = useMemo(() => getPitchYearOptions(), []);

  const firstPitch = useMemo(
    () => (props.pitches.length > 0 ? props.pitches[0] : undefined),
    [props.pitches]
  );

  const [metadata, setMetadata] = useState<IMetadata>({
    name: firstPitch?.name,
    type: firstPitch?.type,
    year: firstPitch?.year,
    listID: firstPitch?._parent_id,
  });

  const mergeMetadata = useCallback(
    (v: Partial<IMetadata>) => setMetadata({ ...metadata, ...v }),
    [metadata]
  );

  const listOptions = useMemo(() => {
    const visible = lists.filter(
      (l) => !props.excludedListIDs || !props.excludedListIDs.includes(l._id)
    );

    return getListOptions(visible, current);
  }, [lists, props.excludedListIDs, current]);

  const [showScale, setShowScale] = useState(false);

  // only show breaks scaling if at least one pitch prioritizes breaks
  const hasBreaks = useMemo(
    () =>
      props.pitches.findIndex((p) => p.priority === BuildPriority.Breaks) !==
      -1,
    [props.pitches]
  );

  const hasSpins = useMemo(
    () =>
      props.pitches.findIndex(
        (p) =>
          !p.priority ||
          [BuildPriority.Default, BuildPriority.Spins].includes(p.priority)
      ) !== -1,
    [props.pitches]
  );

  const [scaleSpeed, setScaleVelo] = useState(1);
  const [scaleBreakZ, setScaleBreakZ] = useState(1);
  const [scaleBreakX, setScaleBreakX] = useState(1);

  // 100%...70% in 5% decrements
  const scaleOptions = useMemo(
    () =>
      ArrayHelper.getIntegerOptions(0, 6)
        // descending order (100% first)
        .sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? -1 : 1))
        .map((m) => {
          // e.g. 0, 5, 10, ... 30
          const delta = parseInt(m.value) * 5;
          const value = 70 + delta;

          const o: IOption = {
            // e.g. 70%
            label: `${value}%`,
            // e.g. "0.7"
            value: (value / 100).toString(),
          };

          return o;
        }),
    []
  );

  const getPitchPayloads = () => {
    const isSingle = props.pitches.length === 1;

    if (isSingle && (!metadata.name || metadata.name.trim().length === 0)) {
      NotifyHelper.error({
        message_md: 'Please provide a name for the pitch and try again.',
      });
      return [];
    }

    return props.pitches.map((p) => {
      const o: Partial<IPitch> = {
        ...p,
        _parent_id: metadata.listID,
      };

      // only update metadata from form if saving a single pitch
      if (isSingle) {
        o.name = metadata.name?.trim();
        o.type = metadata.type;
        o.year = metadata.year;
      }

      // always set plate_loc_backup based on given traj
      if (p.traj) {
        o.plate_loc_backup = TrajHelper.getPlateLoc(p.traj);
      }

      return o;
    });
  };

  const getRebuiltPitchPayloads = async () => {
    const pitches = getPitchPayloads();

    const refChars: Partial<IBuildPitchChars>[] = [];

    pitches.forEach((p) => {
      if (!p.bs) {
        console.warn(`Pitch ${p._id} has an empty bs`);
        return;
      }

      // do not let the speed drop below 50mph
      const MIN_SPEED_MPH = 50;

      const bsVelo: IVelocity = {
        vx: scaleSpeed * p.bs.vx,
        vy: -Math.max(Math.abs(scaleSpeed * p.bs.vy), MIN_SPEED_MPH),
        vz: scaleSpeed * p.bs.vz,
      };

      const o: Partial<IBuildPitchChars> = {
        mongo_id: p._id,
        priority: p.priority,
        plate: p.plate_loc_backup,
        bs: {
          ...p.bs,
          ...bsVelo,
          vnet: BallHelper.getSpeed(bsVelo),
        },
        breaks:
          p.priority !== BuildPriority.Breaks
            ? // use original breaks (if any)
              p.breaks
            : {
                xInches: scaleBreakX * (p.breaks?.xInches ?? 0),
                zInches: scaleBreakZ * (p.breaks?.zInches ?? 0),
              },
        seams: p.seams,
      };

      refChars.push(o);
    });

    if (refChars.length === 0) {
      return [];
    }

    const newChars = await StateTransformService.getInstance().buildPitches({
      machine: machine,
      notifyError: true,
      chars: refChars,
    });

    const output = newChars.map((chars) => {
      const pitch = pitches.find((m) => m._id === chars.mongo_id);

      const o: Partial<IPitch> = {
        ...pitch,
        _original_id: pitch?._id,
        name: pitch?.name?.startsWith('Scaled:')
          ? pitch.name
          : `Scaled: ${pitch?.name}`,
        priority: chars.priority,
        plate_loc_backup: chars.plate,
        traj: chars.traj,
        bs: chars.bs,
        breaks: chars.breaks,
        seams: chars.seams,
        msDict: chars.ms ? getMergedMSDict(machine, [chars.ms]) : undefined,
      };

      return o;
    });

    return output;
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={props.identifier}
        width={RADIX.DIALOG.WIDTH.MD}
        title={props.title}
        loading={loading}
        description={props.description}
        content={
          <CommonFormGrid columns={3}>
            {props.pitches.length === 1 && (
              <>
                <CommonTextInput
                  id="name"
                  label="common.pitch-name"
                  name="pitch_name"
                  value={metadata.name}
                  disabled={loadingLists}
                  placeholder="Type in new name"
                  onChange={(v) => mergeMetadata({ name: v })}
                />
                <CommonSearchInput
                  id="type"
                  name="type"
                  label="common.pitch-type"
                  options={PITCH_TYPE_FILTER_OPTIONS}
                  values={metadata.type ? [metadata.type] : []}
                  onChange={(v) =>
                    mergeMetadata({
                      type: v[0] as PitchType,
                    })
                  }
                  disabled={loadingLists}
                  labelOptional
                  optional
                  skipSort
                />
                <CommonSelectInput
                  id="year"
                  name="year"
                  label="common.year"
                  options={yearOptions}
                  value={metadata.year}
                  onChange={(v) => mergeMetadata({ year: v })}
                  disabled={loadingLists}
                  labelOptional
                  optional
                />
              </>
            )}

            <Box gridColumn="span 3">
              <CommonSearchInput
                key={listKey}
                id="listID"
                name="listID"
                label="common.pitch-list"
                options={listOptions}
                values={
                  metadata.listID !== SEARCH_ID && metadata.listID
                    ? [metadata.listID]
                    : []
                }
                onChange={(v) => {
                  const id = v[0];

                  if (id === CREATE_NEW_LIST_ID) {
                    setDialogCreateList(Date.now());
                    return;
                  }

                  mergeMetadata({
                    listID: id,
                  });
                }}
                disabled={loadingLists}
                optional
              />
            </Box>

            <Box gridColumn="span 3">
              <Card>
                <Flex gap="2">
                  <Checkbox
                    checked={showScale}
                    onCheckedChange={(v) => setShowScale(v as boolean)}
                  />
                  <Flex direction="column" gap="3" style={{ width: '100%' }}>
                    <Box>
                      <Heading size="2">Scale Pitches</Heading>
                      <Text size="1" color={RADIX.COLOR.SECONDARY}>
                        Adjust the values of the pitch based on a percentage.
                      </Text>
                    </Box>

                    {showScale && (
                      <>
                        <CommonFormGrid columns={hasBreaks ? 3 : 1}>
                          <Box>
                            <CommonSelectInput
                              id="scale-speed"
                              name="scale-speed"
                              label="common.speed"
                              options={scaleOptions}
                              value={scaleSpeed.toString()}
                              onNumericChange={(v) => setScaleVelo(v)}
                              skipSort
                            />
                          </Box>

                          {hasBreaks && (
                            <Box>
                              <CommonSelectInput
                                id="scale-break-z"
                                name="scale-break-z"
                                label="common.v-break"
                                options={scaleOptions}
                                value={scaleBreakZ.toString()}
                                onNumericChange={(v) => setScaleBreakZ(v)}
                                skipSort
                              />
                            </Box>
                          )}

                          {hasBreaks && (
                            <Box>
                              <CommonSelectInput
                                id="scale-break-x"
                                name="scale-break-x"
                                label="common.h-break"
                                options={scaleOptions}
                                value={scaleBreakX.toString()}
                                onNumericChange={(v) => setScaleBreakX(v)}
                                skipSort
                              />
                            </Box>
                          )}
                        </CommonFormGrid>

                        {hasBreaks && hasSpins && (
                          <CommonCallout
                            size="1"
                            text="Break scaling won't affect spin priority pitches."
                          />
                        )}
                      </>
                    )}
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </CommonFormGrid>
        }
        buttons={[
          {
            ...DEFAULT_ACCEPT_BTN,
            onClick: async () => {
              const payloads = showScale
                ? await getRebuiltPitchPayloads()
                : getPitchPayloads();

              if (payloads.length === 0) {
                // don't proceed, there was an error getting payloads
                return;
              }

              const isSingle = payloads.length === 1;

              try {
                /** validate inputs */
                if (!metadata.listID) {
                  NotifyHelper.error({
                    message_md: 'Please select a pitch list and try again.',
                  });
                  return;
                }

                if (payloads.length === 0) {
                  NotifyHelper.error({
                    message_md: 'There are no pitches to save.',
                  });
                  return;
                }

                /** should disable accept/cancel buttons until the following is complete */
                setLoading(true);

                const postResult = await PitchListsService.getInstance()
                  .postPitchesToList({
                    listID: metadata.listID,
                    data: payloads,
                  })
                  .finally(() => setLoading(false));

                if (!postResult.success) {
                  NotifyHelper.warning({
                    message_md:
                      postResult.error ??
                      `${
                        payloads.length > 1 ? 'Pitches' : metadata.name
                      } could not be created.`,
                  });
                  return;
                }

                SessionEventsService.postEvent({
                  category: 'pitch',
                  tags: 'create',
                  data: {
                    count: payloads.length,
                  },
                });

                /** update the active pitches to show newly added entries */
                if (
                  activeList &&
                  metadata.listID === activeList._id &&
                  props.reloadPitches
                ) {
                  props.reloadPitches();
                }

                const hashes = payloads.map(
                  (p) => getMSFromMSDict(p, machine).ms?.matching_hash || ''
                );
                addListSummaryDictHashes(hashes, metadata.listID);

                const dupeMsg = `${
                  isSingle ? 'Pitch' : 'Pitches'
                } duplicated successfully.`;

                const scaleMsg = showScale
                  ? `${
                      isSingle ? 'Pitch' : 'Pitches'
                    } scaled based on selected percentages.`
                  : '';

                NotifyHelper.success({
                  message_md: props.duplicating
                    ? `${dupeMsg} ${scaleMsg}`.trim()
                    : t('pu.pitches-created-msg'),
                  buttons: [
                    {
                      label: 'common.go-to-pitch-list',
                      dismissAfterClick: true,
                      onClick: () =>
                        tryChangeSection({
                          trigger: `${COMPONENT_NAME} > added pitches > toast`,
                          section: SectionName.Pitches,
                          subsection: SubSectionName.List,
                          fragments: metadata.listID
                            ? [metadata.listID]
                            : undefined,
                          ignoreDirty: true,
                        }),
                    },
                  ],
                });

                props.onCreated?.();
                props.onClose();
              } catch (e) {
                console.error(e);
                NotifyHelper.error({
                  message_md: `${
                    isSingle ? metadata.name : 'Pitches'
                  } could not be created.`,
                });
              }
            },
          },
        ]}
        onClose={props.onClose}
      />

      {dialogCreateList && (
        <ManageListDialog
          key={dialogCreateList}
          identifier="CopyPitchesCreateListDialog"
          mode="create"
          onCreated={(created) => {
            setTimeout(() => {
              // will preselect the new list
              mergeMetadata({
                listID: created._id,
              });

              // hide the create dialog
              setDialogCreateList(undefined);

              // reset the search input for the new data
              setListKey(Date.now());
            }, 500);
          }}
          onClose={() => setDialogCreateList(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
