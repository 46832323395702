import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MachineEditorDialogHoC } from 'components/sections/admin-portal/machines/editor';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IAdminMachine, IMachine } from 'lib_ts/interfaces/i-machine';
import { IMachineDetails } from 'lib_ts/interfaces/i-machine-details';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

const CONTEXT_NAME = 'AdminMachinesContext';

interface IOptionsDict {
  _created: string[];
  machines: IOption[];
  firmware: string[];
}

interface IFilters {
  machineIDs: string[];
  teams: string[];
  created: string[];
  firmware: string[];
  isSandbox?: boolean;
  isConnected?: boolean;
  hasActive?: boolean;
}

export interface IMachinesContext {
  models: IMachineModel[];
  machines: IAdminMachine[];

  filtered: IAdminMachine[];
  filters: IFilters;
  readonly mergeMachineFilters: (value: Partial<IFilters>) => void;

  readonly openDialogEditor: (model: IMachine | undefined) => void;

  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly create: (
    payload: Partial<IMachine>
  ) => Promise<IMachine | undefined>;
  readonly update: (
    payload: Partial<IMachine>
  ) => Promise<IMachine | undefined>;

  /** set by getDetails */
  details: IMachineDetails;

  /** sets details on context to be used */
  readonly loadDetails: (machineID: string) => Promise<void>;
}

const DEFAULT: IMachinesContext = {
  models: [],
  machines: [],
  filtered: [],
  filters: {
    machineIDs: [],
    teams: [],
    created: [],
    firmware: [],
    isConnected: env.production ? true : undefined,
  },
  mergeMachineFilters: () => console.error(`${CONTEXT_NAME}: not init`),
  openDialogEditor: () => console.error(`${CONTEXT_NAME}: not init`),

  options: {
    _created: [],
    machines: [],
    firmware: [],
  },

  loading: false,

  refresh: () => console.error(`${CONTEXT_NAME}: not init`),
  create: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  update: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  details: {
    machineID: 'none',
    calibrated: false,
    connected: false,
    waiting: [],
  },

  loadDetails: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

export const MachinesContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const MachinesProvider: FC<IProps> = (props) => {
  const { current } = useContext(AuthContext);

  const [lastFetched, setLastFetched] = useState<Date>();

  /** reload data to match session access */
  useEffect(() => setLastFetched(new Date()), [current.session]);

  const [models, setModels] = useState(DEFAULT.models);
  const [machines, setMachines] = useState(DEFAULT.machines);

  const [filters, setFilters] = useState(DEFAULT.filters);

  const filtered = useMemo(() => {
    return machines
      .filter(
        (m) =>
          filters.isConnected === undefined ||
          m.connected === filters.isConnected
      )
      .filter(
        (m) =>
          filters.hasActive === undefined || !m.active === !filters.hasActive
      )
      .filter(
        (m) =>
          filters.machineIDs.length === 0 ||
          filters.machineIDs.includes(m.machineID)
      )
      .filter(
        (m) =>
          filters.teams.length === 0 || filters.teams.includes(m._parent_id)
      )
      .filter(
        (m) =>
          filters.created.length === 0 ||
          (m._created &&
            filters.created.includes(
              lightFormat(parseISO(m._created), 'yyyy-MM-dd')
            ))
      )
      .filter(
        (m) =>
          filters.firmware.length === 0 ||
          (m.last_firmware &&
            filters.firmware.includes(m.last_firmware.split(' ')[0]))
      )
      .filter(
        (m) =>
          filters.isSandbox === undefined || !!m.sandbox === filters.isSandbox
      );
  }, [machines, filters]);

  const options = useMemo(() => {
    const output: IOptionsDict = {
      _created: ArrayHelper.unique(
        machines.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),

      machines: machines.map((m) => {
        const o: IOption = {
          label: `${m.machineID} (${m.nickname ?? 'no nickname'})`,
          value: m.machineID,
        };
        return o;
      }),

      firmware: ArrayHelper.unique(
        machines.map((m) => m.last_firmware?.split(' ')[0] as string)
      ),
    };

    return output;
  }, [machines]);

  const [loading, setLoading] = useState(DEFAULT.loading);
  const [details, setDetails] = useState(DEFAULT.details);

  const [dialogEditor, setDialogEditor] = useState<number>();
  const [dialogMachine, setDialogMachine] = useState<IMachine>();

  const state: IMachinesContext = {
    models: models,
    machines: machines,
    filtered: filtered,
    filters: filters,
    mergeMachineFilters: (v) => {
      setFilters((prev) => ({
        ...prev,
        ...v,
      }));
    },
    openDialogEditor: (m) => {
      setDialogMachine(m);
      setDialogEditor(Date.now());
    },

    options: options,

    loading: loading,

    refresh: () => setLastFetched(new Date()),

    details: details,

    loadDetails: async (machineID) => {
      try {
        NotifyHelper.info({
          message_md: `Retrieving details for \`${machineID}\`, please wait...`,
        });

        setLoading(true);
        const results =
          await AdminMachinesService.getInstance().getMachineDetails(machineID);

        setDetails(results);
      } catch (e) {
        console.error(e);
        NotifyHelper.warning({
          message_md: `Details for \`${machineID}\` could not be retrieved at this time. Please try again later.`,
        });
      } finally {
        setLoading(false);
      }
    },

    create: async (payload) => {
      try {
        setLoading(true);

        const result = await AdminMachinesService.getInstance().create(payload);

        /** append to end of list */
        setMachines((prev) => [
          ...prev,
          {
            ...result,
            connected: false,
            calibrated: false,
            waiting: [],
          },
        ]);

        NotifyHelper.success({
          message_md: `\`${result.machineID}\` created!`,
        });

        return result;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return undefined;
      } finally {
        setLoading(false);
      }
    },

    update: async (payload) => {
      try {
        setLoading(true);

        const result = await AdminMachinesService.getInstance().update(payload);

        if (result) {
          /** success */
          const current = [...machines];
          const index = current.findIndex((v) => v._id === result._id);
          if (index !== -1) {
            const existing = current[index];
            /** replace current context value with updated result */
            current.splice(index, 1, {
              ...existing,
              ...result,
            });
          } else {
            /** append to end */
            current.push({
              connected: false,
              calibrated: false,
              waiting: [],
              ...result,
            });
          }

          setMachines(current);
        }

        return result;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return undefined;
      } finally {
        setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    if (!lastFetched) {
      return;
    }

    const callback = async (): Promise<void> => {
      setLoading(true);

      const instance = AdminMachinesService.getInstance();

      const [models, machines, summaries] = await Promise.all([
        instance.getActiveModels(),
        instance.getMachines(true),
        instance.getMachinesSummary(),
      ]);

      setModels(models.sort((a, b) => a.name.localeCompare(b.name)));

      const newMachines = machines
        .sort((a, b) => (a.machineID ?? '').localeCompare(b.machineID ?? ''))
        .map((m) => {
          const mQueue = summaries.machines.find(
            (ms) => ms.machineID === m.machineID
          );
          const machine: IAdminMachine = {
            ...m,
            active: mQueue ? mQueue.active : undefined,
            waiting: mQueue ? mQueue.waiting : [],
            connected: mQueue ? mQueue.connected : false,
            connect_date: mQueue ? mQueue.connect_date : undefined,
            calibrated: mQueue ? mQueue.calibrated : false,
            disconnect_date: mQueue ? mQueue.disconnect_date : undefined,
          };

          return machine;
        });

      setMachines(newMachines);
      setLoading(false);
    };

    callback();
  }, [lastFetched]);

  return (
    <MachinesContext.Provider value={state}>
      {props.children}

      {dialogEditor && (
        <MachineEditorDialogHoC
          key={dialogEditor}
          id={dialogMachine?._id}
          onClose={() => {
            setDialogEditor(undefined);
            setDialogMachine(undefined);
          }}
        />
      )}
    </MachinesContext.Provider>
  );
};
