import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { MachineButtonMode } from 'enums/machine.enums';
import { SectionName } from 'enums/route.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MachineHelper } from 'lib_ts/classes/machine.helper';
import {
  getMachineActiveModelID,
  getMSFromMSDict,
} from 'lib_ts/classes/ms.helper';
import { IPitch } from 'lib_ts/interfaces/pitches';
import {
  IAggregateMachineShotsDict,
  IAggregateMachineShotsEntry,
  IAggregateMachineShotsRequest,
  IArchiveMachineShotsByHashRequest,
  IMachineShot,
  IMatchingShotsDict,
  IMatchingShotsRequest,
} from 'lib_ts/interfaces/training/i-machine-shot';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ShotsService } from 'services/shots.service';

const CONTEXT_NAME = 'MatchingShotsContext';

const MATCHING_BATCH_SIZE = 50;

// if false, UI elements that allow the user to refresh will be shown, otherwise rely on training refreshable pitches to trigger refreshes
export const ONLY_ALLOW_REFRESH_ON_TRAIN = true;

// also used when working with smaller dicts
export const getShotsFromDict = (config: {
  shotsDict: IMatchingShotsDict;
  hash: string | undefined;
}): IMachineShot[] => {
  try {
    // console.debug('getShotsFromDict', config.shotsDict);

    if (!config.hash) {
      // key is not specified
      return [];
    }

    const entry = config.shotsDict[config.hash];

    if (!entry) {
      // key doesn't exist
      return [];
    }

    const output = entry
      // clone the array to avoid sorting the original
      .map((m) => m)
      // sort the newest shots to the front
      .sort((a, b) => -a._created.localeCompare(b._created));

    return output;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const DEFAULT_AGG_SHOT_ENTRY: IAggregateMachineShotsEntry = {
  total: 0,
  qt: 0,
  qt_complete: false,
  trained: false,
};

export interface IMatchesCount {
  before: number;
  after: number;
}

export interface IMatchingShotsContext {
  lastUpdated: number | undefined;

  aggReady: boolean;
  loading: boolean;

  readonly readyToTrain: (options?: {
    ignoreConnection?: boolean;
    ignoreGameStatus?: boolean;
  }) => boolean;

  readonly readyToRefresh: (pitch: IPitch) => boolean;

  readonly machineButtonMode: (config: {
    isActive: boolean;
    calibrated: boolean;
    requireTraining: boolean;
    requireSend: boolean;
    pitch: IPitch | undefined;
    awaitingResend: boolean | undefined;
  }) => MachineButtonMode;

  // mostly unused
  readonly isHashTrained: (matching_hash?: string) => boolean;
  readonly isPitchTrained: (pitch: Partial<IPitch>) => boolean;

  readonly safeGetShotsByPitch: (pitch: Partial<IPitch>) => IMachineShot[];

  // load and return BP-friendly shots (e.g. within PL, QS, or queue context)
  // todo: if cont. training is removed, then lazy is not necessary and it should behave as always lazy
  readonly fetchShotsForBP: (
    pitch: IPitch,
    lazy: boolean
  ) => Promise<IMachineShot[]>;

  readonly getAggShotsByPitch: (
    pitch: IPitch
  ) => IAggregateMachineShotsEntry | undefined;

  readonly updatePitch: (config: {
    pitch: Partial<IPitch>;
    includeHitterPresent: boolean;
    includeLowConfidence: boolean;
    newerThan?: string;
  }) => Promise<IMatchesCount>;

  readonly updatePitches: (config: {
    pitches: Partial<IPitch>[];
    includeHitterPresent: boolean;
    includeLowConfidence: boolean;
    newerThan?: string;
    limit?: number;
    skipAggregation?: boolean;
  }) => Promise<IMatchingShotsDict>;

  /** archives one shot by _id */
  readonly archiveShotByID: (shot: IMachineShot) => Promise<boolean>;
  /** archives all shots matching the filter criteria */
  readonly archiveShotsByHashes: (
    filter: IArchiveMachineShotsByHashRequest
  ) => Promise<boolean>;

  /** updates a specific shot for a specific hash and updates the dictionary entry accordingly */
  readonly updateShot: (config: {
    matching_hash: string;
    shot_id: string;
    payload: Partial<IMachineShot>;
  }) => Promise<boolean>;

  /** replaces matching shots for a single hash */
  readonly replaceHash: (matching_hash: string, shots: IMachineShot[]) => void;
}

const DEFAULT: IMatchingShotsContext = {
  lastUpdated: undefined,

  aggReady: false,
  loading: false,

  readyToTrain: () => false,
  readyToRefresh: () => false,
  machineButtonMode: () => MachineButtonMode.NoPitch,

  isPitchTrained: () => false,
  isHashTrained: () => false,

  safeGetShotsByPitch: () => [],

  fetchShotsForBP: () => new Promise(() => []),

  getAggShotsByPitch: () => undefined,

  updatePitch: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updatePitches: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  archiveShotByID: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  archiveShotsByHashes: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  updateShot: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  replaceHash: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const MatchingShotsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const MatchingShotsProvider: FC<IProps> = (props: IProps) => {
  const { restrictedGameStatus } = useContext(AuthContext);
  const { active: activeSection } = useContext(SectionsContext);
  const {
    activeModel,
    lastMSHash,
    machine,
    loading: loadingMachine,
    checkActive,
    resetMSHash,
  } = useContext(MachineContext);

  const [shotsDict, setShotsDict] = useState<IMatchingShotsDict>({});
  const [aggDict, setAggDict] = useState<IAggregateMachineShotsDict>({});

  // should start as undefined until agg loads up
  const [lastUpdated, setLastUpdated] = useState<number>();

  // automatically update whenever either aggDict or shotsDict load/change
  useEffect(() => {
    if (Object.keys(aggDict).length > 0) {
      setLastUpdated(Date.now());
      return;
    }
  }, [aggDict]);

  const aggReady = useMemo(() => lastUpdated !== undefined, [lastUpdated]);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const refreshAggregateDict = async (
    config: IAggregateMachineShotsRequest
  ) => {
    const result = await ShotsService.getInstance().getAggregateMatches(config);

    if (!result) {
      console.warn('no aggregation result received from the server!');
      return;
    }

    // if this is the first time we're running this, we always set lastUpdated regardless whether the result has any keys
    // e.g. if the machine has never been fired before, the dict would be empty
    if (!lastUpdated) {
      setLastUpdated(Date.now());
    }

    setAggDict((prev) => {
      const newAggDict = { ...prev };

      // if we just archived shots for a hash, it would exist in aggDict but not in the result
      // delete it from the aggDict to ensure it doesn't stick around (until page refresh)
      if (config.matching_hash && !result[config.matching_hash]) {
        delete newAggDict[config.matching_hash];
      }

      return {
        ...newAggDict,
        ...result,
      };
    });
  };

  const updateAggDict = async (
    config: Partial<IMatchingShotsRequest>,
    limit?: number,
    skipAggregation?: boolean
  ) => {
    try {
      const output: IMatchingShotsDict = {};

      if (!config.matching_hashes) {
        // i.e. nothing changed
        return output;
      }

      const service = ShotsService.getInstance();

      setLoading(true);

      const hashChunks = ArrayHelper.chunkArray(
        config.matching_hashes,
        MATCHING_BATCH_SIZE
      );

      for (const chunk of hashChunks) {
        const payload: IMatchingShotsRequest = {
          matching_hashes: chunk,
          machineID: machine.machineID,
          ball_type: machine.ball_type,
          newerThan: config.newerThan,
          includeHitterPresent: !!config.includeHitterPresent,
          includeLowConfidence: !!config.includeLowConfidence,
        };

        const matches = await service.getMatches(payload, limit);

        for (const hash of payload.matching_hashes) {
          // if there are no matches in result (e.g. payload has newerThan set), this will ensure the dict entry is reset to undefined
          output[hash] = matches[hash] ?? [];
        }
      }

      setShotsDict((prev) => ({
        // keep existing values
        ...prev,
        // merge updated values
        ...output,
      }));

      if (!skipAggregation) {
        await refreshAggregateDict({
          machineID: machine.machineID,
          start_date: config.newerThan,
          includeHitterPresent: !!config.includeHitterPresent,
          includeLowConfidence: !!config.includeLowConfidence,
        });
      }

      return output;
    } catch (e) {
      console.error(e);
      return {};
    } finally {
      setLoading(false);
    }
  };

  const _isHashTrained = useCallback(
    (matching_hash?: string) => {
      if (!matching_hash) {
        return false;
      }

      if (!aggDict) {
        return false;
      }

      const summary = aggDict[matching_hash];

      if (!summary) {
        return false;
      }

      if (summary.qt_complete) {
        return true;
      }

      return summary.total - summary.qt >= machine.training_threshold;
    },
    [aggDict, machine.training_threshold]
  );

  const _isPitchTrained = (pitch: Partial<IPitch>) => {
    const { ms } = getMSFromMSDict(pitch, machine);
    if (!ms) {
      return false;
    }

    const hash = ms.matching_hash ?? MachineHelper.getMSHash('matching', ms);
    return _isHashTrained(hash);
  };

  const readyToRefresh = (pitch: IPitch) => {
    const { ms } = getMSFromMSDict(pitch, machine);
    if (!ms) {
      // require a rebuild to get an ms
      return true;
    }

    const currentID = getMachineActiveModelID(machine);
    if (ms.model_id === currentID) {
      // nothing would happen if we rebuild
      return false;
    }

    // only recommend rebuilding outdated ms that are not trained
    return !_isHashTrained(ms.matching_hash);
  };

  const state: IMatchingShotsContext = {
    lastUpdated: lastUpdated,
    aggReady: aggReady,
    loading: loading,

    readyToTrain: (options) => {
      if (loading) {
        return false;
      }

      if (loadingMachine) {
        return false;
      }

      if (activeModel?.calibration_only) {
        return false;
      }

      if (!options?.ignoreGameStatus && restrictedGameStatus) {
        return false;
      }

      if (!options?.ignoreConnection && !checkActive(true)) {
        return false;
      }

      return true;
    },

    readyToRefresh: readyToRefresh,

    machineButtonMode: (config) => {
      if (!config.isActive) {
        return MachineButtonMode.Unavailable;
      }

      if (!config.calibrated) {
        return MachineButtonMode.Calibrate;
      }

      if (!config.pitch) {
        /** draw nothing while nothing is selected */
        return MachineButtonMode.NoPitch;
      }

      if (readyToRefresh(config.pitch)) {
        return ONLY_ALLOW_REFRESH_ON_TRAIN
          ? MachineButtonMode.Train
          : MachineButtonMode.Refresh;
      }

      if (config.requireTraining && !_isPitchTrained(config.pitch)) {
        /** requires additional training */
        return MachineButtonMode.Train;
      }

      if (!config.requireSend) {
        return MachineButtonMode.Fire;
      }

      if (config.awaitingResend) {
        /** to avoid flickering to send to machine when nudging mstarget */
        return MachineButtonMode.Fire;
      }

      /** default */
      return MachineButtonMode.Send;
    },

    isPitchTrained: _isPitchTrained,

    isHashTrained: _isHashTrained,

    safeGetShotsByPitch: (pitch) => {
      const { ms } = getMSFromMSDict(pitch, machine);

      return getShotsFromDict({
        shotsDict: shotsDict,
        hash: ms?.matching_hash,
      });
    },

    fetchShotsForBP: async (pitch, lazy) => {
      try {
        const ms = getMSFromMSDict(pitch, machine).ms;

        if (!ms?.matching_hash) {
          throw new Error('Cannot fetch shots for empty ms/matching hash');
        }

        // if continuous training is on, don't be lazy
        if (lazy && !machine.enable_continuous_training) {
          const existingShots = getShotsFromDict({
            shotsDict: shotsDict,
            hash: ms.matching_hash,
          });

          if (existingShots.length > 0) {
            return existingShots;
          }
        }

        const partialDict = await updateAggDict(
          {
            includeHitterPresent: false,
            // * AVOIDS INSUFFICIENT DATA WARNING BUT POTENTIALLY DANGEROUS
            includeLowConfidence: true,
            matching_hashes: [ms.matching_hash],
          },
          machine.training_threshold + 1,
          true
        );

        const shots = getShotsFromDict({
          shotsDict: partialDict,
          hash: ms.matching_hash,
        });

        return shots;
      } catch (e) {
        console.error(e);
        return [];
      }
    },

    getAggShotsByPitch: (pitch) => {
      const { ms } = getMSFromMSDict(pitch, machine);

      if (!ms) {
        return DEFAULT_AGG_SHOT_ENTRY;
      }

      const h = ms.matching_hash ?? MachineHelper.getMSHash('matching', ms);

      const entry = aggDict[h];

      if (!entry) {
        return DEFAULT_AGG_SHOT_ENTRY;
      }

      const regularTotal = entry.total - entry.qt;

      // recalculate this in case machine threshold changes
      entry.trained =
        entry.qt_complete || regularTotal >= machine.training_threshold;

      // console.debug('getAggShotsByPitch', pitch, safeEntry);

      return entry;
    },

    updatePitch: async (config) => {
      const empty: IMatchesCount = {
        before: 0,
        after: 0,
      };

      try {
        const hash = getMSFromMSDict(config.pitch, machine).ms?.matching_hash;

        // console.debug('_updateOneHash');

        if (!hash) {
          return empty;
        }

        const prevShots = getShotsFromDict({
          shotsDict: shotsDict,
          hash: hash,
        });

        setLoading(true);

        const result = await ShotsService.getInstance()
          .getMatches({
            matching_hashes: [hash],
            machineID: machine.machineID,
            ball_type: machine.ball_type,
            newerThan: config.newerThan,
            includeHitterPresent: config.includeHitterPresent,
            includeLowConfidence: config.includeLowConfidence,
          })
          .finally(() => setLoading(false));

        if (!result || !result[hash]) {
          NotifyHelper.error({
            message_md: 'Failed to fetch matching shots, please try again.',
          });
          return empty;
        }

        const nextShots = result[hash];

        /** take snapshot of lengths before vs after */
        const output: IMatchesCount = {
          before: prevShots?.length ?? 0,
          after: nextShots.length,
        };

        /** new object should trigger re-renders */
        setShotsDict((prev) => ({
          ...prev,
          [hash]: nextShots,
        }));

        // try to update the specific entry in _aggShotsDict too
        if (nextShots.length > 0) {
          ShotsService.getInstance()
            .getAggregateMatches({
              machineID: machine.machineID,
              matching_hash: hash,
              includeLowConfidence: config.includeLowConfidence,
              includeHitterPresent: config.includeHitterPresent,
            })
            .then((aggResult) => {
              setAggDict((prev) => {
                const newAggDict = { ...prev };

                if (!aggResult?.[hash]) {
                  delete newAggDict[hash];
                }

                return {
                  ...newAggDict,
                  ...aggResult,
                };
              });
            });
        }

        return output;
      } catch (e) {
        console.error(e);
        return empty;
      } finally {
        setLoading(false);
      }
    },

    updatePitches: async (config) => {
      try {
        // console.debug('updatePitches');

        const hashes = ArrayHelper.unique(
          config.pitches.map(
            (p) => getMSFromMSDict(p, machine).ms?.matching_hash ?? ''
          )
        );

        return await updateAggDict(
          {
            includeHitterPresent: config.includeHitterPresent,
            includeLowConfidence: config.includeLowConfidence,
            newerThan: config.newerThan,
            matching_hashes: hashes,
          },
          config.limit,
          config.skipAggregation
        );
      } catch (e) {
        console.error(e);
        return {};
      }
    },

    archiveShotByID: async (shot) => {
      try {
        setLoading(true);

        const success = await ShotsService.getInstance().archiveShotByID(shot);

        if (!success) {
          NotifyHelper.error({
            message_md:
              'Training data could not be archived. Please try again.',
          });
          return false;
        }

        NotifyHelper.success({
          message_md: 'Training data archived successfully!',
        });

        const { matching_hash } = shot.target_ms;

        if (matching_hash) {
          await updateAggDict({
            matching_hashes: [matching_hash],
            includeHitterPresent: false,
            includeLowConfidence: true,
          });

          if (lastMSHash === matching_hash) {
            resetMSHash();
          }
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was an error while archiving training data.',
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    archiveShotsByHashes: async (filter) => {
      try {
        setLoading(true);

        const success =
          await ShotsService.getInstance().archiveShotsByHashes(filter);

        if (!success) {
          NotifyHelper.error({
            message_md:
              'Training data could not be archived. Please try again.',
          });

          return false;
        }

        NotifyHelper.success({
          message_md: 'Training data archived successfully!',
        });

        // we remove entries by hash
        setShotsDict((prev) => {
          const withoutArchived = { ...prev };

          filter.matching_hashes.forEach((h) => {
            delete withoutArchived[h];
          });

          return withoutArchived;
        });

        // we remove entries by hash
        setAggDict((prev) => {
          const withoutArchived = { ...prev };

          filter.matching_hashes.forEach((h) => {
            delete withoutArchived[h];
          });

          return withoutArchived;
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was an error while archiving training data.',
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    updateShot: (config) => {
      return ShotsService.getInstance()
        .updateShot(config.shot_id, config.payload)
        .then((uShot) => {
          if (!uShot) {
            return false;
          }

          const nextShots = getShotsFromDict({
            shotsDict: shotsDict,
            hash: config.matching_hash,
          }).filter((s) => s._id !== config.shot_id);

          setShotsDict((prev) => ({
            ...prev,
            [config.matching_hash]: [...nextShots, uShot],
          }));

          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },

    replaceHash: (matching_hash, shots) => {
      setShotsDict((prev) => ({
        ...prev,
        [matching_hash]: shots,
      }));
    },
  };

  useEffect(() => {
    if (!machine.machineID) {
      // can't refresh without a machineID
      return;
    }

    if (!machine.ball_type) {
      // can't refresh without a ball_type
      return;
    }

    if (
      ![SectionName.Pitches, SectionName.QuickSession].includes(
        activeSection.section
      )
    ) {
      // only reload within certain sections
      return;
    }

    // console.debug({
    //   event: 'matching shots provider loading agg dict',
    //   machineID: machine.machineID,
    //   ball_type: machine.ball_type,
    //   section: activeSection.section,
    // });

    refreshAggregateDict({
      machineID: machine.machineID,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    // reset shots for new machine/ball type/section
    setShotsDict({});
  }, [machine.machineID, machine.ball_type, activeSection.section]);

  return (
    <MatchingShotsContext.Provider value={state}>
      {props.children}
    </MatchingShotsContext.Provider>
  );
};
