import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

const COMPONENT_NAME = 'RefreshListsDialog';

interface IProps {
  name: string;
  parent_def: PitchListOwner;
  parent_id: string;

  onClose: () => void;
}

export const RefreshListsDialog = (props: IProps) => {
  const summary = useMemo(() => {
    switch (props.parent_def) {
      case PitchListOwner.Machine: {
        return `${props.name} and its users`;
      }

      case PitchListOwner.User: {
        return props.name;
      }

      case PitchListOwner.Team: {
        return `${props.name}, its machines, and its users`;
      }

      default: {
        throw new Error(`Invalid parent_def: ${props.parent_def}`);
      }
    }
  }, [props.parent_def, props.name]);

  const refresh = (config: { ms: boolean; traj: boolean }) => {
    AdminPitchListsService.getInstance().refreshLists(
      props.parent_def,
      props.parent_id,
      { ms: config.ms, traj: config.traj }
    );

    props.onClose();
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        title="Refresh Pitch Lists"
        width={RADIX.DIALOG.WIDTH.MD}
        content={
          <ReactMarkdown
            children={[
              `This will update machine states and/or trajectory values for all pitches in all lists belonging to ${summary}.`,
              'Retraining may be necessary before any modified pitches can be used again (e.g. when refreshing MS values).',
            ].join('\n\n')}
          />
        }
        buttons={[
          {
            label: 'Update MS',
            color: RADIX.COLOR.INFO,
            onClick: () => refresh({ ms: true, traj: false }),
          },
          {
            label: 'Update Traj',
            color: RADIX.COLOR.INFO,
            onClick: () => refresh({ ms: false, traj: true }),
          },
          {
            label: 'Update Both',
            color: RADIX.COLOR.INFO,
            onClick: () => refresh({ ms: true, traj: true }),
          },
        ]}
        onClose={() => props.onClose()}
      />
    </ErrorBoundary>
  );
};
