import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { ITrainingMsgExt } from 'interfaces/i-training';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import slugify from 'slugify';

const CONTEXT_NAME = 'TrainingContext';

// detectionFailed will not auto-reset to false if there are too many fails in a row
export const MAX_CONSECUTIVE_FAILS = 5;

// how long before auto-dismissing the warning about missed detections
export const DETECTION_FAILED_DELAY_S = 5;

export interface ITrainingContext {
  training_mode: TrainingMode;

  lastUpdated: Date;

  // e.g. whether to show the callout re: failed detection and changing tracking devices
  warnFailure: boolean;

  failCount: number;

  // for children to see what has arrived since the last reset
  msgs: ITrainingMsgExt[];

  finalMsg?: ITrainingMsgExt;

  // e.g. when a new fire happens and previous msgs are no longer relevant
  readonly resetMsgs: () => void;
  readonly resetFailures: () => void;

  pitchIndex: number;
  readonly setPitchIndex: (v: number) => void;
}

const DEFAULT: ITrainingContext = {
  training_mode: TrainingMode.Quick,
  lastUpdated: new Date(),

  warnFailure: false,
  failCount: 0,

  msgs: [],
  resetMsgs: () => console.error(`${CONTEXT_NAME}: not init`),
  resetFailures: () => console.error(`${CONTEXT_NAME}: not init`),

  pitchIndex: 0,
  setPitchIndex: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const TrainingContext = createContext(DEFAULT);

interface IProps {
  // usually from user setting, but can be overridden (e.g. to manual mode during a home game)
  mode: TrainingMode | undefined;

  children: ReactNode;

  // if something has to happen after a training msg is received by the context, e.g. fire button
  // use this if the action exists in the parent to the provider (otherwise just react to changes in last updated or msgs)
  afterTrainingMsg?: (msg: ITrainingMsgExt) => void;
}

export const TrainingProvider: FC<IProps> = (props) => {
  const [pitchIndex, setPitchIndex] = useState(DEFAULT.pitchIndex);

  // counts up whenever a failed detection occurs, resets to 0 whenever a successful detection occurs
  const [failures, setFailures] = useState(0);

  // separate from _failCount to allow dismissing the callout without resetting the counter
  const [warnFailure, setWarnFailure] = useState(DEFAULT.warnFailure);

  const hideWarningTimeout = useRef<NodeJS.Timeout>();

  const [trainingMsgs, setTrainingMsgs] = useState<ITrainingMsgExt[]>([]);

  const lastUpdated = useMemo(() => new Date(), [trainingMsgs]);

  const finalMsg = useMemo(
    () => trainingMsgs.find((m) => typeof m.success === 'boolean'),
    [trainingMsgs]
  );

  const state: ITrainingContext = {
    training_mode: props.mode ?? TrainingMode.Quick,
    lastUpdated: lastUpdated,
    warnFailure: warnFailure,
    failCount: failures,
    msgs: trainingMsgs,
    finalMsg: finalMsg,
    resetMsgs: () => setTrainingMsgs([]),
    resetFailures: () => {
      setWarnFailure(false);
      setFailures(0);
    },

    pitchIndex: pitchIndex,
    setPitchIndex: setPitchIndex,
  };

  // listen for training msgs
  useEffect(() => {
    const callback = (event: CustomEvent) => {
      const data: ITrainingMsgExt = {
        ...event.detail,
        received: new Date(),
      };

      setTrainingMsgs([...trainingMsgs, data]);

      switch (data.success) {
        case true: {
          setFailures(0);
          setWarnFailure(false);
          break;
        }

        case false: {
          setFailures((prev) => prev + 1);
          setWarnFailure(true);
          break;
        }

        case undefined:
        default: {
          // not a final msg
          break;
        }
      }

      props.afterTrainingMsg?.(data);

      NotifyHelper.debug({
        message_md: `Received a training message.`,
        buttons: [
          {
            label: 'common.save',
            onClick: () => {
              const filename = `training-response_${slugify(
                new Date().toISOString(),
                { strict: true }
              )}`;

              MiscHelper.saveAs(
                new Blob([JSON.stringify(data, null, 2)]),
                `${filename}.json`
              );
            },
          },
        ],
      });
    };

    WebSocketHelper.on(WsMsgType.M2U_TrainingResponse, callback);

    return () => {
      WebSocketHelper.remove(WsMsgType.M2U_TrainingResponse, callback);
    };
  }, []);

  useEffect(() => {
    if (!warnFailure) {
      // do nothing while warning isn't shown
      return;
    }

    clearTimeout(hideWarningTimeout.current);

    // auto-dismiss the warning after a timeout (if not at or above threshold)
    hideWarningTimeout.current = setTimeout(() => {
      if (failures >= MAX_CONSECUTIVE_FAILS) {
        return;
      }

      setWarnFailure(false);
    }, DETECTION_FAILED_DELAY_S * 1000);
  }, [failures, warnFailure]);

  return (
    <TrainingContext.Provider value={state}>
      {props.children}
    </TrainingContext.Provider>
  );
};
