import { Table } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ActionsBodyCell } from 'components/common/table/actions-cells';
import { BasicCell, CheckboxCell } from 'components/common/table/body-cell';
import { TableContext } from 'components/common/table/context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { CHECKBOX_KEY } from 'enums/tables';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableRow } from 'interfaces/tables/rows';
import { ITableSelectable } from 'interfaces/tables/selection';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { MouseEventHandler, useContext, useMemo } from 'react';

interface IProps
  extends Partial<ITableSelectable>,
    Partial<ITableCheckable>,
    Partial<ITableRow> {
  tableID: string;
  index: number;
  item: any;

  columns: ITableColumn[];
}

export const TableRow = (props: IProps) => {
  const {
    contextMenuTarget,
    pageData,
    selected,
    setContextMenuTarget,
    setSelected,
  } = useContext(TableContext);

  const { lastCheckedIndex, getChecked, setChecked, setLastCheckedIndex } =
    useContext(CheckedContext);

  const { index, item, tableID } = props;

  const menuID = useMemo(() => `${tableID}-menu-${index}`, [tableID, index]);

  const itemID = useMemo(() => (item as IMongoBase)._id, [item]);

  const classes = useMemo(
    () =>
      StringHelper.classNames([
        props.blockSelect
          ? 'cursor-not-allowed'
          : props.enableSelect
          ? 'cursor-pointer'
          : undefined,
        props.rowClassNameFn?.(item),
      ]),
    [props.blockSelect, props.enableSelect, props.rowClassNameFn, item]
  );

  const _onClick: MouseEventHandler = (event) => {
    // console.debug('row onClick triggered', event);

    if (event.shiftKey) {
      // deselect any selected/highlighted UI elements
      document.getSelection()?.removeAllRanges();

      const nextValue = !getChecked(itemID);

      if (lastCheckedIndex === null || lastCheckedIndex === undefined) {
        // This is the first row that was checked so no need to bulk select yet
        setLastCheckedIndex(index);
        props.afterCheckOne?.(itemID, nextValue);
        return;
      }

      const iStart = Math.min(lastCheckedIndex, index);
      const iEnd = Math.max(lastCheckedIndex, index);

      const ids = pageData
        .filter((_, i) => i >= iStart && i <= iEnd)
        .map((m) => m._id);

      setChecked(ids, nextValue);
      setLastCheckedIndex(index);
      props.afterCheckMany?.(ids, nextValue);
      return;
    }

    if (!props.enableSelect) {
      return;
    }

    setSelected({
      key: Date.now(),
      index: index,
    });

    // Use the selected row as the last checked index for shift-click handling (but don't check it off yet)
    setLastCheckedIndex(index);
  };

  return (
    <Table.Row
      className={classes}
      data-checked={getChecked(item._id)}
      data-active={index === selected.index}
      data-context-menu={item === contextMenuTarget}
      data-testid={index}
      data-testlocator={props.rowTestLocatorFn?.(item)}
      onClick={_onClick}
      onContextMenu={() => {
        // the small delay allows for the context menu trigger for the table (which clears the selection) to go through before this happens
        setTimeout(() => {
          console.debug('set context menu target from row', item);
          setContextMenuTarget(item);
        }, 100);
      }}
    >
      {props.columns.map((col, iCol) => {
        switch (col.key) {
          case CHECKBOX_KEY: {
            return (
              <CheckboxCell
                key={iCol}
                menuID={menuID}
                col={col}
                item={item}
                index={index}
                afterCheckOne={props.afterCheckOne}
                onShiftClick={_onClick}
              />
            );
          }

          default: {
            if (col.actions) {
              /** draw actions menu with an option for each action */
              return (
                <ActionsBodyCell
                  key={iCol}
                  menuID={menuID}
                  col={col}
                  item={item}
                />
              );
            }

            return (
              <BasicCell key={iCol} menuID={menuID} col={col} item={item} />
            );
          }
        }
      })}
    </Table.Row>
  );
};
