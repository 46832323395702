import { TagPrefix } from '../enums/machine-models.enums';
import { BallType } from '../enums/machine.enums';
import { IMachine, IMachineModelDictionary } from '../interfaces/i-machine';
import { IMachineState } from '../interfaces/i-machine-state';
import { IEvalModelResult } from '../interfaces/modelling/i-eval-models';
import { IMachineModel } from '../interfaces/modelling/i-machine-model';
import { IMSDictionary, IPitch } from '../interfaces/pitches';
import { ArrayHelper } from './array.helper';

type MSSource = 'root' | 'msDict' | 'model-key' | 'machineID' | 'pitch';

interface IGetMSResult {
  ms?: IMachineState;
  foundVia?: MSSource;
  notFoundVia?: string;
}

/** uses details from machine to construct the active model_key value,
 * ignores whether it's defined in model_ids
 * defaults to 'NO-KEY' if key would be empty */
export const getModelKey = (machine: Partial<IMachine>): string => {
  return machine.ball_type || 'NO-KEY';
};

export const getModelKeyFromModel = (model: Partial<IMachineModel>): string => {
  // updated logic for new modelling shape
  if (model.ball_type) {
    return model.ball_type;
  }

  const tag = model.tags?.find((t) => t.startsWith(`${TagPrefix.BallType}:`));
  if (tag) {
    return `${tag.split(':')[1]}`;
  }

  return 'NO-KEY';
};

// kind of janky but works even if the interfaces aren't aligned
export const getModelKeyFromEvalResult = (model: IEvalModelResult): string => {
  return getModelKeyFromModel({
    ball_type: model.ball_type,
    tags: model.tags,
  });
};

/** generates empty key-value pairs for every possible permutation of keys */
export const generateModelKeys = (
  currentDict?: IMachineModelDictionary
): IMachineModelDictionary => {
  const output: IMachineModelDictionary = {};

  Object.values(BallType)
    .sort()
    .forEach((ball) => {
      /** should match what comes out of getModelKey */
      const key = `${ball}`;
      output[key] = '';
    });

  if (currentDict) {
    /** keep values from current dictionary */
    Object.keys(currentDict)
      .sort()
      .forEach((key) => (output[key] = currentDict[key]));
  }

  return output;
};

/** returns model_id (if any) based on the active model_key and model_ids dictionary */
export const getMachineActiveModelID = (
  machine: Partial<IMachine>
): string | undefined => {
  const key = getModelKey(machine);
  return machine.model_ids?.[key];
};

/** returns ms from msDict (backwards compatible) if at all possible, else undefined */
export const getMSFromMSDict = (
  pitch: Partial<IPitch>,
  machine: {
    machineID: string;
    ball_type: BallType;
  }
): IGetMSResult => {
  if (!pitch) {
    return { notFoundVia: 'pitch' };
  }

  if (!pitch.msDict) {
    return { notFoundVia: 'msDict' };
  }

  if (!machine.machineID) {
    return { notFoundVia: 'machineID' };
  }

  if (!machine.ball_type) {
    return { notFoundVia: 'ball_type' };
  }

  const entry = pitch.msDict[machine.machineID];
  if (!entry) {
    return { notFoundVia: 'msDict entry' };
  }

  if (!ArrayHelper.isArray(entry)) {
    /** legacy pitch before model_key change */
    return {
      ms: entry as IMachineState,
      foundVia: 'machineID',
    };
  }

  const mss = entry as IMachineState[];
  const modelKey = getModelKey(machine);
  const found = mss.find((m) => m.model_key === modelKey);

  if (!found) {
    return { notFoundVia: 'model-key' };
  }

  return { ms: found, foundVia: 'model-key' };
};

/** creates a new dictionary based on existing entries from pitch's msDict
 * removes existing entries for machine that share the same model_key (if any)
 * adds the machineStates to the entry's list
 */
export const getMergedMSDict = (
  machine: IMachine,
  values: IMachineState[],
  existing?: IMSDictionary
): IMSDictionary => {
  /** starting value contains whatever is already there */
  const result = { ...existing };

  /** all the non-conflicting ms values for this machineID found on the msDict */
  const entry = (() => {
    const output: IMachineState[] = [];

    /** check for existing entry/entries */
    const en = result[machine.machineID];
    if (en) {
      if (ArrayHelper.isArray(en)) {
        /** retain existing ms values */
        output.push(...(en as IMachineState[]));
      } else {
        /** legacy pitches, push single ms into list */
        output.push(en as IMachineState);
      }
    }

    /** unique model_key values from the machine states to be merged into the dictionary entry */
    const mergeKeys: string[] = ArrayHelper.unique(
      values.map((ms) => ms.model_key)
    );

    /** if any ms has a model_key, filter results, otherwise return everything */
    return mergeKeys.length > 0
      ? output.filter((ms) => !mergeKeys.includes(ms.model_key))
      : output;
  })();

  /** add the new machineState to the list */
  entry.push(...values);

  /** update the dictionary entry for the machine, throw out any ms without model_key */
  result[machine.machineID] = entry.filter((m) => m.model_key);

  return result;
};
