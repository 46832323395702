import {
  CheckCircledIcon,
  GlobeIcon,
  LightningBoltIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Heading, Spinner, Text } from '@radix-ui/themes';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { QueueState, WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React, { useContext, useEffect } from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineConnectionDialog';

interface IProps extends IBaseDialog {}

const STEPS: { header: string; text: string; icon: JSX.Element }[] = [
  {
    header: 'common.check-power',
    text: 'common.check-power-message',
    icon: <LightningBoltIcon />,
  },
  {
    header: 'common.check-internet',
    text: 'common.check-internet-message',
    icon: <GlobeIcon />,
  },
];

const StepCard = (props: { icon: JSX.Element; content: React.ReactNode }) => (
  <CommonCallout color="gray" icon={props.icon} content={props.content} />
);

export const MachineConnectionDialog = (props: IProps) => {
  const { lastStatus } = useContext(MachineContext);

  const isConnected = [QueueState.Active, QueueState.Busy].includes(
    lastStatus.queueState
  );

  // every 2 seconds, ask for the machine status, as long as the dialog lives
  useEffect(() => {
    const interval = setInterval(() => {
      // console.debug('requesting machine status message');

      WebSocketService.sendWithoutRetry(
        WsMsgType.Misc_MachineStatus,
        undefined,
        COMPONENT_NAME
      );
    }, 2_000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        title="common.machine-disconnected"
        width={RADIX.DIALOG.WIDTH.LG}
        content={
          <Flex direction="column" gap={RADIX.FLEX.GAP.LG}>
            <Box>{t('common.machine-disconnected-message')}</Box>

            {STEPS.map((s, i) => (
              <StepCard
                key={`dc-step-${i}`}
                icon={s.icon}
                content={
                  <>
                    <Heading size={RADIX.HEADING.SIZE.SM}>
                      {t(s.header)}
                    </Heading>
                    <Text>{t(s.text)}</Text>
                  </>
                }
              />
            ))}

            <StepCard
              icon={
                isConnected ? (
                  <CheckCircledIcon color={RADIX.COLOR.SUCCESS} />
                ) : (
                  <Spinner size="3" />
                )
              }
              content={
                isConnected ? (
                  <Text>{t('common.machine-connected-message')}</Text>
                ) : (
                  <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                    <Heading size={RADIX.HEADING.SIZE.SM}>
                      {t('common.attempting-reconnection')}
                    </Heading>

                    <Text>{t('common.attempting-reconnection-message')}</Text>

                    <Box mt="2">
                      <ContactSupportButton />
                    </Box>
                  </Flex>
                )
              }
            />
          </Flex>
        }
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
