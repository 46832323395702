import { Box, Code, Flex, Link } from '@radix-ui/themes';
import { CommonTooltip } from 'components/common/tooltip';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachineUser } from 'lib_ts/interfaces/i-machine-summary';
import { getShortenedSession } from 'services/session-events.service';

/** provide callback to do something when username is clicked, otherwise open a mailto link as the default behaviour */
export const MachineUser = (props: {
  user: IMachineUser;
  callback?: (m: IMachineUser) => void;
}) => {
  return (
    <CommonTooltip
      trigger={
        <Link
          onClick={() => props.callback?.(props.user)}
          href={props.callback ? undefined : `mailto:${props.user.email}`}
        >
          {props.user.email}
        </Link>
      }
      content={
        <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
          <Box>
            Session: <Code>{getShortenedSession(props.user.session)}</Code>
          </Box>
          <Box>
            Connected:{' '}
            {format(parseISO(props.user.connectDate), LOCAL_DATETIME_FORMAT, {
              timeZone: LOCAL_TIMEZONE,
            })}
          </Box>
        </Flex>
      }
    />
  );
};
