import { PitchListOwner } from '../../enums/pitch-list.enums';
import {
  PitchListExtType,
  PitchType,
  ReferenceListType,
  TrainingStatus,
} from '../../enums/pitches.enums';
import { IMachine } from '../i-machine';
import { ITeam } from '../i-team';
import { IUser } from '../i-user';
import { IMongoChild } from '../mongo/_base';
import { IPitcherCard } from './i-pitcher-card';

export const FOLDER_SEPARATOR_REGEX = /[/]+/g;

export const FOLDER_SEPARATOR = '/';

export const safeFolder = (rawFolder?: string): string => {
  if (rawFolder && typeof rawFolder === 'string') {
    return rawFolder
      .split(FOLDER_SEPARATOR_REGEX)
      .map((p) => p.trim())
      .filter((p) => !!p)
      .join(FOLDER_SEPARATOR);
  }

  return '';
};

export interface ITrainingDict {
  [machineID: string]: TrainingStatus;
}

export interface IPitchList extends IMongoChild {
  _parent_def: PitchListOwner;

  type?: PitchListExtType;

  // only relevant for reference lists
  reference_type?: ReferenceListType;

  name: string;
  folder: string;

  /** for filtering which lists show up for a user's session
   * true: only loaded when the user has super access
   * false (or empty): does not require super access to load
   * */
  super: boolean;

  card?: IPitcherCard;

  /** for toggling on/off reference lists to be shown in model building UI */
  live?: boolean;

  /** for choosing what is selected by default */
  model_builder_default?: boolean;

  /** for prescribing a certain number of shots for machine calibration */
  default_calibration_shot_count?: number;

  description_md?: string;

  // mixin via the client side
  _mx_summary?: IPitchListSummary;
}

/**
 * options shown to the current user when attempting reassignment
 */
export interface IReassignOptions {
  teams: ITeam[];
  machines: IMachine[];
  users: IUser[];
}

export interface IPitchListPutManyRequest {
  filter: Partial<IPitchList>;
  update: Partial<IPitchList>;

  /** if provided, _id of lists matching filter param will need to be provided here to be reassigned */
  filterLists?: IPitchList[];
}

export interface IRenameFolderRequest {
  type: PitchListExtType | undefined;
  _parent_id: string;
  fromFolder: string;
  toFolder: string;
}

export interface IPitchListSummary {
  training_status: TrainingStatus;

  types: PitchType[];

  // 0 from server, filled in by lists context
  trained: number;
  // 0 from server, filled in by lists context
  trained_percent: number;

  matching_hashes: string[];
  missing_hashes: number;
  total: number;
}

export const DEFAULT_MX_SUMMARY: IPitchListSummary = {
  training_status: TrainingStatus.Not,
  types: [],
  trained: 0,
  trained_percent: 0,
  matching_hashes: [],
  missing_hashes: 0,
  total: 0,
};

export interface IPitchListSummaryDict {
  [listID: string]: IPitchListSummary | undefined;
}
