import { ISpin } from '../interfaces/pitches/i-base';
import { ITrackmanRelease } from '../interfaces/trackman/i-ball';
import { DEG_TO_RAD } from './math.utilities';

export class TrackmanHelper {
  static get3DSpin = (rel: ITrackmanRelease): ISpin | undefined => {
    if (rel.SpinAxis3dLongitudinalAngle === undefined) {
      return undefined;
    }
    const longRAD = rel.SpinAxis3dLongitudinalAngle * DEG_TO_RAD;

    if (rel.SpinRate === undefined) {
      return undefined;
    }
    const activeSpin = rel.SpinRate * Math.cos(longRAD);

    if (rel.SpinAxis3dTransverseAngle === undefined) {
      return undefined;
    }
    const transRAD = rel.SpinAxis3dTransverseAngle * DEG_TO_RAD;

    const output: ISpin = {
      wx: activeSpin * Math.cos(transRAD),
      wy: rel.SpinRate * Math.sin(longRAD),
      wz: -activeSpin * Math.sin(transRAD),
    };

    return output;
  };
}
