import {
  closestCenter,
  DndContext,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';
import { Box, Card, Flex, Text } from '@radix-ui/themes';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import {
  DEFAULT_ACCEPT_BTN,
  IBaseDialog,
  IFullDialog,
} from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { keyBy } from 'lodash';
import { useMemo, useState } from 'react';
import './index.scss';

const COMPONENT_NAME = 'CommonReorderDialog';

interface ISortableItemProps {
  item: IOption;
  i: number;
  total: number;
}

const SortableItem = (props: ISortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.item.value });

  return (
    <Card
      ref={setNodeRef}
      className="sortable-item"
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition,
      }}
    >
      <Flex justify="between" align="center" gap={RADIX.FLEX.GAP.SM}>
        <Flex flexGrow="1" minWidth="0" align="center">
          <DragHandleDots2Icon
            className="drag-handle"
            style={{
              padding: 'var(--space-3)',
              flexShrink: 0,
            }}
            {...attributes}
            {...listeners}
          />
          <Text truncate>{props.item.label}</Text>
        </Flex>
        <Box mr="3">
          <Text wrap="nowrap">
            {props.i + 1} / {props.total}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

interface IDialogProps extends IBaseDialog {
  items: IOption[];

  // provided function should also close the dialog if successful
  onReorder: (items: IOption[]) => void;
}

export const CommonReorderDialog = (props: IDialogProps) => {
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));

  // Array of item IDs to power dnd-kit sorting
  const [orderedIds, setOrderedIds] = useState<string[]>(
    props.items.map(({ value }) => value)
  );
  // { [item.value]: item }
  const itemsByKey = useMemo(() => keyBy(props.items, 'value'), [props.items]);

  const mergeProps: IFullDialog = {
    identifier: props.identifier,
    width: RADIX.DIALOG.WIDTH.MD,
    title: 'common.reorder-items',
    content: (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[
          restrictToVerticalAxis,
          restrictToWindowEdges,
          restrictToParentElement,
        ]}
        onDragEnd={(event) => {
          const { active, over } = event;

          if (over && active.id !== over?.id) {
            setOrderedIds((items) => {
              const oldIndex = items.indexOf(active.id.toString());
              const newIndex = items.indexOf(over.id.toString());

              return arrayMove(items, oldIndex, newIndex);
            });
          }
        }}
      >
        <SortableContext
          items={orderedIds}
          strategy={verticalListSortingStrategy}
        >
          <Flex
            direction="column"
            gap={RADIX.FLEX.GAP.SM}
            className="ReorderList"
          >
            {orderedIds.map((id, i) => (
              <SortableItem
                key={id}
                item={itemsByKey[id]}
                i={i}
                total={orderedIds.length}
              />
            ))}
          </Flex>
        </SortableContext>
      </DndContext>
    ),
    buttons: [
      {
        ...DEFAULT_ACCEPT_BTN,
        onClick: () => {
          const reOrderedPitches = orderedIds.map((id) => itemsByKey[id]);
          return props.onReorder(reOrderedPitches);
        },
      },
    ],
    onClose: props.onClose,
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog {...mergeProps} />
    </ErrorBoundary>
  );
};
