import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { AdminPitchListsService } from 'services/admin/pitch-lists.service';

const COMPONENT_NAME = 'RefreshListsDialog';

interface IProps {
  name: string;
  parent_def: PitchListOwner;
  parent_id: string;

  onClose: () => void;
}

export const RestoreListsDialog = (props: IProps) => {
  const summary = useMemo(() => {
    switch (props.parent_def) {
      case PitchListOwner.Machine: {
        return `${props.name} and its users`;
      }

      case PitchListOwner.User: {
        return props.name;
      }

      case PitchListOwner.Team: {
        return `${props.name}, its machines, and its users`;
      }

      default: {
        throw new Error(`Invalid parent_def: ${props.parent_def}`);
      }
    }
  }, [props.name, props.parent_def]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        title="admin.restore-lists"
        width={RADIX.DIALOG.WIDTH.MD}
        content={
          <ReactMarkdown
            children={[
              `This will restore the most recently trained target machine states for all pitches in all lists belonging to ${summary}.`,
              'No changes will be made to any pitches where an unarchived training shot could not be found.',
            ].join('\n\n')}
          />
        }
        buttons={[
          {
            label: 'admin.restore-lists',
            color: RADIX.COLOR.INFO,
            onClick: () => {
              AdminPitchListsService.getInstance().restoreLists(
                props.parent_def,
                props.parent_id
              );

              props.onClose();
            },
          },
        ]}
        onClose={() => props.onClose()}
      />
    </ErrorBoundary>
  );
};
