import { Badge } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import {
  ServerErrorsContext,
  ServerErrorsProvider,
} from 'contexts/admin/server-errors.context';
import { GlobalContext } from 'contexts/global.context';
import { format } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SubSectionName } from 'enums/route.enums';
import { TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IServerErrorSummary } from 'lib_ts/interfaces/i-server-error';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { useContext, useMemo, useState } from 'react';
import { ServerErrorDetailsDialog } from './details';
import { ServerErrorsTableToolbar } from './toolbar';

const IDENTIFIER = TableIdentifier.AdminServerErrors;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export const ServerErrorsTableHoC = () => {
  return (
    <ServerErrorsProvider>
      <ServerErrorsTable />
    </ServerErrorsProvider>
  );
};

export const ServerErrorsTable = () => {
  const { filtered } = useContext(ServerErrorsContext);

  const columns: ITableColumn[] = useMemo(() => {
    const output: ITableColumn[] = [
      {
        label: 'common.created',
        key: '_created',
        formatFn: (m: IMongoBase) =>
          format(parseISO(m._created), LOCAL_DATETIME_FORMAT, {
            timeZone: LOCAL_TIMEZONE,
          }),
        sortRowsFn: (a: IMongoBase, b: IMongoBase, dir) =>
          a._created.localeCompare(b._created) * dir,
      },
      {
        label: 'common.category',
        key: 'category',
        formatFn: (m: IServerErrorSummary) => StringHelper.capitalize(m.level),
      },
      {
        label: 'common.level',
        key: 'level',
        formatFn: (m: IServerErrorSummary) => (
          <Badge color={NotifyHelper.getColorFromLevel(m.level)}>
            {m.level.toUpperCase()}
          </Badge>
        ),
      },
      {
        label: 'common.summary',
        key: 'summary',
        disableSort: true,
      },
    ];

    return output;
  }, []);

  const [dialogID, setDialogID] = useState<string>();

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filtered.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: '_created',
      dir: -1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: (error: IServerErrorSummary | undefined) =>
      setDialogID(error?._id),
  };

  return (
    <ErrorBoundary componentName="TeamsTable">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={<AdminTabNav active={SubSectionName.ServerErrors} />}
        table={
          <GlobalContext.Consumer>
            {(globalCx) => (
              <CommonTableHoC
                id="ServerErrors"
                toolbarContent={<ServerErrorsTableToolbar />}
                displayColumns={columns}
                displayData={filtered}
                enableListener={globalCx.dialogs.length === 0}
                {...pagination}
                {...select}
                {...sort}
                vFlex
              />
            )}
          </GlobalContext.Consumer>
        }
      />

      {dialogID && (
        <ServerErrorDetailsDialog
          id={dialogID}
          identifier="ViewServerErrorDialog"
          onClose={() => setDialogID(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
