import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { IconButton, Table } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonMenu } from 'components/common/menu';
import { TableHeaderText } from 'components/common/table/header-text';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableAction } from 'interfaces/tables/action';
import { IBodyCell } from 'interfaces/tables/body';
import { IHeaderCell } from 'interfaces/tables/header';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useMemo } from 'react';

export const STICKY_ACTIONS = false;

const ACTION_CLASSES = STICKY_ACTIONS
  ? ['sticky', 'right-0', 'bg-background']
  : ['width-80px'];

// creates menu actions from table actions and the provided data model
export const convertTableToMenu = (
  action: ITableAction,
  model: any
): IMenuAction => {
  const output: IMenuAction = {
    ...action,
    invisible: action.invisibleFn?.(model),
    onClick: () => {
      if (action.disableFn && action.disableFn(model)) {
        return;
      }

      action.onClick(model);
    },
  };

  return output;
};

export const ActionsHeaderCell = (props: IHeaderCell) => {
  const safeProps = { ...props };

  if (!safeProps.col.align) {
    safeProps.col.align = 'center';
  }

  const classes = useMemo(() => {
    return StringHelper.classNames([
      ...ACTION_CLASSES,
      safeProps.col.thClassNameFn?.(safeProps.col),
    ]);
  }, [safeProps.col]);

  return (
    <Table.ColumnHeaderCell
      title={safeProps.col.labelTooltip}
      className={classes}
      data-testid="col-actions"
      align={safeProps.col.align}
    >
      {/* do not draw the header label if actions are sticky */}
      {!STICKY_ACTIONS && <TableHeaderText {...safeProps} />}
    </Table.ColumnHeaderCell>
  );
};

export const ActionsBodyCell = (props: IBodyCell) => {
  const { col, item } = props;

  const classes = useMemo(() => {
    return StringHelper.classNames([
      ...ACTION_CLASSES,
      col.classNameFn?.(item),
    ]);
  }, [col.classNameFn, item]);

  const safeActions = useMemo(() => {
    if (!col.actions) {
      return [];
    }

    return col.actions.map((a) => convertTableToMenu(a, item));
  }, [col.actions, item]);

  return (
    <Table.Cell
      className={classes}
      data-testid="col-actions"
      align={col.align ?? 'center'}
    >
      <CommonMenu
        title="Actions"
        trigger={
          <IconButton
            id={props.menuID}
            color={col.colorFn?.(item) ?? col.color ?? RADIX.COLOR.NEUTRAL}
            className="btn-floating"
            size={RADIX.BUTTON.SIZE.TABLE}
            variant={RADIX.BUTTON.VARIANT.TABLE}
          >
            <DotsVerticalIcon />
          </IconButton>
        }
        actions={safeActions}
        skipSort
      />
    </Table.Cell>
  );
};
