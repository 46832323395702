import { Box } from '@radix-ui/themes';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { ServerErrorsContext } from 'contexts/admin/server-errors.context';
import { useContext } from 'react';

export const ServerErrorsTableToolbar = () => {
  const { options, filters, mergeFilters } = useContext(ServerErrorsContext);

  return (
    <CommonFormGrid columns={3}>
      <Box>
        <CommonSearchInput
          id="errors-created"
          placeholder="common.created"
          options={options._created}
          values={filters._created}
          onChange={(v) => {
            mergeFilters({
              _created: v,
            });
          }}
          multiple
          optional
        />
      </Box>
      <Box>
        <CommonSearchInput
          id="errors-category"
          placeholder="common.category"
          options={options.categories}
          values={filters.categories}
          onChange={(v) => {
            mergeFilters({
              categories: v,
            });
          }}
          multiple
          optional
        />
      </Box>
      <Box>
        <CommonSearchInput
          id="errors-level"
          placeholder="common.level"
          options={options.levels}
          values={filters.levels}
          onChange={(v) => {
            mergeFilters({
              levels: v,
            });
          }}
          multiple
          optional
        />
      </Box>
    </CommonFormGrid>
  );
};
