export class CanvasHelper {
  static async combine(config: {
    target: HTMLCanvasElement;
    sources: HTMLCanvasElement[];
  }): Promise<string> {
    const ctx = config.target.getContext('2d');

    if (!ctx) {
      return '';
    }

    // clear old results so they don't pollute
    ctx.clearRect(0, 0, config.target.width, config.target.height);

    const dataUrls = config.sources.map((c) => c.toDataURL('image/png'));

    const images = (await Promise.all(
      dataUrls.map((d) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = d;
          img.onload = () => resolve(img);
        });
      })
    )) as HTMLImageElement[];

    // layer every image on top of previous images
    images.forEach((img) => ctx.drawImage(img, 0, 0));

    const output = config.target.toDataURL('image/png');
    return output;
  }
}
