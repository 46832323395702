import { CommonCallout } from 'components/common/callouts';
import { SessionEventsContext } from 'contexts/session-events.context';
import {
  DETECTION_FAILED_DELAY_S,
  MAX_CONSECUTIVE_FAILS,
  TrainingContext,
} from 'contexts/training.context';
import { MachineSettingsTabKey, SettingsSection } from 'enums/settings';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo, useState } from 'react';

interface IProps {
  testing?: boolean;

  // side-effect when user clicks on open settings, e.g. hide training
  onOpenSettings?: () => void;
}

export const DetectionFailedCallout = (props: IProps) => {
  const [testing, setTesting] = useState(props.testing);

  const { failCount, resetFailures } = useContext(TrainingContext);
  const { setDialogSettings } = useContext(SessionEventsContext);

  const actions: IMenuAction[] = useMemo(
    () => [
      {
        label: 'common.settings',
        onClick: () => {
          setTesting(false);
          props.onOpenSettings?.();

          setDialogSettings({
            identifier: 'DetectionFailedSettingsDialog',
            initialSection: SettingsSection.Machine,
            sections: [SettingsSection.Machine],
            initialMachineTab: MachineSettingsTabKey.TrackingDevice,
            onClose: () => setDialogSettings(undefined),
          });
        },
      },
      {
        label: 'common.dismiss',
        onClick: () => {
          resetFailures();
          setTesting(false);
        },
      },
    ],
    [props.onOpenSettings]
  );

  if (failCount === MAX_CONSECUTIVE_FAILS) {
    return (
      <CommonCallout
        color={RADIX.COLOR.DANGER}
        text="tr.max-no-pitch-detected-msg"
        actions={actions}
      />
    );
  }

  if (testing || failCount > 0) {
    return (
      <CommonCallout
        color={RADIX.COLOR.WARNING}
        header="tr.no-pitch-detected"
        text={t('tr.no-pitch-detected-msg', {
          x: DETECTION_FAILED_DELAY_S,
        }).toString()}
        actions={actions}
      />
    );
  }

  return <></>;
};
