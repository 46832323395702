import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { MachineContext } from 'contexts/machine.context';
import { DirtyForm, SectionsContext } from 'contexts/sections.context';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { DEFAULT_PLATE } from 'lib_ts/interfaces/pitches';
import {
  DEFAULT_PITCH,
  IBuildPitchChars,
  IPitch,
} from 'lib_ts/interfaces/pitches/i-pitch';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StateTransformService } from 'services/state-transform.service';
import { v4 } from 'uuid';

const CONTEXT_NAME = 'PitchDesignContext';

export interface IPitchDesignContext {
  priority: BuildPriority;
  readonly setPriority: (value: BuildPriority) => void;

  referenceKey: number;
  reference: IPitch;
  readonly setReference: (pitch: IPitch) => void;

  // for forms and editors
  ball: Partial<IBallChar>;
  readonly mergeBall: (config: {
    trigger: string;
    ball: Partial<IBallChar>;
  }) => void;

  videoID?: string;
  readonly setVideoID: (id: string | undefined) => void;

  workingKey: number;
  workingChars: Partial<IBuildPitchChars>;
  readonly mergeWorkingChars: (value: Partial<IBuildPitchChars>) => void;

  readonly getPitchPayload: () => Partial<IPitch> | undefined;
}

const DEFAULT: IPitchDesignContext = {
  priority: BuildPriority.Default,
  setPriority: () => console.error(`${CONTEXT_NAME}: not init`),

  reference: DEFAULT_PITCH,
  setReference: () => console.error(`${CONTEXT_NAME}: not init`),

  referenceKey: Date.now(),
  ball: {},
  mergeBall: () => console.error(`${CONTEXT_NAME}: not init`),

  setVideoID: () => console.error(`${CONTEXT_NAME}: not init`),

  workingKey: Date.now(),
  workingChars: {},
  mergeWorkingChars: () => console.error(`${CONTEXT_NAME}: not init`),

  getPitchPayload: () => undefined,
};

export const PitchDesignContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PitchDesignProvider: FC<IProps> = (props) => {
  const validationTimeout = useRef<any>();

  const { dialogs } = useContext(GlobalContext);
  const { app } = useContext(CookiesContext);
  const { current } = useContext(AuthContext);
  const { markDirtyForm } = useContext(SectionsContext);
  const { activeModel, machine, getDefaultPitch } = useContext(MachineContext);

  // note: reference py may differ from machine plate distance if the pitch was originally created for a different machine
  const [refPitch, setRefPitch] = useState<IPitch>(getDefaultPitch());
  const refKey = useMemo(() => Date.now(), [refPitch]);
  const refHash = useMemo(() => MiscHelper.hashify(refPitch), [refPitch]);

  const [buildPriority, setBuildPriority] = useState(
    refPitch.priority ?? app.build_priority
  );

  // rebuild true ensures the ball gets built the first time
  const [ballChars, setBallChars] = useState<Partial<IBallChar>>({
    ...BallHelper.getCharsFromPitch(refPitch),
  });

  useEffect(() => {
    if (!current.auth) {
      return;
    }

    // console.debug(
    //   `${CONTEXT_NAME} resetting ball because reference hash changed`
    // );

    setBallChars(BallHelper.getCharsFromPitch(refPitch));
  }, [current.auth, refHash]);

  const [workingChars, setWorkingChars] = useState(DEFAULT.workingChars);
  const workingKey = useMemo(() => Date.now(), [workingChars]);

  const [videoID, setVideoID] = useState(refPitch.video_id);

  useEffect(() => {
    if (!activeModel) {
      return;
    }

    if (dialogs.length > 0) {
      return;
    }

    // console.debug(
    //   `${CONTEXT_NAME} checking "${_priority}" support for model "${activeModel.name}"`
    // );

    switch (buildPriority) {
      case BuildPriority.Spins:
      case BuildPriority.Default: {
        if (!activeModel.supports_spins) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes spins but your machine's active model does not support spins.`,
          });
        }
        return;
      }

      case BuildPriority.Breaks: {
        if (!activeModel.supports_breaks) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes breaks but your machine's active model does not support breaks.`,
          });
        }
        return;
      }

      default: {
        return;
      }
    }
  }, [activeModel, dialogs, buildPriority]);

  // automatically define working chars from reference (without a build)
  useEffect(() => {
    const getSafeMS = async () => {
      const refMS = getMSFromMSDict(refPitch, machine).ms;
      if (refMS) {
        return refMS;
      }

      const payload = getBuildPayload();
      if (!payload) {
        return undefined;
      }

      const result = (
        await StateTransformService.getInstance().buildPitches({
          machine: machine,
          notifyError: true,
          chars: [payload],
        })
      )[0];

      return result.ms;
    };

    const callback = async () => {
      const safeMS = await getSafeMS();

      if (!safeMS) {
        console.warn(
          `${CONTEXT_NAME}: got undefined safeMS value while setting working chars`
        );
      }

      const nextChars: Partial<IBuildPitchChars> = {
        ...workingChars,
        bs: refPitch.bs,
        traj: refPitch.traj,
        ms: safeMS,
        priority: refPitch.priority,
        seams: refPitch.seams,
        breaks: refPitch.breaks,
        plate: refPitch.plate_loc_backup ?? DEFAULT_PLATE,
      };

      setWorkingChars(nextChars);
    };

    callback();
  }, [refPitch, machine]);

  const getBuildPayload = (): Partial<IBuildPitchChars> | undefined => {
    if (!activeModel) {
      return undefined;
    }

    switch (buildPriority) {
      case BuildPriority.Default:
      case BuildPriority.Spins: {
        if (!activeModel.supports_spins) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes spins but your machine's active model does not support spins.`,
          });
          return undefined;
        }
        break;
      }

      case BuildPriority.Breaks: {
        if (!activeModel.supports_breaks) {
          NotifyHelper.warning({
            message_md: `The pitch definition prioritizes breaks but your machine's active model does not support breaks.`,
          });
          return undefined;
        }
        break;
      }

      default: {
        break;
      }
    }

    const output: Partial<IBuildPitchChars> = {
      temp_index: 0,
      bs: BallHelper.getBallStateFromChars(ballChars),
      plate: workingChars.plate ?? DEFAULT_PLATE,
      priority: buildPriority,
      seams: {
        latitude_deg: ballChars.latitude_deg ?? 0,
        longitude_deg: ballChars.longitude_deg ?? 0,
      },
      breaks: {
        // already flipped by the input function (i.e. in trajekt frame of ref)
        xInches: ballChars.breaks?.xInches ?? 0,
        zInches: ballChars.breaks?.zInches ?? 0,
      },
    };

    // console.debug('got a build payload', output);

    return output;
  };

  useEffect(() => {
    if (ballChars.skipRebuild) {
      // console.debug('Ball Rebuild: skipped');
      return;
    }

    const jobID = v4();

    // console.debug(`${CONTEXT_NAME} queueing ball build ${jobID}`);

    clearTimeout(validationTimeout.current);

    validationTimeout.current = setTimeout(async () => {
      if (!current.auth) {
        // console.debug('Ball Rebuild: not auth');
        return;
      }

      console.debug(`${CONTEXT_NAME} executing ball build ${jobID}`);

      const warnings = PitchDesignHelper.getBallErrors(
        ballChars,
        buildPriority
      );

      // show an error toast if necessary
      if (warnings.length > 0) {
        NotifyHelper.warning({
          message_md: warnings[0],
        });
        return;
      }

      // rebuild the ball (e.g. so that traj view updates)
      const payload = getBuildPayload();
      if (!payload) {
        console.warn(`${CONTEXT_NAME} got empty build payload`);
        return;
      }

      const builtChars = (
        await StateTransformService.getInstance().buildPitches({
          machine: machine,
          notifyError: true,
          chars: [payload],
        })
      )[0];

      if (!builtChars) {
        NotifyHelper.warning({
          message_md: 'Empty results received from pitch build.',
        });
        return;
      }

      const nextChars: Partial<IBuildPitchChars> = {
        ...workingChars,
        ...builtChars,
      };

      setWorkingChars(nextChars);

      const nextBall: Partial<IBallChar> = {
        ...ballChars,
        // avoid infinite rebuilds
        skipRebuild: true,
      };

      switch (buildPriority) {
        case BuildPriority.Breaks: {
          if (nextChars.bs) {
            nextBall.wx = nextChars.bs.wx;
            nextBall.wy = nextChars.bs.wy;
            nextBall.wz = nextChars.bs.wz;

            const spinExt = BallHelper.convertSpinToSpinExt({
              wx: nextBall.wx ?? 0,
              wy: nextBall.wy ?? 0,
              wz: nextBall.wz ?? 0,
            });

            nextBall.wnet = spinExt.wnet;
            nextBall.gyro_angle = spinExt.gyro_angle;
            nextBall.waxis = spinExt.waxis;
          }
          break;
        }

        case BuildPriority.Spins:
        default: {
          if (nextChars.breaks) {
            nextBall.breaks = nextChars.breaks;
          }
          break;
        }
      }

      setBallChars(nextBall);
    }, 1_000);
  }, [current.auth, activeModel, ballChars]);

  const state: IPitchDesignContext = {
    priority: buildPriority,
    setPriority: setBuildPriority,

    referenceKey: refKey,
    reference: refPitch,
    setReference: (pitch) => {
      if (pitch.priority) {
        setBuildPriority(pitch.priority);
      }

      const safePitch: IPitch = { ...pitch };

      if (!safePitch.breaks) {
        safePitch.breaks = { xInches: 0, zInches: 0 };
      }

      if (!safePitch.seams) {
        safePitch.seams = { latitude_deg: 0, longitude_deg: 0 };
      }

      setRefPitch(safePitch);
      setVideoID(safePitch.video_id);
    },

    ball: ballChars,
    mergeBall: (config) => {
      console.debug(
        `${CONTEXT_NAME} mergeBall triggered from ${config.trigger}`
      );

      markDirtyForm(DirtyForm.PitchDesign);

      setBallChars({
        ...ballChars,
        ...config.ball,
        // always rebuild unless specifically skipped, regardless of current ball's flag
        skipRebuild: config.ball.skipRebuild ? true : false,
      });
    },

    videoID: videoID,
    setVideoID: setVideoID,

    workingKey: workingKey,
    workingChars: workingChars,
    mergeWorkingChars: (v) =>
      setWorkingChars({
        ...workingChars,
        ...v,
      }),

    getPitchPayload: () => {
      if (!ballChars) {
        console.warn(`${CONTEXT_NAME}: no ball specified for pitch payload`);
        return;
      }

      if (!workingChars.bs) {
        console.warn(
          `${CONTEXT_NAME}: no ball state specified for pitch payload`
        );
        return;
      }
      if (!workingChars.ms) {
        console.warn(
          `${CONTEXT_NAME}: no machine state specified for pitch payload`
        );
        return;
      }

      if (!workingChars.traj) {
        console.warn(
          `${CONTEXT_NAME}: no trajectory specified for pitch payload`
        );
        return;
      }

      if (!workingChars.plate) {
        console.warn(`${CONTEXT_NAME}: no plate specified for pitch payload`);
        return;
      }

      const aimed = AimingHelper.aimWithoutShots({
        chars: {
          bs: workingChars.bs,
          ms: workingChars.ms,
          traj: workingChars.traj,

          seams: workingChars.seams,
          // breaks are not necessary for aiming
          // breaks: _workingChars.breaks,
          priority: buildPriority,
        },
        release: {
          px: workingChars.bs.px,
          pz: workingChars.bs.pz,
        },
        plate_location: workingChars.plate,
      });

      const output: Partial<IPitch> = {
        _id: refPitch._id || `temp-${v4()}`,
        _parent_id: refPitch._parent_id,

        name: refPitch.name ?? '',

        bs: aimed.bs,
        msDict: getMergedMSDict(machine, [aimed.ms]),
        traj: aimed.traj,

        seams: workingChars.seams,
        priority: buildPriority,
        breaks: workingChars.breaks,
        plate_loc_backup: aimed.plate,
      };

      /** append video only if selected, else leave alone to avoid overwriting video with undefined */
      if (videoID) {
        output.video_id = videoID;
      }

      return output;
    },
  };

  // keep ball py in sync with plate distance
  useEffect(() => {
    if (!ballChars) {
      return;
    }

    // console.debug(
    //   `${CONTEXT_NAME} updating ball py to match machine plate distance`
    // );

    setBallChars({
      ...ballChars,
      py: machine.plate_distance,
    });
  }, [machine.plate_distance]);

  return (
    <PitchDesignContext.Provider value={state}>
      {props.children}
    </PitchDesignContext.Provider>
  );
};
