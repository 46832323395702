import { Box, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RootFolder } from 'components/main/sidebar/pitch-lists/full-menu/root-folder';
import { AuthContext, getLeagueTeams } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';
import { Controls } from './controls';
import './index.scss';

export const FullMenu = (props: {
  onCreate: () => void;
  onRefresh: () => void;
  getFolderActions: (folder: ISidebarFolder) => IMenuAction[];
}) => {
  const { sidebarFilters, sidebarRootFolders } = useContext(PitchListsContext);
  const { current } = useContext(AuthContext);
  const { machine } = useContext(MachineContext);

  const allRoots = useMemo(() => {
    const output = sidebarRootFolders.slice();

    if (!current.placeholder_folders) {
      return output;
    }

    const personalRoot = output.find(
      (f) => f.depth === 0 && f._parent_def === PitchListOwner.User
    );

    if (personalRoot) {
      // only add placeholders to the personal root
      const teamNames = getLeagueTeams(current.league);
      teamNames.forEach((team) => {
        const pathComponents = [team.value];

        const existingTeam = personalRoot.folders.find((f) =>
          ArrayHelper.equals(f.pathComponents, pathComponents)
        );

        const tFolder: ISidebarFolder = existingTeam ?? {
          depth: 1,
          _parent_def: PitchListOwner.User,
          _parent_id: current.userID,
          _parent_field: 'lists',

          pathComponents: pathComponents,
          pathDisplay: pathComponents.join('/'),
          pathEnd: pathComponents.slice(-1)[0] ?? '',

          files: [],
          folders: [],
          placeholder: true,
        };

        if (!existingTeam) {
          // avoid duplicate entries, but continue processing in case the subfolders need to be created
          personalRoot.folders.push(tFolder);
        }

        ['Starters', 'Relievers'].forEach((sub) => {
          const pathComponents = [team.value, sub];

          if (
            tFolder.folders.findIndex((f) =>
              ArrayHelper.equals(pathComponents, f.pathComponents)
            ) !== -1
          ) {
            // avoid duplicate entries
            return;
          }

          const sFolder: ISidebarFolder = {
            depth: 2,
            _parent_def: PitchListOwner.User,
            _parent_id: current.userID,
            _parent_field: 'lists',

            pathComponents: pathComponents,
            pathDisplay: pathComponents.join('/'),
            pathEnd: pathComponents.slice(-1)[0] ?? '',

            files: [],
            folders: [],
            placeholder: true,
          };
          tFolder.folders.push(sFolder);
        });

        return tFolder;
      });
    }

    return output;
  }, [current, sidebarRootFolders]);

  const filteredRoots = useMemo(() => {
    const { category } = sidebarFilters;

    return (
      allRoots
        // only render non-empty stuff
        .filter((r) => r.folders.length > 0 || r.files.length > 0)
        // only render the selected category/root
        .filter((s) => {
          if (!category) {
            // all lists
            return true;
          }

          // filtering by owner
          switch (category) {
            case PitchListOwner.Team: {
              return (
                !s.type &&
                s._parent_def === category &&
                s._parent_id === current.teamID
              );
            }

            case PitchListOwner.Machine: {
              return (
                !s.type &&
                s._parent_def === category &&
                s._parent_id === machine._id
              );
            }

            case PitchListOwner.User: {
              return (
                !s.type &&
                s._parent_def === category &&
                s._parent_id === current.userID
              );
            }

            case PitchListExtType.Card:
            case PitchListExtType.Reference:
            case PitchListExtType.Sample: {
              // ignore owner when filtering by type
              return s.type === category;
            }

            default: {
              return false;
            }
          }
        })
    );
  }, [current, machine, allRoots, sidebarFilters]);

  const noResults = filteredRoots.length === 0;

  return (
    <ErrorBoundary componentName="FullMenu">
      <Flex className="FullMenu" direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <Controls onCreate={props.onCreate} onRefresh={props.onRefresh} />
        </Box>

        {noResults ? (
          <Flex
            direction="column"
            justify="center"
            align="center"
            gap="2"
            pt="3"
            pb="3"
            pl="2"
            pr="2"
          >
            <img
              height="48"
              width="48"
              alt="no search results"
              src={appearanceImgPath('search-empty.svg')}
            />

            <Text size="1" align="center" color={RADIX.COLOR.NEUTRAL}>
              {t('pl.no-search-results-msg')}
            </Text>
          </Flex>
        ) : (
          filteredRoots.map((folder, iFolder) => (
            <RootFolder
              key={iFolder}
              folder={folder}
              getFolderActions={props.getFolderActions}
            />
          ))
        )}
      </Flex>
    </ErrorBoundary>
  );
};
