import { Box, Flex } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { InstallStep } from 'enums/installation';
import { t } from 'i18next';
import { IInstallStepDef } from 'interfaces/i-installation';
import { InstallStepStatus } from 'lib_ts/enums/machine.enums';
import { useMemo } from 'react';

const COMPONENT_NAME = 'StepCompletion';

interface IProps {
  steps: IInstallStepDef[];
}

export const StepCompletion = (props: IProps) => {
  const skipped = useMemo(() => {
    return props.steps
      .filter((s) => ![InstallStep.End, InstallStep.Completion].includes(s.id))
      .filter((s) => s.status !== InstallStepStatus.Complete);
  }, [props.steps]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="4">
        <CommonStepHeader label="Completion" />

        <Box>
          You've reached the end of the calibration process. Review your
          settings and test results to ensure everything looks correct.
        </Box>

        <Box>
          When you're ready, click Complete Machine Install to finalize your
          setup.
        </Box>

        {skipped.length > 0 && (
          <CommonCallout
            content={
              <>
                The following steps haven't been marked as completed:
                <ul
                  className="no-margin"
                  style={{ paddingInlineStart: '20px' }}
                >
                  {skipped.map((s, i) => (
                    <li key={i}>{t(s.label)}</li>
                  ))}
                </ul>
              </>
            }
          />
        )}
      </Flex>
    </ErrorBoundary>
  );
};
