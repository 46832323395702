import { Button, Checkbox, Flex, Radio, Switch, Text } from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { IChecklistInput } from 'interfaces/forms/checklist';
import { IOptionExt } from 'interfaces/forms/option-ext';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ReactNode, useMemo, useState } from 'react';
import { v4 } from 'uuid';

const Wrapper = (props: {
  label: ReactNode;
  suffix?: ReactNode;
  onClickLabel: () => void;
  control: ReactNode;
}) => (
  <Flex gap={RADIX.FLEX.GAP.SM} align="center" pl="1" pr="1">
    {props.control}
    <Text className="cursor-pointer" onClick={props.onClickLabel} truncate>
      {props.label}
      {props.suffix}
    </Text>
  </Flex>
);

export const CommonChecklist = (props: IChecklistInput) => {
  const [id] = useState(v4());

  const safeOptions = useMemo(() => {
    const { truncateTo = 0, options, values } = props;

    const visible = options.filter((o) => !o.invisible);

    if (truncateTo <= 0) {
      // don't truncate
      return visible;
    }

    if (visible.length <= truncateTo) {
      // no need to truncate
      return visible;
    }

    const selectedDict: { [key: string]: boolean | undefined } = {};

    // faster than iterating over all the options, most of which will be deselected
    values.forEach((v) => {
      selectedDict[v] = true;
    });

    // only keep up to the first X options that are not group entries and are not selected
    const deselected = visible
      .filter((o) => !o.isGroup && !selectedDict[o.value])
      .filter((_, i) => i < truncateTo);

    const deselectedDict: { [key: string]: boolean | undefined } = {};

    deselected.forEach((o) => {
      deselectedDict[o.value] = true;
    });

    // keep all selected options
    const selected = visible.filter((o) => selectedDict[o.value]);

    // keep all groups for any options from above
    const visibleGroups = ArrayHelper.unique(
      [...deselected, ...selected].map((o) => o.group)
    );

    const output: IOptionExt[] = visible.filter((o) => {
      if (o.isGroup && visibleGroups.includes(o.group)) {
        return true;
      }

      if (selectedDict[o.value]) {
        return true;
      }

      if (deselectedDict[o.value]) {
        return true;
      }

      return false;
    });

    return output;
  }, [props.truncateTo, props.options, props.values]);

  return (
    <CommonInputWrapper {...props}>
      <CommonInputLabel {...props} />

      <Flex direction="column" gap={RADIX.FLEX.GAP.INPUT}>
        {safeOptions
          .filter((o) => !o.invisible)
          .map((option, i) => {
            if (props.as === 'button') {
              const checked = props.values.includes(option.value);
              const isGroupOption = option.group && !option.isGroup;

              return (
                <Button
                  id={`${id}-${i}`}
                  key={`${id}-${i}`}
                  className={checked ? undefined : 'btn-floating'}
                  size={props.btnSize}
                  style={{
                    justifyContent: 'start',
                  }}
                  disabled={props.disabled || option.disabled}
                  onClick={() => {
                    const nextValue = !props.values.includes(option.value);
                    props.onChange(nextValue ? [option.value] : []);
                  }}
                  variant="soft"
                  color={
                    checked
                      ? RADIX.COLOR.ACCENT
                      : (option.color ?? RADIX.COLOR.NEUTRAL)
                  }
                >
                  <Flex
                    justify="between"
                    style={{ width: '100%' }}
                    // indent group options for hierarchy
                    pl={isGroupOption ? RADIX.FLEX.PAD.SM : undefined}
                  >
                    <Text truncate>{t(option.label)}</Text>
                    {option.suffix}
                  </Flex>
                </Button>
              );
            }

            const onClickLabel = () => {
              if (props.disabled) {
                return;
              }

              if (option.disabled) {
                return;
              }

              switch (props.as) {
                case 'radio': {
                  // you can't uncheck a radio option
                  props.onChange([option.value]);
                  break;
                }

                case 'button':
                case 'checkbox':
                case 'switch':
                default: {
                  // toggle the option
                  const checked = props.values.includes(option.value);

                  props.onChange(
                    checked
                      ? props.values.filter((v) => v !== option.value)
                      : ArrayHelper.unique([...props.values, option.value])
                  );
                  break;
                }
              }
            };

            const labelNode = (
              <Text
                color={
                  option.disabled || option.hideControl
                    ? RADIX.COLOR.NEUTRAL
                    : option.color
                }
                truncate
              >
                {t(option.label)}
              </Text>
            );

            switch (props.as) {
              case 'radio': {
                return (
                  <Wrapper
                    key={`${id}-${i}`}
                    label={labelNode}
                    suffix={option.suffix}
                    onClickLabel={onClickLabel}
                    control={
                      <Radio
                        name={id}
                        value={option.value}
                        disabled={option.disabled}
                        onChange={(e) => {
                          if (e.target.value) {
                            props.onChange([e.target.value]);
                          }
                        }}
                        checked={props.values.includes(option.value)}
                      />
                    }
                  />
                );
              }

              case 'switch': {
                return (
                  <Wrapper
                    key={`${id}-${i}`}
                    label={labelNode}
                    suffix={option.suffix}
                    onClickLabel={onClickLabel}
                    control={
                      option.hideControl ? (
                        <></>
                      ) : (
                        <Switch
                          name={props.name}
                          disabled={props.disabled}
                          checked={props.values.includes(option.value)}
                          onCheckedChange={(v) => {
                            props.onChange(
                              v
                                ? ArrayHelper.unique([
                                    ...props.values,
                                    option.value,
                                  ])
                                : props.values.filter((v) => v !== option.value)
                            );
                          }}
                        />
                      )
                    }
                  />
                );
              }

              case 'checkbox':
              default: {
                return (
                  <Wrapper
                    key={`${id}-${i}`}
                    label={labelNode}
                    suffix={option.suffix}
                    onClickLabel={onClickLabel}
                    control={
                      <Checkbox
                        id={`${id}-${i}`}
                        title={t(option.label).toString()}
                        disabled={props.disabled || option.disabled}
                        checked={props.values.includes(option.value)}
                        style={{
                          // hides the control while preserving the spacing
                          visibility: option.hideControl ? 'hidden' : 'visible',
                          // left-aligns the group label to maintain hierarchy
                          display: option.isGroup ? 'none' : 'initial',
                        }}
                        onCheckedChange={(v) => {
                          props.onChange(
                            v
                              ? ArrayHelper.unique([
                                  ...props.values,
                                  option.value,
                                ])
                              : props.values.filter((v) => v !== option.value)
                          );
                        }}
                      />
                    }
                  />
                );
              }
            }
          })}
      </Flex>

      <CommonInputHint {...props} />
    </CommonInputWrapper>
  );
};
