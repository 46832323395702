import {
  Badge,
  Box,
  Card,
  Flex,
  IconButton,
  Popover,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { AddPitchIcon } from 'components/common/custom-icon/shorthands';
import { GameDataPlayerAvatar } from 'components/sections/game-data/players/avatar';
import { GameDataPitcherRepertoire } from 'components/sections/game-data/players/pitcher-repertoire';
import { GameDataTeamLogo } from 'components/sections/game-data/team-logo';
import { GameDataContext } from 'contexts/game-data.context';
import { SectionsContext } from 'contexts/sections.context';
import { GameDataTab } from 'enums/game-data.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { IMlbPlayer } from 'lib_ts/interfaces/mlb-stats-api/i-player';
import { useContext, useMemo, useState } from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

export const GameDataPlayerCard = (props: {
  isDark: boolean;
  player: IMlbPlayer;
  mode: GameDataTab;
}) => {
  const [hover, setHover] = useState(false);

  const { tryChangeSection } = useContext(SectionsContext);
  const { mergeFilterPitches } = useContext(GameDataContext);

  const { player } = props;

  const teamName = useMemo(() => {
    const { _mx_details } = player;

    if (_mx_details?.teamCode && _mx_details?.teamShortName) {
      return `${_mx_details?.teamCode} ${_mx_details?.teamShortName}`;
    }

    if (_mx_details?.teamName) {
      return _mx_details?.teamName;
    }

    return 'No Active Roster';
  }, [player._mx_details]);

  const rgb = StringHelper.hexToRgb(player._mx_details?.teamColorHex);

  const [pitches, setPitches] = useState<IMlbPitchExt[]>();

  return (
    <Card
      key={player.playerPk}
      size="2"
      className="cursor-pointer animate-background"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={
        hover
          ? {
              background: `radial-gradient(201.63% 141.42% at 100% 100%, rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%), var(--Panel-default, rgba(29, 29, 33, 0.70))`,
            }
          : undefined
      }
      onClick={() =>
        tryChangeSection({
          trigger: `${props.mode} card`,
          section: SectionName.Pitches,
          subsection: SubSectionName.GameData,
          fragments: [props.mode, player.playerPk.toString()],
          // reset filters from previous interactions
          beforeNavCallback: async () =>
            mergeFilterPitches({
              isHome: undefined,
              pitchType: undefined,
              inning: undefined,
              outcomeType: undefined,
              batSide: undefined,
              pitchHand: undefined,
              gamePks: undefined,
              hitterPks: undefined,
              pitcherPks: undefined,
            }),
        })
      }
    >
      <Flex gap="3" justify="between" align="center">
        <GameDataPlayerAvatar
          size="4"
          name={player.name}
          playerPk={player.playerPk}
        />

        <Flex direction="column" gap="1" flexGrow="1">
          <Box minWidth="0">
            <Text size="3" truncate>
              {player.name}
            </Text>
          </Box>

          <Flex gap="2">
            <GameDataTeamLogo
              size="20px"
              teamPK={player._mx_details?.teamPk}
              name={player._mx_details?.teamName}
              isDark={props.isDark}
            />

            <Box minWidth="0">
              <Text color={RADIX.COLOR.SECONDARY} truncate>
                {teamName}
              </Text>
            </Box>
          </Flex>
        </Flex>

        {props.mode === 'pitchers' && (
          <Badge
            color={
              player.pitchHand === PitcherHand.LHP
                ? RADIX.COLOR.LEFT
                : RADIX.COLOR.RIGHT
            }
          >
            {player.pitchHand}
          </Badge>
        )}

        {props.mode === 'hitters' && (
          <Badge
            color={
              player.batSide === HitterSide.LHH
                ? RADIX.COLOR.LEFT
                : RADIX.COLOR.RIGHT
            }
          >
            {player.batSide}
          </Badge>
        )}

        {props.mode === 'pitchers' && (
          <Popover.Root
            onOpenChange={(open) => {
              if (!open) {
                // avoid taking too much memory if a bunch of different repertoires are opened/closed
                setPitches(undefined);
              }
            }}
          >
            <Popover.Trigger>
              <IconButton
                className="btn-floating"
                variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                color={RADIX.COLOR.NEUTRAL}
                onClick={async (e) => {
                  e.stopPropagation();

                  if (pitches) {
                    return;
                  }

                  const built =
                    await MlbStatsService.getInstance().getPlayerPitches({
                      mode: 'pitcher',
                      player: player,
                    });

                  setPitches(built);
                }}
              >
                <AddPitchIcon />
              </IconButton>
            </Popover.Trigger>
            <Popover.Content asChild>
              <Card
                onClick={(e) => {
                  // prevent clicking on the card from opening the pitcher page
                  e.stopPropagation();
                }}
              >
                {pitches ? (
                  <GameDataPitcherRepertoire pitches={pitches} />
                ) : (
                  <Spinner />
                )}
              </Card>
            </Popover.Content>
          </Popover.Root>
        )}
      </Flex>
    </Card>
  );
};
