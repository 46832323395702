import { isEqual } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

export class ReactHelper {
  /*
      Does a deep-comparison and only performs a state update if necessary.
      Could potentially be expensive if the object is huuuge but likely worth
      the trade off in most cases if an unnecessary big re-render is avoided
    */
  static setStateIfChanged = <T>(
    setState: Dispatch<SetStateAction<T>>,
    newValue: T
  ) => {
    setState((prev) => (isEqual(prev, newValue) ? prev : newValue));
  };
}
