import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { PresetTrainingMode } from 'lib_ts/enums/training.enums';
import { IPitch } from 'lib_ts/interfaces/pitches';
import {
  IPresetOption,
  IPresetTrainingSpec,
} from 'lib_ts/interfaces/training/i-preset-training-spec';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

const CONTEXT_NAME = 'PresetTrainingContext';

const PRESET_TRAINING_OPTIONS: IPresetOption[] = [
  {
    mode: PresetTrainingMode.Quick,
    minPerPitch: 1,
    spec: {
      sampleSize: 3,
      maxAdjustments: 0,
      deltaSpeedMPH: 3,
      deltaBreaksInches: 5,
      deltaSpinsRPM: 400,
      smartSampling: false,
    },
  },
  {
    mode: PresetTrainingMode.Precision,
    minPerPitch: 2,
    spec: {
      sampleSize: 5,
      maxAdjustments: 4,
      deltaSpeedMPH: 1.5,
      deltaBreaksInches: 4,
      deltaSpinsRPM: 400,
      smartSampling: true,
    },
    precisionTrained: true,
  },
  {
    mode: PresetTrainingMode.Custom,
    spec: {
      sampleSize: 3,
      maxAdjustments: 3,
      deltaSpeedMPH: 2,
      deltaSpinsRPM: 400,
      deltaBreaksInches: 4,
      smartSampling: true,
    },
    precisionTrained: true,
    showSmartSamplingToggle: true,
    showControls: true,
  },
];

export interface IPresetTrainingContext {
  options: IPresetOption[];

  gradient: boolean;
  readonly setGradient: (m: boolean) => void;

  active: IPresetOption;
  readonly selectPreset: (m: IPresetOption) => void;
  readonly updateSpec: (m: Partial<IPresetTrainingSpec>) => void;

  readonly isPitchAdjustable: (m: Partial<IPitch>) => boolean;
}

const DEFAULT: IPresetTrainingContext = {
  options: PRESET_TRAINING_OPTIONS,

  gradient: true,
  setGradient: () => console.error(`${CONTEXT_NAME}: not init`),

  active: PRESET_TRAINING_OPTIONS[0],
  selectPreset: () => console.error(`${CONTEXT_NAME}: not init`),
  updateSpec: () => console.error(`${CONTEXT_NAME}: not init`),

  isPitchAdjustable: () => false,
};

export const PresetTrainingContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PresetTrainingProvider: FC<IProps> = (props) => {
  const [_options] = useState(DEFAULT.options);

  const { current } = useContext(AuthContext);
  const { machine, buildOptions } = useContext(MachineContext);

  const userPreset = _options.find(
    (p) => p.mode === current.preset_training_mode
  );

  const initPreset: IPresetOption = {
    ...(userPreset ?? _options[0]),
  };

  if (initPreset.spec.sampleSize < machine.training_threshold) {
    // in case the sample size is less than the machine threshold
    initPreset.spec.sampleSize = machine.training_threshold;
  }

  const [_active, _setActive] = useState(initPreset);

  const [_gradient, _setGradient] = useState(DEFAULT.gradient);

  const _selectPreset = useCallback(
    (preset: IPresetOption) => {
      console.debug('selecting preset', preset);

      const { training_threshold } = machine;

      if (preset.spec.sampleSize < training_threshold) {
        preset.spec.sampleSize = training_threshold;
      }

      _setActive({
        ...preset,
      });
    },
    [machine.training_threshold]
  );

  const _updateSpec = useCallback(
    (spec: Partial<IPresetTrainingSpec>) => {
      console.debug('updating spec', spec);

      // this is whack but it ensures the changes persist when changing presets
      const original = _options.find((o) => o.mode === _active.mode);

      if (!original) {
        return;
      }

      original.spec = { ...original.spec, ...spec };

      _setActive({
        ...original,
      });
    },
    [_active]
  );

  const _isPitchAdjustable = useCallback(
    (pitch: Partial<IPitch>) => {
      if (_active.spec.maxAdjustments === 0) {
        console.warn(
          `Cannot adjust pitch ${pitch._id}: active preset ${_active.mode} has zero max adjustments`
        );
        return false;
      }

      if (!pitch.priority) {
        console.warn(`Cannot adjust pitch ${pitch._id}: no build priority`);
        return false;
      }

      const supportedPriorities = buildOptions.map(
        (o) => o.value as BuildPriority
      );

      switch (pitch.priority) {
        case BuildPriority.Breaks: {
          return supportedPriorities.includes(BuildPriority.Breaks);
        }

        case BuildPriority.Spins:
        case BuildPriority.Default:
        default: {
          // default is the same as spins
          return supportedPriorities.includes(BuildPriority.Spins);
        }
      }
    },
    [_active, buildOptions]
  );

  const state: IPresetTrainingContext = {
    options: _options,

    gradient: _gradient,
    setGradient: _setGradient,

    active: _active,
    selectPreset: _selectPreset,
    updateSpec: _updateSpec,

    isPitchAdjustable: _isPitchAdjustable,
  };

  return (
    <PresetTrainingContext.Provider value={state}>
      {props.children}
    </PresetTrainingContext.Provider>
  );
};
