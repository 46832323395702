import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';
import { useContext } from 'react';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterDateAdded = (props: IProps) => {
  const { options, filter, mergeFilter } = useContext(VideosContext);

  return (
    <CommonSearchInput
      id="video-date-added"
      placeholder="common.date-added"
      options={options._created.map((o) => ({
        label: o,
        value: o,
      }))}
      values={filter.dateAdded}
      onChange={(v) => {
        mergeFilter({ dateAdded: v });
        props.onChange?.();
      }}
      multiple
      optional
      reverseSort
    />
  );
};
