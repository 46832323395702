import { Card, ScrollArea, Text } from '@radix-ui/themes';
import { CommonDetails } from '.';

export const ScrollingToggle = (props: {
  content: string | undefined;
  defaultOpen?: boolean;
}) => {
  return (
    <CommonDetails summary="Toggle" defaultOpen={props.defaultOpen}>
      <Card size="1">
        <ScrollArea
          type="auto"
          scrollbars="vertical"
          style={{ maxHeight: 180 }}
        >
          <Text size="1">
            <pre>{props.content}</pre>
          </Text>
        </ScrollArea>
      </Card>
    </CommonDetails>
  );
};
