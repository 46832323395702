import { CheckCircledIcon, DownloadIcon } from '@radix-ui/react-icons';
import { Avatar, Badge, Code, Flex, Skeleton, Text } from '@radix-ui/themes';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { TableProvider } from 'components/common/table/context';
import { CommonTooltip } from 'components/common/tooltip';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { PresetTrainingDialogHoC } from 'components/machine/dialogs/preset-training';
import { TrainingDialogHoC } from 'components/machine/dialogs/training';
import { PitchListsToolbar } from 'components/sections/pitch-lists/toolbar';
import { PitchesHeader } from 'components/sections/pitches/header';
import { AuthContext } from 'contexts/auth.context';
import { GlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { SectionsContext } from 'contexts/sections.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { parseISO } from 'date-fns';
import lightFormat from 'date-fns/lightFormat';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { TrainingStatus } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PLATE, IPitch, IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MainService } from 'services/main.service';
import { PitchesService } from 'services/pitches.service';
import slugify from 'slugify';

const COMPONENT_NAME = 'PitchLists';

const ENABLE_TRAIN_LISTS = false;

const IDENTIFIER = TableIdentifier.PitchLists;

// notify user every x pitch lists as they are being exported
const PROGRESS_FREQUENCY = 50;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

const MAX_PITCH_TYPE_BADGES = 5;

export const PitchListsHoC = () => {
  const { filtered } = useContext(PitchListsContext);

  return (
    <TableProvider>
      <CheckedProvider data={filtered}>
        <PitchLists />
      </CheckedProvider>
    </TableProvider>
  );
};

const TrainingDialog = (props: { pitches: IPitch[]; onClose: () => void }) => {
  const { machine } = useContext(MachineContext);
  const { effectiveTrainingMode } = useContext(AuthContext);

  const mode = effectiveTrainingMode();

  if (mode === TrainingMode.Manual) {
    return (
      <TrainingDialogHoC
        identifier="PLS-TrainingDialog"
        mode={mode}
        pitches={props.pitches}
        threshold={machine.training_threshold}
        onClose={props.onClose}
      />
    );
  }

  return (
    <PresetTrainingDialogHoC
      identifier="PLS-PT-TrainingDialog"
      mode={mode}
      pitches={props.pitches}
      onClose={props.onClose}
    />
  );
};

const sortListOwner = (owner: PitchListOwner): number => {
  switch (owner) {
    case PitchListOwner.Team: {
      return 0;
    }

    case PitchListOwner.Machine: {
      return 1;
    }

    case PitchListOwner.User:
    default: {
      return 2;
    }
  }
};

const PitchLists = () => {
  const { dialogs } = useContext(GlobalContext);
  const { current } = useContext(AuthContext);
  const { machine } = useContext(MachineContext);
  const { getVideo } = useContext(VideosContext);
  const { filtered, loadingSummaryDict, reloadSummaryDict } =
    useContext(PitchListsContext);
  const { getChecked } = useContext(CheckedContext);
  const { isPitchTrained, updatePitches, readyToTrain } =
    useContext(MatchingShotsContext);
  const { tryChangeSection } = useContext(SectionsContext);

  const [dialogTraining, setDialogTraining] = useState<number>();
  const [trainPitches, setTrainPitches] = useState<IPitch[]>();

  useEffect(
    () => reloadSummaryDict(),
    [current.teamID, machine.machineID, machine.ball_type]
  );

  const columns: ITableColumn[] = useMemo(
    () => [
      {
        label: 'common.created',
        key: '_created',
        dataType: 'date',
        invisible: true,
      },
      // TODO: Reduce <Avatar /> flickering during filtering/re-render
      {
        label: 'common.pitch-list-name',
        key: 'name',
        formatFn: (list: IPitchList) => {
          return (
            <Flex gap="2" align="center">
              <Avatar
                fallback={StringHelper.getInitials(list.name).substring(0, 2)}
              />
              <Text truncate>{list.name}</Text>
            </Flex>
          );
        },
      },
      {
        label: 'common.trained-pitches',
        key: '_trained',
        sortRowsFn: (a: IPitchList, b: IPitchList, dir) => {
          const { _mx_summary: aSum } = a;
          const { _mx_summary: bSum } = b;

          if (aSum && bSum) {
            if (aSum.trained_percent === bSum.trained_percent) {
              return aSum.trained > bSum.trained ? -dir : dir;
            }

            return aSum.trained_percent > bSum.trained_percent ? -dir : dir;
          }

          if (aSum) {
            return -dir;
          }

          if (bSum) {
            return dir;
          }

          return dir;
        },
        formatFn: (list: IPitchList) => {
          const color =
            list._mx_summary?.training_status === TrainingStatus.Full
              ? RADIX.COLOR.SUCCESS
              : RADIX.COLOR.NEUTRAL;

          if (!list._mx_summary) {
            return (
              <Skeleton loading={loadingSummaryDict}>
                <Badge color={color}>0/0</Badge>
              </Skeleton>
            );
          }

          const { trained, total } = list._mx_summary;

          return (
            <Badge color={color}>
              {trained}/{total}
            </Badge>
          );
        },
      },
      {
        label: 'common.pitch-types',
        key: '_types',
        disableSort: true,
        formatFn: (list: IPitchList) => {
          if (loadingSummaryDict) {
            return (
              <Flex gap="1">
                {Array.from({ length: 4 }).map((_, i) => (
                  <Skeleton key={i} loading={loadingSummaryDict}>
                    <Badge color={RADIX.COLOR.NEUTRAL}>??</Badge>
                  </Skeleton>
                ))}
                <Skeleton loading={loadingSummaryDict}>
                  <Badge color={RADIX.COLOR.NEUTRAL}>??</Badge>
                </Skeleton>
              </Flex>
            );
          }

          if (!list._mx_summary) {
            return null;
          }

          const { _mx_summary } = list;

          const shouldTruncate =
            _mx_summary.types.length > MAX_PITCH_TYPE_BADGES;

          // replace the last pitch type badge with a badge indicating the # of hidden pitch types
          const numVisiblePitchTypes = shouldTruncate
            ? MAX_PITCH_TYPE_BADGES - 1
            : MAX_PITCH_TYPE_BADGES;

          const visiblePitchTypes = _mx_summary.types.slice(
            0,
            numVisiblePitchTypes
          );

          const hiddenPitches = _mx_summary.types.slice(numVisiblePitchTypes);

          return (
            <Flex gap="1">
              {visiblePitchTypes.map((t, i) => (
                <Badge key={i} color={RADIX.COLOR.NEUTRAL}>
                  {t ?? '??'}
                </Badge>
              ))}
              {hiddenPitches.length > 0 && (
                <CommonTooltip
                  trigger={
                    <Badge
                      color={RADIX.COLOR.NEUTRAL}
                    >{`+${hiddenPitches.length}`}</Badge>
                  }
                  text_md={hiddenPitches.join(', ')}
                />
              )}
            </Flex>
          );
        },
      },
      {
        label: 'common.visibility',
        key: '_parent_def',
        sortRowsFn: (a: IPitchList, b: IPitchList, dir) => {
          return (
            dir *
            (sortListOwner(a._parent_def) < sortListOwner(b._parent_def)
              ? -1
              : 1)
          );
        },
        formatFn: (list: IPitchList) => {
          switch (list._parent_def) {
            case PitchListOwner.Team:
              return t('common.team');

            case PitchListOwner.Machine:
              return t('common.machine');

            case PitchListOwner.User:
              return t('common.personal');

            default:
              return t('common.unknown');
          }
        },
      },
      {
        label: 'pl.folder',
        key: 'folder',
        formatFn: (list: IPitchList) =>
          list.folder ? <Code>{list.folder}</Code> : <></>,
      },
      {
        label: 'common.date-created',
        key: '_created',
        invisible: current.role !== UserRole.admin,
        formatFn: (list: IPitchList) =>
          lightFormat(parseISO(list._created), 'yyyy-MM-dd'),
      },
      {
        label: 'common.type',
        labelIcon: <SuperAdminIcon />,
        key: 'type',
        invisible: current.role !== UserRole.admin,
      },
    ],
    [current.role]
  );

  /** exports lists as CSV file */
  const exportListsCSV = (mode: 'checked' | 'all', lists: IPitchList[]) => {
    if (lists.length === 0) {
      NotifyHelper.warning({ message_md: 'Empty export request.' });
      return;
    }

    MainService.getInstance()
      .convertJSONToCSV(lists)
      .then((csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv' });
        MiscHelper.saveAs(
          blob,
          `pitch-lists_${mode}_${lightFormat(new Date(), 'yyyy-MM-dd')}.csv`
        );
      });
  };

  /** exports lists' contents as CSV file */
  const exportListsContentsCSV = async (lists: IPitchList[]) => {
    if (lists.length === 0) {
      NotifyHelper.warning({ message_md: 'Empty export request.' });
      return;
    }

    // get each list's content, save each as a file
    const plService = PitchesService.getInstance();
    const mainService = MainService.getInstance();

    for (let i = 0; i < lists.length; i++) {
      await MiscHelper.sleep(500);

      if (i > 0 && (i + 1) % PROGRESS_FREQUENCY === 0) {
        NotifyHelper.info({
          message_md: `Progress: ${i + 1} / ${lists.length} lists exported...`,
        });
      }

      const list = lists[i];

      const pitches = await plService.searchPitches(
        {
          _parent_def: 'pitch-lists',
          _parent_field: 'pitches',
          _parent_id: list._id,
          // ignored for search pitches
          limit: 0,
        },
        0
      );

      if (pitches.length === 0) {
        NotifyHelper.warning({
          message_md: `No pitches found for "${list.name}".`,
        });
        continue;
      }

      const customPitches = pitches.map((p) => {
        return PitchListHelper.convertToCustomExport({
          pitch: p,
          plate_ft: machine.plate_distance,
          video: getVideo(p.video_id),
        });
      });

      const csvString = await mainService.convertJSONToCSV(customPitches);
      const blob = new Blob([csvString], { type: 'text/csv' });
      MiscHelper.saveAs(blob, `${slugify(list.name)}.csv`);
    }

    NotifyHelper.success({
      message_md: `Progress: ${lists.length} lists' contents exported.`,
      delay_ms: 0,
    });
  };

  const handleTrain = async () => {
    const lists = filtered.filter((v) => getChecked(v._id));

    if (lists.length === 0) {
      NotifyHelper.warning({
        message_md: 'Please select at least one list to train and try again.',
      });
      return;
    }

    NotifyHelper.warning({
      message_md: `Loading data to train ${lists.length} lists, please wait as this may take a while...`,
    });

    const allPitches = (
      await Promise.all(
        lists.map((l) => PitchesService.getInstance().getListPitches(l._id))
      )
    ).flat();

    await updatePitches({
      pitches: allPitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    const untrainedPitches = allPitches.filter((p) => !isPitchTrained(p));

    if (untrainedPitches.length === 0) {
      NotifyHelper.success({
        message_md: 'There are no untrained pitches in the selected lists!',
      });
      return;
    }

    const aimedPitches = untrainedPitches
      .map((pitch) =>
        AimingContextHelper.getAdHocAimed({
          source: `${COMPONENT_NAME} > trainChecked`,
          machine: machine,
          pitch: pitch,
          plate: DEFAULT_PLATE,
          usingShots: [],
        })
      )
      .map((m) => m?.pitch as IPitch)
      .filter((m) => !!m);

    if (aimedPitches.length < untrainedPitches.length) {
      NotifyHelper.warning({
        message_md: `No models for ${machine.machineID} found for one or more pitches. Please refresh models and trying again.`,
      });
      return;
    }

    setTrainPitches(aimedPitches);
    setDialogTraining(Date.now());
  };

  const getCheckedMenuActions = (checked: IPitchList[]): IMenuAction[] => {
    const output: IMenuAction[] = [
      {
        prefixIcon: <DownloadIcon />,
        label: 'common.export-selected-pitches',
        onClick: () => exportListsContentsCSV(checked),
      },
      {
        prefixIcon: <DownloadIcon />,
        label: 'common.export-selected-lists',
        onClick: () => exportListsCSV('checked', checked),
      },
      {
        prefixIcon: <CheckCircledIcon />,
        invisible: !ENABLE_TRAIN_LISTS,
        label: 'common.train-checked',
        onClick: () => handleTrain(),
        color: RADIX.COLOR.TRAIN_PITCH,
        disabled: !readyToTrain(),
      },
    ];

    return output;
  };

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filtered.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: '_created',
      dir: 1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: (m: IPitchList | undefined) => {
      if (!m) {
        return;
      }

      tryChangeSection({
        trigger: 'pitch lists',
        section: SectionName.Pitches,
        subsection: SubSectionName.List,
        fragments: [m._id],
      });
    },
  };

  const checkable: ITableCheckable = {
    checkboxColumnIndex: 0,
    // todo: kind of weird deviation from rules here, since the checkboxes interact with the actions menu at the top rather than a checked menu at the bottom
    checkedActions: getCheckedMenuActions,
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={
          <>
            <PitchesHeader
              extraActions={[
                {
                  prefixIcon: <DownloadIcon />,
                  label: 'common.export-all-pitches',
                  onClick: () => exportListsContentsCSV(filtered),
                },
                {
                  prefixIcon: <DownloadIcon />,
                  label: 'common.export-all-lists',
                  onClick: () => exportListsCSV('all', filtered),
                },
              ]}
            />
            <ActiveCalibrationModelWarning showSettingsButton />
          </>
        }
        table={
          <CommonTable
            id="PitchLists"
            displayColumns={columns}
            displayData={filtered}
            toolbarContent={<PitchListsToolbar />}
            enableListener={dialogs.length === 0}
            rowClassNameFn={() => 'tall-3'}
            {...pagination}
            {...checkable}
            {...select}
            {...sort}
            vFlex
          />
        }
      />

      {dialogTraining && trainPitches && (
        <TrainingDialog
          key={dialogTraining}
          pitches={trainPitches}
          onClose={async () => {
            setDialogTraining(undefined);

            /** update any lists whose training status may have changed because of training */
            const lists = filtered.filter((v) => getChecked(v._id));

            for (const list of lists) {
              const pitches = await PitchesService.getInstance().getListPitches(
                list._id
              );

              await updatePitches({
                pitches: pitches,
                includeHitterPresent: false,
                includeLowConfidence: true,
              });
            }
          }}
        />
      )}
    </ErrorBoundary>
  );
};
