import { Box, Flex, Text } from '@radix-ui/themes';
import { VoidIcon } from 'components/common/custom-icon/shorthands';
import { CommonTableSortIcon } from 'components/common/table/sort-icon';
import { t } from 'i18next';
import { IHeaderCell } from 'interfaces/tables/header';
import { RADIX, RadixFlexJustify, RadixTextAlign } from 'lib_ts/enums/radix-ui';

const convertAlignToJustify = (
  value: RadixTextAlign | undefined
): RadixFlexJustify | undefined => {
  switch (value) {
    case 'center': {
      return 'center';
    }

    case 'left': {
      return 'start';
    }

    case 'right': {
      return 'end';
    }

    default: {
      return undefined;
    }
  }
};

export const TableHeaderText = (props: IHeaderCell) => (
  <Flex
    gap={RADIX.FLEX.GAP.XS}
    justify={convertAlignToJustify(props.col.align)}
  >
    <Box>
      <Text color={props.col.color}>
        {t(props.col.label)}&nbsp;
        {props.col.subLabel && <small>{t(props.col.subLabel)}</small>}
      </Text>
      {/* if labelIcon is not needed, this preserves the text height without adding gaps */}
      {!props.col.labelIcon && (
        <span style={{ marginTop: '2px' }}>
          <VoidIcon />
        </span>
      )}
    </Box>

    {props.col.labelIcon && (
      <Box style={{ marginTop: '2px' }}>{props.col.labelIcon}</Box>
    )}

    <CommonTableSortIcon
      colKey={props.col.key}
      disabled={props.col.disableSort}
    />
  </Flex>
);
