import { MlbOutcomeCode } from '../../enums/mlb-stats-api/guid-metadata-types.enum';
import { BuildPriority, PitchType } from '../../enums/pitches.enums';
import { IMachineState } from '../i-machine-state';
import { IMongoChild } from '../mongo/_base';
import { DEFAULT_BALL_STATE, IBallState } from './i-ball-state';
import { ISeamOrientation } from './i-base';
import { IPlateLoc } from './i-plate-loc';
import {
  DEFAULT_TRAJECTORY,
  ITrajectory,
  ITrajektRefBreak,
} from './i-trajectory';

export interface IMSDictionary {
  [machineID: string]: IMachineState | IMachineState[];
}

/** bare minimum to define a pitch */
export interface IBasePitchChars {
  bs: IBallState;
  traj: ITrajectory;
  ms: IMachineState;
  priority: BuildPriority;

  seams?: ISeamOrientation;

  // saved in Trajekt frame of ref (-ve => movement towards 3B, +ve => movement towards 1B)
  breaks?: ITrajektRefBreak;
}

/** used for building pitches */
export interface IBuildPitchChars extends IBasePitchChars {
  /** for tracking chars before and after building via python server, when mongo_id is not necessarily available */
  temp_index?: number;
  /** when mongo_id is available (e.g. refreshing existing pitches) */
  mongo_id?: string;

  plate: IPlateLoc;
}

export interface IPitchMetadata {
  // for pitches built using browse feature
  mlb_gamePk: number;
  // for pitches built using browse feature
  mlb_guid: string;

  name: string;
  type: PitchType;
  year: string;

  // for shuffle purposes, value from 1-5, undefined => 1
  frequency?: number;
}

// if created via the MLB Stats API
interface IMlbMetadata {
  hitter: string;
  outcome: MlbOutcomeCode;
  game: string;
}

export interface IPitch
  extends IMongoChild,
    Partial<IPitchMetadata>,
    Partial<IMlbMetadata> {
  // shortcut for identifying relevant pitches given a user
  team_id?: string;

  bs: IBallState;
  traj: ITrajectory;

  video_id?: string;

  msDict?: IMSDictionary;

  /** used for post-build rotations, should only be set when:
   * - user creates a pitch
   * - user updates a pitch
   * - server rebuilds a pitch (e.g. for model changes) and there is no value already */
  plate_loc_backup?: IPlateLoc;

  // if provided in CSV uploader
  seams?: ISeamOrientation;

  // in trajekt frame of ref
  breaks?: ITrajektRefBreak;

  priority?: BuildPriority;

  /** set by pitch lists context when pitch is processed and loaded from msDict */
  _msDictLoaded?: Date;

  /** set by rotation function to identify if/when a pitch is rotated (e.g. after loading from msDict) */
  _msRotated?: Date;
}

export const DEFAULT_PITCH: IPitch = {
  _id: '',
  _created: '',
  _changed: '',
  _parent_id: '',
  _parent_def: 'pitch-lists',
  _parent_field: 'pitches',
  bs: {
    ...DEFAULT_BALL_STATE,
  },
  traj: {
    ...DEFAULT_TRAJECTORY,
  },
  priority: BuildPriority.Spins,
};
