import { Box, Flex, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTableButton } from 'components/common/table/button';
import { SearchPitchesDialog } from 'components/sections/pitch-list/dialogs';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import env from 'config';
import { CheckedContext } from 'contexts/layout/checked.context';
import { PITCH_TYPE_FILTER_OPTIONS } from 'enums/filters';
import {
  PITCHER_HANDS,
  PitcherHand,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const PitchListToolbar = () => {
  const [searchName, setSearchName] = useState('');
  const [searchType, setSearchType] = useState<PitchType | undefined>();
  const [searchHand, setSearchHand] = useState<PitcherHand | undefined>();

  const [dialogSearch, setDialogSearch] = useState<number>();

  const checkedCx = useContext(CheckedContext);

  const listStore = usePitchListStore(
    useShallow(
      ({
        loading,
        search,
        tags,
        searchCriteria,
        setTags,
        setSearchCriteria,
      }) => ({
        loading,
        search,
        tags,
        searchCriteria,
        setTags,
        setSearchCriteria,
      })
    )
  );

  // Keep our controlled input value in sync with searchCriteria.name (in case it changes from elsewhere like SearchPitchesDialog)
  useEffect(() => {
    setSearchName(listStore.searchCriteria.name || '');
  }, [listStore.searchCriteria.name]);

  useEffect(() => {
    setSearchType(listStore.searchCriteria.type);
  }, [listStore.searchCriteria.type]);

  useEffect(() => {
    setSearchHand(listStore.searchCriteria.hand);
  }, [listStore.searchCriteria.hand]);

  const performSearch = () => {
    checkedCx.resetChecked();
    listStore.setSearchCriteria({
      ...listStore.searchCriteria,
      name: searchName,
      type: searchType,
      hand: searchHand,
    });
  };

  // auto-search when dropdown values change
  useEffect(() => {
    performSearch();
  }, [searchType, searchHand]);

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Grid flexGrow="1" columns="3" gap={RADIX.FLEX.GAP.SM}>
          <Box>
            <CommonTextInput
              id="pitch-list-search-name"
              name="name"
              placeholder="common.search-placeholder"
              value={searchName}
              disabled={listStore.loading}
              onChange={(v = '') => setSearchName(v)}
              onKeyUp={(e) => {
                if (e.code === 'Enter') {
                  performSearch();
                }
              }}
            />
          </Box>
          <Box>
            <CommonSearchInput
              id="pitch-list-search-type"
              name="type"
              placeholder="common.pitch-type"
              options={PITCH_TYPE_FILTER_OPTIONS}
              values={searchType ? [searchType] : []}
              onChange={(v) => setSearchType(v[0] as PitchType)}
              disabled={listStore.loading}
              optional
              skipSort
            />
          </Box>
          <Box>
            <CommonSelectInput
              id="pitch-list-search-hand"
              name="hand"
              placeholder="common.hand"
              options={PITCHER_HANDS}
              value={searchHand}
              onChange={(v) => setSearchHand(v as PitcherHand)}
              disabled={listStore.loading}
              optional
            />
          </Box>
        </Grid>

        <Box>
          <CommonTableButton
            label="common.search"
            className="btn-block"
            variant="soft"
            onClick={() => performSearch()}
          />
        </Box>
        <Box>
          <CommonTableButton
            label="common.advanced-filters"
            className="btn-block"
            variant="soft"
            onClick={() => setDialogSearch(Date.now())}
          />
        </Box>
      </Flex>

      {env.enable.fire_tags && (
        <Flex gap={RADIX.FLEX.GAP.SM}>
          <Box>
            <CommonTextInput
              id="pitch-list-tags"
              value={listStore.tags.toUpperCase()}
              placeholder="Tags (comma-delimited)"
              onChange={(v) => listStore.setTags(v?.toUpperCase() ?? '')}
            />
          </Box>
        </Flex>
      )}

      {dialogSearch && (
        <SearchPitchesDialog
          key={dialogSearch}
          onClose={() => {
            checkedCx.resetChecked();
            setDialogSearch(undefined);
          }}
        />
      )}
    </Flex>
  );
};

export default PitchListToolbar;
