import { DragDropEngine, SidebarState } from 'enums/cookies.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';

export enum TableIdentifier {
  AdminMachineList = 'AdminMachineList',
  AdminModelsList = 'AdminModelsList',
  AdminErrorTypeList = 'AdminErrorTypeList',
  AdminServerErrors = 'AdminServerErrors',
  AdminTeamList = 'AdminTeamList',
  AdminUserList = 'AdminUserList',
  AdminVideoList = 'AdminVideoList',
  HitterList = 'HitterList',
  HittingReports = 'HittingReports',
  HitterSession = 'HitterSession',
  ManageCardAccess = 'ManageCardAccess',
  MlbBrowsePitches = 'MlbBrowsePitches',
  PitchList = 'PitchList',
  PitchLists = 'PitchLists',
  PitchUploaderBalls = 'PitchUploaderBalls',
  PitchUploaderCsv = 'PitchUploaderCsv',
  SessionList = 'SessionList',
  TrainingData = 'TrainingData',
  VideoList = 'VideoList',
  ViewSessions = 'ViewSessions',
}

const IS_MOBILE = navigator.maxTouchPoints > 0;

export const nextSidebarState = (mode: SidebarState): SidebarState => {
  const allStates = Object.values(SidebarState);

  return allStates[
    (allStates.findIndex((m) => m === mode) + 1) % allStates.length
  ];
};

interface IPlateViewConfig {
  /** force plate view sliders to aim such that the ellipse bounds do not exceed the strike zone if possible */
  plate_limit_by_ellipse: boolean;

  /** display location of actual shots */
  plate_show_actual: boolean;

  /** display debug table and toggles */
  plate_show_debug: boolean;

  /** display warnings related to ellipses and potentially dangerous conditions */
  plate_show_ellipse_warnings: boolean;

  /** display location of rotated shots */
  plate_show_rotated: boolean;
}

export interface IAppCookie extends IPlateViewConfig {
  /** for storing users' custom page size preferences
   * each distinct user's preference can be stored on the same cookie
   * format: `${user.email}-${TableIdentifier}` */
  page_sizes: { [key: string]: number };

  /** determines which engine to use for drag-drop features, requires reload */
  drag_drop_engine: DragDropEngine;

  build_priority: BuildPriority;

  pitch_upload_options: {
    /** false implies skip this */
    average_release: boolean;

    /** undefined implies skip this */
    average_pitch?: 'append' | 'replace';

    priority: BuildPriority;
  };

  hitter_tags: { [hitter_id: string]: string };

  enable_debug_toasts: boolean;

  /** controls whether menu is collapsed/expanded */
  sidebar_state: SidebarState;
}

/** used as default when none was found in cookies */
export const DEFAULT_APP_SETTINGS: IAppCookie = {
  plate_show_actual: false,
  plate_show_rotated: true,
  plate_show_debug: false,
  plate_limit_by_ellipse: false,
  plate_show_ellipse_warnings: false,

  drag_drop_engine: IS_MOBILE ? DragDropEngine.touch : DragDropEngine.click,

  sidebar_state: IS_MOBILE ? SidebarState.mini : SidebarState.full,

  page_sizes: {},

  build_priority: BuildPriority.Default,

  pitch_upload_options: {
    average_release: false,
    average_pitch: undefined,
    priority: BuildPriority.Spins,
  },

  hitter_tags: {},

  enable_debug_toasts: false,
};
