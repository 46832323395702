import { IVideo, IVideoError } from '../interfaces/i-video';
import { IPosition } from '../interfaces/pitches/i-base';
import { isNumber } from './math.utilities';

/** earliest in any video that we allow the release time to be */
const MIN_RELEASE_TIME_SEC = 1;

/** min frame in any video that we allow the release frame to be */
const MIN_RELEASE_FRAME = 30;

const MAX_VIDEO_DURATION_S = 10;

/** max difference between release time and expected release time after converting release frame to seconds using video fps */
const MAX_DELTA_RELEASE_TIME_FRAME_SEC = 0.5;

const VALIDATION_TOLERANCE = {
  /** feet */
  X: { value: 2, text: '2 ft' },
  /** feet */
  Z: { value: 1, text: '1 ft' },
};

export class VideoHelper {
  static getFatalErrors = (video: Partial<IVideo>): IVideoError[] =>
    this.getErrors(video).filter((e) => e.fatal);

  /** returns a list of errors (if any) that prevent the video from being used, empty list if there are no issues detected */
  static getErrors = (video: Partial<IVideo>): IVideoError[] => {
    const output: IVideoError[] = [
      ...VideoHelper.getCompletenessErrors(video),
      ...VideoHelper.getValidityErrors(video as IVideo),
    ];

    return output;
  };

  static getCompletenessErrors(video: Partial<IVideo>): IVideoError[] {
    try {
      const output: IVideoError[] = [];

      if (!video.ffmpeg) {
        output.push({
          fatal: true,
          msg: 'FFMPEG metadata is not defined',
        });
      }

      if (!isNumber(video.n_frames)) {
        output.push({
          fatal: true,
          msg: 'Total frames is not a number',
        });
      } else if (video.n_frames === 0) {
        output.push({
          fatal: true,
          msg: 'Total frames is zero',
        });
      }

      if (!isNumber(video.ReleasePixelX)) {
        output.push({
          fatal: true,
          msg: 'Release Pixel X is not a number',
        });
      }

      if (!isNumber(video.ReleasePixelY)) {
        output.push({
          fatal: true,
          msg: 'Release Pixel Y is not a number',
        });
      }

      if (!isNumber(video.MoundPixelX)) {
        output.push({
          fatal: true,
          msg: 'Mound Pixel X is not a number',
        });
      }

      if (!isNumber(video.MoundPixelY)) {
        output.push({
          fatal: true,
          msg: 'Mound Pixel Y is not a number',
        });
      }

      const parsedRT = parseFloat(video.ReleaseTime as any);
      if (!isNumber(parsedRT)) {
        output.push({
          fatal: true,
          msg: 'Release Time is not a number',
        });
      }

      if (!isNumber(video.ReleaseSide)) {
        output.push({
          fatal: true,
          msg: 'Release Side is not a number',
        });
      }

      if (!isNumber(video.ReleaseHeight)) {
        output.push({
          fatal: true,
          msg: 'Release Height is not a number',
        });
      }

      return output;
    } catch (e) {
      return [
        {
          fatal: true,
          msg:
            e instanceof Error
              ? e.message
              : 'Unknown error while checking video completeness',
        },
      ];
    }
  }

  private static getValidityErrors(video: IVideo): IVideoError[] {
    try {
      const output: IVideoError[] = [];

      if (video.ffmpeg.duration.seconds > MAX_VIDEO_DURATION_S) {
        output.push({
          fatal: false,
          msg: `Video duration should not exceed ${MAX_VIDEO_DURATION_S} seconds`,
        });
      }

      if (
        video.ReleasePixelY < 0 ||
        video.ReleasePixelY > video.ffmpeg.video.resolution.h
      ) {
        output.push({
          fatal: true,
          msg: `Release Pixel Y (${video.ReleasePixelY}) is not between 0 and ${video.ffmpeg.video.resolution.h}`,
        });
      }

      if (
        video.ReleasePixelX < 0 ||
        video.ReleasePixelX > video.ffmpeg.video.resolution.w
      ) {
        output.push({
          fatal: true,
          msg: `Release Pixel X (${video.ReleasePixelX}) is not between 0 and ${video.ffmpeg.video.resolution.w}`,
        });
      }

      if (
        video.MoundPixelY < 0 ||
        video.MoundPixelY > video.ffmpeg.video.resolution.h
      ) {
        output.push({
          fatal: true,
          msg: `Mound Pixel Y (${video.MoundPixelY}) is not between 0 and ${video.ffmpeg.video.resolution.h}`,
        });
      }

      if (
        video.MoundPixelX < 0 ||
        video.MoundPixelX > video.ffmpeg.video.resolution.w
      ) {
        output.push({
          fatal: true,
          msg: `Mound Pixel X (${video.MoundPixelX}) is not between 0 and ${video.ffmpeg.video.resolution.w}`,
        });
      }

      if (video.ReleasePixelY > video.MoundPixelY) {
        output.push({
          fatal: true,
          msg: `Release Pixel Y (${video.ReleasePixelY}) is greater than Mound Pixel Y (${video.MoundPixelY})`,
        });
      }

      if (video.ReleaseTime < MIN_RELEASE_TIME_SEC) {
        output.push({
          fatal: true,
          msg: `Release Time (${video.ReleaseTime.toFixed(
            3
          )}s) is less than the minimum (${MIN_RELEASE_TIME_SEC}s)`,
        });
      }

      if (
        Math.abs(
          video.ReleaseFrame / video.ffmpeg.video.fps - video.ReleaseTime
        ) > MAX_DELTA_RELEASE_TIME_FRAME_SEC
      ) {
        output.push({
          fatal: true,
          msg: `Release Time (${video.ReleaseTime.toFixed(
            3
          )}s) is inconsistent with Release Frame (${
            video.ReleaseFrame
          }) and FPS (${video.ffmpeg.video.fps})`,
        });
      }

      if (video.ReleaseFrame > video.n_frames) {
        output.push({
          fatal: true,
          msg: `Release Frame (${video.ReleaseFrame}) is greater than the max value (${video.n_frames})`,
        });
      }

      if (video.ReleaseFrame < MIN_RELEASE_FRAME) {
        output.push({
          fatal: true,
          msg: `Release Frame (${video.ReleaseFrame}) is less than the min value (${MIN_RELEASE_FRAME})`,
        });
      }

      return output;
    } catch (e) {
      return [
        {
          fatal: true,
          msg:
            e instanceof Error
              ? e.message
              : 'Unknown error while checking video validity',
        },
      ];
    }
  }

  /** returns warnings (e.g. when there's a large discrepancy between video release and pitch release) */
  static validateSelection(config: {
    position: IPosition;
    video: IVideo;
    /** used for errors if provided */
    pitch_name?: string;
  }): string[] {
    const output: string[] = [];

    if (
      Math.abs(config.position.px - config.video.ReleaseSide) >
      VALIDATION_TOLERANCE.X.value
    ) {
      output.push(
        `Release side (${config.position.px.toFixed(1)} ft) ${
          config.pitch_name ? `for "${config.pitch_name}" ` : ''
        }is more than ${
          VALIDATION_TOLERANCE.X.text
        } off of video release side (${config.video.ReleaseSide.toFixed(
          1
        )} ft).`
      );
    }

    if (
      Math.abs(config.position.pz - config.video.ReleaseHeight) >
      VALIDATION_TOLERANCE.Z.value
    ) {
      output.push(
        `Release height (${config.position.pz.toFixed(1)} ft) ${
          config.pitch_name ? `for "${config.pitch_name}" ` : ''
        }is more than ${
          VALIDATION_TOLERANCE.Z.text
        } off of video release height (${config.video.ReleaseHeight.toFixed(
          1
        )} ft).`
      );
    }

    return output;
  }
}
