import * as Tooltip from '@radix-ui/react-tooltip';
import useOnClickAway from 'hooks/useOnClickAway';
import { t } from 'i18next';
import React, { useCallback, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

interface IProps {
  /** trigger for tooltip */
  trigger: React.ReactNode;

  /** what gets rendered inside the bubble */
  content?: React.ReactNode;

  /** will be translated via i18next */
  text?: string;

  /** will be rendered via react markdown, not used when rendered as title */
  text_md?: string;

  /** disabled just renders the children without any tooltip wrapper */
  disabled?: boolean;
}

export const CommonTooltip: React.FC<IProps> = ({
  trigger,
  content,
  text,
  text_md,
  disabled,
}) => {
  const tooltipRef = useRef(null);
  const [open, setOpen] = useState(false);

  /*
    Close the tooltip when clicking outside of it:
    
    Radix Tooltip handles this fine on desktop, but there are certain cases
    where the tooltip doesn't close properly on mobile devices (likely because Radix
    doesn't officially support mobile / touch-event tooltips). 
  */
  const onClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickAway({
    ref: tooltipRef,
    shouldListen: open,
    onClickAway,
  });

  if (disabled) {
    return <>{trigger}</>;
  }

  const tooltipContent = (
    <>
      {text && t(text)}
      {text_md && <ReactMarkdown>{t(text_md)}</ReactMarkdown>}
      {content}
    </>
  );

  return (
    <Tooltip.Root open={open} onOpenChange={setOpen}>
      <Tooltip.Trigger
        onClick={() => setOpen((prev) => !prev)}
        ref={tooltipRef}
        asChild
      >
        {trigger}
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="rt-TooltipContent">
          {tooltipContent}
          <Tooltip.Arrow className="rt-TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};
