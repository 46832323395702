import { NotifyHelper } from 'classes/helpers/notify.helper';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import { RestoreListsDialog } from 'components/sections/admin-portal/dialogs/restore-lists';
import { EditTeamDialog } from 'components/sections/admin-portal/teams/edit';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { TeamDialog } from 'lib_ts/enums/teams.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IAdminTeam, ITeam } from 'lib_ts/interfaces/i-team';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminTeamsService } from 'services/admin/teams.service';

const CONTEXT_NAME = 'AdminTeamsContext';

interface IOptionsDict {
  teams: IOption[];
  _created: string[];
}

export interface ITeamsContext {
  teams: IAdminTeam[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly create: (payload: Partial<IAdminTeam>) => Promise<boolean>;
  readonly update: (payload: Partial<IAdminTeam>) => Promise<boolean>;

  readonly openDialog: (args: {
    mode: TeamDialog;
    // undefined for when we're using the editor to create a new team
    team: ITeam | undefined;
  }) => void;
  readonly closeDialog: () => void;
}

const DEFAULT: ITeamsContext = {
  teams: [],

  options: {
    teams: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.error(`${CONTEXT_NAME}: not init`),

  create: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  update: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  openDialog: () => console.error(`${CONTEXT_NAME}: not init`),
  closeDialog: () => console.error(`${CONTEXT_NAME}: not init`),
};

interface IDialog {
  key: number;
  mode: TeamDialog;
  team: ITeam | undefined;
}

export const TeamsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const TeamsProvider: FC<IProps> = (props) => {
  const authCx = useContext(AuthContext);

  const [lastFetched, setLastFetched] = useState(new Date());

  const [teams, setTeams] = useState(DEFAULT.teams);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const options = useMemo(() => {
    const output: IOptionsDict = {
      teams: teams.map((m) => {
        const o: IOption = {
          label: m.name,
          group: m.league,
          value: m._id,
        };

        return o;
      }),

      _created: ArrayHelper.unique(
        teams.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };

    return output;
  }, [teams]);

  const [dialog, setDialog] = useState<IDialog>();

  const state: ITeamsContext = {
    teams: teams,

    options: options,

    loading: loading,

    refresh: () => setLastFetched(new Date()),

    create: async (payload) => {
      try {
        setLoading(true);
        const result = await AdminTeamsService.getInstance().create(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const newTeam: IAdminTeam = result.data;
        setTeams((prev) => [...prev, newTeam]);

        NotifyHelper.success({
          message_md: `Team ${newTeam.name} created!`,
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    update: async (payload) => {
      try {
        setLoading(true);

        const result = await AdminTeamsService.getInstance().update(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const team = result.data as ITeam;
        const newTeams = [...teams];

        const index = newTeams.findIndex((v) => v._id === team._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newTeams.splice(index, 1, team);
        } else {
          /** append to end */
          newTeams.push(team);
        }

        setTeams(newTeams);

        const warnings: string[] = [];

        // check if any domains are duplicated across different teams
        if (!!team.email_domains && ArrayHelper.isArray(team.email_domains)) {
          // other teams with domains defined
          const otherTeams = newTeams.filter(
            (t) =>
              t._id !== team._id &&
              !!t.email_domains &&
              ArrayHelper.isArray(t.email_domains)
          );

          // all unique domains from other teams
          const otherDomains = ArrayHelper.unique(
            otherTeams.map((t) => t.email_domains).flat()
          );

          team.email_domains.forEach((domain) => {
            if (otherDomains.includes(domain)) {
              const allTeamNames = [
                team.name,
                ...otherTeams
                  .filter((t) => t.email_domains.includes(domain))
                  .map((t) => t.name),
              ];

              warnings.push(
                `Domain <${domain}> is associated with multiple teams: ${allTeamNames.join(
                  ', '
                )}`
              );
            }
          });
        }

        if (warnings.length === 0) {
          NotifyHelper.success({
            message_md: `Team "${team.name}" updated!`,
          });
        } else {
          NotifyHelper.warning({
            message_md: `Team "${team.name}" updated with warnings. Check console for more info.`,
            inbox: true,
            delay_ms: 0,
          });

          console.warn({
            event: 'ADMIN: team update warnings',
            warnings,
          });
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    openDialog: (value) =>
      setDialog({
        key: Date.now(),
        mode: value.mode,
        team: value.team,
      }),

    closeDialog: () => setDialog(undefined),
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    const callback = async (): Promise<void> => {
      try {
        setLoading(true);

        const result = await AdminTeamsService.getInstance().getTeams(true);

        result.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

        setTeams(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    callback();
  }, [lastFetched]);

  const { current } = useContext(AuthContext);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    setLastFetched(new Date());
  }, [current.session]);

  return (
    <TeamsContext.Provider value={state}>
      {props.children}

      {dialog && dialog.mode === TeamDialog.Refresh && dialog.team && (
        <RefreshListsDialog
          key={dialog.key}
          name={dialog.team.name}
          parent_def={PitchListOwner.Team}
          parent_id={dialog.team._id}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog && dialog.mode === TeamDialog.Restore && dialog.team && (
        <RestoreListsDialog
          key={dialog.key}
          name={dialog.team.name}
          parent_def={PitchListOwner.Team}
          parent_id={dialog.team._id}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog && dialog.mode === TeamDialog.Editor && (
        <TeamsContext.Consumer>
          {(teamsCx) => (
            <EditTeamDialog
              key={dialog.key}
              id={dialog.team?._id}
              authCx={authCx}
              teamsCx={teamsCx}
              onClose={() => setDialog(undefined)}
            />
          )}
        </TeamsContext.Consumer>
      )}
    </TeamsContext.Provider>
  );
};
