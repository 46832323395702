import { CommonSearchInput } from 'components/common/form/search';
import { VideosContext } from 'contexts/videos/videos.context';
import { PITCH_TYPE_FILTER_OPTIONS } from 'enums/filters';
import { PitchType } from 'lib_ts/enums/pitches.enums';
import { useContext } from 'react';

interface IProps {
  onChange?: () => void;
}

export const VideosFilterPitchType = (props: IProps) => {
  const { filter, mergeFilter } = useContext(VideosContext);

  return (
    <CommonSearchInput
      id="video-type"
      placeholder="common.pitch-type"
      options={PITCH_TYPE_FILTER_OPTIONS}
      values={filter.type}
      onChange={(v) => {
        mergeFilter({ type: v as PitchType[] });
        props.onChange?.();
      }}
      multiple
      optional
      skipSort
    />
  );
};
