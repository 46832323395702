import { Card, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonVideoPlayer } from 'components/common/video/player';
import { VideoPreviewButton } from 'components/common/video/preview-button';
import { PitchListSidebarSectionTitle } from 'components/sections/pitch-list/sidebar/section-title';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { useContext, useMemo, useState } from 'react';
import { CommonInputLabel } from '../form/label';
import { CommonInputWrapper } from '../form/wrapper';

const COMPONENT_NAME = 'CommonVideoPreview';

interface IProps {
  previewPx?: number;
  previewPz?: number;

  headerAsInput?: boolean;
  hideHeader?: boolean;
  playback?: IVideoPlayback;

  disabled?: boolean;

  selectConfig?: {
    px: number;
    video_id: string | undefined;
    onChange: (video_id: string | undefined) => void;
  };

  hidePlayback?: boolean;
  hidePreviewButton?: boolean;
}

export const CommonVideoPreview = (props: IProps) => {
  const { getVideosByReleaseSide } = useContext(VideosContext);

  // Show recently uploaded first if the user is not searching for a video
  const [showRecentlyUploadedFirst, setShowRecentlyUploadedFirst] =
    useState(true);

  // force re-render of specific components
  const key = useMemo(() => Date.now(), [props.playback]);

  const subLabel = useMemo(() => {
    if (props.selectConfig) {
      return undefined;
    }

    if (!props.playback) {
      return t('videos.no-video');
    }

    if (props.playback.video.VideoTitle) {
      return props.playback.video.VideoTitle;
    }

    if (props.playback.video.VideoFileName) {
      return props.playback.video.VideoFileName;
    }

    return t('videos.untitled');
  }, [props.playback, props.selectConfig]);

  const options = useMemo(() => {
    if (!props.selectConfig) {
      return [];
    }

    return getVideosByReleaseSide(
      props.selectConfig?.px ?? 0,
      showRecentlyUploadedFirst
    );
  }, [props.selectConfig, showRecentlyUploadedFirst]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="2">
        {!props.hideHeader && (
          <Flex justify="between" align="center">
            {props.headerAsInput ? (
              <CommonInputWrapper id="video-preview">
                <CommonInputLabel label="videos.video-preview" />
              </CommonInputWrapper>
            ) : (
              <PitchListSidebarSectionTitle
                label="videos.video-preview"
                subLabel={subLabel}
              />
            )}

            {!props.hidePreviewButton && (
              <VideoPreviewButton
                id={props.selectConfig?.video_id}
                px={props.previewPx}
                pz={props.previewPz}
                disabled={props.disabled}
              />
            )}
          </Flex>
        )}

        {props.selectConfig && (
          <CommonSearchInput
            key={`search-${key}`}
            id="video-preview-videoID"
            name="video_id"
            values={
              props.selectConfig.video_id ? [props.selectConfig.video_id] : []
            }
            options={options}
            disabled={props.disabled}
            onChange={(videoIDs) => props.selectConfig?.onChange(videoIDs[0])}
            optional
            skipSort
            onSearch={(q) => setShowRecentlyUploadedFirst(!q)}
          />
        )}

        {!props.hidePlayback && !props.playback && (
          <Card
            key={`placeholder-${key}`}
            style={{
              aspectRatio: '16 / 10',
              textAlign: 'center',
            }}
          >
            <Flex height="100%" align="center" justify="center">
              <Text color={RADIX.COLOR.NEUTRAL}>
                {t('videos.no-video-selected')}
              </Text>
            </Flex>
          </Card>
        )}

        {!props.hidePlayback && props.playback && (
          <CommonVideoPlayer key={`player-${key}`} playback={props.playback} />
        )}
      </Flex>
    </ErrorBoundary>
  );
};
