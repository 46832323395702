import { CopyIcon, InfoCircledIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Grid, Text } from '@radix-ui/themes';
import { AimingContextHelper } from 'classes/helpers/aiming-context.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallState,
  IClosedLoopBuildChars,
  IPitch,
} from 'lib_ts/interfaces/pitches';
import { ISpin } from 'lib_ts/interfaces/pitches/i-base';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { StateTransformService } from 'services/state-transform.service';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'EditSpinsDialog';

// how many decimals to print
const PRECISION = 0;

// show warning if any axis requested differs from corresponding avgs by more than this
const WARNING_THRESHOLD_RPM = 500;

interface IProps {
  pitch: IPitch;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  onClose: () => void;

  // hides the update button
  readonly: boolean;
}

interface IState {
  actual_wx_text?: string;
  actual_wx_rpm: number;
  actual_wy_text?: string;
  actual_wy_rpm: number;
  actual_wz_text?: string;
  actual_wz_rpm: number;

  target_wx_text?: string;
  target_wx_rpm: number;
  target_wy_text?: string;
  target_wy_rpm: number;
  target_wz_text?: string;
  target_wz_rpm: number;

  overriding: boolean;
}

// TODO: I don't think we need this many useCallbacks
export const EditSpinsDialog = (props: IProps) => {
  const init = useRef(false);

  const listsCx = useContext(PitchListsContext);
  const listStore = usePitchListStore(
    useShallow(({ loading, updatePitches, reloadPitches }) => ({
      loading,
      updatePitches,
      reloadPitches,
    }))
  );

  const [state, setState] = useState<IState>({
    actual_wx_rpm: 0,
    actual_wy_rpm: 0,
    actual_wz_rpm: 0,

    target_wx_rpm: props.pitch.bs?.wx ?? 0,
    target_wy_rpm: props.pitch.bs?.wy ?? 0,
    target_wz_rpm: props.pitch.bs?.wz ?? 0,

    overriding: false,
  });

  const getShotsWithBallStates = useCallback((): IMachineShot[] => {
    return props.matchingCx
      .safeGetShotsByPitch(props.pitch)
      .filter((s) => !!s.bs);
  }, [props.matchingCx, props.pitch]);

  const getMedianSpins = useCallback((): ISpin => {
    const spins = getShotsWithBallStates().map((s) => s.bs as ISpin);

    if (spins.length === 0) {
      return {
        wx: 0,
        wy: 0,
        wz: 0,
      };
    }

    return MiscHelper.getMedianObject(spins) as ISpin;
  }, [getShotsWithBallStates]);

  const initialize = useCallback(async () => {
    if (getShotsWithBallStates().length === 0) {
      await props.matchingCx.updatePitch({
        pitch: props.pitch,
        includeHitterPresent: false,
        includeLowConfidence: false,
      });
    }

    const target = props.pitch.bs;
    const useTarget = props.pitch.priority
      ? props.pitch.priority === 'Spins'
      : true;

    const medianSpins = getMedianSpins();

    const target_wx_rpm = useTarget ? target.wx : medianSpins.wx;
    const target_wy_rpm = useTarget ? target.wy : medianSpins.wy;
    const target_wz_rpm = useTarget ? target.wz : medianSpins.wz;

    setState((prev) => ({
      ...prev,
      actual_wx_rpm: medianSpins.wx,
      actual_wx_text: medianSpins.wx.toFixed(PRECISION),
      actual_wy_rpm: medianSpins.wy,
      actual_wy_text: medianSpins.wy.toFixed(PRECISION),
      actual_wz_rpm: medianSpins.wz,
      actual_wz_text: medianSpins.wz.toFixed(PRECISION),

      target_wx_rpm: target_wx_rpm,
      target_wx_text: target_wx_rpm.toFixed(PRECISION),
      target_wy_rpm: target_wy_rpm,
      target_wy_text: target_wy_rpm.toFixed(PRECISION),
      target_wz_rpm: target_wz_rpm,
      target_wz_text: target_wz_rpm.toFixed(PRECISION),
    }));
  }, [getShotsWithBallStates, getMedianSpins, props.matchingCx, props.pitch]);

  const resetActualSpins = useCallback(() => {
    const medianSpins = getMedianSpins();

    setState((prev) => ({
      ...prev,
      actual_wx_rpm: medianSpins.wx,
      actual_wx_text: medianSpins.wx.toFixed(PRECISION),
      actual_wy_rpm: medianSpins.wy,
      actual_wy_text: medianSpins.wy.toFixed(PRECISION),
      actual_wz_rpm: medianSpins.wz,
      actual_wz_text: medianSpins.wz.toFixed(PRECISION),
    }));
  }, [getMedianSpins]);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      initialize();
    }
  }, [initialize]);

  const submit = useCallback(
    async (mode: 'copy' | 'update') => {
      if (mode === 'update' && props.readonly) {
        NotifyHelper.error({
          message_md: 'Updating is not allowed in read-only mode.',
        });
        return;
      }

      const medianSpins = getMedianSpins();
      if (!medianSpins) {
        NotifyHelper.error({
          message_md: 'There is insufficient data to proceed.',
        });
        return;
      }

      const msResult = getMSFromMSDict(props.pitch, props.machineCx.machine);
      if (!msResult.ms) {
        NotifyHelper.error({ message_md: 'There is no machine state.' });
        return;
      }

      const newSpin = {
        wx: state.target_wx_rpm,
        wy: state.target_wy_rpm,
        wz: state.target_wz_rpm,
      } as ISpin;

      const newTargetBS = {
        ...props.pitch.bs,
        ...newSpin,
        wnet: BallHelper.getNetSpin(newSpin),
      };

      const actualSpin = {
        wx: state.actual_wx_rpm,
        wy: state.actual_wy_rpm,
        wz: state.actual_wz_rpm,
      } as ISpin;

      const medianBS = MiscHelper.getMedianObject(
        getShotsWithBallStates().map((s) => s.bs as IBallState)
      );

      const actualBS = {
        ...medianBS,
        ...actualSpin,
      } as unknown as IBallState;

      const chars: IClosedLoopBuildChars = {
        mongo_id: props.pitch._id,
        priority: BuildPriority.Spins,
        use_gradient: true,
        ms: msResult.ms,
        target_bs: newTargetBS,
        actual_bs: actualBS,
        traj: props.pitch.traj,
      };

      const result = (
        await StateTransformService.getInstance().buildClosedLoop({
          machineID: props.machineCx.machine.machineID,
          pitches: [chars],
          stepSize: 1,
          notifyError: true,
        })
      ).find((p) => p.mongo_id === props.pitch._id);

      if (!result) {
        NotifyHelper.error({
          message_md: 'Failed to find adjusted pitch by ID.',
        });
        return;
      }

      const nextMSDict = getMergedMSDict(
        props.machineCx.machine,
        [result.ms],
        props.pitch.msDict
      );

      const payload: Partial<IPitch> = {
        ...props.pitch,
        bs: result.target_bs,
        traj: result.traj,
        msDict: nextMSDict,
        priority: BuildPriority.Spins,
      };

      const aimed = AimingContextHelper.getAdHocAimed({
        source: `${COMPONENT_NAME} > submit`,
        machine: props.machineCx.machine,
        pitch: payload as IPitch,
        plate: TrajHelper.getPlateLoc(props.pitch.traj),
        usingShots: [],
      });

      if (!aimed) {
        NotifyHelper.error({ message_md: 'Failed to build new pitch.' });
        return;
      }

      switch (mode) {
        case 'copy': {
          aimed.pitch._original_id = props.pitch._id;
          const nameSuffix = `(Edited)`;
          aimed.pitch.name = `${props.pitch.name} ${nameSuffix}`;

          await PitchListsService.getInstance()
            .postPitchesToList({
              listID: props.pitch._parent_id,
              data: [aimed.pitch],
            })
            .then((result) => {
              if (!result.success) {
                NotifyHelper.warning({
                  message_md: result.error ?? 'Pitch could not be created.',
                });
                return;
              }

              if (props.pitch._parent_id === listsCx.active?._id) {
                listStore.reloadPitches();
              }
            });
          break;
        }

        case 'update': {
          await listStore.updatePitches({
            payloads: [aimed.pitch],
          });
          break;
        }

        default: {
          break;
        }
      }

      props.onClose();
    },
    [
      getMedianSpins,
      getShotsWithBallStates,
      props.machineCx,
      props.matchingCx,
      props.onClose,
      props.pitch,
      props.readonly,
      state.actual_wx_rpm,
      state.actual_wy_rpm,
      state.actual_wz_rpm,
      state.target_wx_rpm,
      state.target_wy_rpm,
      state.target_wz_rpm,
      listsCx.active,
      listStore.updatePitches,
      listStore.reloadPitches,
    ]
  );

  const renderContent = useCallback(() => {
    const diffX = state.actual_wx_rpm - state.target_wx_rpm;
    const warnX = Math.abs(diffX) > WARNING_THRESHOLD_RPM;

    const diffY = state.actual_wy_rpm - state.target_wy_rpm;
    const warnY = Math.abs(diffY) > WARNING_THRESHOLD_RPM;

    const diffZ = state.actual_wz_rpm - state.target_wz_rpm;
    const warnZ = Math.abs(diffZ) > WARNING_THRESHOLD_RPM;

    return (
      <Flex gap={RADIX.FLEX.GAP.LG} direction="column">
        <Text>
          Use this feature to directly control the 3D spin of a pitch. This
          feature will save a new pitch with the adjusted spin values.
        </Text>

        <Grid columns="3" gap={RADIX.FLEX.GAP.MD}>
          <Box></Box>
          <Box>
            {state.overriding ? 'User Override' : 'Actual'} &nbsp;
            <CommonTooltip
              trigger={<InfoCircledIcon />}
              text_md={
                state.overriding
                  ? 'Actual spins as input by user'
                  : 'Actual spins as reported by Rapsodo'
              }
            />
          </Box>
          <Box>Target</Box>
          <Box>Spin X (RPM)</Box>
          <Box>
            <CommonTextInput
              id="edit-spins-actual-wx"
              name="actual_wx_text"
              type="number"
              className="align-center"
              value={state.actual_wx_text}
              disabled={listStore.loading || !state.overriding}
              onOptionalNumericChange={(v) => {
                setState((prev) => ({
                  ...prev,
                  actual_wx_text: v?.toString(),
                  actual_wx_rpm: v ?? 0,
                }));
              }}
            />
          </Box>
          <Box>
            <CommonTextInput
              id="edit-spins-target-wx"
              name="target_wx_text"
              type="number"
              className="align-center"
              value={state.target_wx_text}
              disabled={listStore.loading}
              onOptionalNumericChange={(v) => {
                setState((prev) => ({
                  ...prev,
                  target_wx_text: v?.toString(),
                  target_wx_rpm: v ?? 0,
                }));
              }}
            />
          </Box>
          <Box>Spin Y (RPM)</Box>
          <Box>
            <CommonTextInput
              id="edit-spins-actual-wy"
              name="actual_wy_text"
              type="number"
              className="align-center"
              value={state.actual_wy_text}
              disabled={listStore.loading || !state.overriding}
              onOptionalNumericChange={(v) => {
                setState((prev) => ({
                  ...prev,
                  actual_wy_text: v?.toString(),
                  actual_wy_rpm: v ?? 0,
                }));
              }}
            />
          </Box>
          <Box>
            <CommonTextInput
              id="edit-spins-target-wy"
              name="target_wy_text"
              type="number"
              className="align-center"
              value={state.target_wy_text}
              disabled={listStore.loading}
              onOptionalNumericChange={(v) => {
                setState((prev) => ({
                  ...prev,
                  target_wy_text: v?.toString(),
                  target_wy_rpm: v ?? 0,
                }));
              }}
            />
          </Box>
          <Box>Spin Z (RPM)</Box>
          <Box>
            <CommonTextInput
              id="edit-spins-actual-wz"
              name="actual_wz_text"
              type="number"
              className="align-center"
              value={state.actual_wz_text}
              disabled={listStore.loading || !state.overriding}
              onOptionalNumericChange={(v) => {
                setState((prev) => ({
                  ...prev,
                  actual_wz_text: v?.toString(),
                  actual_wz_rpm: v ?? 0,
                }));
              }}
            />
          </Box>
          <Box>
            <CommonTextInput
              id="edit-spins-target-wz"
              name="target_wz_text"
              type="number"
              className="align-center"
              value={state.target_wz_text}
              disabled={listStore.loading}
              onOptionalNumericChange={(v) => {
                setState((prev) => ({
                  ...prev,
                  target_wz_text: v?.toString(),
                  target_wz_rpm: v ?? 0,
                }));
              }}
            />
          </Box>
        </Grid>

        {(warnX || warnY || warnZ) && (
          <CommonCallout
            text={(() => {
              const axesStrings = [];
              if (warnX) {
                axesStrings.push('X');
              }
              if (warnY) {
                axesStrings.push('Y');
              }
              if (warnZ) {
                axesStrings.push('Z');
              }
              const axesString = axesStrings.join(', ');
              return `Target value differs by more than ${WARNING_THRESHOLD_RPM} for ${
                axesStrings.length > 1 ? 'Axes' : 'Axis'
              } ${axesString}`;
            })()}
          />
        )}
      </Flex>
    );
  }, [
    listStore.loading,
    state.actual_wx_rpm,
    state.actual_wx_text,
    state.actual_wy_rpm,
    state.actual_wy_text,
    state.actual_wz_rpm,
    state.actual_wz_text,
    state.overriding,
    state.target_wx_rpm,
    state.target_wx_text,
    state.target_wy_rpm,
    state.target_wy_text,
    state.target_wz_rpm,
    state.target_wz_text,
  ]);

  const pitchName = props.pitch.name;
  const shots = getShotsWithBallStates();

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.LG}
        title={`${t('pl.edit-spins')} for "${pitchName}"`}
        content={renderContent()}
        buttons={[
          {
            label: `Override: ${state.overriding ? 'ON' : 'OFF'}`,
            color: state.overriding ? RADIX.COLOR.WARNING : undefined,
            onClick: () => {
              setState((prev) => ({
                ...prev,
                overriding: !state.overriding,
              }));
              resetActualSpins();
            },
          },
          {
            icon: <CopyIcon />,
            label: 'common.copy',
            color: RADIX.COLOR.SUCCESS,
            disabled: shots.length === 0,
            onClick: () => submit('copy'),
          },
          {
            invisible: props.readonly,
            icon: <UpdateIcon />,
            label: 'common.update',
            color: RADIX.COLOR.WARNING,
            disabled: shots.length === 0,
            onClick: () => submit('update'),
          },
        ]}
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
