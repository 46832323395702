import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IUser } from 'lib_ts/interfaces/i-user';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminUsersService } from 'services/admin/users.service';

const CONTEXT_NAME = 'AdminUsersContext';

interface IOptionsDict {
  emails: IOption[];
  _created: string[];
}

interface IFilter {
  email: string[];
  team: string[];
  machines: string[];
  created: string[];
}

export interface IUsersContext {
  users: IUser[];

  filtered: IUser[];
  filter: IFilter;
  readonly mergeFilter: (config: Partial<IFilter>) => void;

  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly createUser: (payload: Partial<IUser>) => Promise<boolean>;
  readonly updateUser: (
    payload: Partial<IUser>,
    silently?: boolean
  ) => Promise<boolean>;
  readonly deleteUsers: (ids: string[]) => Promise<boolean>;
}

const DEFAULT: IUsersContext = {
  users: [],

  filtered: [],
  filter: {
    email: [],
    team: [],
    machines: [],
    created: [],
  },
  mergeFilter: () => console.error(`${CONTEXT_NAME}: not init`),

  options: {
    emails: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.error(`${CONTEXT_NAME}: not init`),
  createUser: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  updateUser: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  deleteUsers: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

export const UsersContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const UsersProvider: FC<IProps> = (props) => {
  const { current } = useContext(AuthContext);

  const [lastFetched, setLastFetched] = useState(new Date());

  /** reload data to match session access */
  useEffect(() => setLastFetched(new Date()), [current.session]);

  const [users, setUsers] = useState(DEFAULT.users);
  const [filter, setFilter] = useState(DEFAULT.filter);

  const filtered = useMemo(() => {
    const output = users
      .filter(
        (m) => filter.email.length === 0 || filter.email.includes(m.email)
      )
      .filter(
        (m) => filter.team.length === 0 || filter.team.includes(m._parent_id)
      )
      .filter(
        (m) =>
          filter.machines.length === 0 || filter.machines.includes(m.machineID)
      )
      .filter(
        (m) =>
          filter.created.length === 0 ||
          filter.created.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );

    return output;
  }, [users, filter]);

  const options = useMemo(() => {
    const output: IOptionsDict = {
      emails: users.map((m) => {
        const o: IOption = {
          label: m.email,
          group: m.email.split('@')[1]?.toUpperCase(),
          value: m.email,
        };

        return o;
      }),

      _created: ArrayHelper.unique(
        users.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };

    return output;
  }, [users]);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const state: IUsersContext = {
    users: users,

    filtered: filtered,
    filter: filter,
    mergeFilter: (config) => {
      setFilter({ ...filter, ...config });
    },

    options: options,

    loading: loading,

    refresh: () => setLastFetched(new Date()),

    deleteUsers: async (ids) => {
      try {
        setLoading(true);

        const result = await AdminUsersService.getInstance().deleteUsers(ids);

        if (!result.success) {
          throw new Error(result.error);
        }

        setUsers((prev) => prev.filter((u) => !ids.includes(u._id)));

        NotifyHelper.success({
          message_md: `${ids.length > 1 ? 'Users' : 'User'} deleted.`,
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    createUser: async (payload) => {
      try {
        setLoading(true);

        const result = await AdminUsersService.getInstance().postUser(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const user = result.data as IUser;

        setUsers((prev) => [...prev, user]);

        NotifyHelper.success({ message_md: `User ${user.email} created!` });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    updateUser: async (payload, silently) => {
      try {
        setLoading(true);

        const result = await AdminUsersService.getInstance().putUser(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const user = result.data as IUser;
        const newUsers = [...users];

        const index = newUsers.findIndex((v) => v._id === user._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newUsers.splice(index, 1, user);
        } else {
          /** append to end */
          newUsers.push(user);
        }

        setUsers(newUsers);

        if (!silently) {
          NotifyHelper.success({
            message_md: `User ${user.email} updated!`,
          });
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });
        return false;
      } finally {
        setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    const callback = async (): Promise<void> => {
      try {
        setLoading(true);

        const result = await AdminUsersService.getInstance().getUsers(true);

        setUsers(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    callback();
  }, [lastFetched]);

  return (
    <UsersContext.Provider value={state}>
      {props.children}
    </UsersContext.Provider>
  );
};
