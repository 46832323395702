import { Spinner } from '@radix-ui/themes';
import { MachineContext } from 'contexts/machine.context';
import { QueueType } from 'interfaces/i-queue-mode';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';
import { QueueContext } from '../queue.context';

export const QueueIndex = (props: { pitch: IPitch }) => {
  const { lastPitchID, firing } = useContext(MachineContext);
  const { def: queueDef, ids: queuePitchIDs } = useContext(QueueContext);

  if (firing && lastPitchID === props.pitch._id) {
    return <Spinner />;
  }

  if (queueDef.type !== QueueType.RepeatAll) {
    // not the right mode
    return <></>;
  }

  const queueIndex = queuePitchIDs.findIndex((id) => id === props.pitch._id);

  if (queueIndex === -1) {
    // not in queue
    return <></>;
  }

  return <>{queueIndex + 1}</>;
};
