import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import {
  IServerError,
  IServerErrorSummary,
} from 'lib_ts/interfaces/i-server-error';
import { BaseRESTService } from 'services/_base-rest.service';

export class ServerErrorsService extends BaseRESTService {
  private static instance: ServerErrorsService;
  static getInstance(): ServerErrorsService {
    if (!ServerErrorsService.instance) {
      ServerErrorsService.instance = new ServerErrorsService();
    }

    return ServerErrorsService.instance;
  }

  private constructor() {
    super({
      controller: 'server-errors',
    });
  }

  async getSummaries(): Promise<IServerErrorSummary[]> {
    return await this.get({
      uri: 'summaries',
    }).then((result: IServerResponse) => result.data as IServerErrorSummary[]);
  }

  async getById(id: string): Promise<IServerError> {
    return await this.get({
      uri: id,
    }).then((result: IServerResponse) => result.data as IServerError);
  }
}
