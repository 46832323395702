import { Skeleton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CopyFolderDialog } from 'components/common/pitch-lists/copy-folder';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { DeleteFolderDialog } from 'components/main/sidebar/pitch-lists/dialogs/delete-folder';
import { RenameFolderDialog } from 'components/main/sidebar/pitch-lists/dialogs/rename-folder';
import { FullMenu } from 'components/main/sidebar/pitch-lists/full-menu';
import { MiniMenu } from 'components/main/sidebar/pitch-lists/mini-menu';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICopyToFrom, IPitchList } from 'lib_ts/interfaces/pitches';
import { useCallback, useContext, useState } from 'react';

const COMPONENT_NAME = 'SidebarPitchLists';

export const SidebarPitchLists = () => {
  const { app } = useContext(CookiesContext);
  const { lists, loading, refreshLists } = useContext(PitchListsContext);
  const { current, restrictedGameStatus } = useContext(AuthContext);

  const [renameFolder, setRenameFolder] = useState<
    ISidebarFolder | undefined
  >();

  const [dialogRenameFolder, setDialogRenameFolder] = useState<
    number | undefined
  >();

  const [manageLists, setManageLists] = useState<IPitchList[] | undefined>();
  const [fromFolder, setFromFolder] = useState<ICopyToFrom | undefined>();

  const [dialogCopyFolder, setDialogCopyFolder] = useState<
    number | undefined
  >();
  const [dialogReassignFolder, setDialogReassignFolder] = useState<
    number | undefined
  >();
  const [dialogDeleteFolder, setDialogDeleteFolder] = useState<
    number | undefined
  >();
  const [dialogCreateList, setDialogCreateList] = useState<
    number | undefined
  >();

  const getFolderActions = useCallback(
    (folder: ISidebarFolder): IMenuAction[] => {
      /** only allow admins to change machine or team lists (i.e. reassign or delete) */
      const canMutate =
        // personal list belonging to the current user
        folder._parent_id === current.userID ||
        // non-personal list but the current user is an admin
        [UserRole.admin, UserRole.team_admin].includes(current.role);

      const folderType = (() => {
        switch (folder._parent_def as PitchListOwner) {
          case PitchListOwner.Team: {
            return 'team folders';
          }

          case PitchListOwner.Machine: {
            return 'machine folders';
          }

          case PitchListOwner.User: {
            return 'personal folders';
          }

          case PitchListOwner.None:
          default: {
            return 'folders';
          }
        }
      })();

      return [
        {
          label: t('common.rename-x', { x: t('pl.folder') }),
          invisible: restrictedGameStatus,
          onClick: () => {
            setRenameFolder(folder);
            setDialogRenameFolder(Date.now());
          },
        },
        {
          label: t('common.duplicate-x', { x: t('pl.folder') }),
          onClick: () => {
            if (!folder._parent_def) {
              NotifyHelper.warning({
                message_md:
                  'Parent definition cannot be empty. Please contact support.',
              });
              return;
            }

            const folderContents = SidebarHelper.getFilesRecursively(folder);
            if (folderContents.length === 0) {
              NotifyHelper.warning({
                message_md: `No pitch lists found in folder "${folder.pathDisplay}".`,
              });
              return;
            }

            setManageLists(folderContents.map((f) => f.object));
            setFromFolder({
              _parent_def: folder._parent_def,
              _parent_id: folder._parent_id,
              folder: folder.pathDisplay,
            });
            setDialogCopyFolder(Date.now());
          },
        },
        {
          label: t('common.reassign-x', { x: t('pl.folder') }),
          color: RADIX.COLOR.WARNING,
          onClick: () => {
            if (!canMutate) {
              NotifyHelper.warning({
                message_md: `Only admins can reassign ${folderType}. Please contact your team's admin or support for more assistance.`,
                buttons: [
                  {
                    label: t('common.read-more'),
                    onClick: () =>
                      window.open(
                        t('common.intercom-url') + HELP_URLS.PITCH_REASSIGN
                      ),
                  },
                ],
              });
              return;
            }

            if (!folder._parent_def) {
              NotifyHelper.warning({
                message_md:
                  'Parent definition cannot be empty. Please contact support.',
              });
              return;
            }

            const folderContents = SidebarHelper.getFilesRecursively(folder);
            if (folderContents.length === 0) {
              NotifyHelper.warning({
                message_md: `No pitch lists found in folder "${folder.pathDisplay}".`,
              });
              return;
            }

            setManageLists(folderContents.map((f) => f.object));
            setFromFolder({
              _parent_def: folder._parent_def,
              _parent_id: folder._parent_id,
              folder: folder.pathDisplay,
            });
            setDialogReassignFolder(Date.now());
          },
        },
        {
          label: t('common.delete-x', { x: t('pl.folder') }),
          color: RADIX.COLOR.DANGER,
          onClick: () => {
            if (!canMutate) {
              NotifyHelper.warning({
                message_md: `Only admins can delete ${folderType}. Please contact your team's admin or support for more assistance.`,
                buttons: [
                  {
                    label: t('common.read-more'),
                    onClick: () =>
                      window.open(
                        t('common.intercom-url', {
                          x: HELP_URLS.PITCH_REASSIGN,
                        }).toString()
                      ),
                  },
                ],
              });
              return;
            }

            const folderContents = SidebarHelper.getFilesRecursively(folder);
            if (folderContents.length === 0) {
              NotifyHelper.warning({
                message_md: `No pitch lists found in folder "${folder.pathDisplay}".`,
              });
              return;
            }

            setManageLists(folderContents.map((f) => f.object));
            setDialogDeleteFolder(Date.now());
          },
        },
      ];
    },
    [current]
  );

  if (loading && lists.length === 0) {
    return <Skeleton />;
  }

  return (
    <ErrorBoundary
      componentName={COMPONENT_NAME}
      data-testid="SidebarPitchLists"
    >
      {app.sidebar_state === 'mini' ? (
        <MiniMenu
          actions={[
            {
              label: 'common.create-pitch-list',
              group: 'common.actions',
              onClick: () => setDialogCreateList(Date.now()),
            },
            {
              label: 'common.refresh',
              group: 'common.actions',
              onClick: () => refreshLists(),
            },
          ]}
        />
      ) : (
        <FullMenu
          onCreate={() => setDialogCreateList(Date.now())}
          onRefresh={() => refreshLists()}
          getFolderActions={getFolderActions}
        />
      )}

      {dialogRenameFolder && renameFolder && (
        <RenameFolderDialog
          key={dialogRenameFolder}
          folder={renameFolder}
          onClose={() => setDialogRenameFolder(undefined)}
        />
      )}

      {dialogReassignFolder && fromFolder && (
        <ReassignListsDialog
          key={dialogReassignFolder}
          identifier="SidebarPitchListsReassignListsDialog"
          refPayload={{
            filterLists: manageLists,
            filter: {
              _parent_id: fromFolder._parent_id,
              _parent_def: fromFolder._parent_def,
            },
            update: {
              processed: new Date(),
              process_notes: `reassigned from sidebar by user ${current.email}`,
            },
          }}
          onClose={() => setDialogReassignFolder(undefined)}
        />
      )}

      {dialogCreateList && (
        <ManageListDialog
          key={dialogCreateList}
          identifier="SidebarPitchListsCreateListDialog"
          mode="create"
          onCreated={() => setDialogCreateList(undefined)}
          onClose={() => setDialogCreateList(undefined)}
        />
      )}

      {dialogCopyFolder && fromFolder && manageLists && (
        <CopyFolderDialog
          key={dialogCopyFolder}
          identifier="SidebarPitchListsCopyFolderDialog"
          lists={manageLists}
          from={fromFolder}
          onClose={() => setDialogCopyFolder(undefined)}
        />
      )}

      {dialogDeleteFolder && manageLists && (
        <DeleteFolderDialog key={dialogDeleteFolder} lists={manageLists} />
      )}
    </ErrorBoundary>
  );
};
