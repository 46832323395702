import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import env from 'config';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { IUserEventMsg, IWSMsg } from 'lib_ts/interfaces/i-machine-msg';
import { ICloseEvent, IMessageEvent, w3cwebsocket } from 'websocket';

/** 1 retry every 500 ms, 10 times => max wait of 5 seconds before giving up */
const MAX_ATTEMPTS = 10;
const NOTIFY_WS_ERROR = false;

/** for talking to the machine */
export class WebSocketService {
  private static instance?: WebSocketService;
  private onCloseCallback?: () => void;

  static isConnected(): boolean {
    return this.instance?.client.OPEN !== undefined;
  }

  static init(token: string) {
    try {
      const FN_NAME = 'WsInit';

      const callback = () => {
        console.debug(
          `${FN_NAME}: created new WS instance w/ token *-${token.slice(-6)}`
        );
        WebSocketService.instance = new WebSocketService(token);
      };

      if (!token) {
        console.debug(
          `${FN_NAME}: skipped creation of WS instance w/ empty token`
        );
        return;
      }

      const current = WebSocketService.instance;

      if (!current) {
        callback();
        return;
      }

      console.debug(`${FN_NAME}: closing existing WS instance first`);

      // prepare the onclose handler to init an instance with the new token when we're done closing
      current.onCloseCallback = callback;
      current.close();
    } catch (e) {
      console.error(e);
    }
  }

  /** should be the only way anything external ever accesses the instance */
  static getInstance(): WebSocketService | undefined {
    return WebSocketService.instance;
  }

  /** for sending messages back to the server */
  private client: w3cwebsocket;

  static async send(type: WsMsgType, data: any, source: string) {
    try {
      const client = WebSocketService.instance?.client;

      if (!client) {
        throw new Error(
          `Attempted to send ${type} message via undefined WS client (${source})`
        );
      }

      /** wait for open before sending */
      const waitForConnection = (callback: () => void) => {
        let attempts = 0;

        const interval = setInterval(() => {
          attempts++;

          if (client.readyState !== client.OPEN) {
            if (attempts > MAX_ATTEMPTS) {
              console.warn(
                `WS was not open after ${MAX_ATTEMPTS} attempts, abandoning send instruction`
              );
              clearInterval(interval);
            }
            return;
          }

          clearInterval(interval);
          callback();
        }, 1_000);
      };

      waitForConnection(() =>
        client.send(JSON.stringify({ type: type, data: data }))
      );
    } catch (e) {
      console.error(e);

      if (NOTIFY_WS_ERROR) {
        NotifyHelper.warning({
          message_md:
            'There was a problem sending the WebSocket message. Please try again or refresh your browser.',
        });
      }
    }
  }

  // returns flag for success
  static sendWithoutRetry(type: WsMsgType, data: any, source: string) {
    try {
      const client = WebSocketService.instance?.client;

      if (!client) {
        throw new Error(
          `Attempted to send ${type} message via undefined WS client (${source})`
        );
      }

      client.send(JSON.stringify({ type: type, data: data }));
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private constructor(token: string) {
    const conn = new w3cwebsocket(`${env.ws_url}?token=${token}`);

    conn.onmessage = (payload: IMessageEvent) => {
      if (typeof payload.data !== 'string') {
        console.warn({
          event: 'WS received payload with non-string data',
          payload,
        });
        return;
      }

      const message = JSON.parse(payload.data) as IWSMsg;
      if (!message.type) {
        console.error({
          event: 'WS received message with empty type, nothing dispatched',
          message,
        });
        return;
      }

      WebSocketHelper.dispatch(message.type, message.data);
    };

    conn.onclose = (event: ICloseEvent) => {
      const FN_NAME = 'WsOnClose';

      console.debug(`${FN_NAME}: triggered`, event);

      // isConnected will return false until a new connection is created
      WebSocketService.instance = undefined;

      if (this.onCloseCallback) {
        console.debug(`${FN_NAME}: executing onCloseCallback`);
        this.onCloseCallback();
        this.onCloseCallback = undefined;
      }
    };

    conn.onerror = console.error;

    conn.onopen = () => {
      const data: IUserEventMsg = { type: 'open' };
      WebSocketService.send(WsMsgType.Misc_UserConnection, data, 'open');
    };

    this.client = conn;
  }

  close() {
    try {
      this.client.close();
    } catch (e) {
      console.error(e);
    }
  }
}
