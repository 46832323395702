import { Box, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { SessionEventsContext } from 'contexts/session-events.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const SessionsToolbar = () => {
  const { filters, options, mergeFilters } = useContext(SessionEventsContext);

  return (
    <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
      <Box gridColumn="span 2">
        <CommonSearchInput
          id="filter-session"
          placeholder="common.session"
          options={options.session.map((o) => ({
            label: o,
            value: o,
          }))}
          values={filters.sessions}
          onChange={(v) => {
            mergeFilters({ sessions: v });
          }}
          multiple
          strict
        />
      </Box>
      <Box gridColumn="span 2">
        <CommonSearchInput
          id="filter-session-start"
          placeholder="common.start-date"
          options={options.start.map((o) => ({
            label: o,
            value: o,
          }))}
          values={filters.starts}
          onChange={(v) => {
            mergeFilters({ starts: v });
          }}
          multiple
        />
      </Box>
    </Grid>
  );
};
