import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Grid } from '@radix-ui/themes';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { QuickSessionContext } from 'contexts/quick-session.context';
import {
  PITCHER_HANDS,
  PITCHER_RELEASES,
  PitcherHand,
  PitcherRelease,
  PLAYER_LEVELS,
  PlayerLevel,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const SelectCardToolbar = () => {
  const { filters, options, mergeFilters } = useContext(QuickSessionContext);

  return (
    <Grid columns="5" gap={RADIX.FLEX.GAP.SM}>
      <Box>
        <CommonSearchInput
          key={`names-${filters.key}`}
          id="filter-names"
          placeholder="common.name"
          options={options.names.map((o) => ({
            label: o,
            value: o,
          }))}
          values={filters.names}
          onChange={(v) => mergeFilters({ names: v })}
          multiple
          optional
        />
      </Box>

      <CommonSelectInput
        key={`slot-${filters.key}`}
        id="card-slot"
        placeholder="common.slot"
        name="filter-slot"
        options={PITCHER_RELEASES}
        value={filters.release}
        onChange={(v) => mergeFilters({ release: v as PitcherRelease })}
        optional
      />

      <CommonSelectInput
        key={`level-${filters.key}`}
        id="card-level"
        placeholder="common.level"
        name="filter-level"
        options={PLAYER_LEVELS}
        value={filters.level}
        onChange={(v) => mergeFilters({ level: v as PlayerLevel })}
        optional
        skipSort
      />

      <CommonSelectInput
        key={`hand-${filters.key}`}
        id="card-hand"
        placeholder="common.hand"
        name="filter-hand"
        options={PITCHER_HANDS}
        value={filters.hand}
        onChange={(v) => mergeFilters({ hand: v as PitcherHand })}
        optional
      />

      <CommonTableButton
        label="common.reset"
        icon={<ResetIcon />}
        variant="soft"
        onClick={() => {
          mergeFilters({
            names: [],
            hand: undefined,
            level: undefined,
            release: undefined,
            key: Date.now(),
          });
        }}
      />
    </Grid>
  );
};
