import { EnvelopeOpenIcon, PlusIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Badge, Box, Code, Grid, Link } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { AnnouncementDialog } from 'components/common/dialogs/announcement';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { TableProvider } from 'components/common/table/context';
import { RefreshListsDialog } from 'components/sections/admin-portal/dialogs/refresh-lists';
import { RestoreListsDialog } from 'components/sections/admin-portal/dialogs/restore-lists';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { DeleteUsersDialog } from 'components/sections/admin-portal/users/dialogs/delete-users';
import { EditUserDialog } from 'components/sections/admin-portal/users/dialogs/edit-user';
import { ViewSessionsDialog } from 'components/sections/admin-portal/users/dialogs/view-sessions';
import { MachinesContext } from 'contexts/admin/machines.context';
import { TeamsContext } from 'contexts/admin/teams.context';
import { UsersContext } from 'contexts/admin/users.context';
import { AuthContext } from 'contexts/auth.context';
import { GlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { USER_ROLES, UserRole, UserStatus } from 'lib_ts/enums/auth.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IUser } from 'lib_ts/interfaces/i-user';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { useCallback, useContext, useMemo, useState } from 'react';
import { AdminUsersService } from 'services/admin/users.service';

const IDENTIFIER = TableIdentifier.AdminUserList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

enum DialogMode {
  Announcement,
  Delete,
  Editor,
  Reassign,
  Refresh,
  Restore,
  ResetPassword,
  Sessions,
}

export const UsersTable = () => {
  // todo: clean up dialogs so we don't have to do this
  const globalCx = useContext(GlobalContext);
  const authCx = useContext(AuthContext);
  const teamsCx = useContext(TeamsContext);
  const machinesCx = useContext(MachinesContext);
  const usersCx = useContext(UsersContext);

  const { options: teamOptions } = useContext(TeamsContext);
  const { options: machineOptions } = useContext(MachinesContext);
  const { current, impersonate } = useContext(AuthContext);
  const {
    filter,
    filtered,
    loading: usersLoading,
    options,
    mergeFilter,
    refresh,
    updateUser,
  } = useContext(UsersContext);

  const [manageUser, setManageUser] = useState<IUser>();
  const [reassignListsPayload, setReassignListsPayload] =
    useState<IPitchListPutManyRequest>({ filter: {}, update: {} });
  const [deleteUsers, setDeleteUsers] = useState<IUser[]>();

  const [dialog, setDialog] = useState<DialogMode>();
  const dialogKey = useMemo(() => Date.now(), [dialog]);

  const columns = useMemo(() => {
    const output: ITableColumn[] = [
      {
        label: 'common.actions',
        key: ACTIONS_KEY,
        actions: [
          {
            label: 'common.edit',
            onClick: (user: IUser) => {
              setManageUser(user);
              setDialog(DialogMode.Editor);
            },
          },
          {
            label: 'admin.reset-password',
            color: RADIX.COLOR.WARNING,
            onClick: (user: IUser) => {
              setManageUser(user);
              setDialog(DialogMode.ResetPassword);
            },
          },
          {
            label: 'admin.refresh-lists',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            color: RADIX.COLOR.WARNING,
            onClick: (user: IUser) => {
              setManageUser(user);
              setDialog(DialogMode.Refresh);
            },
          },
          {
            label: 'admin.restore-lists',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            color: RADIX.COLOR.WARNING,
            onClick: (user: IUser) => {
              setManageUser(user);
              setDialog(DialogMode.Restore);
            },
          },
          {
            label: 'common.reassign-lists',
            onClick: (user: IUser) => {
              const payload: IPitchListPutManyRequest = {
                filter: {
                  _parent_id: user._id,
                  _parent_def: PitchListOwner.User,
                },
                update: {
                  processed: new Date(),
                  process_notes: `reassigned from user ${user.email} by admin ${current.email}`,
                  _parent_def: PitchListOwner.User,
                },
              };

              setReassignListsPayload(payload);
              setDialog(DialogMode.Reassign);
            },
            color: RADIX.COLOR.WARNING,
          },
          {
            label: 'admin.view-sessions',
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: () => current.role !== UserRole.admin,
            onClick: (user: IUser) => {
              setManageUser(user);
              setDialog(DialogMode.Sessions);
            },
            color: RADIX.COLOR.WARNING,
          },
          {
            label: 'admin.impersonate',
            suffixIcon: <SuperAdminIcon />,
            invisibleFn: (user: IUser) =>
              current.role !== UserRole.admin ||
              user.status !== UserStatus.verified,
            onClick: (user: IUser) => {
              impersonate({ email: user.email });
            },
            color: RADIX.COLOR.WARNING,
          },
          {
            label: 'admin.toggle-access',
            onClick: async (user: IUser) => {
              const wasVerified = user.status === UserStatus.verified;

              const success = await updateUser(
                {
                  _id: user._id,
                  status: wasVerified
                    ? UserStatus.deactivated
                    : UserStatus.verified,
                },
                true
              );

              if (!success) {
                NotifyHelper.error({
                  message_md: t('common.request-failed-msg'),
                });

                return;
              }

              NotifyHelper.success({
                message_md: `User ${user.email} has been ${
                  wasVerified ? 'deactivated' : 'activated'
                }!`,
              });
            },
            color: RADIX.COLOR.DANGER,
          },
          {
            label: 'common.delete',
            suffixIcon: <SuperAdminIcon />,
            onClick: (user: IUser) => {
              setDeleteUsers([user]);
              setDialog(DialogMode.Delete);
            },
            color: RADIX.COLOR.DANGER,
            /** only super admins can delete users */
            invisibleFn: () => current.role !== UserRole.admin,
          },
        ],
      },
      {
        label: 'common.created',
        key: '_created',
        formatFn: (m: IMongoBase) =>
          lightFormat(parseISO(m._created), 'yyyy-MM-dd'),
      },
      {
        label: 'common.email',
        key: 'email',
        formatFn: (m: IUser) => (
          <Link href={`mailto:${m.email}`}>{m.email}</Link>
        ),
      },
      {
        label: 'common.machine',
        key: 'machineID',
        formatFn: (m: IUser) => <Badge>{m.machineID}</Badge>,
      },
      {
        label: 'admin.role',
        key: 'role',
        formatFn: (m: IUser) => {
          const label = USER_ROLES.find((r) => r.value === m.role)?.label;
          return label ? (
            t(label)
          ) : (
            <Badge color={RADIX.COLOR.DANGER}> {m.role}</Badge>
          );
        },
      },
      {
        label: 'admin.access',
        key: '_access',
        formatFn: (v: IUser) => (
          <Badge
            color={
              v.status === UserStatus.verified
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.DANGER
            }
          >
            {v.status === UserStatus.verified ? 'Enabled' : 'Disabled'}
          </Badge>
        ),
        sortRowsFn: (a: IUser, b: IUser, dir: number) =>
          (a.status ?? '').localeCompare(b.status ?? '') * -dir,
      },
    ];

    return output;
  }, [current.role]);

  const checkedActions = useCallback(
    (checked: IUser[]): IMenuAction[] => {
      const actions: IMenuAction[] = [
        {
          label: 'admin.copy-emails',
          onClick: () => {
            navigator.clipboard
              .writeText(checked.map((u) => u.email).join(';'))
              .then(() =>
                NotifyHelper.success({
                  message_md: 'Copied user emails to clipboard!',
                })
              );
          },
        },
        {
          label: 'common.delete',
          suffixIcon: <SuperAdminIcon />,
          color: RADIX.COLOR.DANGER,
          invisible: current.role !== UserRole.admin,
          onClick: () => {
            setDeleteUsers(checked);
            setDialog(DialogMode.Delete);
          },
        },
      ];

      return actions;
    },
    [current.role]
  );

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filtered.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: 'email',
      dir: -1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
  };

  const checkable: ITableCheckable = {
    checkboxColumnIndex: 0,
    checkedActions: checkedActions,
  };

  return (
    <ErrorBoundary componentName="UsersTable">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={<AdminTabNav active={SubSectionName.Users} />}
        table={
          <TableProvider>
            <CheckedProvider data={filtered}>
              <CheckedContext.Consumer>
                {(checkedCx) => (
                  <CommonTable
                    id="AdminUsers"
                    toolbarContent={
                      <Grid columns="7" gap={RADIX.FLEX.GAP.SM}>
                        <Box>
                          <CommonSearchInput
                            id="users-email"
                            placeholder="common.email"
                            options={options.emails}
                            values={filter.email}
                            onChange={(v) => {
                              checkedCx.resetChecked();
                              mergeFilter({ email: v });
                            }}
                            multiple
                            optional
                          />
                        </Box>
                        <Box>
                          <CommonSearchInput
                            id="users-team"
                            placeholder="common.team"
                            options={teamOptions.teams}
                            values={filter.team}
                            onChange={(v) => {
                              checkedCx.resetChecked();
                              mergeFilter({ team: v });
                            }}
                            multiple
                            optional
                          />
                        </Box>
                        <Box>
                          <CommonSearchInput
                            id="users-machine"
                            placeholder="common.machine"
                            options={machineOptions.machines}
                            values={filter.machines}
                            onChange={(v) => {
                              checkedCx.resetChecked();
                              mergeFilter({ machines: v });
                            }}
                            multiple
                            optional
                          />
                        </Box>
                        <Box>
                          <CommonSearchInput
                            id="users-created"
                            placeholder="common.date-added"
                            options={options._created.map((o) => ({
                              label: o,
                              value: o,
                            }))}
                            values={filter.created}
                            onChange={(v) => {
                              checkedCx.resetChecked();
                              mergeFilter({ created: v });
                            }}
                            multiple
                            optional
                            reverseSort
                          />
                        </Box>

                        <CommonTableButton
                          label="common.refresh"
                          icon={<UpdateIcon />}
                          color={RADIX.COLOR.NEUTRAL}
                          disabled={usersLoading}
                          onClick={() => {
                            checkedCx.resetChecked();
                            refresh();
                          }}
                        />

                        <CommonTableButton
                          color={RADIX.COLOR.SUCCESS}
                          disabled={usersLoading}
                          onClick={() => {
                            setManageUser(undefined);
                            setDialog(DialogMode.Editor);
                          }}
                          icon={<PlusIcon />}
                          label="common.create"
                        />

                        <CommonTableButton
                          color={RADIX.COLOR.WARNING}
                          disabled={usersLoading}
                          onClick={() => {
                            setDialog(DialogMode.Announcement);
                          }}
                          icon={<EnvelopeOpenIcon />}
                          label="admin.announcement"
                        />
                      </Grid>
                    }
                    displayColumns={columns}
                    displayData={filtered}
                    enableListener={globalCx.dialogs.length === 0}
                    {...pagination}
                    {...checkable}
                    {...select}
                    {...sort}
                    vFlex
                  />
                )}
              </CheckedContext.Consumer>
            </CheckedProvider>
          </TableProvider>
        }
      />

      {dialog === DialogMode.ResetPassword && manageUser && (
        <CommonConfirmationDialog
          identifier="ResetPassword"
          title="admin.reset-password"
          content={
            <p>
              Are you sure you want to reset <Code>{manageUser.email}</Code>'s
              password?
            </p>
          }
          action={{
            label: 'admin.reset-password',
            onClick: async () => {
              if (!manageUser) {
                setDialog(undefined);
                return;
              }

              const result =
                await AdminUsersService.getInstance().resetPasswordUrl(
                  manageUser._id
                );

              if (!result.success) {
                NotifyHelper.warning({
                  message_md:
                    result.error ??
                    `There was an error generating a reset password URL for ${
                      manageUser?.email ?? '(no email)'
                    }.`,
                });
                return;
              }

              const url = result.data as string;

              NotifyHelper.info({
                message_md:
                  'Password reset URL generated. How would you like to notify the user?',
                delay_ms: 0,
                buttons: [
                  {
                    label: 'Link',
                    dismissAfterClick: true,
                    onClick: () => {
                      navigator.clipboard.writeText(url).then(() =>
                        NotifyHelper.success({
                          message_md: 'Copied URL to clipboard.',
                        })
                      );
                    },
                  },
                  {
                    label: 'Email',
                    dismissAfterClick: true,
                    onClick: () => {
                      NotifyHelper.info({
                        message_md: 'Attempting to open email client...',
                      });

                      /** this will allow the mailto link to preserve the ?email and &code parts of the URL */
                      const escapedUrl = url
                        .replace('?', '%3F')
                        .replace('&', '%26');
                      window.open(
                        `mailto:${manageUser?.email}?subject=Trajekt App Password Reset&body=Please visit the following link to  reset your password:%0D%0A%0D%0A${escapedUrl}`
                      );
                    },
                  },
                ],
              });

              setDialog(undefined);
            },
          }}
          cancel={{
            onClick: () => {
              setDialog(undefined);
            },
          }}
        />
      )}
      {dialog === DialogMode.Sessions && manageUser && (
        <ViewSessionsDialog
          key={dialogKey}
          user={manageUser}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Refresh && manageUser && (
        <RefreshListsDialog
          key={dialogKey}
          name={manageUser.email}
          parent_def={PitchListOwner.User}
          parent_id={manageUser._id}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Restore && manageUser && (
        <RestoreListsDialog
          key={dialogKey}
          name={manageUser.email}
          parent_def={PitchListOwner.User}
          parent_id={manageUser._id}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Reassign && (
        <ReassignListsDialog
          key={dialogKey}
          identifier="UsersTableReassignListsDialog"
          refPayload={reassignListsPayload}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Announcement && (
        <AnnouncementDialog
          key={dialogKey}
          identifier="UsersTableAnnouncementDialog"
          authCx={authCx}
          teamsCx={teamsCx}
          machinesCx={machinesCx}
          usersCx={usersCx}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Editor && (
        <EditUserDialog
          key={dialogKey}
          id={manageUser?._id}
          authCx={authCx}
          teamsCx={teamsCx}
          machinesCx={machinesCx}
          usersCx={usersCx}
          onClose={() => setDialog(undefined)}
        />
      )}

      {dialog === DialogMode.Delete && deleteUsers && (
        <DeleteUsersDialog key={dialogKey} users={deleteUsers} />
      )}
    </ErrorBoundary>
  );
};
