import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MachinesContext } from 'contexts/admin/machines.context';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getModelKeyFromModel } from 'lib_ts/classes/ms.helper';
import {
  MachineModelType,
  ModelStatus,
} from 'lib_ts/enums/machine-models.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  IEvalModelResult,
  IPythonEvalModelsResult,
} from 'lib_ts/interfaces/modelling/i-eval-models';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { ITrainModelsRequest } from 'lib_ts/interfaces/modelling/i-train-model';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

const CONTEXT_NAME = 'AdminMachineModelsContext';

interface IOptionsDict {
  names: IOption[];
  machineIDs: string[];
  types: MachineModelType[];
  tags: string[];
  _created: string[];
}

export interface IMachineModelsContext {
  models: IMachineModel[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly activateModel: (config: {
    modelID: string;
    machineID: string;
  }) => Promise<boolean>;

  readonly trainModels: (
    payload: ITrainModelsRequest
  ) => Promise<IPythonEvalModelsResult | undefined>;
  readonly updateModel: (payload: Partial<IMachineModel>) => Promise<boolean>;
  readonly archiveModels: (ids: string[]) => Promise<boolean>;

  readonly evalModelMetrics: (
    modelIDs: string[],
    query: IGatherShotDataQuery
  ) => Promise<IEvalModelResult[]>;

  /** mostly for using an existing model for a new machine */
  readonly copyModel: (payload: Partial<IMachineModel>) => Promise<boolean>;
}

const DEFAULT: IMachineModelsContext = {
  models: [],

  options: {
    names: [],
    machineIDs: [],
    tags: [],
    types: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.error(`${CONTEXT_NAME}: not init`),

  activateModel: async () => new Promise(() => false),

  trainModels: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  updateModel: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  archiveModels: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  evalModelMetrics: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  copyModel: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

const getOptions = (data: IMachineModel[]): IOptionsDict => {
  try {
    if (!data) {
      throw new Error('MODELS CONTEXT: no data provided to getOptions');
    }

    return {
      names: data.map((m) => ({
        label: m.name,
        group: m.machineID,
        value: m.name,
      })),

      machineIDs: ArrayHelper.unique(data.map((m) => m.machineID)),

      tags: ArrayHelper.unique(data.flatMap((m) => m.tags ?? [])),

      types: ArrayHelper.unique(data.map((m) => m.type)),

      /** for some reason, some models exist without _created date */
      _created: ArrayHelper.unique(
        data.map((m) =>
          m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : ''
        )
      ),
    };
  } catch (e) {
    console.error(e);
    return DEFAULT.options;
  }
};

export const MachineModelsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const MachineModelsProvider: FC<IProps> = (props) => {
  const [lastFetched, setLastFetched] = useState(new Date());

  const [models, setModels] = useState(DEFAULT.models);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const options = useMemo(() => getOptions(models), [models]);

  const updateModel = async (
    payload: Partial<IMachineModel>
  ): Promise<boolean> => {
    try {
      setLoading(true);

      const result =
        await AdminMachineModelsService.getInstance().updateModel(payload);

      if (result) {
        const current = [...models];
        const index = current.findIndex((v) => v._id === result._id);

        if (index !== -1) {
          if (result.archived) {
            /** remove current without replacement */
            current.splice(index, 1);
          } else {
            /** remove current with replacement using updated value */
            current.splice(index, 1, result);
          }
        } else if (!result.archived) {
          /** append to end */
          current.push(result);
        }

        setModels(current);
      }

      return !!result;
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md: t('common.request-failed-msg'),
      });

      return false;
    } finally {
      setLoading(false);
    }
  };

  const { machines, update } = useContext(MachinesContext);

  const state: IMachineModelsContext = {
    models: models,

    options: options,

    loading: loading,

    refresh: () => setLastFetched(new Date()),

    activateModel: async (config) => {
      const model = models.find((m) => m._id === config.modelID);

      if (!model) {
        NotifyHelper.warning({
          message_md: `Failed to find model ${config.modelID} in loaded models.`,
        });
        return false;
      }

      const machine = machines.find((m) => m.machineID === config.machineID);

      if (!machine) {
        NotifyHelper.warning({
          message_md: `Failed to find machine ${config.machineID} in loaded machines.`,
        });
        return false;
      }

      if (!machine.model_ids) {
        machine.model_ids = {};
      }

      machine.model_ids[getModelKeyFromModel(model)] = model._id;

      const success = !!(await update({
        _id: machine._id,
        model_ids: machine.model_ids,
      }));

      if (success) {
        const payload: Partial<IMachineModel> = {
          _id: model._id,
          status: ModelStatus.Published,
        };

        updateModel(payload);
      }

      return success;
    },

    copyModel: async (model) => {
      try {
        setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().copyModel(model);

        if (result) {
          setModels((prev) => [...prev, result]);
        }

        return !!result;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        setLoading(false);
      }
    },

    trainModels: async (payload) => {
      try {
        setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().trainModels(payload);

        if (result && result.results && result.results.length > 0) {
          setLastFetched(new Date());
        }

        return result;
      } catch (e) {
        console.error(e);

        return undefined;
      } finally {
        setLoading(false);
      }
    },

    updateModel: updateModel,

    archiveModels: async (ids) => {
      try {
        setLoading(true);

        const success =
          await AdminMachineModelsService.getInstance().archiveModels(ids);

        if (success) {
          setModels((prev) => prev.filter((m) => !ids.includes(m._id)));
        }

        return success;
      } catch (e) {
        console.error(e);

        return false;
      } finally {
        setLoading(false);
      }
    },

    evalModelMetrics: async (modelIDs, query) => {
      try {
        setLoading(true);

        const result =
          await AdminMachineModelsService.getInstance().evalModelMetrics(
            modelIDs,
            query
          );

        return result;
      } catch (e) {
        console.error(e);
        return [];
      } finally {
        setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true);

      return AdminMachineModelsService.getInstance()
        .getAllModels()
        .then((result) => {
          setModels(
            result.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
          );
        })
        .finally(() => setLoading(false));
    })();
  }, [lastFetched]);

  const { current } = useContext(AuthContext);

  /** reload data to match session access */
  useEffect(() => {
    /** trigger refresh */
    setLastFetched(new Date());
  }, [current.session]);

  return (
    <MachineModelsContext.Provider value={state}>
      {props.children}
    </MachineModelsContext.Provider>
  );
};
