import { RouteHelper } from 'classes/helpers/route.helper';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { HOME, ISection, ISectionDef } from 'interfaces/i-section';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { GAME_STATUS_BLACKLIST } from 'lib_ts/enums/mlb.enums';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CONTEXT_NAME = 'SectionsContext';

export enum DirtyForm {
  AdminVideos = 'AdminVideos',
  PitchDesign = 'PitchDesign',
  PitchUploader = 'PitchUploader',
  VideoLibrary = 'VideoLibrary',
}

interface IChangeSectionConfig extends ISectionDef {
  trigger: string;
  beforeNavCallback?: () => Promise<void>;

  // resets dirty forms and skips prompts
  ignoreDirty?: boolean;
}

export interface ISectionsContext {
  active: ISectionDef;
  userSections?: ISection[];
  adminSections?: ISection[];

  // todo: get rid of this shim for a better breadcrumb management method
  lastListID?: string;

  dirtyForms: DirtyForm[];
  readonly markDirtyForm: (context: DirtyForm) => void;
  readonly clearDirtyForm: (context: DirtyForm) => void;

  readonly tryGoHome: () => boolean;
  readonly tryChangeSection: (config: IChangeSectionConfig) => boolean;
}

const DEFAULT: ISectionsContext = {
  active: {
    section: SectionName.Pitches,
    subsection: SubSectionName.Library,
  },
  userSections: [],

  dirtyForms: [],
  markDirtyForm: () => console.error(`${CONTEXT_NAME}: not init`),
  clearDirtyForm: () => console.error(`${CONTEXT_NAME}: not init`),

  tryGoHome: () => false,
  tryChangeSection: () => false,
};

export const SectionsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const SectionsProvider: FC<IProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { current, restrictedGameStatus, gameStatus } = useContext(AuthContext);
  const { machine, specialMstarget } = useContext(MachineContext);

  const [restored, setRestored] = useState(false);

  const userSections = useMemo(
    () =>
      SectionsHelper.getUserSections({
        permissions: current,
        restricted: restrictedGameStatus,
      }),
    [current, restrictedGameStatus]
  );

  const adminSections = useMemo(
    () =>
      SectionsHelper.getAdminSections({
        role: current.role,
        restricted: restrictedGameStatus,
        mode: current.mode,
      }),
    [current, restrictedGameStatus]
  );

  const [active, setActive] = useState(DEFAULT.active);
  const [pending, setPending] = useState<IChangeSectionConfig>();

  const [lastListID, setLastListID] = useState(DEFAULT.lastListID);

  useEffect(() => {
    if (active.section !== SectionName.Pitches) {
      setLastListID(undefined);
      return;
    }

    switch (active.subsection) {
      case SubSectionName.Lists:
      case SubSectionName.Library: {
        // e.g. PD and PU breadcrumbs will go back to library
        setLastListID(undefined);
        break;
      }

      case SubSectionName.List: {
        // e.g. PD and PU breadcrumbs will go back to this particular list
        setLastListID(active.fragments?.[0]);
        break;
      }

      default: {
        break;
      }
    }
  }, [active]);

  const [dirty, setDirty] = useState(DEFAULT.dirtyForms);
  const [dialogConfirm, setDialogConfirm] = useState<number>();

  const changeSection = async (config: IChangeSectionConfig) => {
    if (config.beforeNavCallback) {
      await config.beforeNavCallback();
    }

    /** clear the dirty contexts whenever changing sections so it doesn't get stuck */
    setDirty([]);

    /** attempt to start screensaver (only if moving to different section, e.g. list to list won't trigger this) */
    if (machine.enable_auto_reset_ms) {
      specialMstarget(SpecialMsPosition.lowered);
    }

    setActive({
      section: config.section,
      subsection: config.subsection,
      fragments: config.fragments,
    });

    navigate(
      RouteHelper.getSlug([config.section, config.subsection], config.fragments)
    );
  };

  const tryChangeSection = useCallback(
    (config: IChangeSectionConfig): boolean => {
      // console.debug(
      //   `trying to change sections with ${_dirtyForms.length} dirty forms`,
      //   _dirtyForms
      // );

      if (config.ignoreDirty || dirty.length === 0) {
        changeSection(config);
        return true;
      }

      /** store the selection */
      setPending(config);

      /** show confirmation */
      setDialogConfirm(Date.now());

      return false;
    },
    [dirty]
  );

  const state: ISectionsContext = {
    active: active,

    userSections: userSections,
    adminSections: adminSections,

    lastListID: lastListID,

    dirtyForms: dirty,
    markDirtyForm: (form) => {
      console.debug(`adding form ${form} to dirty list`);
      dirty.push(form);
      setDirty(ArrayHelper.unique(dirty));
    },
    clearDirtyForm: (form) => {
      console.debug(`removing form ${form} from dirty list`);
      setDirty(dirty.filter((c) => c !== form));
    },

    tryGoHome: () =>
      tryChangeSection({
        ...HOME,
        trigger: 'go home',
      }),
    tryChangeSection: tryChangeSection,
  };

  /** after launch, once user and admin sections have loaded
   * do a one-time restore (if possible) of the active section and subsection
   * based on the window location */
  useEffect(() => {
    if (restored) {
      return;
    }

    if (!userSections) {
      return;
    }

    if (!adminSections) {
      return;
    }

    /**
     * 0-index => empty, since the paths start with /
     * 1-index => section name slugified
     * 2-index => (optional) subsection name slugified OR fragment
     * 3-index => (optional) fragment(s) (only if subsection exists)
     */
    const locationParts = location.pathname.split('/');

    const sectionSlug = RouteHelper.getSlug([locationParts[1]]);

    const section =
      userSections.find((s) => s.slug === sectionSlug) ??
      adminSections.find((s) => s.slug === sectionSlug);

    if (!section) {
      tryChangeSection({
        ...HOME,
        trigger: 'failed from location at startup, go home',
      });
      return;
    }

    const subsectionSlug = section.subsections
      ? RouteHelper.getSlug([locationParts[2]])
      : undefined;

    const subsection = section?.subsections?.find(
      (ss) => ss.slug === subsectionSlug
    );

    const fragments = locationParts.slice(subsection ? 3 : 2);

    tryChangeSection({
      trigger: 'from location at startup',
      section: section.value,
      subsection: subsection?.value,
      fragments: fragments,
    });

    setRestored(true);
  }, [restored, userSections, adminSections]);

  /** go home upon starting impersonation */
  useEffect(() => {
    if (current.mode !== 'impostor') {
      // skip if the user isn't impersonating
      return;
    }

    tryChangeSection({
      ...HOME,
      trigger: 'started impersonation, go home',
    });
  }, [current.mode]);

  /** update sidebar options and potentially active section whenever user role and/or game status changes */
  useEffect(() => {
    if (!gameStatus) {
      // game status is not ready
      return;
    }
    if (!GAME_STATUS_BLACKLIST.includes(gameStatus)) {
      // game status is not blacklisted
      return;
    }

    if (
      userSections.findIndex(
        (s) => !s.invisible && s.value === active.section
      ) !== -1
    ) {
      /** user section is still available */
      return;
    }

    if (
      adminSections.findIndex(
        (s) => !s.invisible && s.value === active.section
      ) !== -1
    ) {
      /** admin section is still available */
      return;
    }

    tryChangeSection({
      trigger: `user on section ${active} redirected due to game status ${gameStatus}`,
      section: SectionName.GameInProgress,
      ignoreDirty: true,
    });
  }, [userSections, adminSections, gameStatus]);

  return (
    <SectionsContext.Provider value={state}>
      {props.children}

      {dialogConfirm && (
        <CommonConfirmationDialog
          // every new value of _confirm causes the alert dialog to re-mount
          key={dialogConfirm}
          identifier="ConfirmChangeSectionDialog"
          title="common.warning"
          description="common.unsaved-changes-msg"
          action={{
            label: 'common.proceed',
            onClick: () => {
              setDirty([]);

              if (pending) {
                changeSection(pending);
              }
            },
          }}
          cancel={{
            onClick: () => {
              // do nothing
            },
          }}
        />
      )}
    </SectionsContext.Provider>
  );
};
