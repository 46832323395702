import { NotifyHelper } from 'classes/helpers/notify.helper';
import { EditHitterDialogHoC } from 'components/common/dialogs/edit-hitter';
import { DeleteHittersDialog } from 'components/sections/hitter-library/dialogs/delete-hitters';
import { AuthContext } from 'contexts/auth.context';
import { lightFormat, parseISO } from 'date-fns';
import { CrudAction } from 'enums/tables';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { IHitter, IHitterStats } from 'lib_ts/interfaces/i-hitter';
import { IHitterExt } from 'lib_ts/interfaces/i-session-event';
import {
  DEFAULT_STRIKEZONE,
  STRINGER_STRIKEZONES,
} from 'lib_ts/interfaces/i-strike-zone';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HittersService } from 'services/hitters.service';

const CONTEXT_NAME = 'HittersContext';

/** in inches */
const STRINGER_STRIKEZONES_HEIGHTS = STRINGER_STRIKEZONES.map(
  (s) => s.height_in
).sort();
const MIN_HITTER_HEIGHT_IN = STRINGER_STRIKEZONES_HEIGHTS[0];
const MAX_HITTER_HEIGHT_IN =
  STRINGER_STRIKEZONES_HEIGHTS[STRINGER_STRIKEZONES_HEIGHTS.length - 1];

/**
 *
 * @param height_ft will be used to determine strike zone that's the closest match
 * @returns
 */
const getZone = (height_ft?: number) => {
  if (height_ft !== undefined && !isNaN(height_ft)) {
    /** if there is a failure to find, the closest zone will be used */
    const height_in = Math.round(height_ft * 12);
    if (height_in <= MIN_HITTER_HEIGHT_IN) {
      /** use the min strike zone */
      return STRINGER_STRIKEZONES.find(
        (s) => s.height_in === MIN_HITTER_HEIGHT_IN
      );
    } else if (height_in >= MAX_HITTER_HEIGHT_IN) {
      /** use the max strike zone */
      return STRINGER_STRIKEZONES.find(
        (s) => s.height_in === MAX_HITTER_HEIGHT_IN
      );
    } else {
      /** find the strike zone by height */
      return STRINGER_STRIKEZONES.find((s) => s.height_in === height_in);
    }
  }
};

/** values used for spawning and resetting stats entries */
export const getEmptyStats = (hitter_id: string): IHitterStats => ({
  hitter_id: hitter_id,
  pitches: 0,
  swings: 0,
  hits: 0,
});

interface ICrudConfig {
  key: number;
  action: CrudAction;
  models: IHitter[];
  onClose?: () => void;
}

interface IOptionsDict {
  _created: string[];
}

interface IFilterState extends Partial<IHitter> {}

export interface IHittersContext {
  lastChanged: number;

  filters: IFilterState;
  readonly setFilters: (filters: IFilterState) => void;

  filtered: IHitter[];

  active?: IHitter;

  hitters: IHitter[];

  stats: IHitterStats[];

  /** unique values for each key */
  options: IOptionsDict;

  loading: boolean;

  readonly getHitterExt: (hitter_id: string) => IHitterExt | undefined;

  /** change the active_id */
  readonly setActive: (hitter_id?: string) => void;

  /** inserts (it doesn't exist) or updates (if it exists) the stats entry for a given hitter, provide only a partial to only modify specific attributes */
  readonly upsertStats: (
    hitter_id: string,
    value: Partial<IHitterStats>
  ) => Promise<IHitterStats[]>;
  /** reverts the stats entry for a given hitter to the default (starting) values */
  readonly resetStats: (hitter_id: string) => void;
  readonly create: (
    payload: Partial<IHitter>,
    onCreate?: (value: IHitter) => void
  ) => Promise<boolean>;
  readonly update: (
    payload: Partial<IHitter>,
    onUpdate?: (value: IHitter) => void
  ) => Promise<boolean>;
  readonly delete: (ids: string[]) => Promise<boolean>;

  readonly openCrudDialog: (config: ICrudConfig) => void;
}

const DEFAULT: IHittersContext = {
  lastChanged: Date.now(),

  filters: {},
  setFilters: () => console.error(`${CONTEXT_NAME}: not init`),
  filtered: [],

  hitters: [],

  stats: [],

  options: {
    _created: [],
  },

  loading: false,

  getHitterExt: () => undefined,
  setActive: () => console.error(`${CONTEXT_NAME}: not init`),
  upsertStats: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  resetStats: () => console.error(`${CONTEXT_NAME}: not init`),
  create: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  delete: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  update: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  openCrudDialog: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const HittersContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

const getOptions = (v: IHitter[]): IOptionsDict => {
  if (v) {
    return {
      _created: ArrayHelper.unique(
        v.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const HittersProvider: FC<IProps> = (props) => {
  const [active, setActive] = useState(DEFAULT.active);

  const [hitters, setHitters] = useState(DEFAULT.hitters);
  const options = useMemo(() => getOptions(hitters), [hitters]);

  const [filters, setFilters] = useState(DEFAULT.filters);
  const [hitterStats, setHitterStats] = useState(DEFAULT.stats);
  const [loading, setLoading] = useState(DEFAULT.loading);

  const { current } = useContext(AuthContext);

  const [dialogCRUD, setDialogCRUD] = useState<ICrudConfig>();

  /** reload data to match session access */
  const lastFetched = useMemo(() => {
    /** trigger refresh only once logged in/successfully resumed */
    if (!current.auth) {
      return undefined;
    }

    if (!current.session) {
      return undefined;
    }

    return Date.now();
  }, [current.auth, current.session]);

  const [lastChanged, setLastChanged] = useState(Date.now());

  const filtered = useMemo(
    () =>
      hitters
        .filter((h) => !filters.side || filters.side === h.side)
        .filter((h) => !filters.level || filters.level === h.level),
    [hitters, filters]
  );

  const state: IHittersContext = {
    lastChanged: lastChanged,

    filters: filters,
    setFilters: setFilters,
    filtered: filtered,

    hitters: hitters,
    stats: hitterStats,
    options: options,
    loading: loading,

    active: active,

    getHitterExt: (hitter_id) => {
      const hitter = hitters.find((h) => h._id === hitter_id);
      if (!hitter) {
        return;
      }

      const stats = hitterStats.find((s) => s.hitter_id === hitter_id);
      if (!stats) {
        return;
      }

      const output: IHitterExt = {
        ...hitter,
        stats: stats,
        zone: getZone(hitter.height_ft) ?? DEFAULT_STRIKEZONE,
      };

      return output;
    },

    setActive: (hitter_id) => {
      setActive(hitters.find((h) => h._id === hitter_id));
    },

    upsertStats: async (hitter_id, value) => {
      // because the set function is async
      const index = hitterStats.findIndex((s) => s.hitter_id === hitter_id);

      const currentStats =
        index === -1 ? getEmptyStats(hitter_id) : hitterStats[index];

      const nextStats: IHitterStats = {
        ...currentStats,
        ...value,
      };

      const nextHitterStats = [...hitterStats];

      if (index === -1) {
        nextHitterStats.push(nextStats);
      } else {
        nextHitterStats[index] = nextStats;
      }

      setHitterStats(nextHitterStats);

      return nextHitterStats;
    },

    resetStats: (hitter_id) => {
      const index = hitterStats.findIndex((s) => s.hitter_id === hitter_id);
      if (index !== -1) {
        hitterStats[index] = getEmptyStats(hitter_id);
      }
    },

    delete: async (ids) => {
      try {
        setLoading(true);

        const success = await HittersService.getInstance().deleteHitters(ids);

        if (!success) {
          throw new Error('failed to delete hitters');
        }

        NotifyHelper.success({
          message_md:
            ids.length === 1
              ? 'Hitter deleted.'
              : `${ids.length} hitters deleted.`,
        });

        setTimeout(() => {
          /** remove from context */
          const newValues = hitters.filter((v) => !ids.includes(v._id));
          setHitters(newValues);
        }, 500);

        return success;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    create: async (payload, onCreate) => {
      try {
        setLoading(true);

        const result = await HittersService.getInstance().postHitter(payload);
        const newHitters = [...hitters];

        const index = newHitters.findIndex((v) => v._id === result._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newHitters.splice(index, 1, result);
        } else {
          /** append to end */
          newHitters.push(result);
        }

        setHitters(newHitters);

        NotifyHelper.success({ message_md: 'Hitter created!' });

        if (onCreate) {
          onCreate(result);
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    update: async (payload, onUpdate) => {
      try {
        setLoading(true);

        const result = await HittersService.getInstance().updateHitter(payload);

        const newHitters = [...hitters];

        const index = newHitters.findIndex((v) => v._id === result._id);
        if (index !== -1) {
          /** replace current context value with updated result */
          newHitters.splice(index, 1, result);
        } else {
          /** append to end */
          newHitters.push(result);
        }

        setHitters(newHitters);

        if (active?._id === result._id) {
          setActive(result);
        }

        NotifyHelper.success({ message_md: 'Hitter updated!' });

        if (onUpdate) {
          onUpdate(result);
        }

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    openCrudDialog: (config) => {
      setDialogCRUD(config);
    },
  };

  /** fetch the data at load */
  useEffect(() => {
    if (lastFetched === undefined) {
      return;
    }

    setLoading(true);

    HittersService.getInstance()
      .getHitters()
      .then((v) => setHitters(v))
      .finally(() => setLoading(false));
  }, [lastFetched]); // dependency list => run whenever lastFetched is changed

  useEffect(() => {
    // active selection may not be visible after filtering
    setActive(undefined);
  }, [filters]);

  return (
    <HittersContext.Provider value={state}>
      {props.children}

      {dialogCRUD?.action === CrudAction.Create && (
        <EditHitterDialogHoC
          key={dialogCRUD.key}
          hitter_id={undefined}
          onCreate={(value) => setActive(value)}
          onClose={() => {
            dialogCRUD?.onClose?.();
            setDialogCRUD(undefined);
            setLastChanged(Date.now());
          }}
        />
      )}

      {dialogCRUD?.action === CrudAction.Update &&
        dialogCRUD.models.length > 0 && (
          <EditHitterDialogHoC
            key={dialogCRUD.key}
            hitter_id={dialogCRUD.models[0]._id}
            onClose={() => {
              dialogCRUD.onClose?.();
              setDialogCRUD(undefined);
              setLastChanged(Date.now());
            }}
          />
        )}

      {dialogCRUD?.action === CrudAction.Delete && (
        <DeleteHittersDialog key={dialogCRUD.key} hitters={dialogCRUD.models} />
      )}
    </HittersContext.Provider>
  );
};
