import { ISpin } from '../interfaces/pitches/i-base';
import { IRapsodoShot } from '../interfaces/training/i-rapsodo-shot';
import { BallHelper } from './ball.helper';
import { DEG_RIGHT_ANGLE, DEG_TO_RAD } from './math.utilities';

export class RapsodoHelper {
  private static _degreesToSpin = (value: number): ISpin => {
    const valueRAD = value * DEG_TO_RAD;

    const output: ISpin = {
      wx: -Math.sin(valueRAD),
      wy: 0,
      wz: Math.cos(valueRAD),
    };

    return output;
  };

  static get3DSpin = (data: IRapsodoShot): ISpin | undefined => {
    if (!data.PITCH_SpinAxis || !data.PITCH_TotalSpin) {
      return undefined;
    }

    const spinDegrees = BallHelper.convertTimeToDegrees(data.PITCH_SpinAxis);
    if (spinDegrees === undefined) {
      return undefined;
    }

    // the spin axis is orthogonal to the direction of the hour hand
    const spinAxis = this._degreesToSpin(spinDegrees + DEG_RIGHT_ANGLE);
    const gyroRAD = data.PITCH_GyroDegree * DEG_TO_RAD;

    const output: ISpin = {
      wx: Math.cos(gyroRAD) * data.PITCH_TotalSpin * spinAxis.wx,
      wy: -Math.sin(gyroRAD) * data.PITCH_TotalSpin,
      wz: Math.cos(gyroRAD) * data.PITCH_TotalSpin * spinAxis.wz,
    };

    return output;
  };
}
