import { Box, Container, Flex, Heading, Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoError } from 'lib_ts/interfaces/i-video';

interface IProps {
  errors: IVideoError[];
}

export const ErrorsTab = (props: IProps) => {
  return (
    <Container size="1" align="left">
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {props.errors.length === 0 ? 'No Errors' : 'Errors'}
        </Heading>

        {props.errors.length === 0 && (
          <Box>
            <Text>Your video is ready to be used!</Text>
          </Box>
        )}

        {props.errors.map((e, i) => (
          <Box key={`error-${i}`}>
            <CommonCallout
              color={e.fatal ? RADIX.COLOR.DANGER : undefined}
              text_md={e.msg}
            />
          </Box>
        ))}
      </Flex>
    </Container>
  );
};
