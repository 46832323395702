import { Box, Code, Flex } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { getVisibilityBlurb } from 'components/common/pitch-lists/manage-list';
import { AuthContext } from 'contexts/auth.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { ICopyToFrom, IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useMemo, useState } from 'react';

interface IProps extends IBaseDialog {
  from: ICopyToFrom;
  lists: IPitchList[];
}

export const CopyFolderDialog = (props: IProps) => {
  const { current, reassignOptions } = useContext(AuthContext);
  const { copyLists, refreshLists } = useContext(PitchListsContext);

  const [target, setTarget] = useState<ICopyToFrom>({ ...props.from });

  const defOptions = useMemo<IOption[]>(
    () => [
      {
        label: 'common.personal',
        invisible: reassignOptions.users.length === 0,
        value: PitchListOwner.User,
      },
      {
        label: 'common.machine',
        invisible:
          !current.machine_lists || reassignOptions.machines.length === 0,
        value: PitchListOwner.Machine,
      },
      {
        label: 'common.team',
        invisible: !current.team_lists || reassignOptions.teams.length === 0,
        value: PitchListOwner.Team,
      },
    ],

    [current, reassignOptions]
  );

  const idOptions = useMemo<IOption[]>(
    () =>
      PitchListHelper.getCopyToIdOptions({
        options: reassignOptions,
        role: current.role,
        teamID: current.teamID,
        parentDef: target._parent_def,
      }),
    [reassignOptions, current, target]
  );

  const warning = useMemo(() => {
    const machineID =
      target._parent_def !== PitchListOwner.Machine
        ? undefined
        : reassignOptions.machines.find((m) => m._id === target._parent_id)
            ?.machineID;

    return getVisibilityBlurb(target._parent_def, machineID);
  }, [target, reassignOptions]);

  return (
    <ErrorBoundary componentName="CopyFolderDialog">
      <CommonDialog
        identifier={props.identifier}
        width={RADIX.DIALOG.WIDTH.MD}
        title="Copy Pitch Lists"
        content={
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Box>
              <p>
                Use the form below to copy the following{' '}
                {props.lists.length === 1 ? 'list' : 'lists'} in your folder:
              </p>

              <ul>
                {props.lists
                  .sort((a, b) => {
                    if (a.folder === b.folder) {
                      return (a.name ?? '').localeCompare(b.name ?? '');
                    }

                    return (a.folder ?? '').localeCompare(b.folder ?? '');
                  })
                  .map((list, iList) => (
                    <li key={iList}>
                      <Code>{list.folder}/</Code>
                      {list.name}
                    </li>
                  ))}
              </ul>
            </Box>

            <CommonFormGrid columns={1}>
              <CommonSelectInput
                id="copy-folder-parent-type"
                name="_parent_def"
                label="Parent Type"
                options={defOptions}
                value={target._parent_def}
                onChange={(v) =>
                  setTarget({
                    ...target,
                    _parent_def: v as PitchListOwner,
                    _parent_id: '',
                  })
                }
                hint_md={warning}
                optional
              />
              <CommonSearchInput
                id="copy-folder-parent"
                name="_parent_id"
                label="Parent"
                options={idOptions}
                values={target._parent_id ? [target._parent_id] : []}
                onChange={(v) =>
                  setTarget({
                    ...target,
                    _parent_id: v[0] ?? '',
                  })
                }
                optional
              />
              <CommonTextInput
                id="copy-folder-destination"
                label="Destination Folder"
                value={target.folder}
                onChange={(v) => setTarget({ ...target, folder: v ?? '' })}
              />
            </CommonFormGrid>
          </Flex>
        }
        buttons={[
          {
            ...DEFAULT_ACCEPT_BTN,
            onClick: async () => {
              if (!target._parent_id || !target._parent_def) {
                NotifyHelper.error({
                  message_md: t('common.check-inputs-msg'),
                });
                return;
              }

              const success = await copyLists({
                lists: props.lists,
                from: props.from,
                to: target,
              });

              if (success) {
                refreshLists(true);
                props.onClose();
              }
            },
          },
        ]}
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
