import { Text } from '@radix-ui/themes';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useMemo } from 'react';

export const getPitchName = (pitch: IPitch): string => {
  const parts: string[] = [];

  if (pitch.year) {
    parts.push(`[${pitch.year}]`);
  }

  parts.push(pitch.name || '(no name)');

  return parts.join(' ');
};

export const PitchName = (props: { pitch: IPitch }) => {
  const label = useMemo(() => getPitchName(props.pitch), [props.pitch]);
  return <Text truncate>{label}</Text>;
};
