import { Avatar, Badge } from '@radix-ui/themes';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { SectionHeader } from 'components/sections/header';
import { SelectCardToolbar } from 'components/sections/quick-session/steps/select-card/toolbar';
import { QuickSessionContext } from 'contexts/quick-session.context';
import { t } from 'i18next';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { PitcherHand, PLAYER_LEVELS } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useMemo } from 'react';

const COMPONENT_NAME = 'QuickSessionSelectCard';

export const SelectCard = () => {
  const { avatarUrlDict, lists, filtered, loaded, loading, selectCard } =
    useContext(QuickSessionContext);

  const BASE_COLUMNS: ITableColumn[] = useMemo(
    () => [
      {
        label: '',
        key: '_avatar',
        thClassNameFn: () => 'width-60px',
        align: 'center',
        formatFn: (m: IPitchList) => (
          <Avatar
            mt="2"
            mb="2"
            src={avatarUrlDict[m.card?.avatar_path ?? '']?.url}
            fallback={StringHelper.getInitials(m.name)}
          />
        ),
        disableSort: true,
      },
      {
        label: 'common.pitcher',
        key: '_name',
        formatFn: (m: IPitchList) => m.card?.name ?? '???',
        sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
          (b.card?.name ?? '').localeCompare(a.card?.name ?? '') * dir,
      },
      {
        label: 'common.hand',
        key: '_hand',
        align: 'center',
        formatFn: (m: IPitchList) => {
          const isLeft = m.card?.hand === PitcherHand.LHP;
          return (
            <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
              {t(isLeft ? 'common.lhp' : 'common.rhp')}
            </Badge>
          );
        },
        sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
          (b.card?.hand ?? '').localeCompare(a.card?.hand ?? '') * dir,
      },
      {
        label: 'common.slot',
        key: '_slot',
        formatFn: (m: IPitchList) =>
          PitchListHelper.shortPitcherRelease(m.card?.release),
        sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) =>
          (b.card?.release ?? '').localeCompare(a.card?.release ?? '') * dir,
      },
      {
        label: 'common.level',
        key: '_level',
        formatFn: (m: IPitchList) => {
          const label = PLAYER_LEVELS.find((o) => o.value === m.card?.level)
            ?.label;
          return label ? t(label) : '--';
        },
        sortRowsFn: (a: IPitchList, b: IPitchList, dir: number) => {
          const aIndex = PLAYER_LEVELS.findIndex(
            (l) => l.value === a.card?.level
          );
          const bIndex = PLAYER_LEVELS.findIndex(
            (l) => l.value === b.card?.level
          );
          return (aIndex > bIndex ? 1 : -1) * dir;
        },
      },
    ],
    [avatarUrlDict]
  );

  const sort: ITableSortable = {
    enableSort: true,
  };

  const select = useMemo(() => {
    const o: ITableSelectable = {
      enableSelect: true,
      blockSelect: loading,
      afterChangeSelected: (model: IPitchList | undefined) => {
        if (!model) {
          return;
        }

        selectCard(model._id);
      },
    };

    return o;
  }, [loading, selectCard]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={
          <>
            <SectionHeader header={t('qs.select-a-player')} />

            {loaded && filtered.length === 0 && (
              <CommonCallout text="qs.msg-support-add-cards" />
            )}

            {lists.length > 0 && filtered.length === 0 && (
              <CommonCallout text="qs.msg-no-cards-found" />
            )}
          </>
        }
        table={
          // prevents afterChangeSelected from being set before selectCard updates (when cards load)
          loaded && (
            <CommonTableHoC
              id={COMPONENT_NAME}
              displayColumns={BASE_COLUMNS}
              displayData={filtered}
              toolbarContent={<SelectCardToolbar />}
              loading={loading}
              {...select}
              {...sort}
              vFlex
            />
          )
        }
      />
    </ErrorBoundary>
  );
};
