import {
  Avatar,
  Badge,
  Box,
  Flex,
  RadioCards,
  Spinner,
  Strong,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AimingContext } from 'contexts/aiming.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { QuickSessionContext } from 'contexts/quick-session.context';
import { t } from 'i18next';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { expandPitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PLATE, IPitch } from 'lib_ts/interfaces/pitches';
import { useContext } from 'react';

export const SelectPitchBodyContent = (props: {
  lazySendPitch: (v: boolean) => void;
}) => {
  const { aggReady, isPitchTrained, readyToTrain } =
    useContext(MatchingShotsContext);
  const { checkActive } = useContext(MachineContext);
  const { pitches, resetPitches, trainPitches } =
    useContext(QuickSessionContext);
  const { pitch, setPitch, setPlate } = useContext(AimingContext);

  const isActive = checkActive(true);

  const selectPitch = async (p: IPitch) => {
    if (isPitchTrained(p)) {
      setPlate(DEFAULT_PLATE);

      await setPitch(p);

      props.lazySendPitch(true);
      return;
    }

    // can't train yet
    if (!readyToTrain()) {
      return;
    }

    trainPitches([p]);
  };

  return (
    <RadioCards.Root
      onValueChange={(v) => {
        const p = pitches.find((p) => p._id === v);

        if (!p) {
          return;
        }

        setPitch(p);
      }}
    >
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        {pitches.map((p) => {
          const selected = p._id === pitch?._id;
          const speedMPH = TrajHelper.getSpeedMPH(p.traj);
          const spin = BallHelper.getSafeNetSpin(p.bs);
          const breaks = TrajHelper.getBreaks(p.traj);
          const trained = isPitchTrained(p);

          return (
            <RadioCards.Item key={`pitch-${p._id}`} value={p._id}>
              <Flex
                style={{
                  width: '100%',
                }}
                gap={RADIX.FLEX.GAP.MD}
                title={selected ? 'Selected' : undefined}
                data-selected={selected}
                data-trained={trained}
                onClick={() => {
                  if (!isActive) {
                    return;
                  }

                  if (!aggReady) {
                    NotifyHelper.info({
                      message_md: `Please wait while training data loads...`,
                    });
                    return;
                  }

                  selectPitch(p);
                }}
              >
                <Box>
                  <Avatar
                    color={selected ? RADIX.COLOR.ACCENT : RADIX.COLOR.NEUTRAL}
                    fallback={p.type ?? '??'}
                  />
                </Box>

                <Box flexGrow="1">
                  <Strong>{t(expandPitchType(p.type))}</Strong>

                  <Flex justify="between" gap={RADIX.FLEX.GAP.LG}>
                    <Text color={RADIX.COLOR.NEUTRAL}>
                      {speedMPH?.toFixed(1)} <small>MPH</small>
                    </Text>
                    <Text color={RADIX.COLOR.NEUTRAL}>
                      {spin?.toFixed(0)} <small>RPM</small>
                    </Text>
                    <Text color={RADIX.COLOR.NEUTRAL}>
                      {-breaks.xInches.toFixed(1)} <small>IN (H)</small>
                    </Text>
                    <Text color={RADIX.COLOR.NEUTRAL}>
                      {breaks.zInches.toFixed(1)} <small>IN (V)</small>
                    </Text>
                  </Flex>
                </Box>

                <Box className="valign-center">
                  <Spinner loading={!aggReady}>
                    <Badge
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        width: '65px',
                      }}
                      title={
                        trained
                          ? 'Right-click to reset training data'
                          : undefined
                      }
                      color={
                        trained ? RADIX.COLOR.SUCCESS : RADIX.COLOR.WARNING
                      }
                      onContextMenu={(e) => {
                        if (trained) {
                          e.preventDefault();

                          resetPitches([p]);
                        }
                      }}
                    >
                      {trained ? 'Trained' : 'Untrained'}
                    </Badge>
                  </Spinner>
                </Box>
              </Flex>
            </RadioCards.Item>
          );
        })}
      </Flex>
    </RadioCards.Root>
  );
};
