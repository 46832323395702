import {
  CaretDownIcon,
  CaretUpIcon,
  Cross2Icon,
  MagnifyingGlassIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Popover, Text, TextField } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonChecklist } from 'components/common/checklist';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ISearchInput } from 'interfaces/forms/search';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useMemo, useState } from 'react';

const AUTO_CLOSE_POPOVER = true;
const AUTO_CLEAR_SEARCH = false;
const MAX_ITEMS = 50;

interface ISearchOption extends IOption {
  searchKeys: string[];
}

export const CommonSearchInput = (props: ISearchInput) => {
  const [checklistKey, setChecklistKey] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);

  const search = (query: string) => {
    setSearchTerm(query);

    if (props.onSearch) {
      props.onSearch(query);
    }
  };

  const groupedOptions = useMemo(() => {
    const { options, skipSort, reverseSort } = props;

    const output: ISearchOption[] = [];

    const grouped = ArrayHelper.groupBy(
      options.filter((o) => !o.invisible),
      'group'
    );

    const keys = Object.keys(grouped);

    if (!skipSort) {
      keys.sort();

      if (reverseSort) {
        keys.reverse();
      }
    }

    keys.forEach((key) => {
      const group = grouped[key];

      if (group.length === 0) {
        return;
      }

      if (key) {
        output.push({
          label: key,
          value: key,
          group: key,
          isGroup: true,
          disabled: true,
          hideControl: true,
          // for strict matching to find the full, unchanged group
          searchKeys: [key, ...StringHelper.keyify(key)],
        });
      }

      if (!skipSort) {
        group.sort((a, b) => a.label.localeCompare(b.label));

        if (reverseSort) {
          group.reverse();
        }
      }

      output.push(
        ...group.map((m) => {
          const o: ISearchOption = {
            ...m,
            searchKeys: [
              // for strict matching to find the full, unchanged value
              m.value,
              ...StringHelper.keyify(
                [m.value, m.label, m.group ?? '', ...(m.aliases ?? [])].join(
                  ' '
                )
              ),
            ],
          };

          return o;
        })
      );
    });

    return output;
  }, [props.options, props.skipSort, props.reverseSort]);

  const slotIcon = useMemo(() => {
    if (props.disabled) {
      // don't show any icon since the user can't use it anyway
      return undefined;
    }

    if (props.optional && props.values && props.values.length > 0) {
      return (
        <Cross2Icon
          onClick={() => {
            if (props.disabled) {
              return;
            }

            props.onChange([]);
          }}
        />
      );
    }

    if (open) {
      return <CaretUpIcon />;
    }

    return (
      <CaretDownIcon
        onClick={() => {
          if (props.disabled) {
            return;
          }

          setOpen(true);
        }}
      />
    );
  }, [props.disabled, props.optional, props.values, props.onChange, open]);

  const valueDisplay = useMemo(() => {
    if (!props.values) {
      return '';
    }

    if (props.values.length === 0) {
      return '';
    }

    if (props.values.length === 1) {
      const firstValue = props.values[0];

      const selected = props.options.find((o) => o.value === firstValue);

      const parts = [selected?.label ?? '', selected?.suffixLabel ?? ''].filter(
        (s) => s
      );

      if (parts.length > 0) {
        return parts.map((s) => t(s).toString()).join(HINT_DELIMITER);
      }

      return firstValue;
    }

    // todo: add translation key for this
    return `${props.values.length} options selected`;
  }, [props.values, props.options]);

  const placeholder = t(
    props.placeholder ??
      (props.hideSearch
        ? 'common.select-placeholder'
        : 'common.search-placeholder')
  ).toString();

  const matchingOptions = useMemo(() => {
    const safeSearchTerms = props.strict
      ? [searchTerm]
      : StringHelper.keyify(searchTerm);

    if (safeSearchTerms.length === 0) {
      return groupedOptions;
    }

    return groupedOptions.filter((o) =>
      ArrayHelper.hasSubstringIntersection(o.searchKeys, safeSearchTerms)
    );
  }, [props.strict, searchTerm, groupedOptions]);

  return (
    <CommonInputWrapper {...props}>
      <CommonInputLabel {...props} />

      <Popover.Root
        open={open}
        onOpenChange={(value) => {
          setOpen(value);

          if (AUTO_CLEAR_SEARCH && !value) {
            // clear search on popover close
            search('');
          }
        }}
      >
        <Popover.Trigger disabled={props.disabled}>
          <TextField.Root
            size={props.size}
            className={`cursor-pointer ${props.className ?? ''}`}
            disabled={props.disabled}
            color={props.inputColor}
            name={props.name}
            placeholder={placeholder}
            required={!props.optional}
            value={valueDisplay}
            onChange={() => {
              // onChange provided to suppress error re: value w/o onChange
            }}
            // prevent the cursor from entering this input (on click or clear) so users don't think they can type into it
            onFocus={(event) => event.currentTarget.blur()}
            type="text"
          >
            <TextField.Slot side="right">{slotIcon}</TextField.Slot>
          </TextField.Root>
        </Popover.Trigger>

        <Popover.Content className="BorderedContent">
          {/* for performance, don't draw the stuff unless the search is open */}
          {open && (
            <Flex direction="column" gap={RADIX.FLEX.GAP.INPUT}>
              {!props.hideSearch && (
                <>
                  <Box>
                    <TextField.Root
                      className={props.className}
                      disabled={props.disabled}
                      name={props.name}
                      type="text"
                      value={searchTerm}
                      placeholder={
                        props.options.length > MAX_ITEMS
                          ? 'Type to find more options'
                          : undefined
                      }
                      onChange={(e) => {
                        const v = e.target.value;
                        search(v);
                      }}
                    >
                      <TextField.Slot side="right">
                        {searchTerm ? (
                          <Cross2Icon
                            className="cursor-pointer"
                            onClick={() => search('')}
                          />
                        ) : (
                          <MagnifyingGlassIcon />
                        )}
                      </TextField.Slot>
                    </TextField.Root>
                  </Box>

                  {matchingOptions.length === 0 && (
                    <Box>
                      <Text color={RADIX.COLOR.SECONDARY}>
                        No results found.
                      </Text>
                    </Box>
                  )}
                </>
              )}

              <Box
                maxHeight="250px"
                maxWidth="40ch"
                overflowX="hidden"
                overflowY="scroll"
              >
                <CommonChecklist
                  key={checklistKey}
                  id={`${props.id}-search-checklist`}
                  btnSize={props.btnSize}
                  options={matchingOptions}
                  onChange={(v) => {
                    const shouldProceed = props.shouldChange
                      ? props.shouldChange(v)
                      : true;

                    if (!shouldProceed) {
                      // checklist will re-render as if nothing happened
                      setChecklistKey(Date.now());
                      return;
                    }

                    props.onChange(v);

                    if (AUTO_CLOSE_POPOVER && !props.multiple) {
                      setOpen(false);
                    }
                  }}
                  values={props.values}
                  as={props.multiple ? 'checkbox' : 'button'}
                  truncateTo={MAX_ITEMS}
                />
              </Box>
            </Flex>
          )}
        </Popover.Content>
      </Popover.Root>

      <CommonInputHint {...props} />
    </CommonInputWrapper>
  );
};
