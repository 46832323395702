import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { MachineContext } from 'contexts/machine.context';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import {
  ICalibrateResponseMsg,
  IOverlaySummaryResponseMsg,
  IProcessQueryResponseMsg,
  ISoftRebootResponseMsg,
  ISystemRebootResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { IMachineStatusMsg } from 'lib_ts/interfaces/machine-msg/i-machine-status';
import { useContext, useEffect } from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineResponseListener';

export const MachineResponseListener = () => {
  const machineCx = useContext(MachineContext);

  // ask the server for machine status update every 10s
  useEffect(() => {
    const interval = setInterval(() => {
      if (!WebSocketService.getInstance()) {
        return;
      }

      WebSocketService.sendWithoutRetry(
        WsMsgType.Misc_MachineStatus,
        undefined,
        COMPONENT_NAME
      );
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const listeners: {
      type: WsMsgType;
      callback: (event: CustomEvent) => void;
    }[] = [
      {
        type: WsMsgType.Misc_MachineStatus,
        callback: (event: CustomEvent) => {
          const data: IMachineStatusMsg = event.detail;
          // console.debug('received machine status message', data);
          machineCx.setLastStatus(data);
        },
      },
      {
        type: WsMsgType.M2U_CalibrationResponse,
        callback: (event: CustomEvent) => {
          const data: ICalibrateResponseMsg = event.detail;

          machineCx.setCalibrated(!!data.status);
          machineCx.setCanCalibrate(!!data.can_calibrate);

          if (data.status) {
            machineCx.requestOverlayIDs('machine listener');
          }
        },
      },
      {
        type: WsMsgType.M2U_FireResponse,
        callback: () => {
          machineCx.setFiring(false);
        },
      },
      {
        type: WsMsgType.M2U_OverlaysResponse,
        callback: (event: CustomEvent) => {
          const data: IOverlaySummaryResponseMsg = event.detail;
          machineCx.setOverlayIDs(data.overlay_ids);
        },
      },
      {
        type: WsMsgType.M2U_QueryProcesses,
        callback: (event: CustomEvent) => {
          const data: IProcessQueryResponseMsg = event.detail;
          machineCx.setProcessData(data);
        },
      },
      {
        type: WsMsgType.M2U_SoftReboot,
        callback: (event: CustomEvent) => {
          const data: ISoftRebootResponseMsg = event.detail;
          if (!data.success) {
            NotifyHelper.error({ message_md: 'Failed to soft reboot.' });
            return;
          }

          NotifyHelper.success({
            message_md: 'Soft reboot started. Please wait',
          });
        },
      },
      {
        type: WsMsgType.M2U_SystemRestart,
        callback: (event: CustomEvent) => {
          const data: ISystemRebootResponseMsg = event.detail;

          if (!data.success) {
            NotifyHelper.error({
              message_md: `Restart failed on processes ${data.failed_list}.`,
            });
            return;
          }

          NotifyHelper.success({ message_md: 'Restart successful.' });
        },
      },
    ];

    listeners.forEach((m) => {
      WebSocketHelper.on(m.type, m.callback);
    });

    return () => {
      listeners.forEach((m) => {
        WebSocketHelper.remove(m.type, m.callback);
      });
    };
  }, []);

  return <></>;
};
