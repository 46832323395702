import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTabs } from 'components/common/tabs';
import { InstallationContext } from 'components/machine/dialogs/installation/context';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { BallTypeResults } from 'components/machine/dialogs/installation/steps/ball-type/results';
import { DataCollectorHoC } from 'components/machine/dialogs/installation/steps/data-collector';
import { MachineContext } from 'contexts/machine.context';
import { SubStatus } from 'enums/installation';
import { appearanceImgPath } from 'index';
import { ReferenceListType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useState } from 'react';
import { PitchesService } from 'services/pitches.service';

const COMPONENT_NAME = 'StepBallTypeCalibration';

enum TabKey {
  DataCollection = 'DataCollection',
  Results = 'Results',
}

interface IProps {
  state: SubStatus;
  onStart: () => void;
  onComplete: () => void;
}

export const StepBallTypeCalibration = (props: IProps) => {
  const { details, updateDetails } = useContext(InstallationContext);
  const { machine } = useContext(MachineContext);
  const [pitches, setPitches] = useState<IPitch[]>();

  const [activeTab, setActiveTab] = useState(TabKey.DataCollection);

  // after the first time, it will not force you to go back
  const [goToResults, setGoToResults] = useState(true);

  useEffect(() => {
    PitchesService.getInstance()
      .getReferenceListPitches(ReferenceListType.BallTypeCalibration)
      .then((result) => {
        setPitches(result);

        if (result.length === 0) {
          return;
        }

        if (!machine.last_installation) {
          return;
        }

        if (details.calibration_list_id) {
          return;
        }

        // fix the list id to match
        updateDetails({
          calibration_list_id: result[0]._parent_id,
        });
      });
  }, []);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex
        direction="column"
        gap="4"
        style={{
          height: '100%',
          // border: '1px solid red'
        }}
      >
        <CommonStepHeader label="Ball Type Calibration" />

        {props.state === SubStatus.NotStarted && (
          <Flex
            flexGrow="1"
            direction="column"
            gap="5"
            align="center"
            justify="center"
            style={
              {
                // border: '1px solid blue'
              }
            }
          >
            <Box>
              <img
                style={{
                  width: '176px',
                }}
                src={appearanceImgPath('/renders/ball-type-calibration.svg')}
              />
            </Box>
            <Text align="center" color={RADIX.COLOR.SECONDARY}>
              You're about to fire your Machine to test its repeatability with
              the selected ball type. This step ensures the Machine can
              consistently deliver pitches to the targeted location. Please
              ensure that only {machine.ball_type} balls are loaded into the
              Machine.
            </Text>
            <Box>
              <Button onClick={props.onStart}>
                Start Ball Type Calibration
              </Button>
            </Box>
          </Flex>
        )}

        {props.state !== SubStatus.NotStarted && (
          <CommonTabs
            tabs={[
              {
                label: 'Data Collection',
                value: TabKey.DataCollection,
                content: (
                  <>
                    {pitches &&
                      (pitches.length === 0 ? (
                        <CommonCallout text="Failed to find any pitches for ball type calibration, please contact support." />
                      ) : (
                        <DataCollectorHoC
                          // for drawing error instead of complete
                          state={props.state}
                          collectionID={details.calibration_id}
                          pitches={pitches}
                          refType={ReferenceListType.BallTypeCalibration}
                          onComplete={() => {
                            // automatically move to results tab 1x
                            if (goToResults) {
                              setGoToResults(false);
                              setActiveTab(TabKey.Results);
                            }

                            // notify parent component
                            props.onComplete();
                          }}
                        />
                      ))}
                  </>
                ),
              },
              {
                label: 'Results',
                value: TabKey.Results,
                content: <BallTypeResults machineID={machine.machineID} />,
              },
            ]}
            value={activeTab}
            onValueChange={(value) => setActiveTab(value as TabKey)}
          />
        )}
      </Flex>
    </ErrorBoundary>
  );
};
