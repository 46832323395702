export type CheckMode = 'all' | 'some' | 'none';

interface TableConstants {
  PAGE_SIZES: {
    SM: number[];
    MD: number[];
    LG: number[];
    XL: number[];
  };
}

export const TABLES: TableConstants = {
  PAGE_SIZES: {
    SM: [10, 20, 30],
    MD: [30, 50, 100],
    LG: [50, 100, 200],
    XL: [100, 200, 300],
  },
};

export const ACTIONS_KEY = '_actions';
export const CHECKBOX_KEY = '_checkbox';

export enum CrudAction {
  Create,
  Read,
  Update,
  Delete,
}
