import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { lightFormat, parseISO } from 'date-fns';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { ISidebarFolder, ISidebarPitchList } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import {
  PitchListCategory,
  PitchListOwner,
} from 'lib_ts/enums/pitch-list.enums';
import {
  PITCH_TYPE_OPTIONS,
  PitchListExtType,
  PitchType,
  ReferenceListType,
  TrainingStatus,
} from 'lib_ts/enums/pitches.enums';
import { ICopyPitchLists } from 'lib_ts/interfaces/pitches/i-copy-pitch-list';
import {
  DEFAULT_MX_SUMMARY,
  IPitchList,
  IPitchListPutManyRequest,
  IPitchListSummaryDict,
  IRenameFolderRequest,
  safeFolder,
} from 'lib_ts/interfaces/pitches/i-pitch-list';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { MatchingShotsContext } from './matching-shots.context';

const CONTEXT_NAME = 'PitchListsContext';

const RECENT_LENGTH = 5;

const SORTED_PARENT_DEFS = [
  PitchListOwner.User,
  PitchListOwner.Machine,
  PitchListOwner.Team,
];

interface IToolbarFilters {
  key: number;
  name: string;
  training_status: TrainingStatus[];
  visibility: PitchListOwner | undefined;
  type: PitchListExtType | undefined;
  pitch_type: PitchType[];
  created: string[];
}

interface ISidebarFilters {
  // matching substrings from folder name or list name
  search: string;
  // can be root (i.e. owner) or type (e.g. reference, card, etc...)
  category: PitchListCategory | undefined;
}

const sanitizeFolders = (lists: IPitchList[]) => {
  /** ensure that any repeated instances of FOLDER_SEPARATOR are collapsed into no more than 1 */
  return lists.map((list) => {
    const out = Object.assign({}, list);
    out.folder = safeFolder(list.folder);
    return out;
  });
};

/** todo: this could probably be refactored into:
 *  - list context (one list and its contents)
 *  - lists context (all lists visible to the user)
 *  - search context (all pitches from all lists visible to the user)
 */
export interface IPitchListsContext {
  lastFetched: number;

  // changing this will cause expanded folders to be collapsed
  collapseKey: number;
  readonly collapseFolders: () => void;

  /** insert list id at index 0 whenever user opens it */
  recentIDs: string[];

  lists: IPitchList[];
  filtered: IPitchList[];

  loadingSummaryDict: boolean;
  summaryDict: IPitchListSummaryDict;
  readonly reloadSummaryDict: () => void;

  toolbarFilters: IToolbarFilters;
  readonly setToolbarFilters: (value: Partial<IToolbarFilters>) => void;

  sidebarFilters: ISidebarFilters;
  readonly setSidebarFilters: (value: Partial<ISidebarFilters>) => void;

  sidebarFilterKeys: string[];
  sidebarRootFolders: ISidebarFolder[];

  active: IPitchList | undefined;

  loading: boolean;

  readonly updateList: (config: {
    payload: Partial<IPitchList>;
    successMsg?: string;
    silently?: boolean;
  }) => Promise<IPitchList | undefined>;

  readonly updateListViaCSV: (files: File[]) => Promise<boolean>;

  readonly uploadAvatar: (
    files: File[],
    onProgress?: (ev: ProgressEvent) => void
  ) => Promise<IPitchList | undefined>;

  readonly createList: (
    payload: Partial<IPitchList>
  ) => Promise<IPitchList | undefined>;

  readonly copyList: (
    payload: Partial<IPitchList>
  ) => Promise<IPitchList | undefined>;

  readonly copyLists: (
    payload: ICopyPitchLists,
    successMsg?: string
  ) => Promise<boolean>;

  readonly updateLists: (
    payload: IPitchListPutManyRequest,
    successMsg?: string
  ) => Promise<boolean>;

  readonly deleteLists: (ids: string[]) => Promise<boolean>;

  readonly renameFolder: (
    payload: IRenameFolderRequest,
    successMsg?: string
  ) => Promise<boolean>;

  /** refreshes lists from server (e.g. if someone else changes a list's visibility) */
  readonly refreshLists: (notify?: boolean) => void;

  /** replaces instances of a hash in all list summaries */
  readonly replaceSummaryDictHashes: (updatedHashesDict: {
    [key: string]: string;
  }) => void;

  /** adds summary.matching_hashes for a specific pitch list */
  readonly addListSummaryDictHashes: (hashes: string[], listId: string) => void;

  /** removes summary.matching_hashes for a specific pitch list */
  readonly removeListSummaryDictHashes: (
    hashes: string[],
    listId: string
  ) => void;
}

const DEFAULT: IPitchListsContext = {
  loading: false,

  lastFetched: Date.now(),

  collapseKey: Date.now(),
  collapseFolders: () => console.error(`${CONTEXT_NAME}: not init`),

  recentIDs: [],

  lists: [],

  filtered: [],

  loadingSummaryDict: false,
  summaryDict: {},
  reloadSummaryDict: () => console.error(`${CONTEXT_NAME}: not init`),

  toolbarFilters: {
    name: '',
    training_status: [],
    created: [],
    visibility: undefined,
    type: undefined,
    pitch_type: [],
    key: Date.now(),
  },
  setToolbarFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  sidebarFilters: {
    search: '',
    category: undefined,
  },
  setSidebarFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  sidebarFilterKeys: [],
  sidebarRootFolders: [],

  active: undefined,

  createList: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  copyList: () => new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  copyLists: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updateList: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updateListViaCSV: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  uploadAvatar: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  updateLists: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  deleteLists: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  renameFolder: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  refreshLists: () => console.error(`${CONTEXT_NAME}: not init`),

  replaceSummaryDictHashes: () => console.error(`${CONTEXT_NAME}: not init`),

  addListSummaryDictHashes: () => console.error(`${CONTEXT_NAME}: not init`),

  removeListSummaryDictHashes: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const PitchListsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const PitchListsProvider: FC<IProps> = (props) => {
  const { current } = useContext(AuthContext);
  const { active: activeSection, tryGoHome } = useContext(SectionsContext);
  const { machine } = useContext(MachineContext);
  const { lastUpdated, isHashTrained } = useContext(MatchingShotsContext);

  const [lastFetched, setLastFetched] = useState(DEFAULT.lastFetched);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const [activeList, setActiveList] = useState(DEFAULT.active);

  const [recentListIDs, setRecentListIDs] = useState(DEFAULT.recentIDs);

  const [rawLists, setRawLists] = useState(DEFAULT.lists);

  const [summaryDict, setSummaryDict] = useState(DEFAULT.summaryDict);

  const lists = useMemo(() => {
    // memoize the summary values
    for (const l of rawLists) {
      const summary = summaryDict[l._id];

      if (!summary) {
        l._mx_summary = {
          ...DEFAULT_MX_SUMMARY,
        };
        continue;
      }

      // Sort the pitch type badges the same way they are sorted in our Pitch Type filters
      summary.types = summary.types.sort((a, b) => {
        const indexA = PITCH_TYPE_OPTIONS.findIndex((v) => v.value === a);
        const indexB = PITCH_TYPE_OPTIONS.findIndex((v) => v.value === b);
        return indexA - indexB;
      });

      // count hashes that are trained according to matching context
      summary.trained = summary.matching_hashes.filter((h) =>
        isHashTrained(h)
      ).length;

      summary.trained_percent = summary.trained / Math.max(summary.total, 1);

      // set the training status for use later
      summary.training_status =
        summary.trained === 0
          ? TrainingStatus.Not
          : summary.trained === summary.total
          ? TrainingStatus.Full
          : TrainingStatus.Partial;

      // tie the summary to the list
      l._mx_summary = summary;
    }

    // fix folder paths
    return sanitizeFolders(rawLists);
  }, [rawLists, summaryDict, lastUpdated, isHashTrained]);

  const [toolbarFilters, setToolbarFilters] = useState(DEFAULT.toolbarFilters);
  const [sidebarFilters, setSidebarFilters] = useState(DEFAULT.sidebarFilters);

  /** assumes that pitch lists (that the user can access) have been loaded */
  const _changeActive = async (config: { trigger: string; listID: string }) => {
    try {
      if (loading) {
        console.debug(
          'skipping _changeActive because context is already loading something'
        );
        return;
      }

      if (lists.length === 0) {
        console.debug('skipping _changeActive because no lists are loaded');
        return;
      }

      const nextActive = lists.find((l) => l._id === config.listID);
      if (!nextActive) {
        /** warning should show if lists is loaded but doesn't contain the target
         * e.g. trying to use a URL for a list that the user shouldn't access
         * e.g. user reassigned their active list and it's no longer accessible
         */
        NotifyHelper.warning({
          message_md: `You do not have access to list \`${config.listID}\`.`,
        });
        tryGoHome();
        return;
      }

      // deserialize to ensure useEffect triggers
      setActiveList({ ...nextActive });
    } catch (e) {
      console.error(e);

      NotifyHelper.error({
        message_md:
          'There was an error while preparing your pitch list. Please try again.',
      });
    }
  };

  const [collapseKey, setCollapseKey] = useState(Date.now());

  const _sidebarFilterKeys = useMemo(
    () => StringHelper.keyify(sidebarFilters.search),
    [sidebarFilters.search]
  );

  const _sidebarRootFolders = useMemo(() => {
    const allFiles = lists
      .map((l) => {
        const safePathComps = `${l.folder}`
          .split('/')
          .map((s) => s.trim())
          .filter((s) => s.length > 0);

        const file: ISidebarPitchList = {
          type: l.type,

          _parent_def: l._parent_def,
          _parent_field: l._parent_field,
          _parent_id: l._parent_id,

          pathComponents: safePathComps,
          pathDisplay: safePathComps.join('/'),
          pathEnd: safePathComps.slice(-1)[0] ?? '',

          name: l.name,
          object: l,
        };

        return file;
      })
      .sort((a, b) => {
        const aIndex = SORTED_PARENT_DEFS.findIndex((d) => d === a._parent_def);
        const bIndex = SORTED_PARENT_DEFS.findIndex((d) => d === b._parent_def);
        return aIndex < bIndex ? -1 : 1;
      });

    const filteredFiles = allFiles.filter(
      (f) =>
        _sidebarFilterKeys.length === 0 ||
        ArrayHelper.hasSubstringIntersection(
          SidebarHelper.getFileKeys(f),
          _sidebarFilterKeys
        )
    );

    const foldersDict: { [key: string]: ISidebarPitchList[] } = {
      ...ArrayHelper.groupBy(
        filteredFiles.filter((f) => !f.type),
        '_parent_id'
      ),
      ...ArrayHelper.groupBy(
        filteredFiles.filter((f) => f.type),
        'type'
      ),
    };

    // 0 => there is no folder value
    const depth = 0;

    const allFolders = Object.values(foldersDict).map((values) =>
      SidebarHelper.makeFolder(
        depth,
        [],
        values.filter((v) => v.pathComponents.length === depth),
        values.filter((v) => v.pathComponents.length > depth)
      )
    );

    const filteredFolders = allFolders.filter(
      (f) => f.files.length > 0 || f.folders.length > 0
    );

    return filteredFolders;
  }, [lists, sidebarFilters.category, _sidebarFilterKeys]);

  const filtered = useMemo(() => {
    return lists
      .filter((m) =>
        // filter out lists that belong to another team but are accessible to the user
        // e.g. reference / sample / card lists belonging to Trajekt, from pov of customers
        [current.teamID, machine._id, current.userID].includes(m._parent_id)
      )
      .filter((m) =>
        m.name.toLowerCase().includes(toolbarFilters.name.toLowerCase())
      )
      .filter(
        (m) =>
          toolbarFilters.training_status.length === 0 ||
          toolbarFilters.training_status.includes(
            m._mx_summary?.training_status ?? TrainingStatus.Unknown
          )
      )
      .filter(
        (m) =>
          !toolbarFilters.visibility ||
          toolbarFilters.visibility === m._parent_def
      )
      .filter((m) => !toolbarFilters.type || toolbarFilters.type === m.type)
      .filter(
        (m) =>
          toolbarFilters.pitch_type.length === 0 ||
          toolbarFilters.pitch_type.every(
            (type) => m._mx_summary?.types.includes(type)
          )
      )
      .filter(
        (m) =>
          toolbarFilters.created.length === 0 ||
          toolbarFilters.created.includes(
            lightFormat(parseISO(m._created), 'yyyy-MM-dd')
          )
      );
  }, [current.userID, machine._id, current.teamID, lists, toolbarFilters]);

  const [loadingSummaryDict, setLoadingSummaryDict] = useState(false);

  const state: IPitchListsContext = {
    lastFetched: lastFetched,

    collapseKey: collapseKey,
    collapseFolders: () => setCollapseKey(Date.now()),

    loading: loading,

    active: activeList,

    recentIDs: recentListIDs,

    lists: lists,
    filtered: filtered,

    loadingSummaryDict: loadingSummaryDict,
    summaryDict: summaryDict,
    reloadSummaryDict: () => {
      setLoadingSummaryDict(true);

      PitchListsService.getInstance()
        .getSummaryDict()
        .then((dict) => setSummaryDict(dict))
        .finally(() => setLoadingSummaryDict(false));
    },

    toolbarFilters: toolbarFilters,
    setToolbarFilters: (value) =>
      setToolbarFilters((prev) => ({
        ...prev,
        ...value,
      })),

    sidebarFilters: sidebarFilters,
    setSidebarFilters: (value) =>
      setSidebarFilters((prev) => ({
        ...prev,
        ...value,
      })),

    sidebarFilterKeys: _sidebarFilterKeys,
    sidebarRootFolders: _sidebarRootFolders,

    deleteLists: async (ids) => {
      try {
        const canDelete =
          ids.length > 0 &&
          ids.every((id) => {
            const found = lists.find((m) => m._id === id);

            if (!found) {
              NotifyHelper.error({
                message_md: `Failed to find list with ID \`${id}\` for deletion.`,
              });

              return false;
            }

            switch (found.reference_type) {
              case ReferenceListType.Repeatability: {
                NotifyHelper.error({
                  message_md: `Please assign another list to be used for repeatability before deleting "${found.name}".`,
                });

                return false;
              }

              case ReferenceListType.BallTypeCalibration: {
                NotifyHelper.error({
                  message_md: `Please assign another list to be used for ball type calibration before deleting "${found.name}".`,
                });

                return false;
              }

              default: {
                return true;
              }
            }
          });

        if (!canDelete) {
          return false;
        }

        setLoading(true);

        const result = await PitchListsService.getInstance()
          .deleteLists(ids)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: `Pitch ${ids.length === 1 ? 'list' : 'lists'} deleted!`,
        });

        setTimeout(() => {
          /** remove deleted lists from session */
          const currentLists = lists.filter((l) => !ids.includes(l._id));
          setRawLists(currentLists);

          // don't linger on a deleted list
          if (activeList && ids.includes(activeList._id)) {
            tryGoHome();
          }
        }, 500);

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      }
    },

    updateList: async (config: {
      payload: Partial<IPitchList>;
      silently?: boolean;
    }) => {
      try {
        if (!config.silently) {
          setLoading(true);
        }

        const result = await PitchListsService.getInstance()
          .putList(config.payload)
          .finally(() => {
            if (!config.silently) {
              setLoading(false);
            }
          });

        if (!result.success) {
          if (!config.silently) {
            NotifyHelper.warning({
              message_md:
                result.error ??
                `There was an error updating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
            });
          }
          return;
        }

        const updated = result.data as IPitchList;

        if (!updated) {
          if (!config.silently) {
            NotifyHelper.error({
              message_md: 'Server responded with an empty result.',
            });
          }
          return;
        }

        const index = lists.findIndex((l) => l._id === updated._id);
        if (index !== -1) {
          /** replace pitch list in lists */
          const currentLists = lists.filter((l) => l._id !== updated._id);
          currentLists.push(updated);
          setRawLists(currentLists);
        }

        /** keep active synced with whatever is in lists, e.g. if its folder is renamed */
        if (activeList?._id === updated._id) {
          setActiveList({
            ...activeList,
            ...updated,
          });
        }

        if (!config.silently) {
          NotifyHelper.success({
            message_md: 'Pitch list updated!',
          });
        }

        return updated;
      } catch (e) {
        console.error(e);

        if (!config.silently) {
          NotifyHelper.error({
            message_md: `There was an unexpected error updating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
          });
        }
      }
    },

    updateListViaCSV: async (files) => {
      try {
        if (!activeList) {
          NotifyHelper.error({
            message_md: 'Cannot import file without an active pitch list.',
          });
          return false;
        }

        setLoading(true);

        await PitchListsService.getInstance()
          .importCSV(activeList._id, files)
          .finally(() => setLoading(false));

        setLastFetched(Date.now());

        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    uploadAvatar: async (files, onProgress) => {
      try {
        if (!activeList) {
          return;
        }

        /** append the files */
        const formData = new FormData();
        files.forEach((f) => {
          formData.append('files', f);
        });

        setLoading(true);

        const result = await PitchListsService.getInstance()
          .postCardAvatar(activeList._id, formData, onProgress)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        setActiveList(result.data);

        return result.data as IPitchList;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : 'There was an error uploading your image.',
        });

        return undefined;
      }
    },

    updateLists: async (payload, successMsg) => {
      try {
        setLoading(true);

        const result = await PitchListsService.getInstance()
          .putLists(payload)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Pitch lists updated!',
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error updating your pitch lists. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    renameFolder: async (payload, successMsg) => {
      try {
        setLoading(true);

        const result = await PitchListsService.getInstance()
          .renameFolder(payload)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Folder renamed!',
        });

        /** triggers reload of rawLists => lists */
        setLastFetched(Date.now());

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error renaming your folder. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    createList: async (payload) => {
      try {
        setLoading(true);

        const result = await PitchListsService.getInstance()
          .postList(payload)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        const newList = result.data as IPitchList;
        const currentLists = [...lists];
        currentLists.push(newList);

        setRawLists(currentLists);

        NotifyHelper.success({
          message_md: `Pitch list "${newList.name}" created!`,
        });

        return newList;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error creating your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return undefined;
      }
    },

    copyList: async (payload) => {
      try {
        setLoading(true);

        const result = await PitchListsService.getInstance()
          .copyList(payload)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        const list = result.data as IPitchList;

        const newLists = [...lists, list];

        setRawLists(newLists);

        NotifyHelper.success({
          message_md: `Pitch list "${list.name}" copied!`,
        });

        return list;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error copying your pitch list. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return undefined;
      }
    },

    copyLists: async (payload, successMsg) => {
      try {
        setLoading(true);

        const result = await PitchListsService.getInstance()
          .copyLists(payload)
          .finally(() => setLoading(false));

        if (!result.success) {
          throw new Error(result.error);
        }

        NotifyHelper.success({
          message_md: successMsg ?? 'Pitch lists copied!',
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error
              ? e.message
              : `There was an error copying your pitch lists. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });

        return false;
      }
    },

    refreshLists: (notify) => {
      if (notify) {
        NotifyHelper.success({ message_md: 'Refreshing pitch lists...' });
      }
      setLastFetched(Date.now());
    },

    replaceSummaryDictHashes: (updatedHashesDict) => {
      if (Object.keys(updatedHashesDict).length === 0) {
        return;
      }

      setSummaryDict((prev) => {
        const updatedDict = { ...prev };

        // [prevHash]: newHash
        Object.keys(updatedHashesDict).forEach((prevHash) => {
          const newHash = updatedHashesDict[prevHash];

          // find all lists that contain the hash
          Object.values(updatedDict).forEach((list) => {
            if (!list) {
              return;
            }

            const hashIndex = list.matching_hashes.findIndex(
              (h) => h === prevHash
            );

            if (hashIndex !== -1) {
              // replace the hash with the new one
              list.matching_hashes[hashIndex] = newHash;
            }
          });
        });

        return updatedDict;
      });
    },

    addListSummaryDictHashes: (hashes, listId) => {
      setSummaryDict((prev) => {
        const updatedDict = { ...prev };
        const list = updatedDict[listId];

        if (!list || !hashes.length) {
          return prev;
        }

        list.matching_hashes.push(...hashes);
        list.total += hashes.length;

        return updatedDict;
      });
    },

    removeListSummaryDictHashes: (hashes, listId) => {
      setSummaryDict((prev) => {
        const updatedDict = { ...prev };
        const list = updatedDict[listId];

        if (!list || !hashes.length) {
          return prev;
        }

        hashes.forEach((hash) => {
          // a list could have multiple instances of a hash, so only remove one
          const firstInstance = list.matching_hashes.findIndex(
            (h) => h === hash
          );

          if (firstInstance > -1) {
            list.matching_hashes.splice(firstInstance, 1);
            list.total -= 1;
          }
        });

        return updatedDict;
      });
    },
  };

  /** reload the data whenever machineID changes to get relevant machine-only lists */
  useEffect(() => {
    setLoading(true);

    PitchListsService.getInstance()
      .getVisible(current.role, current.mode)
      .then((result) => {
        if (!result) {
          setRawLists([]);
          return;
        }

        const filtered = result.filter((l) => {
          if (l.type) {
            return true;
          }

          switch (l._parent_def) {
            case PitchListOwner.Team: {
              return current.team_lists;
            }

            case PitchListOwner.Machine: {
              return current.machine_lists;
            }

            /** always allow personal lists */
            case PitchListOwner.User: {
              return true;
            }

            /** suppress any malformed entries */
            default: {
              return false;
            }
          }
        });

        setRawLists(filtered);
      })
      .finally(() => setLoading(false));
  }, [
    lastFetched,
    /** anything that might result in different pitch mss should trigger a reload */
    machine.machineID,
    machine.ball_type,
    current.session,
    current.role,
    current.mode,
    current.team_lists,
    current.machine_lists,
  ]);

  // monitor route fragment to change active list
  useEffect(() => {
    if (lists.length === 0) {
      return;
    }

    if (!current.auth) {
      return;
    }

    if (activeSection.section !== SectionName.Pitches) {
      return;
    }

    if (activeSection.subsection !== SubSectionName.List) {
      return;
    }

    if (!activeSection.fragments) {
      return;
    }

    if (activeSection.fragments.length === 0) {
      return;
    }

    _changeActive({
      trigger: 'lists context, detected route fragment',
      listID: activeSection.fragments?.[0],
    });
  }, [
    lists,
    current.auth,
    activeSection.section,
    activeSection.subsection,
    activeSection.fragments,
  ]);

  /** keep track of the most recently activated pitch lists */
  useEffect(() => {
    if (!activeList) {
      return;
    }

    // remove it from the recent list if it already existed (e.g. further down)
    const recent = recentListIDs.filter((id) => id !== activeList._id);

    // place it at the top of the recent list
    recent.splice(0, 0, activeList._id);

    // truncate the recent list
    setRecentListIDs(recent.filter((_, i) => i < RECENT_LENGTH));
  }, [activeList]);

  useEffect(() => {
    if (!current.auth) {
      return;
    }

    setLastFetched(Date.now());
  }, [
    /** trigger once logged in/successfully resumed */
    current.auth,
    /** detect special session mode, reload data to match user's access */
    current.session,
  ]);

  /** refresh the list (which will populate msDict if necessary) whenever machine changes */
  useEffect(() => {
    if (!activeList) {
      return;
    }

    _changeActive({
      trigger: 'machine context changed',
      listID: activeList._id,
    });
  }, [machine.machineID, machine.ball_type]);

  /** automatically clean up recent IDs and the folders from rawLists before setting lists for use */
  useEffect(() => {
    /** e.g. a list is deleted, should not show up in recent lists anymore */
    const rawIDs = rawLists.map((l) => l._id);
    setRecentListIDs(recentListIDs.filter((id) => rawIDs.includes(id)));
  }, [rawLists, machine.machineID]);

  return (
    <PitchListsContext.Provider value={state}>
      {props.children}
    </PitchListsContext.Provider>
  );
};
