import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AuthContext } from 'contexts/auth.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { ErrorLevel } from 'lib_ts/enums/errors.enums';
import { IErrorType } from 'lib_ts/interfaces/common/i-error-type';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminErrorTypesService } from 'services/admin/error-types.service';

const CONTEXT_NAME = 'AdminErrorTypesContext';

interface IFilters {
  errorIDs: string[];
  levels: ErrorLevel[];
  internal?: boolean;
  deleted?: boolean;
}

interface IOptionsDict {
  errorIDs: IOption[];
  levels: IOption[];
  _created: string[];
}

export interface IErrorTypesContext {
  filters: IFilters;
  mergeFilters: (value: Partial<IFilters>) => void;

  filtered: IErrorType[];
  loading: boolean;

  options: IOptionsDict;

  /** changes lastFetched date which auto-triggers data to be fetched */
  readonly refresh: () => void;

  readonly create: (payload: Partial<IErrorType>) => Promise<boolean>;
  readonly update: (payload: Partial<IErrorType>) => Promise<boolean>;
}

const DEFAULT: IErrorTypesContext = {
  filters: {
    errorIDs: [],
    levels: [],
    internal: undefined,
    // default view shows only non-deleted errors
    deleted: false,
  },
  mergeFilters: () => console.error(`${CONTEXT_NAME}: not init`),
  filtered: [],

  options: {
    errorIDs: [],
    levels: [],
    _created: [],
  },

  loading: false,

  refresh: () => console.error(`${CONTEXT_NAME}: not init`),
  create: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
  update: async () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),
};

const getOptions = (data: IErrorType[]): IOptionsDict => {
  if (data) {
    return {
      errorIDs: data.map((m) => {
        const o: IOption = {
          label: m.errorID,
          group: m.category,
          value: m.errorID,
        };

        return o;
      }),

      levels: Object.values(ErrorLevel)
        .filter((l) => data.findIndex((m) => m.level === l) !== -1)
        .map((l) => {
          const o: IOption = {
            label: l.toUpperCase(),
            color: NotifyHelper.getColorFromLevel(l),
            value: l,
          };
          return o;
        }),

      _created: ArrayHelper.unique(
        data.map((m) => lightFormat(parseISO(m._created), 'yyyy-MM-dd'))
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const ErrorTypesContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const ErrorTypesProvider: FC<IProps> = (props) => {
  const { current } = useContext(AuthContext);

  // changing this will trigger a reload
  const [lastFetched, setLastFetched] = useState(new Date());

  /** reload data to match session access */
  useEffect(() => setLastFetched(new Date()), [current.session]);

  const [types, setTypes] = useState<IErrorType[]>([]);

  const [filters, setFilters] = useState(DEFAULT.filters);

  const filtered = useMemo(
    () =>
      types
        .filter(
          (m) =>
            filters.errorIDs.length === 0 ||
            filters.errorIDs.includes(m.errorID)
        )
        .filter(
          (m) => filters.levels.length === 0 || filters.levels.includes(m.level)
        )
        .filter(
          (m) =>
            filters.internal === undefined || filters.internal === m.internal
        )
        .filter(
          (m) =>
            // all (nothing selected in the filter)
            filters.deleted === undefined ||
            // not-deleted (false OR undefined on the record)
            (!filters.deleted && !m.deleted) ||
            // deleted
            (filters.deleted && m.deleted)
        ),
    [types, filters]
  );

  const options = useMemo(() => getOptions(types), [types]);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const state: IErrorTypesContext = {
    filters: filters,

    mergeFilters: (v) => setFilters((prev) => ({ ...prev, ...v })),

    filtered: filtered,

    options: options,

    loading: loading,

    refresh: () => setLastFetched(new Date()),

    create: async (payload) => {
      try {
        if (!payload.errorID) {
          NotifyHelper.error({
            message_md: 'Please enter an error ID and try again.',
          });
          return false;
        }

        if (!payload.level) {
          NotifyHelper.error({
            message_md: 'Please enter a level and try again.',
          });
          return false;
        }

        const existing = types.find((m) => m.errorID === payload.errorID);

        if (existing) {
          NotifyHelper.warning({
            message_md: `${
              existing.deleted ? 'A deleted error type' : 'An error type'
            } with \`errorID\` "${payload.errorID}" already exists!`,
          });
          return false;
        }

        setLoading(true);

        const result =
          await AdminErrorTypesService.getInstance().create(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const newValue: IErrorType = result.data;
        setTypes((prev) => [...prev, newValue]);

        NotifyHelper.success({
          message_md: `"${newValue.errorID}" created!`,
        });

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            e instanceof Error ? e.message : t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },

    update: async (payload) => {
      try {
        setLoading(true);

        const result =
          await AdminErrorTypesService.getInstance().update(payload);

        if (!result.success) {
          throw new Error(result.error);
        }

        const updated = result.data as IErrorType;

        setTypes((prev) =>
          prev.map((m) => (m._id === updated._id ? updated : m))
        );

        return true;
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });

        return false;
      } finally {
        setLoading(false);
      }
    },
  };

  /** fetch the data whenever lastFetched changes */
  useEffect(() => {
    const callback = async (): Promise<void> => {
      try {
        setLoading(true);

        const results = await AdminErrorTypesService.getInstance().getAll();

        results.sort((a, b) =>
          (a.errorID ?? '').localeCompare(b.errorID ?? '')
        );

        setTypes(results);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    callback();
  }, [lastFetched]);

  return (
    <ErrorTypesContext.Provider value={state}>
      {props.children}
    </ErrorTypesContext.Provider>
  );
};
