import { Box, Text } from '@radix-ui/themes';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { Folder } from 'components/main/sidebar/pitch-lists/full-menu/folder';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

/**
 * can handle its own filtering, but to accomplish a unified "no results",
 * the search logic below will be mostly redundant while
 * HIDE_ON_NO_RESULTS is true
 */
export const RootFolder = (props: {
  folder: ISidebarFolder;
  getFolderActions: (folder: ISidebarFolder) => IMenuAction[];
}) => {
  const { machine } = useContext(MachineContext);
  const { sidebarFilters } = useContext(PitchListsContext);

  return (
    <ErrorBoundary componentName="RootFolder">
      {/* hide the category label when we're filtering by category since it's redundant */}
      {!sidebarFilters.category && (
        <Box pt="1" pb="1" pl="2" pr="2">
          <Text size="1" color={RADIX.COLOR.NEUTRAL} truncate>
            {SidebarHelper.getFolderName(props.folder, machine.machineID)}
          </Text>
        </Box>
      )}

      <Box>
        <Folder
          folder={props.folder}
          getFolderActions={props.getFolderActions}
        />
      </Box>
    </ErrorBoundary>
  );
};
