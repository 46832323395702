import {
  GearIcon,
  MagicWandIcon,
  MagnifyingGlassIcon,
  ResetIcon,
} from '@radix-ui/react-icons';
import { Badge, Box } from '@radix-ui/themes';
import {
  BusyMachineIcon,
  ConnectedMachineIcon,
  DisconnectedMachineIcon,
  LowerMachineIcon,
  R2FIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MSDebugDialogHoC } from 'components/machine/dialogs/ms-debug';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { AimingProvider } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { QueueState } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { useContext, useMemo, useState } from 'react';

const COMPONENT_NAME = 'SidebarConnectionButton';

const getLabel = (machine: IMachine): string => {
  if (machine.nickname) {
    return `${machine.machineID} (${machine.nickname})`;
  }

  return machine.machineID;
};

const ConnectionDisconnected = () => {
  const { machine, reconnect } = useContext(MachineContext);

  const label = useMemo(() => getLabel(machine), [machine]);

  return (
    <ErrorBoundary componentName="ConnectionDisconnected">
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="disconnected"
        data-machineid={machine.machineID}
        icon={<DisconnectedMachineIcon />}
        title={t('common.not-connected-to-x', {
          x: label,
        }).toString()}
        label={label}
        suffix={<Badge>{t('common.disconnected-shorthand')}</Badge>}
        color={RADIX.COLOR.DANGER}
        onClick={reconnect}
        small
      />
    </ErrorBoundary>
  );
};

const ConnectionBusy = () => {
  const { machine, setDialog } = useContext(MachineContext);

  const label = useMemo(() => getLabel(machine), [machine]);

  return (
    <ErrorBoundary componentName="ConnectionBusy">
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="busy"
        data-machineid={machine.machineID}
        icon={<BusyMachineIcon />}
        label={label}
        color={RADIX.COLOR.WARNING}
        suffix={<Badge>{t('common.busy')}</Badge>}
        onClick={() => setDialog(MachineDialogMode.RequestControl)}
        small
      />
    </ErrorBoundary>
  );
};

const ConnectionActive = () => {
  const { tryChangeSection } = useContext(SectionsContext);
  const { current, restrictedGameStatus } = useContext(AuthContext);
  const { machine, setDialog, specialMstarget, restartArc } =
    useContext(MachineContext);

  const label = useMemo(() => getLabel(machine), [machine]);

  const [dialogDebug, setDialogDebug] = useState<number | undefined>();
  const [dialogRestart, setDialogRestart] = useState<number | undefined>();

  return (
    <ErrorBoundary componentName="ConnectionActive">
      <SidebarNavigationTrigger
        data-testid="ConnectionButton"
        data-status="connected"
        data-machineid={machine.machineID}
        icon={<ConnectedMachineIcon />}
        label={label}
        color={RADIX.COLOR.SUCCESS}
        actions={[
          {
            group: '_1',
            prefixIcon: <R2FIcon />,
            label: 'common.status',
            onClick: () => setDialog(MachineDialogMode.R2F),
          },
          {
            group: '_1',
            prefixIcon: <LowerMachineIcon />,
            label: 'common.lower',
            onClick: () => specialMstarget(SpecialMsPosition.home),
          },
          {
            group: '_1',
            prefixIcon: <GearIcon />,
            label: 'common.calibrate',
            invisible: restrictedGameStatus,
            onClick: () =>
              tryChangeSection({
                trigger: COMPONENT_NAME,
                section: SectionName.CalibrateMachine,
              }),
          },
          {
            group: '_1',
            prefixIcon: <ResetIcon />,
            label: 'common.restart',
            color: RADIX.COLOR.WARNING,
            onClick: () => setDialogRestart(Date.now()),
          },
          {
            group: '_2',
            prefixIcon: <MagnifyingGlassIcon />,
            label: 'common.health-check',
            invisible: current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            onClick: () => setDialog(MachineDialogMode.HealthCheck),
          },
          {
            group: '_2',
            prefixIcon: <MagicWandIcon />,
            label: 'common.debug',
            invisible: current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            onClick: () => setDialogDebug(Date.now()),
          },
        ]}
        small
      />

      {dialogDebug && (
        <AimingProvider>
          <MSDebugDialogHoC
            key={dialogDebug}
            identifier="SidebarFooterMsDebugDialog"
            onClose={() => setDialogDebug(undefined)}
          />
        </AimingProvider>
      )}

      {dialogRestart && (
        <CommonConfirmationDialog
          key={dialogRestart}
          identifier="SidebarMenuConfirmRestart"
          title="common.restart"
          content={<Box>{t('common.machine-not-in-use-msg')}</Box>}
          action={{
            onClick: () => restartArc(COMPONENT_NAME),
          }}
        />
      )}
    </ErrorBoundary>
  );
};

export const MachineConnectionTrigger = () => {
  const { lastStatus } = useContext(MachineContext);

  switch (lastStatus.queueState) {
    case QueueState.Active: {
      return <ConnectionActive />;
    }

    case QueueState.Busy: {
      return <ConnectionBusy />;
    }

    case QueueState.Disconnected:
    default: {
      return <ConnectionDisconnected />;
    }
  }
};
