import { NotifyHelper } from 'classes/helpers/notify.helper';
import env from 'config';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import { SessionMode } from 'lib_ts/interfaces/i-auth-token';
import {
  ICopyPitchLists,
  IPitch,
  IPitchList,
  IPitchListPutManyRequest,
  IPitchListSummaryDict,
  IRenameFolderRequest,
} from 'lib_ts/interfaces/pitches';
import { BaseRESTService } from 'services/_base-rest.service';

export class PitchListsService extends BaseRESTService {
  private static instance: PitchListsService;
  static getInstance(): PitchListsService {
    if (!PitchListsService.instance) {
      PitchListsService.instance = new PitchListsService();
    }

    return PitchListsService.instance;
  }

  private constructor() {
    super({
      controller: 'pitch-lists',
    });
  }

  /** if env.enable_team_lists = true => list respects editor selection, otherwise list will be owned by user */
  async postList(data: Partial<IPitchList>): Promise<IServerResponse> {
    return await this.post({}, data);
  }

  /** creates a copy using data's id to select existing list for duplication,
   * but with the copied list's name as data's name */
  async copyList(data: Partial<IPitchList>): Promise<IServerResponse> {
    if (!data._id) {
      throw new Error('Cannot copy without id');
    }
    return await this.post(
      {
        uri: 'copy',
        params: {
          list_id: data._id,
        } as any,
      },
      data
    );
  }

  async getList(listID: string): Promise<IServerResponse> {
    if (!listID) {
      throw new Error('Cannot get without id');
    }
    return await this.get({
      uri: '',
      params: {
        list_id: listID,
      } as any,
    });
  }

  // returns a list of pitchIDs that were modified
  async restoreList(listID: string): Promise<string[]> {
    if (!listID) {
      throw new Error('Cannot get without id');
    }

    return await this.get({
      uri: 'restore',
      params: {
        list_id: listID,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (result.success) {
          const pitchIDs = result.data as string[];

          if (pitchIDs.length === 0) {
            NotifyHelper.info({
              message_md:
                'Pitch list restore completed. No pitches were changed.',
            });
          } else {
            NotifyHelper.success({
              message_md: `Pitch list restore completed. ${pitchIDs.length} pitch(es) changed.`,
            });
          }

          return pitchIDs;
        }

        console.error(result.error);

        NotifyHelper.warning({
          message_md: result.error ?? 'Pitch list restore failed.',
        });

        return result.data ?? [];
      })
      .catch((error) => {
        console.error(error);

        NotifyHelper.error({
          message_md: 'Pitch list restore failed.',
        });

        return [];
      });
  }

  async putList(data: Partial<IPitchList>): Promise<IServerResponse> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }
    return await this.put(
      {
        uri: '',
        params: {
          list_id: data._id,
        } as any,
      },
      data
    );
  }

  async renameFolder(config: IRenameFolderRequest): Promise<IServerResponse> {
    return await this.post({ uri: 'folder/rename' }, config);
  }

  // because the insertion uses insertMany chunked, the server response is just a boolean of whether it all succeeded instead of the inserted records
  async postPitchesToList(config: {
    listID: string;
    data: Partial<IPitch>[];
  }): Promise<IServerResponse> {
    /** cleanup data before submission */
    config.data.forEach((p) => {
      /** ensure new pitches are created, e.g. when copying/saving from existing */
      delete p._id;
    });

    return await this.post(
      {
        uri: 'pitches',
        params: {
          list_id: config.listID,
        } as any,
      },
      config.data
    );
  }

  async getVisible(role: UserRole, mode: SessionMode): Promise<IPitchList[]> {
    return await this.get({
      uri: 'visible',
      params: {
        samples: env.enable.sample_lists,
        cards: role === UserRole.admin,
        reference: role === UserRole.admin || mode === 'impostor',
      } as any,
    });
  }

  async getReferenceLists(): Promise<IPitchList[]> {
    return await this.get({
      uri: 'reference',
    });
  }

  async getCards(): Promise<IPitchList[]> {
    return await this.get({
      uri: 'cards',
    });
  }

  async putLists(payload: IPitchListPutManyRequest): Promise<IServerResponse> {
    return await this.put(
      {
        uri: 'many',
      },
      payload
    );
  }

  async copyLists(payload: ICopyPitchLists): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'many/copy',
      },
      payload
    );
  }

  async deleteLists(listIDs: string[]): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'many/delete',
      },
      listIDs
    );
  }

  /** import CSV to bulk update pitches in the list with limited subset of provided data (e.g. pitch title, type, video_id) */
  async importCSV(
    listID: string,
    files: File[]
  ): Promise<{ event: string; success: boolean; error?: any }> {
    /** append the files */
    const formData = new FormData();
    files.forEach((f) => formData.append('files', f));

    return await this.post(
      {
        uri: 'import/csv',
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          list_id: listID,
        } as any,
      },
      formData
    );
  }

  async postCardAvatar(
    listID: string,
    formData: FormData,
    onProgress?: (event: ProgressEvent) => void
  ): Promise<IServerResponse> {
    return await this.post(
      {
        uri: 'card/avatar',
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          list_id: listID,
        } as any,
      },
      formData,
      onProgress
    );
  }

  async getSummaryDict(): Promise<IPitchListSummaryDict> {
    return await this.get({
      uri: 'summary',
    })
      .then((res: IServerResponse) => {
        if (res.success) {
          return res.data;
        }

        NotifyHelper.warning({
          message_md:
            res.error ??
            'There was a problem fetching pitch list hash summaries.',
        });

        return {};
      })
      .catch((e) => {
        console.error(e);

        NotifyHelper.error({
          message_md: 'There was a problem fetching pitch list hash summaries.',
        });

        return {};
      });
  }
}
