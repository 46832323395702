import { Box, Card, Flex, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { GameDataTeamLogo } from 'components/sections/game-data/team-logo';
import { GameDataContext } from 'contexts/game-data.context';
import { SectionsContext } from 'contexts/sections.context';
import { LOCAL_GAMETIME_FORMAT } from 'enums/env';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbGame } from 'lib_ts/interfaces/mlb-stats-api/i-game';
import { useContext, useMemo, useState } from 'react';

export const GameDataGameCard = (props: {
  isDark: boolean;
  game: IMlbGame;
}) => {
  const { tryChangeSection } = useContext(SectionsContext);
  const { seasonTeams, mergeFilterPitches } = useContext(GameDataContext);

  const [hover, setHover] = useState(false);

  const { away, home } = props.game;

  const dateString = useMemo(() => {
    if (!props.game) {
      return '';
    }

    if (props.game.gameDate) {
      return StringHelper.getTimezoneString({
        value: props.game.gameDate,
        format: LOCAL_GAMETIME_FORMAT,
      });
    }

    return props.game.officialDate;
  }, [props.game]);

  const homeRGB = useMemo(() => {
    const team = seasonTeams.find((m) => m.teamPk === props.game.home.teamPk);
    return StringHelper.hexToRgb(team?.colorHex);
  }, [props.game, seasonTeams]);

  const awayRGB = useMemo(() => {
    const team = seasonTeams.find((m) => m.teamPk === props.game.away.teamPk);
    return StringHelper.hexToRgb(team?.colorHex);
  }, [props.game, seasonTeams]);

  return (
    <Card
      key={props.game.gamePk}
      size="2"
      className="cursor-pointer animate-background"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={
        hover
          ? {
              background: `radial-gradient(200% 140% at 100% 100%, rgba(${awayRGB[0]}, ${awayRGB[1]}, ${awayRGB[2]}, 0.5) 0%, rgba(${homeRGB[0]}, ${homeRGB[1]}, ${homeRGB[2]}, 0.5) 100%)`,
            }
          : undefined
      }
      onClick={() =>
        tryChangeSection({
          trigger: `game card`,
          section: SectionName.Pitches,
          subsection: SubSectionName.GameData,
          fragments: ['games', props.game.gamePk.toString()],
          // reset filters from previous interactions
          beforeNavCallback: async () => mergeFilterPitches({}),
        })
      }
    >
      <Flex direction="column" gap="2">
        <Text size="1" color={RADIX.COLOR.NEUTRAL}>
          {dateString}
        </Text>

        <Flex gap="2" align="center">
          <GameDataTeamLogo
            size="20px"
            teamPK={away.teamPk}
            name={away.name}
            isDark={props.isDark}
          />

          <Box flexGrow="1">
            <Text size="3">{away.name}</Text>
          </Box>

          <Text size="3">{away.score}</Text>
        </Flex>

        <Flex gap="2" align="center">
          <GameDataTeamLogo
            size="20px"
            teamPK={home.teamPk}
            name={home.name}
            isDark={props.isDark}
          />

          <Box flexGrow="1">
            <Text size="3">{home.name}</Text>
          </Box>

          <Text size="3">{home.score}</Text>
        </Flex>
      </Flex>
    </Card>
  );
};
