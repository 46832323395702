import { useEffect } from 'react';

interface IProps {
  ref: React.RefObject<HTMLElement>;
  shouldListen?: boolean;
  onClickAway: (event: MouseEvent | TouchEvent) => void;
}

// takes a ref and calls the provided onClickAway callback when a click/touch event occurs outside of the ref element
const useOnClickAway = ({ ref, shouldListen, onClickAway }: IProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway(event);
      }
    };

    if (shouldListen) {
      document.addEventListener('pointerdown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [shouldListen, onClickAway]);
};

export default useOnClickAway;
