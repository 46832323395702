import { Badge, Flex, Heading, Skeleton, Table, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { InstallationContext } from 'components/machine/dialogs/installation/context';
import { appearanceImgPath } from 'index';
import {
  BAD_MODEL_PERFORMANCE as BAD,
  GOOD_MODEL_PERFORMANCE as GOOD,
} from 'lib_ts/classes/metric.helper';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';
import { UserMachineModelsService } from 'services/machine-models.service';

interface IRow {
  label: string;
  value: string;
  color: RadixColor;
}

const getRow = (config: {
  label: string;
  value: number;
  decimals: number;
  good: number;
  bad: number;
}) => {
  const output: IRow = {
    label: config.label,
    value: config.value.toFixed(config.decimals),
    color:
      config.value <= config.good
        ? RADIX.COLOR.SUCCESS
        : config.value <= config.bad
        ? RADIX.COLOR.WARNING
        : RADIX.COLOR.DANGER,
  };

  return output;
};

interface IProps {
  machineID: string;
}

export const BallTypeResults = (props: IProps) => {
  const {
    details,
    modelPerformance: perf,
    setModelPerformance,
  } = useContext(InstallationContext);

  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<IMachineModel | undefined>();

  useEffect(() => {
    if (!details.model_id) {
      return;
    }

    UserMachineModelsService.getInstance()
      .getModel(details.model_id)
      .then((model) => setModel(model));
  }, [details.model_id]);

  useEffect(() => {
    if (perf) {
      // we already loaded the data before
      return;
    }

    if (!details.model_id) {
      NotifyHelper.warning({
        message_md: 'No model found from ball type calibration step.',
      });
      return;
    }

    setLoading(true);

    AdminMachineModelsService.getInstance()
      .evalCollectionModel({
        collectionID: details.calibration_id,
        machineID: props.machineID,
        ball_type: details.ball_type,
        model_id: details.model_id,
      })
      .then((results) => {
        console.debug(
          `loaded model performance results for ${details.calibration_id}`,
          results
        );

        if (!results?.model_performance) {
          return;
        }

        setModelPerformance(results.model_performance);
      })
      .finally(() => setLoading(false));
  }, [details, props.machineID]);

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton height="400px" />;
    }

    if (!perf || !model) {
      return (
        <Flex pt="5" pb="5" align="center" direction="column" gap="2">
          <img
            className="select-none"
            src={appearanceImgPath('no-data.svg')}
            alt="no data"
            style={{
              width: '80%',
              maxWidth: '462px',
              height: 'auto',
            }}
          />

          <Heading size="3">No Data to Display</Heading>

          <Text color={RADIX.COLOR.SECONDARY}>
            Please complete data collection to explore the results here.
          </Text>
        </Flex>
      );
    }

    const { mean_best_90p: best, mean_worst_10p: worst } = perf;

    const best90Errors: IRow[] = [
      getRow({
        label: 'vx',
        value: best.vx,
        good: GOOD.vx,
        bad: BAD.vx,
        decimals: 2,
      }),
      getRow({
        label: 'vy',
        value: best.vy,
        good: GOOD.vy,
        bad: BAD.vy,
        decimals: 2,
      }),
      getRow({
        label: 'vz',
        value: best.vz,
        good: GOOD.vz,
        bad: BAD.vz,
        decimals: 2,
      }),
      getRow({
        label: 'wx',
        value: best.wx,
        good: GOOD.wx,
        bad: BAD.wx,
        decimals: 2,
      }),
      getRow({
        label: 'wy',
        value: best.wy,
        good: GOOD.wy,
        bad: BAD.wy,
        decimals: 2,
      }),
      getRow({
        label: 'wz',
        value: best.wz,
        good: GOOD.wz,
        bad: BAD.wz,
        decimals: 2,
      }),
      getRow({
        label: 'break_x_ft',
        value: best.break_x_ft,
        good: GOOD.break_x_ft,
        bad: BAD.break_x_ft,
        decimals: 2,
      }),
      getRow({
        label: 'break_z_ft',
        value: best.break_z_ft,
        good: GOOD.break_z_ft,
        bad: BAD.break_z_ft,
        decimals: 2,
      }),
    ];

    const worst10Errors: IRow[] = [
      getRow({
        label: 'vx',
        value: worst.vx,
        good: GOOD.vx,
        bad: BAD.vx,
        decimals: 2,
      }),
      getRow({
        label: 'vy',
        value: worst.vy,
        good: GOOD.vy,
        bad: BAD.vy,
        decimals: 2,
      }),
      getRow({
        label: 'vz',
        value: worst.vz,
        good: GOOD.vz,
        bad: BAD.vz,
        decimals: 2,
      }),
      getRow({
        label: 'wx',
        value: worst.wx,
        good: GOOD.wx,
        bad: BAD.wx,
        decimals: 2,
      }),
      getRow({
        label: 'wy',
        value: worst.wy,
        good: GOOD.wy,
        bad: BAD.wy,
        decimals: 2,
      }),
      getRow({
        label: 'wz',
        value: worst.wz,
        good: GOOD.wz,
        bad: BAD.wz,
        decimals: 2,
      }),
      getRow({
        label: 'break_x_ft',
        value: worst.break_x_ft,
        good: GOOD.break_x_ft,
        bad: BAD.break_x_ft,
        decimals: 2,
      }),
      getRow({
        label: 'break_z_ft',
        value: worst.break_z_ft,
        good: GOOD.break_z_ft,
        bad: BAD.break_z_ft,
        decimals: 2,
      }),
    ];

    return (
      <Table.Root>
        <Table.Header>
          <Table.ColumnHeaderCell>Attribute</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell align="right">
            Model Performance
          </Table.ColumnHeaderCell>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Machine</Table.Cell>
            <Table.Cell align="right">{model.machineID}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Ball Type</Table.Cell>
            <Table.Cell align="right">{model.ball_type}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Break Support</Table.Cell>
            <Table.Cell align="right">
              {model.supports_breaks ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Spin Support</Table.Cell>
            <Table.Cell align="right">
              {model.supports_spins ? 'Yes' : 'No'}
            </Table.Cell>
          </Table.Row>

          {best90Errors.map((m, i) => (
            <Table.Row key={`best-90-${i}`}>
              <Table.Cell>Best 90% Abs. Error {m.label}</Table.Cell>
              <Table.Cell align="right">
                <Badge color={m.color}>{m.value}</Badge>
              </Table.Cell>
            </Table.Row>
          ))}

          {worst10Errors.map((m, i) => (
            <Table.Row key={`worst-10-${i}`}>
              <Table.Cell>Worst 10% Abs. Error {m.label}</Table.Cell>
              <Table.Cell align="right">
                <Badge color={m.color}>{m.value}</Badge>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    );
  }, [loading, perf, model]);

  return (
    <ErrorBoundary componentName="ModelPerformanceTab">{content}</ErrorBoundary>
  );
};
