export const MLB_TEAMS: {
  teamPk: number;
  code: string;
  name: string;
  shortName: string;
  hexColor: string;
}[] = [
  {
    teamPk: 109,
    code: 'ARI',
    name: 'Arizona Diamondbacks',
    shortName: 'Diamondbacks',
    hexColor: '#992934',
  },
  {
    teamPk: 144,
    code: 'ATL',
    name: 'Atlanta Braves',
    shortName: 'Braves',
    hexColor: '#0C2752',
  },
  {
    teamPk: 110,
    code: 'BAL',
    name: 'Baltimore Orioles',
    shortName: 'Orioles',
    hexColor: '#E95A29',
  },
  {
    teamPk: 111,
    code: 'BOS',
    name: 'Boston Red Sox',
    shortName: 'Red Sox',
    hexColor: '#B72B35',
  },
  {
    teamPk: 112,
    code: 'CHC',
    name: 'Chicago Cubs',
    shortName: 'Cubs',
    hexColor: '#102E68',
  },
  {
    teamPk: 145,
    // or CWS
    code: 'CHW',
    name: 'Chicago White Sox',
    shortName: 'White Sox',
    hexColor: '#272520',
  },
  {
    teamPk: 113,
    code: 'CIN',
    name: 'Cincinnati Reds',
    shortName: 'Reds',
    hexColor: '#C32939',
  },
  {
    teamPk: 114,
    code: 'CLE',
    name: 'Cleveland Guardians',
    shortName: 'Guardians',
    hexColor: '#14223C',
  },
  {
    teamPk: 115,
    code: 'COL',
    name: 'Colorado Rockies',
    shortName: 'Rockies',
    hexColor: '#333363',
  },
  {
    teamPk: 116,
    code: 'DET',
    name: 'Detroit Tigers',
    shortName: 'Tigers',
    hexColor: '#12223E',
  },
  {
    teamPk: 117,
    code: 'HOU',
    name: 'Houston Astros',
    shortName: 'Astros',
    hexColor: '#0F2C5E',
  },
  {
    teamPk: 118,
    code: 'KAN',
    name: 'Kansas City Royals',
    shortName: 'Royals',
    hexColor: '#1B4582',
  },
  {
    teamPk: 108,
    code: 'LAA',
    name: 'Los Angeles Angels',
    shortName: 'Angels',
    hexColor: '#AA2229',
  },
  {
    teamPk: 119,
    code: 'LAD',
    name: 'Los Angeles Dodgers',
    shortName: 'Dodgers',
    hexColor: '#015A9C',
  },
  {
    teamPk: 146,
    code: 'MIA',
    name: 'Miami Marlins',
    shortName: 'Marlins',
    hexColor: '#48A1DB',
  },
  {
    teamPk: 158,
    code: 'MIL',
    name: 'Milwaukee Brewers',
    shortName: 'Brewers',
    hexColor: '#182849',
  },
  {
    teamPk: 142,
    code: 'MIN',
    name: 'Minnesota Twins',
    shortName: 'Twins',
    hexColor: '#12223F',
  },
  {
    teamPk: 121,
    code: 'NYM',
    name: 'New York Mets',
    shortName: 'Mets',
    hexColor: '#0F2C6E',
  },
  {
    teamPk: 147,
    code: 'NYY',
    name: 'New York Yankees',
    shortName: 'Yankees',
    hexColor: '#192544',
  },
  {
    teamPk: 133,
    code: 'OAK',
    name: 'Oakland Athletics',
    shortName: 'Athletics',
    hexColor: '#143731',
  },
  {
    teamPk: 143,
    code: 'PHI',
    name: 'Philadelphia Phillies',
    shortName: 'Phillies',
    hexColor: '#AB2734',
  },
  {
    teamPk: 134,
    code: 'PIT',
    name: 'Pittsburgh Pirates',
    shortName: 'Pirates',
    hexColor: '#F6C951',
  },
  {
    teamPk: 138,
    code: 'STL',
    name: 'St. Louis Cardinals',
    shortName: ' Cardinals',
    hexColor: '#C41E3A',
  },
  {
    teamPk: 135,
    code: 'SD',
    name: 'San Diego Padres',
    shortName: 'Padres',
    hexColor: '#2D241E',
  },
  {
    teamPk: 137,
    code: 'SF',
    name: 'San Francisco Giants',
    shortName: 'Giants',
    hexColor: '#E7552F',
  },
  {
    teamPk: 136,
    code: 'SEA',
    name: 'Seattle Mariners',
    shortName: 'Mariners',
    hexColor: '#265A5A',
  },
  {
    teamPk: 139,
    code: 'TB',
    name: 'Tampa Bay Rays',
    shortName: 'Rays',
    hexColor: '#132B59',
  },
  {
    teamPk: 140,
    code: 'TEX',
    name: 'Texas Rangers',
    shortName: 'Rangers',
    hexColor: '#113174',
  },
  {
    teamPk: 141,
    code: 'TOR',
    name: 'Toronto Blue Jays',
    shortName: 'Blue Jays',
    hexColor: '#244989',
  },
  {
    teamPk: 120,
    code: 'WAS',
    name: 'Washington Nationals',
    shortName: 'Nationals',
    hexColor: '#AB2931',
  },
];

export enum GameStatus {
  Empty = '',
  Ignore = 'Ignore',
  InProgress = 'In Progress',
  NoGame = 'No Game',
  Unknown = 'Unknown',
  Unreachable = 'Unreachable',
}

/** if a team's current game status is in this list, restrictions should apply (e.g. offline mode, block writing) */
export const GAME_STATUS_BLACKLIST: GameStatus[] = [
  GameStatus.InProgress,
  GameStatus.Unreachable,
  GameStatus.Unknown,
];

export const GAME_STATUS_DISABLE_ACTION_MSG =
  'This action is disabled while your team is in a game.';

export const CURRENT_MLB_SEASON = 2024;

export enum InactiveReason {
  OffRoster = 'OffRoster',
}
