import { Code, Text } from '@radix-ui/themes';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { CommonTableHoC } from 'components/common/table';
import { AdminTabNav } from 'components/sections/admin-portal/tab-nav';
import { TeamsTableToolbar } from 'components/sections/admin-portal/teams/toolbar';
import { TeamsContext } from 'contexts/admin/teams.context';
import { AuthContext } from 'contexts/auth.context';
import { GlobalContext } from 'contexts/global.context';
import lightFormat from 'date-fns/lightFormat';
import parseISO from 'date-fns/parseISO';
import { SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { MlbAbbreviation } from 'lib_ts/enums/batrack.enums';
import { GameStatus } from 'lib_ts/enums/mlb.enums';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { TeamDialog } from 'lib_ts/enums/teams.enums';
import { IAdminTeam, ITeam } from 'lib_ts/interfaces/i-team';
import { IMongoBase } from 'lib_ts/interfaces/mongo/_base';
import { IPitchListPutManyRequest } from 'lib_ts/interfaces/pitches/i-pitch-list';
import { useContext, useMemo, useState } from 'react';

const IDENTIFIER = TableIdentifier.AdminTeamList;

const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

export const TeamsTable = () => {
  const { dialogs } = useContext(GlobalContext);
  const { current } = useContext(AuthContext);
  const { teams, openDialog } = useContext(TeamsContext);

  const columns = useMemo<ITableColumn[]>(
    () => [
      {
        label: 'common.actions',
        key: ACTIONS_KEY,
        actions: [
          {
            label: 'common.edit',
            onClick: (m: ITeam) =>
              openDialog({
                mode: TeamDialog.Editor,
                team: m,
              }),
          },
          {
            label: 'admin.refresh-lists',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            color: RADIX.COLOR.WARNING,
            onClick: (m: ITeam) =>
              openDialog({
                mode: TeamDialog.Refresh,
                team: m,
              }),
          },
          {
            label: 'admin.restore-lists',
            invisibleFn: () => current.role !== UserRole.admin,
            suffixIcon: <SuperAdminIcon />,
            color: RADIX.COLOR.WARNING,
            onClick: (m: ITeam) =>
              openDialog({
                mode: TeamDialog.Restore,
                team: m,
              }),
          },
          {
            label: 'common.reassign-lists',
            onClick: (m: ITeam) => {
              const payload: IPitchListPutManyRequest = {
                filter: {
                  _parent_id: m._id,
                  _parent_def: PitchListOwner.Team,
                },
                update: {
                  processed: new Date(),
                  process_notes: `reassigned from team ${m.name} by admin ${current.email}`,
                },
              };

              setReassignListsPayload(payload);
              setDialogReassign(Date.now());
            },
            color: RADIX.COLOR.WARNING,
          },
        ],
      },
      {
        label: 'common.created',
        key: '_created',
        formatFn: (m: IMongoBase) =>
          lightFormat(parseISO(m._created), 'yyyy-MM-dd'),
      },
      {
        label: 'common.name',
        key: 'name',
        formatFn: (m: IAdminTeam) => {
          if (m.abbreviation && m.abbreviation !== MlbAbbreviation.None) {
            return `${m.name} (${m.abbreviation})`;
          }

          return m.name;
        },
      },
      {
        label: 'common.game-status',
        key: 'batrackStatus',
        formatFn: (m: IAdminTeam) => {
          if (
            !m.batrackStatus ||
            ![
              GameStatus.InProgress,
              GameStatus.Unreachable,
              GameStatus.NoGame,
            ].includes(m.batrackStatus)
          ) {
            return <></>;
          }

          const color: RadixColor | undefined = (() => {
            switch (m.batrackStatus) {
              case GameStatus.InProgress: {
                return RADIX.COLOR.WARNING;
              }

              case GameStatus.Unreachable:
              case GameStatus.Unknown: {
                return RADIX.COLOR.DANGER;
              }

              case GameStatus.NoGame: {
                return RADIX.COLOR.SUCCESS;
              }

              default: {
                return;
              }
            }
          })();

          return <Text color={color}>{m.batrackStatus}</Text>;
        },
      },
      {
        label: 'admin.domains',
        key: 'email_domains',
        sortRowsFn: (a: ITeam, b: ITeam, dir: number) =>
          ((a.email_domains ?? []).length > (b.email_domains ?? []).length
            ? 1
            : -1) * dir,
        formatFn: (m: ITeam) => {
          if (m.email_domains && m.email_domains.length > 0) {
            return (
              <ul className="no-style no-padding">
                {m.email_domains.map((d, i) => (
                  <li key={i}>
                    <Code>{d}</Code>
                  </li>
                ))}
              </ul>
            );
          }

          return '--';
        },
      },
      {
        label: 'admin.machines',
        key: 'count_machines',
        align: 'right',
      },
      {
        label: 'admin.users',
        key: 'count_users',
        align: 'right',
      },
      {
        label: 'admin.videos',
        key: 'count_videos',
        align: 'right',
      },
    ],
    [current]
  );

  const [reassignListsPayload, setReassignListsPayload] =
    useState<IPitchListPutManyRequest>({ filter: {}, update: {} });

  const [dialogReassign, setDialogReassign] = useState<number>();

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: teams.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: 'name',
      dir: -1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
  };

  return (
    <ErrorBoundary componentName="TeamsTable">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={<AdminTabNav active={SubSectionName.Teams} />}
        table={
          <CommonTableHoC
            id="AdminTeams"
            toolbarContent={<TeamsTableToolbar />}
            displayColumns={columns}
            displayData={teams}
            checkboxColumnIndex={0}
            enableListener={dialogs.length === 0}
            {...pagination}
            {...select}
            {...sort}
            vFlex
          />
        }
      />

      {dialogReassign && (
        <ReassignListsDialog
          key={dialogReassign}
          identifier="TeamsTableReassignListsDialog"
          refPayload={reassignListsPayload}
          onClose={() => setDialogReassign(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
