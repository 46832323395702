import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PresetTrainingDialogHoC } from 'components/machine/dialogs/preset-training';
import { TrainingDialogHoC } from 'components/machine/dialogs/training';
import { ResetTrainingDialog } from 'components/sections/pitch-list/dialogs';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { SectionsContext } from 'contexts/sections.context';
import { isPast, lightFormat, parseISO } from 'date-fns';
import { SectionName } from 'enums/route.enums';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  PitcherHand,
  PitcherRelease,
  PlayerLevel,
} from 'lib_ts/enums/pitches.enums';
import { IExpiringUrlDict } from 'lib_ts/interfaces/common/i-url-dict';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MainService } from 'services/main.service';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';

const CONTEXT_NAME = 'QuickSessionContext';

interface IOptionsDict {
  names: string[];
  _created: string[];
}

export enum QuickSessionStep {
  SelectCard,
  SelectPitch,
}

interface ICardFilters {
  names: string[];
  key: number;

  release?: PitcherRelease;
  level?: PlayerLevel;
  hand?: PitcherHand;
}

export interface IQuickSessionContext {
  loading: boolean;

  options: IOptionsDict;

  step: QuickSessionStep;

  lists: IPitchList[];
  filtered: IPitchList[];
  loaded: boolean;

  avatarUrlDict: IExpiringUrlDict;
  active?: IPitchList;
  pitches: IPitch[];

  filters: ICardFilters;
  readonly mergeFilters: (value: Partial<ICardFilters>) => void;

  readonly setStep: (step: QuickSessionStep) => void;
  readonly selectCard: (card_id: string) => Promise<void>;

  readonly resetPitches: (pitches: IPitch[]) => void;

  training: boolean;
  readonly trainPitches: (pitches: IPitch[]) => void;

  tags: string;
  readonly setTags: (value: string) => void;
}

const DEFAULT: IQuickSessionContext = {
  step: QuickSessionStep.SelectCard,

  options: {
    names: [],
    _created: [],
  },

  lists: [],
  filtered: [],
  loaded: false,

  avatarUrlDict: {},
  pitches: [],
  loading: false,

  filters: {
    names: [],
    key: 0,
  },
  mergeFilters: () => console.error(`${CONTEXT_NAME}: not init`),

  setStep: () => console.error(`${CONTEXT_NAME}: not init`),
  selectCard: () =>
    new Promise(() => console.error(`${CONTEXT_NAME}: not init`)),

  resetPitches: () => console.error(`${CONTEXT_NAME}: not init`),

  training: false,
  trainPitches: () => console.error(`${CONTEXT_NAME}: not init`),

  tags: '',
  setTags: () => console.error(`${CONTEXT_NAME}: not init`),
};

const getOptions = (data: IPitchList[]): IOptionsDict => {
  if (data) {
    return {
      names: ArrayHelper.unique(data.map((m) => m.card?.name ?? '')),

      _created: ArrayHelper.unique(
        data.map((m) =>
          m._created ? lightFormat(parseISO(m._created), 'yyyy-MM-dd') : ''
        )
      ),
    };
  } else {
    return DEFAULT.options;
  }
};

export const QuickSessionContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const QuickSessionProvider: FC<IProps> = (props) => {
  const { current, effectiveTrainingMode } = useContext(AuthContext);
  const { active: activeSection, tryGoHome } = useContext(SectionsContext);
  const { machine } = useContext(MachineContext);

  const { aggReady, updatePitches } = useContext(MatchingShotsContext);

  const [loading, setLoading] = useState(DEFAULT.loading);

  const [step, setStep] = useState(DEFAULT.step);

  const [lists, setLists] = useState<IPitchList[]>([]);

  const [filters, setFilters] = useState(DEFAULT.filters);

  const filtered = useMemo(() => {
    const output = lists
      .filter(
        (c) =>
          filters.names.length === 0 ||
          filters.names.includes(c.card?.name ?? '')
      )
      .filter((c) => !filters.level || c.card?.level === filters.level)
      .filter((c) => !filters.hand || c.card?.hand === filters.hand)
      .filter((c) => !filters.release || c.card?.release === filters.release);

    return output;
  }, [lists, filters]);

  const [loaded, setLoaded] = useState(DEFAULT.loaded);

  const [avatarUrlDict, setAvatarUrlDict] = useState(DEFAULT.avatarUrlDict);
  const [active, setActiveList] = useState(DEFAULT.active);
  const [pitches, setPitches] = useState(DEFAULT.pitches);

  // automatically load shots to enable firing trained pitches
  useEffect(() => {
    if (pitches.length === 0) {
      return;
    }

    updatePitches({
      pitches: pitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });
  }, [pitches]);

  const options = useMemo(() => getOptions(lists), [lists]);

  const changeActive = useCallback(
    async (config: { trigger: string; list_id?: string }) => {
      try {
        const nextActive = lists.find((l) => l._id === config.list_id);

        if (config.list_id && !nextActive && lists.length > 0) {
          NotifyHelper.warning({
            message_md: `You do not have access to player card \`${config.list_id}\`.`,
          });

          tryGoHome();
        }

        if (!nextActive) {
          return;
        }

        setActiveList(nextActive);

        setStep(QuickSessionStep.SelectPitch);

        setLoading(true);

        const pitches = await PitchesService.getInstance().getListPitches(
          nextActive._id
        );

        setPitches(pitches);

        setLoading(false);
      } catch (e) {
        console.error(e);

        NotifyHelper.error({
          message_md:
            'There was an error while preparing your player card. Please try again.',
        });
        setLoading(false);
      }
    },
    [lists]
  );

  const [managePitches, setManagePitches] = useState<IPitch[]>([]);
  const [dialogReset, setDialogReset] = useState<number | undefined>();
  const [dialogTraining, setDialogTraining] = useState<number | undefined>();

  // for labelling fires, if enabled via env
  const [fireTags, setFireTags] = useState('');

  const state: IQuickSessionContext = {
    loading: loading,

    step: step,

    lists: lists,
    loaded: loaded,
    filtered: filtered,

    avatarUrlDict: avatarUrlDict,
    active: active,
    pitches: pitches,
    options: options,

    filters: filters,
    mergeFilters: (value) =>
      setFilters((prev) => ({
        ...prev,
        ...value,
      })),

    setStep: setStep,

    selectCard: (card_id) =>
      changeActive({
        trigger: 'user selected player card',
        list_id: card_id,
      }),

    resetPitches: (pitches) => {
      setManagePitches(pitches);
      setDialogReset(Date.now());
    },

    training: dialogTraining !== undefined,
    trainPitches: (pitches) => {
      setManagePitches(pitches);
      setDialogTraining(Date.now());
    },

    tags: fireTags,
    setTags: setFireTags,
  };

  useEffect(() => {
    if (!current.auth || !current.quick_session) {
      setLists([]);
      return;
    }

    const loadData = async () => {
      try {
        setLoading(true);

        const cards = await PitchListsService.getInstance()
          .getCards()
          .then((r) => {
            return r;
          })
          .catch((e) => {
            console.error(e);
            return [];
          });

        setLists(cards);

        // toggles on the cards access callout if there are no cards found
        setLoaded(true);

        // update avatarURLs
        const noAvatars = cards
          .filter((c) => {
            if (!c.card?.avatar_path) {
              // card doesn't have an avatar
              return false;
            }

            // card's avatar doesn't have a signed URL (e.g. from previous load)
            const entry = avatarUrlDict[c.card.avatar_path];
            return !entry || isPast(entry.expires);
          })
          .map((c) => c.card?.avatar_path ?? '');

        if (noAvatars.length > 0) {
          const newUrls = await MainService.getInstance().signUrls(
            ArrayHelper.unique(noAvatars)
          );

          setAvatarUrlDict((prev) => ({
            ...prev,
            ...newUrls,
          }));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [
    /** anything that might change access to QS should trigger a reload */
    current.auth,
    current.quick_session,
    current.session,
    /** anything that might result in different pitch mss should also trigger a reload */
    machine.machineID,
    machine.ball_type,
  ]);

  useEffect(() => {
    if (!current.auth) {
      return;
    }

    if (!current.quick_session) {
      return;
    }

    if (activeSection.section !== SectionName.QuickSession) {
      return;
    }

    if (!activeSection.fragments) {
      return;
    }

    if (activeSection.fragments.length === 0) {
      return;
    }

    if (lists.length === 0) {
      return;
    }

    changeActive({
      trigger: 'quick session context, detect sections fragment',
      list_id: activeSection.fragments[0],
    });
  }, [
    lists,
    current.auth,
    current.quick_session,
    activeSection.section,
    activeSection.fragments,
  ]);

  /** refresh the list (which will populate msDict if necessary) whenever machine changes */
  useEffect(() => {
    if (!active) {
      return;
    }

    changeActive({
      trigger: 'machine context changed',
      list_id: active._id,
    });
  }, [machine.machineID, machine.ball_type]);

  const mode = effectiveTrainingMode();

  return (
    <QuickSessionContext.Provider value={state}>
      {props.children}

      {dialogReset && aggReady && managePitches.length > 0 && (
        <ResetTrainingDialog
          key={dialogReset}
          pitches={managePitches}
          onClose={async () => {
            setDialogReset(undefined);

            if (!managePitches || managePitches.length === 0) {
              return;
            }

            await updatePitches({
              pitches: managePitches,
              includeHitterPresent: false,
              includeLowConfidence: true,
            });
          }}
        />
      )}

      {dialogTraining &&
        aggReady &&
        managePitches.length > 0 &&
        (mode === TrainingMode.Manual ? (
          <TrainingDialogHoC
            key={dialogTraining}
            identifier="QS-TrainingDialog"
            mode={mode}
            pitches={managePitches ?? []}
            threshold={machine.training_threshold}
            onClose={async () => {
              setDialogTraining(undefined);

              if (!managePitches || managePitches.length === 0) {
                return;
              }

              await updatePitches({
                pitches: managePitches,
                includeHitterPresent: false,
                includeLowConfidence: true,
              });
            }}
          />
        ) : (
          <PresetTrainingDialogHoC
            key={dialogTraining}
            identifier="QS-PT-TrainingDialog"
            mode={mode}
            pitches={managePitches}
            onClose={async () => {
              setDialogTraining(undefined);

              if (!managePitches || managePitches.length === 0) {
                return;
              }

              updatePitches({
                pitches: managePitches,
                includeHitterPresent: false,
                includeLowConfidence: true,
              });
            }}
          />
        ))}
    </QuickSessionContext.Provider>
  );
};
