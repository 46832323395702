import { Checkbox, Flex, Table } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ActionsHeaderCell } from 'components/common/table/actions-cells';
import { TableContext } from 'components/common/table/context';
import { TableHeaderText } from 'components/common/table/header-text';
import { CheckedContext } from 'contexts/layout/checked.context';
import { ACTIONS_KEY, CHECKBOX_KEY } from 'enums/tables';
import { IHeaderCell } from 'interfaces/tables/header';
import { useContext, useMemo } from 'react';

const CheckboxCell = (props: IHeaderCell) => {
  const classes = useMemo(
    () =>
      StringHelper.classNames([
        'width-40px',
        props.col.thClassNameFn?.(props.col),
      ]),
    [props.col]
  );

  const { tally, setChecked, resetChecked } = useContext(CheckedContext);

  const checked = useMemo(() => {
    switch (tally.mode) {
      case 'some': {
        return 'indeterminate';
      }

      case 'all': {
        return true;
      }

      default: {
        return false;
      }
    }
  }, [tally.mode]);

  return (
    <Table.ColumnHeaderCell
      className={classes}
      data-testid="col-checkbox"
      data-mode={tally.mode}
      align={props.col.align ?? 'center'}
    >
      <Flex align="center" style={{ height: '100%' }}>
        <Checkbox
          // redraw whenever the tally is updated
          key={tally.key}
          checked={checked}
          color={props.col.color}
          onCheckedChange={(value) => {
            const isAll = value !== false;

            if (isAll) {
              setChecked(props.checkAllIDs ?? [], true);
            } else {
              resetChecked();
            }

            props.afterCheckAll?.(isAll ? 'all' : 'none');
          }}
        />
      </Flex>
    </Table.ColumnHeaderCell>
  );
};

const UnsortableCell = (props: IHeaderCell) => {
  const classes = StringHelper.classNames([
    props.col.thClassNameFn?.(props.col),
  ]);

  return (
    <Table.ColumnHeaderCell
      title={props.col.labelTooltip}
      className={classes}
      align={props.col.align}
    >
      <TableHeaderText {...props} />
    </Table.ColumnHeaderCell>
  );
};

const BasicCell = (props: IHeaderCell) => {
  const classes = useMemo(
    () =>
      StringHelper.classNames([
        'cursor-pointer',
        props.col.thClassNameFn?.(props.col),
      ]),
    [props.col, props.col.thClassNameFn]
  );

  const tableCx = useContext(TableContext);

  return (
    <Table.ColumnHeaderCell
      title={props.col.labelTooltip}
      data-type={props.col.dataType}
      className={classes}
      align={props.col.align}
      onClick={() => {
        if (!props.enableSort) {
          return;
        }

        // repeated triggers will cycle through the different dirs automatically
        tableCx.setSort({ key: props.col.key });
      }}
    >
      <TableHeaderText {...props} />
    </Table.ColumnHeaderCell>
  );
};

export const CommonTableHeaderCell = (props: IHeaderCell) => {
  switch (props.col.key) {
    case CHECKBOX_KEY: {
      return <CheckboxCell {...props} />;
    }

    case ACTIONS_KEY: {
      return <ActionsHeaderCell {...props} />;
    }

    default: {
      if (!props.enableSort) {
        // sorting disabled for the whole table
        return <UnsortableCell {...props} />;
      }

      if (props.col.disableSort) {
        // sorting disabled for this column
        return <UnsortableCell {...props} />;
      }

      return <BasicCell {...props} />;
    }
  }
};
