export enum ErrorLevel {
  debug = 'debug',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export const ERROR_MSGS = {
  CONTACT_SUPPORT:
    'Please contact support via the Help Center and/or try again.',
};

export enum ServerErrorCategory {
  auth = 'auth',
  customerApi = 'customer-api',
  database = 'database',
  deprecation = 'deprecation',
  express = 'express',
  dataApi = 'data-api',
  hitter = 'hitter',
  hub = 'hub',
  integration = 'integration',
  job = 'job',
  machine = 'machine',
  machineModel = 'machineModel',
  matching = 'matching',
  other = 'other',
  pitch = 'pitch',
  pitchList = 'pitchList',
  s3 = 's3',
  safety = 'safety',
  session = 'session',
  statsApi = 'stats-api',
  user = 'user',
  video = 'video',
  ws = 'ws',
}
